import React from 'react';
import './styles.sass'

type Props = {
    checked: boolean,
    withIcon: boolean,
    handler: (value: string) => void,
    data: object,
    disabled: boolean
}


const ColumnsCheckboxItem: React.FC<Props> = ({data, withIcon, checked, handler, disabled}) => {
    return (
        <div onClick={() => {
            handler(data['columnId'])
        }} className={`columns_select_window_item` + (disabled ? ' disabled' : '')}>
            <div className={'desc_box '}>
                {/*{withIcon && <ViewWeekRoundedIcon/>}*/}
                {withIcon && <div className={'rows_icon'}/>}
                <div className={`checkbox_text ${checked ? 'active' : ''}`}>{data['showingText']}</div>
            </div>
            <div className={`checkbox_icon ${checked ? 'checked' : ''}`}/>
        </div>
    )
}

export default ColumnsCheckboxItem