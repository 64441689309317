import { takeEvery, call, put, all, takeLatest } from "redux-saga/effects";
import {
  types,
  uploadFileAction,
  deleteFileAction,
  removeProjectSectionAction,
  changeSectionNameAction,
  addSectionAction,
  deleteProjectAction,
} from "./action_types";
import { store } from "../../App";
import { fetchData } from "../../utils/fetchData";
import { cookieMaster } from "../../utils/CookieMaster";
import { State } from "../../rootReducer";
import { NewProjectState } from "./reducer";
import {
  setIsSending,
  clearProject,
  setProjectSections,
  setProjectFiles
} from "./actions";
import { setSections } from "../../common/actions";
import {
  selectProject,
  setAllProjects,
  setProjectAddMode,
  setProjectSectionAddMode, 
  updateProjectById,
  updateProjects
} from "../Projects/actions";
import { CreateNotif } from "../../utils/createNotification";

function* createProject() {
  yield put(setIsSending(true));
  const project: NewProjectState = store.getState().newProject;
  const currentId = store.getState().commonInfo.current_user;
  let company_id = localStorage.getItem("company_id");

  const priorities = store.getState().commonInfo.priorities;
  const body = {
    name: project.name,
    description: project.description,
    author_id: currentId,
    company_id: company_id !== null ? +company_id : company_id,
    curators: project.curators,
    cpe_list: project.cpe_list,
    priority_id: priorities[0].id,
    workflow_id: 1,
    head_of_departments: project.head_of_departments,
    head_of_work_groups: project.head_of_work_groups,
    spectators: project.spectators
  };
  let sectionsList = [...project.sections];
  if (project.files.length > 0) {
    body["files"] = project.files.map((file) => file.id);
  }

  let data = yield call(
    fetchData.post,
    "/api/v1/projects",
    JSON.stringify(body)
  );

  if (data) {
    let newSections = yield all([
      ...sectionsList.map((section) =>
        call(
          fetchData.post,
          `/api/v1/projectsections`,
          JSON.stringify({
            name: section.name,
            project_id: data.id,
          })
        )
      ),
    ]);
    
    const { sections } = store.getState().commonInfo;
    yield put(setSections([...sections, ...newSections]));

    yield put(updateProjects([data])); // Обновление проектов в сторе
    
    yield put(selectProject(data));
    yield put(setProjectAddMode(false));
    yield put(setProjectSectionAddMode(false));
    yield put(clearProject());
    
    CreateNotif("Проект успешно создан", "success");
  }

  yield put(setIsSending(false));
}

function* uploadFile({ file }: uploadFileAction) {
  const formData = new FormData();
  formData.append("file", file);

  const data = yield call(fetchData.post, "/api/v1/files", formData, {
    Authorization: cookieMaster.getCookie("access_token"),
  });

  if (data) {
    let state: State = store.getState();
    const files = state.newProject.files;
    yield put(setProjectFiles([...files, data]));
  }
}

function* deleteFile({ id }: deleteFileAction) {
  let data = yield call(fetchData.delete, `/api/v1/files/${id}`);
  if (data) {
    let state: State = store.getState();
    let files = state.newProject.files.slice(),
      index = files.findIndex((file) => file.id === id);

    files.splice(index, 1);
    yield put(setProjectFiles(files));
  }
}

function* removeProjectSection({ sectionId }: removeProjectSectionAction) {
  let result = yield call(
    fetchData.delete,
    `/api/v1/projectsections/${sectionId}`
  );
  if (result && result.success) {
    const project: NewProjectState = store.getState().newProject;
    yield put(
      setProjectSections(
        project.sections.filter((section) => section.id !== sectionId)
      )
    );
  }
}

function* changeSectionName({ sectionId, name }: changeSectionNameAction) {
  let section = yield call(
    fetchData.patch,
    `/api/v1/projectsections/${sectionId}`,
    JSON.stringify({ name })
  );
  if (section) {
    let sections = store.getState().commonInfo.sections,
      project: NewProjectState = store.getState().newProject,
      projectSections = project.sections.slice();

    yield put(
      setSections([
        ...sections.filter((section) => section.id !== sectionId),
        section,
      ])
    );

    let ind = projectSections.findIndex((section) => section.id === sectionId);
    if (ind !== -1) {
      projectSections.splice(ind, 1, { name: section.name, id: section.id });
      yield put(setProjectSections(projectSections));
    }
  }
}

function* updateProject() {
  yield put(setIsSending(true));
  const updateProject: NewProjectState = store.getState().newProject;
  const body = {
    company_id: localStorage.getItem("company_id"),
    cpe_list: updateProject.cpe_list,
    curators: updateProject.curators,
    description: updateProject.description,
    name: updateProject.name,
    priority_id: updateProject.priority_id,
    head_of_departments: updateProject.head_of_departments,
    head_of_work_groups: updateProject.head_of_work_groups,
    spectators: updateProject.spectators
  };

  // Добавление файлов по примеру функции createProject()
  if (updateProject.files.length > 0) {
    body["files"] = updateProject.files.map((file) => file.id);
  }
  if (updateProject.files.length > 0) {
    body["files"] = updateProject.files.map((file) => file.id);
    const project = yield call(
      fetchData.patch,
      `/api/v1/projects/${updateProject.id}`,
      JSON.stringify(body)
    );
  }

  const project = yield call(
    fetchData.patch,
    `/api/v1/projects/${updateProject.id}`,
    JSON.stringify(body)
  );
  
  if (project) {
    yield put(selectProject(project));
    
    yield put(updateProjectById(project)); // Обновление проекта в сторе
    
    yield put(setProjectAddMode(false));
    yield put(setProjectSectionAddMode(false));
    yield put(clearProject());
    CreateNotif("Изменения успешно сохранены", "success");
  }
  yield put(setIsSending(false));
}

function* addSection({ projectId, name }: addSectionAction) {
  const section = yield call(
    fetchData.post,
    `/api/v1/projectsections`,
    JSON.stringify({ project_id: projectId, name })
  );
  if (section) {
    let sections = store.getState().commonInfo.sections,
      project: NewProjectState = store.getState().newProject,
      projectSections = project.sections.slice();
    yield put(setSections([...sections, section]));
    yield put(
      setProjectSections([
        ...projectSections,
        { name: section.name, id: section.id },
      ])
    );
  }
}

function* deleteProject({ projectId }: deleteProjectAction) {
  yield put(setIsSending(true));
  const deleteResponse = yield call(
    fetchData.delete,
    `/api/v1/projects/${projectId}`
  );

  console.log(deleteResponse);

  if (deleteResponse?.success) {
    const { projectsAll } = store.getState().projectsPage;

    yield put(setAllProjects([...projectsAll]));
    yield put(selectProject(projectsAll[0]));
    yield put(clearProject());
    yield put(setProjectAddMode(false));

    CreateNotif("Проект удален", "success");
  }
  yield put(setIsSending(false));
}

export function* watchCreateProject() {
  yield takeEvery(types.CREATE_PROJECT, createProject);
}

export function* watchUploadFile() {
  yield takeEvery(types.UPLOAD_FILE, uploadFile);
}

export function* watchDeleteFile() {
  yield takeEvery(types.DELETE_FILE, deleteFile);
}

export function* watchRemoveProjectSection() {
  yield takeEvery(types.REMOVE_PROJECT_SECTION, removeProjectSection);
}

export function* watchChangeSectionName() {
  yield takeEvery(types.CHANGE_SECTION_NAME, changeSectionName);
}

export function* watchUpdateProject() {
  yield takeLatest(types.UPDATE_PROJECT, updateProject);
}

export function* watchAddProjectSection() {
  yield takeEvery(types.ADD_SECTION, addSection);
}

export function* watchDeleteProject() {
  yield takeEvery(types.DELETE_PROJECT, deleteProject);
}
