// таблица задач в проекте

import './ProjectTasksTable.scss';

import React, { useEffect } from "react";
import ProjectTasksTableContent from "../ProjectTasksTableContent/ProjectTasksTableContent";
import { State } from "../../../../../../rootReducer";
import { connect } from "react-redux";
import { Task } from "../../../../../../common/types";
import { useParams } from "react-router-dom";
import { changeTaskList, fetchTasks } from "../../../../actions";
import styled from "styled-components";
import PreloaderLocal from "../../../../../../common/components/PreloaderLocal/PreloaderLocal";

type Props = {
  isFetchingTasks: boolean;
  tasks: Task[];
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (id: number, pageNum, abortController?: AbortController) => void;
};

const WrapperTasksTable = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #f4f4f4;

  ${({ isFetchingTasks }) =>
    isFetchingTasks &&
    `
&:before {
      content: '';
      background: #fff;
      z-index: 3;
      width:100%;
      height: 100%;
      position:absolute;
      top:-5px;
      right:0;
}
    &:after {
      content: '';
      border-radius: 50%;
      border: 3px solid #1BAAF0;
      z-index: 3;
      border-bottom-color: #fff;
      border-left-color: #fff;
      animation: spin .5s linear infinite;
      transform: translateX(-50%);
      position:absolute;
      top:250px;
      left:50%;
      height:16px;
      width:16px;
    }
`}
`;
const ProjectTasksTable: React.FC<Props> = ({
  isFetchingTasks,
  tasks,
  changeTaskList,
  fetchTasks,
}) => {
  const abortController = new AbortController();
  const { id } = useParams();
  
  useEffect(() => {
    changeTaskList([]);
  }, [id, changeTaskList]);

  useEffect(() => {
    fetchTasks(id, 1, abortController);
  }, [fetchTasks, id]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <WrapperTasksTable className='project-tasks-table'>
      <div className={
        'project-tasks-table__preloader-wr' +
        (isFetchingTasks ? ' project-tasks-table__preloader-wr--show' : '')
      }>
        <PreloaderLocal/>
      </div>

      <div className={
        "project-tasks-table__content" +
        (tasks.length ? ' project-tasks-table__content--show' : '')
      }>
        <ProjectTasksTableContent />
      </div>

      <div className={
        "project-tasks-table__default-message" +
        ((!isFetchingTasks && !tasks.length) ? ' project-tasks-table__default-message--show' : '')
      }>
        Задачи отсутствуют...
      </div>
    </WrapperTasksTable>
  );
};

const mapStateToProps = ( state: State ) => ({
  isFetchingTasks: state.projectsPage.isFetchingTasks,
  tasks: state.projectsPage.tasks
});

const mapDispatchToProps = {
  changeTaskList,
  fetchTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTasksTable);
