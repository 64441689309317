import React from "react";

const Bp: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 26.591C10.433 26.591 12 25.0477 12 23.144C12 21.2403 10.433 19.697 8.5 19.697C6.567 19.697 5 21.2403 5 23.144C5 25.0477 6.567 26.591 8.5 26.591Z"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 11.8181C10.433 11.8181 12 10.2749 12 8.37116C12 6.46746 10.433 4.92419 8.5 4.92419C6.567 4.92419 5 6.46746 5 8.37116C5 10.2749 6.567 11.8181 8.5 11.8181Z"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 11.8182V19.697"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5003 26.591C25.4333 26.591 27.0003 25.0477 27.0003 23.144C27.0003 21.2403 25.4333 19.697 23.5003 19.697C21.5673 19.697 20.0003 21.2403 20.0003 23.144C20.0003 25.0477 21.5673 26.591 23.5003 26.591Z"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5003 19.6969L23.5001 14.7581C23.5 13.191 22.8679 11.6881 21.7427 10.5799L17 5.90906"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 10.8333V5.90906H22"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bp;
