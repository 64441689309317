import { Item } from "../types";

export const generateTimezoneTitle = (timezones: Item[]): Item[] => {
  return timezones.map(el => ({
    text: `(UTC+${el.value}:00) ${el.text}`,
    value: el.value
  }));
};

export const getSelectedTimezone = (timezones: Item[], selectedTimezone: number|string): Item => {
  return generateTimezoneTitle(timezones.filter(el => el.value === selectedTimezone))[0];
};
