// Выбор сотрудника, по которому фильтруются задачи или проекта

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { State } from "../../../../rootReducer";
import { connect } from "react-redux";
import { User, Project, Role, Item } from "../../../types";
import {
  ActualToggleAction,
  setLoadingTasks,
} from "../../../../pages/Tasks/actions";
import {
  selectProject,
  setIsFetchingTasks,
} from "../../../../pages/Projects/actions";
import { CreateNotif } from "../../../../utils/createNotification";
import MultySelect from "../MultySelect";
import MultySelectUserFromTasks from "../MultySelectUserFromTasks/MultySelectUserFromTasks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { setFilters, ShowFiltersWindow } from "../../../actions";
import { useContext } from "react";
import { useStateContext } from "../../../../useStateContext";
import PreloaderLocal from "../../PreloaderLocal/PreloaderLocal";

import './UserProjectSelect.scss';

type Props = {
  ShowFiltersWindow: () => void;
  current_user: number;
  changeProject?:  boolean;
  setChangeProject?: (boolean) => void;
  type: string;
  users: User[];
  projects: Project[];
  roles: Role[];
  itemsList: Item[];
  attached_items?: Item[];
  exec_auth: string;
  withImage?: boolean;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  current_set: any;
  selectProject: (project: Project) => void;
  wasFetchedSlowRequests: boolean
};

const WrapperSelect = styled.div`
  display: flex;
  height: 100%;
`;

const UserProjectSelect: React.FC<Props> = ({
  ShowFiltersWindow,
  current_user,
  changeProject,
  setChangeProject,
  type,
  users,
  projects,
  roles,
  itemsList,
  attached_items,
  exec_auth,
  withImage,
  setFilters,
  current_set,
  selectProject,
  wasFetchedSlowRequests
}) => {
  const {
    allEmployee, 
    setAllEmployee // Выбранны или нет все сотрудники в фильтре
  } = useContext(useStateContext);

  const [name, setName] = useState("");
  const [selectedItemImage, setSelectedItemImage] = useState("");

  const [subtitle, setSubtitle] = useState("");
  const [open, setOpen] = useState(false);
  const [severalUsersSelected, setSeveralUsersSelected] = useState(false);

  const idFromUrl = useParams();
  const id = +idFromUrl["id"];

  const select = useRef<HTMLDivElement>(null);
  let history = useHistory();
  const [TogglerPos, setTogglerPos] = useState<string>("");

  const outsideClick = useCallback((e: Event) => {
    if (!select.current?.contains(e.target as HTMLElement)) setOpen(false);
  }, []);
  
  useEffect(() => {   
    setAllEmployee(false);
  }, []);
  
  useEffect(() => {
    document.addEventListener("click", outsideClick);
    return () => document.removeEventListener("click", outsideClick);
  }, [outsideClick]);

  useEffect(() => {
    if (type === "tasks" && users.length > 0) {
      let current = users.find((user) => user.id === +id); // текущий пользователь, взятый из get params

      if (current) {
        // если авторов или исполнителей больше 1го
        if (
          current_set.authors.length > 1 ||
          current_set.executors.length > 1 ||
          current_set.executors.length + current_set.authors.length > 1
        ) {
          setAllEmployee(false);
          setSeveralUsersSelected(true);
          let full_phrase = "";
          if (current_set.executors.length) {
            full_phrase += "Исполнитель (" + current_set.executors.length + ")";
          }
          if (
            (full_phrase !== "" && current_set.authors.length) ||
            current_set.authors.length > 1
          ) {
            if (full_phrase !== "") full_phrase += ",";
            full_phrase += "Автор (" + current_set.authors.length + ")";
          }
          setName(full_phrase);
        } else {
          let only_one_usr =
            current_set.executors.length + current_set.authors.length;
          if (only_one_usr === 1) {
            setAllEmployee(false);
            setSelectedItemImage(current.image?.url as string);
            setName(`${current.surname} ${current.name}`);
            setSeveralUsersSelected(false);
          } else {
            setAllEmployee(true);
            setName(`Все сотрудники`);
            setSeveralUsersSelected(true);
          }
        }
      } else {
        CreateNotif("Пользователь удален, либо еще не создан");
        setName("Выбрать другого пользователя");
      }
    }
  }, [id, projects, roles, type, users, current_set]);
  
  useEffect(() => {
    if (type === "projects") {
      let current = projects.find((project) => project.id === +id);

      if (current) {
        setName(current.name);
        setSubtitle(current.description);
      } else {
        setName("Выбрать другой проект");
      }
    }
  }, [id, projects, type]);

  useEffect(() => {
    if (type === "calendar" && users.length > 0) {
      const current = users.find((user) => user.id === +id); // текущий пользователь, взятый из get params
      if (current) {
        setSelectedItemImage(current.image?.url as string);
        setName(`${current.surname} ${current.name}`);
        setSeveralUsersSelected(false);
      }
    }
  }, [id, type, users, changeProject]);

  useEffect(() => {
    setTogglerPos(exec_auth);
  }, [exec_auth]);

  return (
    <WrapperSelect
      ref={select}
      className="user-project-select MultySelectUserFromTasks"
    >
      <div className={"user-project-select__preloader" +
                    (!wasFetchedSlowRequests ? ' user-project-select__preloader--show' : '')
      }>
        <PreloaderLocal/>
      </div>
      
    {type === "tasks" && (
      <MultySelectUserFromTasks      
        ShowFiltersWindow={ShowFiltersWindow}
        setFilters={setFilters}
        current_user={current_user}
        setName={setName}
        setSeveralUsersSelected={setSeveralUsersSelected}
        changeProject = {changeProject}
        radio={true}
        withImage={withImage}
        selectedItemImage={selectedItemImage}
        showAvatar={
          type === "tasks" || (type === "calendar" && !severalUsersSelected)
        }
        IconName={severalUsersSelected ? "peoples" : undefined}
        selectedItems={[]}
        length_block={260 as number}
        current_value={name}
        attached_items={attached_items}
        itemsList={itemsList}
        addHandler={(arg) => {
          if (type === "tasks") {
            if (
              current_set.authors.length + current_set.executors.length ===
              1
            ) {
              if (current_set.authors.length) {
                setFilters(undefined, undefined, undefined, [arg]);
              } else {
                setFilters(undefined, undefined, [arg]);
              }
            } else {
              setFilters("", "", [arg], [], [2, 3, 10, 12, 14]);
            }
          } // Нового исполнителя в фильтры мы назначаем только если выбор произошел в разделе таскс

          history.replace(`${arg}`);
        }}
        removeHandler={() => {}}
      /> )}
     
     {type !== "tasks" && (
      <MultySelect
        changeProject = {changeProject}
        radio={true}
        withImage={withImage}
        selectedItemImage={selectedItemImage}
        showAvatar={
          type === "tasks" || (type === "calendar" && !severalUsersSelected)
        }
        IconName={severalUsersSelected ? "peoples" : undefined}
        selectedItems={[]}
        length_block={260 as number}
        current_value={name}
        attached_items={attached_items}
        itemsList={itemsList}
        addHandler={(arg) => {
          if (type === "tasks") {
            if (
              current_set.authors.length + current_set.executors.length ===
              1
            ) {
              if (current_set.authors.length) {
                setFilters(undefined, undefined, undefined, [arg]);
              } else {
                setFilters(undefined, undefined, [arg]);
              }
            } else {
              setFilters("", "", [arg], [], [2, 3, 10, 12, 14]);
            }
          } // Нового исполнителя в фильтры мы назначаем только если выбор произошел в разделе таскс

          if (type === "projects") {
            let found_project = projects.find((project) => project.id === arg);
            if (found_project) {selectProject(found_project);setChangeProject &&  setChangeProject(prev => !prev)}  //*************** */
          }

          history.replace(`${arg}`);
        }}
        removeHandler={() => {}}
      /> )}
    </WrapperSelect>
  );
};

const mapStateToProps = (state: State) => {
  return {
    default_templates: state.commonInfo.filters.default_templates,
    exec_auth: state.tasksPage.filters.exec_auth,
    users: state.commonInfo.users,
    projects: state.projectsPage.projectsAll,
    roles: state.commonInfo.roles,
    current_set: state.commonInfo.filters.current_set,
    current_user: state.commonInfo.current_user,
    wasFetchedSlowRequests: state.commonInfo.wasFetchedSlowRequests
  };
};

const mapDispatchToProsp = {
  ShowFiltersWindow,
  setLoadingTasks,
  setIsFetchingTasks,
  ActualToggleAction,
  setFilters,
  selectProject,
};

export default connect(mapStateToProps, mapDispatchToProsp)(UserProjectSelect);
