import {Action} from 'redux';
import {
  Position,
  Department,
  Role,
  Status,
  User,
  Priority,
  Workgroup,
  Section,
  Notification,
  Schedule, 
  IMeta,
} from './types';
import { TProjectForSelect, TSelectOption } from "./interfaces/select";

export enum types {
    FETCH_COMMON_INFO = '@@COMMON/FETCH_COMMON_INFO',
    ADD_COMMON_INFO_FAST = '@@COMMON/ADD_COMMON_INFO_FAST',
    ADD_COMMON_INFO_SLOW = '@@COMMON/ADD_COMMON_INFO_SLOW',
    ENQUEUE_SNACKBAR = '@@COMMON/ENQUEUE_SNACKBAR',
    CLOSE_SNACKBAR = '@@COMMON/CLOSE_SNACKBAR',
    REMOVE_SNACKBAR = '@@COMMON/REMOVE_SNACKBAR',
    SET_USERS = '@@COMMON/SET_USERS',
    CHANGE_WORK_SCHEDULE = '@@COMMON/CHANGE_WORK_SCHEDULE',
    SET_DEPARTMENTS = '@@COMMON/SET_DEPARTMENTS',
    SET_WORKGROUPS = '@@COMMON/SET_WORKGROUPS',
    SET_SECTIONS = '@@COMMON/SET_SECTIONS',
    SET_POSITIONS = '@@COMMON/SET_POSITIONS',
    UPDATE_POSITIONS = '@@COMMON/UPDATE_POSITIONS',
    GET_POSITIONS_FROM_SERVER = '@@COMMON/GET_POSITIONS_FROM_SERVER',
    UPDATE_TASK = '@@COMMON/UPDATE_TASK',
    SET_VERIFYED = '@@COMMON/SET_VERIFYED',
    SET_SEARCH_MEETINGS = '@@COMMON/SET_SEARCH_MEETINGS',
    GET_SEARCH_PROJECTS = '@@COMMON/GET_SEARCH_PROJECTS',
    SET_SEARCH_PROJECTS = '@@COMMON/SET_SEARCH_PROJECTS',
    FETCH_EXISTING_USER_DATA = '@@COMMON/FETCH_EXISTING_USER_DATA',
    SET_SHOW_NOTIF_WINDOW = '@@COMMON/SET_SHOW_NOTIF_WINDOW',
    SET_SHOW_COMPANY_WINDOW = '@@COMMON/SET_SHOW_COMPANY_WINDOW',
    SET_CURRENT_USER_INFO = '@@COMMON/SET_CURRENT_USER_INFO',
    SET_NOTIFICATION_READ = '@@COMMON/SET_NOTIFICATION_READ',
    SET_REMOTE_NOTIFICATIONS = '@@COMMON/SET_REMOTE_NOTIFICATIONS',
    SET_LOAD_NEXTPAGE_NOTIFICATIONS = '@@COMMON/SET_LOAD_NEXTPAGE_NOTIFICATIONS',
    CLEAR_NOTIFICATIONS = '@@COMMON/CLEAR_NOTIFICATIONS',
    SET_SEARCH_CLEAR = '@@COMMON/SET_SEARCH_CLEAR',
    UPDATE_TIMESTAMP = '@@COMMON/UPDATE_TIMESTAMP',
    UPDATE_NEW_NOTOFICATION = '@@COMMON/UPDATE_NEW_NOTOFICATION',
    SET_FILTERS = '@@COMMON/SET_FILTERS',
    SET_FILTER_TEMPLATES = '@@COMMON/SET_FILTER_TEMPLATES',
    SET_DEFAULT_FILTER_TEMPLATES = '@@COMMON/SET_DEFAULT_FILTER_TEMPLATES',
    SHOW_FILTERS_WINDOW = '@@COMMON/SHOW_FILTERS_WINDOW',
    SET_ANIMATE_SHOW_COMPANY_WINDOW = '@@COMMON/SET_ANIMATE_SHOW_COMPANY_WINDOW',
    SET_ADDED_MEETING = '@@SET_ADDED_MEETING',
    SET_CREATED_TASK_INFO = "@@COMMON/SET_CREATED_TASK_INFO",
    SET_ID_ACTIVE_TASK = "@@COMMON/SET_ID_ACTIVE_TASK",
    SET_LOADING_PROJECTS = '@@COMMON/SET_LOADING_PROJECTS',
    GET_PROJECTS = '@@COMMON/GET_PROJECTS',
    GET_ONE_PROJECT = '@@COMMON/GET_ONE_PROJECT',
    UPDATE_PROJECT_BY_ID = '@@COMMON/UPDATE_PROJECT_BY_ID',

    GET_PROJECT_FOR_SELECTOR = "@@COMMON/GET_PROJECT_FOR_SELECTOR",
    SET_PROJECT_FOR_SELECTOR = "@@COMMON/SET_PROJECT_FOR_SELECTOR",
    SET_PROJECT_FOR_SELECTOR_LOADING = "@@COMMON/SET_PROJECT_FOR_SELECTOR_LOADING",
    SET_PROJECT_FOR_SELECTOR_META = "@@COMMON/SET_PROJECT_FOR_SELECTOR_META",
    SET_SELECTED_PROJECT = "@@COMMON/SET_SELECTED_PROJECT",
    SET_PROJECT_FOR_SELECTOR_CURRENT_PAGE = "@@COMMON/SET_PROJECT_FOR_SELECTOR_CURRENT_PAGE",
    SEARCH_PROJECT_FOR_SELECTOR = "@@COMMON/SEARCH_PROJECT_FOR_SELECTOR"
}

export interface fetchCommonInfoAction
    extends Action<types.FETCH_COMMON_INFO> {
}

export interface changeWorkScheduleAction
    extends Action<types.CHANGE_WORK_SCHEDULE> {
    schedule: Schedule[];
}

export interface addCommonInfoFastAction extends Action<types.ADD_COMMON_INFO_FAST> {
  priorities: Priority[];
  statuses: Status[];
  roles: Role[];
  workgroups: Workgroup[];
  wasFetchedFastRequests: boolean;
}

export interface addCommonInfoSlowAction extends Action<types.ADD_COMMON_INFO_SLOW> {
  users: User[];
  positions: Position[];
  departments: Department[];
  sections: Section[];
  unread_notifications: Notification[];
  read_notifications: Notification[];
  load_next: string|[];
  end: boolean;
  company: any;
  wasFetchedSlowRequests: boolean;
}

export interface enqueueSnackbarAction extends Action<types.ENQUEUE_SNACKBAR> {
    notification: any;
}

export interface setCreatedTaskInfoAction extends Action<types.SET_CREATED_TASK_INFO> {
    task: any;
}
  
export interface setAddedMeetingAction extends Action<types.SET_ADDED_MEETING> {
    meeting: any;
}

export interface closeSnackbarAction extends Action<types.CLOSE_SNACKBAR> {
    key: number;
}

export interface removeSnackbarAction extends Action<types.REMOVE_SNACKBAR> {
    key: number;
}

export interface setUsersAction extends Action<types.SET_USERS> {
    users: User[];
}

export interface setSearchMeetingsAction extends Action<types.SET_SEARCH_MEETINGS>{
    search: string | null
}

export interface getSearchProjectsAction extends Action<types.GET_SEARCH_PROJECTS>{
    search: string | null
}

export interface setSearchProjectsAction extends Action<types.SET_SEARCH_PROJECTS>{
    search: string | null
}

export interface setDepartmentsAction extends Action<types.SET_DEPARTMENTS> {
    departments: Department[];
}

export interface setWorkgroupsAction extends Action<types.SET_WORKGROUPS> {
    workgroups: Workgroup[];
}

export interface setSectionsAction extends Action<types.SET_SECTIONS> {
    sections: Section[];
}

export interface setPositionsAction extends Action<types.SET_POSITIONS> {
    positions: Position[];
}

export interface updatePositionsAction extends Action<types.UPDATE_POSITIONS> {
  positions: Position[];
}

export interface getPositionsFromServerAction extends Action<types.GET_POSITIONS_FROM_SERVER> {
  positionIds: number[];
}

export interface updateTaskAction extends Action<types.UPDATE_TASK> {
    taskId: string|number;
    params: any;
    withNotif: boolean;
}

export interface setVerifiedAction extends Action<types.SET_VERIFYED> {
    verified: boolean;
}

export interface fetchExistingUserDataAction
    extends Action<types.FETCH_EXISTING_USER_DATA> {
    id: number;
}

export interface setCurrentUserInfoAction
    extends Action<types.SET_CURRENT_USER_INFO> {
    user: User;
}

export interface setShowNotifWindow
    extends Action<types.SET_SHOW_NOTIF_WINDOW> {
    val: boolean;
}

export interface setShowAnimateCompanyWindow
    extends Action<types.SET_ANIMATE_SHOW_COMPANY_WINDOW> {
    value: boolean;
}

export interface setShowCompanyWindow
    extends Action<types.SET_SHOW_COMPANY_WINDOW> {
    value: boolean;
}

export interface setMarkNotificationRead
    extends Action<types.SET_NOTIFICATION_READ> {
    id_notification: string;
    id_current_user: number;
    readonly?: boolean;
}

export interface setChangeRemoteNotifs
    extends Action<types.SET_REMOTE_NOTIFICATIONS> {
    unread: object;
    read: object;
    load_next?: string;
    end?: boolean;
    offset?: number;
}

export interface setLoadNextPageNotifications
    extends Action<types.SET_LOAD_NEXTPAGE_NOTIFICATIONS> {
    context: string;
}

export interface clearNotificationsAction
    extends Action<types.CLEAR_NOTIFICATIONS> {
}

export interface updateTimestampAction extends Action<types.UPDATE_TIMESTAMP> {
    timestamp: string;
}


export interface setFiltersAction extends Action<types.SET_FILTERS> {
    date_from?: string,
    date_to?: string,
    executors?: [],
    authors?: [],
    statuses?: [],
    actual?: boolean
}


export interface filterTemplateAction extends Action<types.SET_FILTER_TEMPLATES> {
    data: {}
}

export interface defaultfilterTemplateAction extends Action<types.SET_DEFAULT_FILTER_TEMPLATES> {
    data: {}
}


export interface ShowFiltersWindowAction extends Action<types.SHOW_FILTERS_WINDOW> {

}

export interface updateNewNotificationAction
    extends Action<types.UPDATE_NEW_NOTOFICATION> {
    newNotifications: any[];
}

export interface setSearchClearAction
    extends Action<types.SET_SEARCH_CLEAR> {
    isSearchCleared: boolean;
    isNeedReload: boolean;
}

export interface setIdActiveTaskAction
    extends Action<types.SET_ID_ACTIVE_TASK> {
    idActiveTask: string|number;
}

export interface getOneProjectAction
  extends Action<types.GET_ONE_PROJECT> {
  id: number|string; 
}

export interface updateProjectByIdAction
  extends Action<types.UPDATE_PROJECT_BY_ID> {
  project: {
    id: number|string;
  };
}

export interface getProjectsAction extends Action<types.GET_PROJECTS> {
  pageNum: number;
}


export interface setLoadingProjectsAction extends Action<types.SET_LOADING_PROJECTS> {
  isLoadingProjects: boolean;
}

export interface getProjectsForSelectorAction extends Action<types.GET_PROJECT_FOR_SELECTOR> {
  params?: any,
  needTodo?: string
}

export interface setProjectsForSelectorAction extends Action<types.SET_PROJECT_FOR_SELECTOR> {
  projects: TProjectForSelect[]|null,
  needTodo?: string
}

export interface setProjectsForSelectorLoadingAction extends Action<types.SET_PROJECT_FOR_SELECTOR_LOADING> {
  isProjectsForSelectLoading: boolean
}

export interface setProjectsForSelectorMetaAction extends Action<types.SET_PROJECT_FOR_SELECTOR_META> {
  projectsForSelectMeta: IMeta|null
}

export interface setSelectedProjectAction extends Action<types.SET_SELECTED_PROJECT> {
  selectedProject: TSelectOption|null
}

export interface setProjectsForSelectorCurrentPageAction extends Action<types.SET_PROJECT_FOR_SELECTOR_CURRENT_PAGE> {
  currentPage: number
}

export interface searchProjectsForSelectorAction extends Action<types.SEARCH_PROJECT_FOR_SELECTOR> {
  search: string|null;
}

export type CommonAction =
    | addCommonInfoFastAction
    | addCommonInfoSlowAction
    | fetchCommonInfoAction
    | enqueueSnackbarAction
    | closeSnackbarAction
    | removeSnackbarAction
    | setUsersAction
    | setDepartmentsAction
    | setWorkgroupsAction
    | setSectionsAction
    | setPositionsAction
    | updatePositionsAction
    | getPositionsFromServerAction
    | updateTaskAction
    | setVerifiedAction
    | fetchExistingUserDataAction
    | setCurrentUserInfoAction
    | setShowNotifWindow
    | setMarkNotificationRead
    | setChangeRemoteNotifs
    | setLoadNextPageNotifications
    | changeWorkScheduleAction
    | clearNotificationsAction
    | updateTimestampAction
    | updateNewNotificationAction
    | setFiltersAction
    | filterTemplateAction
    | ShowFiltersWindowAction
    | defaultfilterTemplateAction
    | setShowAnimateCompanyWindow
    | setShowCompanyWindow
    | setAddedMeetingAction
    | setCreatedTaskInfoAction
    | setSearchMeetingsAction
    | getSearchProjectsAction
    | setSearchProjectsAction
    | setSearchClearAction
    | setIdActiveTaskAction
    | getOneProjectAction
    | updateProjectByIdAction
    | getProjectsAction
    | setLoadingProjectsAction

    | getProjectsForSelectorAction
    | setProjectsForSelectorAction
    | setProjectsForSelectorLoadingAction
    | setProjectsForSelectorMetaAction
    | setSelectedProjectAction
    | setProjectsForSelectorCurrentPageAction
    | searchProjectsForSelectorAction;
