import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import MultySelect from '../../../common/components/Selects/MultySelect';
import { setTasksObservers } from "../actions";
import { getTaskObservers, getTaskObserversData } from "../selectors";

type Props = {
  observersSelectData: any;
  setTasksObservers: (cpe: number[]) => void;
  observersIds: number[];
  disabled?: boolean;
  isShownTaskInfoPlate?: boolean;
};

const TasksObserversSelect: React.FC<Props> = ({
  observersSelectData,
  observersIds,
  setTasksObservers,
  disabled,
  isShownTaskInfoPlate
}) => 
{
  const [resetInput, setResetInput] = useState<boolean>(false);

  useEffect(() => {
    isShownTaskInfoPlate ? setResetInput(false): setResetInput(true);
  }, [isShownTaskInfoPlate]);
  
  return (
    <MultySelect
      resetInput={resetInput}
      disabled={disabled}
      itemsList={observersSelectData.list}
      selectedItems={observersSelectData.selected}
      isValid={observersSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setTasksObservers([...observersIds, value as number]);
      }}
      removeHandler={(value: number) => {
        setTasksObservers(observersIds.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    observersIds: getTaskObservers(state), // ид выбранных наблюдателей
    observersSelectData: getTaskObserversData(state), // Получить юзеров по роли - кто может быть наблюдателем
  };
};

const mapDispatchToProps = {
  setTasksObservers
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksObserversSelect);
