//   Дерево проектов

import { tableFilter2 } from "../../../ProjectActions";
import React, { useEffect, useState, useRef } from "react";
import { State } from "../../../../../../rootReducer";
import { connect } from "react-redux";
import { Task, Project, User } from "../../../../../../common/types";
import { changeTaskList, fetchTasks } from "../../../../actions";
import TreeTask from "../../../../../../common/components/TreeTasks/treeTask";
import styled from "styled-components";

type Props = {
  checked: boolean;
  isFetchingTasks: boolean;
  tasks: Task[];
  projects: Project[];
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (id: number, pageNum, abortController: AbortController) => void;
  activeColumns: string[];
  commonUsers: User[];
  positions: any;
  current_time_date: any;
  selectedProjectId: number | undefined;
  tableOrTreeToggler: string;
  priorities: any;
};

const WrapperTasksTree = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  ${({ isFetchingTasks }) =>
    isFetchingTasks &&
    `
&:before {
      content: '';
      background: #fff;
      z-index: 3;
      width:100%;
      height: 100%;
      position:absolute;
      top:-5px;
      right:0;
}
    &:after {
      content: '';
      border-radius: 50%;
      border: 3px solid #1BAAF0;
      z-index: 3;
      border-bottom-color: #fff;
      border-left-color: #fff;
      animation: spin .5s linear infinite;
      transform: translateX(-50%);
      position:absolute;
      top:250px;
      left:50%;
      height:16px;
      width:16px;
    }
`}
`;
const MainTreeTask = styled.div`
  position: relative;
  margin-bottom: 24px;
  display: flex;
`;

const HeaderItem = styled.div`
  min-width: 340px;
  height: 24px;
  margin-right: 40px;
  border-left: 2px solid #1baaf0;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  color: #02405e;
  display: flex;
  justify-content: space-between;
`;

const ChildHorizontalLine = styled.div`
  position: absolute;
  top: 17px;
  left: -20px;
  height: 1px;
  width: 20px;
  border-bottom: 2px solid #1baaf0;
`;

const ParentHorizontalLine = styled.div`
  position: absolute;
  top: 17px;
  left: 340px;
  height: 1px;
  width: 20px;
  border-bottom: 2px solid #1baaf0;
`;

const ProjectTree: React.FC<Props> = ({
  checked,
  tasks,
  commonUsers,
  positions,
  selectedProjectId,
  tableOrTreeToggler,
  fetchTasks,
  changeTaskList,
  isFetchingTasks,
  priorities,
}) => {
  const inputEl = useRef<HTMLDivElement>(null);
  
  const onButtonClick = () => {
    if (inputEl && inputEl.current) {
      inputEl.current.scrollIntoView();
      setTimeout(() => {
        localStorage.setItem("taskChoice", "false");
        localStorage.setItem("taskId", "0");
      }, 1000);
    }
  };

  useEffect(() => {
    onButtonClick();
  });
  
  let taskId = Number(localStorage.getItem("taskId"));
  
  useEffect(() => {
    return () => {
      localStorage.setItem("taskChoice", "false"); 
      localStorage.setItem("taskId", "0");
    };
  }, []);

  let filter = tableFilter2.toLowerCase();
  let tasksFilter: Task[] = [];

  // Фильтрация массива
  tasksFilter = tasks.filter((item) => {
    if (checked === true) {
      if (+item.status_id == 11) {
        return false;
      }
      if (+item.status_id == 12) {
        return false;
      }
    }
    let name = item.name.toLowerCase();
    return name.indexOf(filter) !== -1;
  });

  // функция сортировки массива задач по убыванию даты начала задачи
  function sortFunction(a, b) {
    var dateA = new Date(a.begin).getTime();
    var dateB = new Date(b.begin).getTime();
    return dateA < dateB ? 1 : -1;
  }

  // Отсортировать массив задач по убыванию даты начала задачи
  tasksFilter.sort(sortFunction);

  const abortController = new AbortController();
  const [maxLevelsCounter, setMaxLevelsCounter] = useState<number>(1);
  
  const BuildVerticalLines = () => {
    let lastChildLine = document.getElementsByClassName("boxForChilds");
    if (lastChildLine.length) {
      for (let i = 0; i < lastChildLine.length; i++) {
        let child_nodes = lastChildLine[i].childNodes;
        if (child_nodes.length) {
          let lastChild = child_nodes[child_nodes.length - 1] as HTMLElement;
          if (lastChild) {
            let verticalLine = document.createElement("div");
            verticalLine.setAttribute("class", "verticalToChild");
            verticalLine.setAttribute(
              "style",
              "position: absolute; top: 17px; left: 360px; width: 20px; border-left: 2px solid #1BAAF0; border-bottom:2px solid #1BAAF0; border-bottom-left-radius: 6px;"
            );
            verticalLine.style.height = lastChild.offsetTop + "px";
            lastChildLine[i].appendChild(verticalLine);
          }
        }
      }
    }
  };

  useEffect(() => {
    // строить вертикальные линии к последнему ребенку
    BuildVerticalLines();
  });

  useEffect(() => {
    changeTaskList([]);
    if (selectedProjectId)
      fetchTasks(selectedProjectId as number, 1, abortController);
  }, [selectedProjectId, tableOrTreeToggler]);

  useEffect(() => {
    if (tasksFilter.length) {
      let vertical = document.getElementsByClassName("verticalToChild"); // принудительно подтирает вертикальные полоски, потому что сами они не всегда подтираются.
      if (vertical.length) {
        while (vertical[0]) vertical[0].remove();
      }

      if (tasksFilter) {
        // определить максимальную глубину уровня по этому проекту
        let max_depth = 1;
        tasksFilter.forEach((task) => {
          let levels_depth = checkForChild(task, 1);
          if (max_depth < levels_depth) max_depth = levels_depth;
        });
        setMaxLevelsCounter(max_depth);
      }

      BuildVerticalLines();
    }
  }, [tasksFilter]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  const checkForChild = (tasksFilter, depth) => {
    if (tasksFilter.children) {
      depth++;
      tasksFilter.children.forEach((child) => {
        depth = checkForChild(child, depth);
      });
    }
    return depth;
  };

  const Header = ({}) => {
    let result;
    const levels_count = Array.from(
      { length: maxLevelsCounter },
      (_, i) => i + 1
    );
    result = levels_count.map((each) => {
      return (
        <HeaderItem key={each}>
          <div>Уровень {each}</div>
        </HeaderItem>
      );
    });
    return result;
  };

  const ChildTasks = ({ ArgTask: ArgTask }) => {
    let current_child = ArgTask.children; // объект с детьми от родительского таска
    let result;
    while (current_child) {
      // если дети есть
      result = Object.keys(current_child).map((keyTask) => {
        let foundUser,
          position,
          childTask = current_child[keyTask]; // собственно дочерний элемент
        if (commonUsers.length) {
          foundUser = commonUsers.find((userObj) => {
            if (userObj?.id === childTask["executor_id"]) return userObj;
          });
        }
        if (foundUser)
          position = positions.find(
            (each) => each.id === foundUser?.positions[0]
          );

        return (
          <>
            {childTask.id == taskId && <div ref={inputEl}></div>}

            <div
              key={keyTask}
              style={{
                display: "flex",
                marginLeft: "40px",
                position: "relative"
              }}
            >
              {(current_child.length - 1 === 0
                ? true
                : current_child.length - 1 !== parseInt(keyTask)) && (
                <ChildHorizontalLine />
              )}

              {/* карточка задачи */}
              <TreeTask
                key={keyTask}
                id={childTask.id}
                begin={childTask["begin"]}
                end={childTask["end"]}
                priority_slug={
                  priorities.find((el) => el.id === childTask["priority_id"])
                    ? priorities.find(
                        (el) => el.id === childTask["priority_id"]
                      ).slug
                    : null
                }
                name={childTask["name"]}
                img={foundUser?.image ? foundUser?.image?.url : null}
                task_load={Math.round(childTask["task_load"])}
                executor={
                  foundUser
                    ? foundUser["name"] + " " + foundUser["surname"]
                    : childTask["executor_id"].toString()
                }
                position={position ? position.name : "роль"}
                status={childTask.status_id}
                nesting={childTask.children ? childTask.children.length : null}
              />

              {childTask.children && <ParentHorizontalLine />}

              {childTask.children && (
                <div className={"boxForChilds"}>
                  <ChildTasks ArgTask={childTask} />
                </div>
              )}
            </div>
          </>
        );
      });
      current_child = current_child.children;
    }
    return result;
  };

  return (
    <WrapperTasksTree
      className='project-tree'
      isFetchingTasks={isFetchingTasks}
      style={{ overflowX: "scroll" }}
      id={"treeContent"}
    >
      <div
        className='project-tree__header'
        style={{ marginLeft: "40px", marginBottom: "16px", display: "flex" }}
      >
        <Header />
      </div>
      <div
        className='project-tree__items'
        style={{
          paddingLeft: "40px",
          height: "calc(100vh - 220px)",
        }}
      >
        {tasksFilter &&
          Object.keys(tasksFilter).map((keyTask) => {
            // взял id таска
            let position,
              foundUser,
              mainTask = tasksFilter[keyTask];
            if (commonUsers.length) {
              foundUser = commonUsers.find((userObj) => {
                if (userObj?.id === mainTask["executor_id"]) return userObj;
              });
            }
            if (foundUser)
              position = positions.find(
                (each) => each.id === foundUser?.positions[0]
              );
            
            return (
              <React.Fragment key={keyTask}>
                {mainTask.id == taskId && <div ref={inputEl}></div>}
                <MainTreeTask className='project-tree__item'>
                  {" "}
                  {/*// это каждый блок тасков*/}
                  <div>
                    <TreeTask
                      id={mainTask.id}
                      begin={mainTask["begin"]}
                      end={mainTask["end"]}
                      priority_slug={
                        priorities.find(
                          (el) => el.id === mainTask["priority_id"]
                        )
                          ? priorities.find(
                              (el) => el.id === mainTask["priority_id"]
                            ).slug
                          : null
                      }
                      name={mainTask["name"]}
                      img={foundUser?.image ? foundUser?.image?.url : null}
                      task_load={Math.round(mainTask["task_load"])}
                      executor={
                        foundUser
                          ? foundUser["name"] + " " + foundUser["surname"]
                          : mainTask["executor_id"].toString()
                      }
                      position={position ? position.name : "роль"}
                      status={mainTask.status_id}
                      nesting={
                        mainTask.children ? mainTask.children.length : null
                      }
                      bottom_margin={false}
                    />

                    {mainTask.children && <ParentHorizontalLine />}
                  </div>
                  {mainTask.children && (
                    <div className={"boxForChilds"} style={{ width: "100%" }}>
                      <ChildTasks ArgTask={mainTask} />
                    </div>
                  )}
                </MainTreeTask>
              </React.Fragment>
            );
          })}
      </div>
    </WrapperTasksTree>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeColumns: state.projectsPage.activeColumns,
    isFetchingTasks: state.projectsPage.isFetchingTasks,
    tasks: state.projectsPage.tasks,
    projects: state.projectsPage.projectsAll,
    commonUsers: state.commonInfo.users,
    positions: state.commonInfo.positions,
    current_time_date: state.taskInfoPlate.begin,
    selectedProjectId: state.projectsPage.selectedProject?.id,
    tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
    priorities: state.commonInfo.priorities,
  };
};

const mapDispatchToProps = {
  changeTaskList,
  fetchTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTree); // изменено
