import React, { useEffect, useState, useRef } from "react";
import TasksTableContent from "../TasksTableContent";
import { connect, useDispatch } from "react-redux";
import {
  changeTaskList,
  fetchTasks,
  getOneTask,
  setSelectedUserId,
} from "../../actions";
import { Task } from "../../../../common/types";
import { State } from "../../../../rootReducer";
import { useParams } from "react-router-dom";
import { selectTask, setShownTaskInfoPlate } from "../../../TaskInfoPlate/actions";
import { store } from "../../../../App";
import styled from "styled-components";
import { setFilters } from "../../../../common/actions";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";

import './TasksTable.scss';
import { getAllProjects } from "../../../Projects/actions";

type Props = {
  tasks: Task[];
  isLoading: boolean;
  currentId: number;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  setSelectedUserId: (id: number) => void;
  selectTask: (val: Task) => void;
  setShownTaskInfoPlate: (val: boolean) => void;
  getOneTask: (val: number) => void;
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  updateTask: boolean;
  setUpdateTask: (bool: boolean) => void;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
};

const WrapperTasksTable = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
`;

const TasksTable: React.FC<Props> = ({
  selectedTask,
  isShownTaskInfoPlate,
  getOneTask,
  selectTask,
  setShownTaskInfoPlate,
  tasks,
  isLoading,
  currentId,
  changeTaskList,
  fetchTasks,
  setSelectedUserId,
  updateTask,
  setUpdateTask,
  setFilters
}) => {

  const [specTask, setSpecTask] = useState<boolean>(true);
  const { id } = useParams();
  const dispatch = useDispatch();
  let plate2 = useRef<HTMLDivElement>(null);

  // здесь смотрю, есть ли в строке адреса решетка, то есть хэш. Если да, значит нужно открыть задачу с конкретным id. Если есть - показываю ее. Если нет - делаю на нее запрос, пригоняю, и сразу же, без добавления в tasks, показываю в боковой панели.
  if (!isShownTaskInfoPlate) {
    const hash = window.location.hash;
    if (hash) {
      let task_id, found_task;
      if (hash.substr(0, 9) === "#task_id=")
        task_id = parseInt(hash.substr(9, hash.length - 9));
      if (task_id && !selectedTask && tasks.length) {
        Object.keys(tasks).forEach((item) => {
          if (tasks[item].id === task_id) found_task = tasks[item];
        });

        if (found_task) {
          // найдена в уже приехавших тасках
          setTimeout(() => {
            if (store.getState().tasksPage.taskAddMode) return;
            selectTask(found_task);
            setShownTaskInfoPlate(true);
          }, 500);
        } else {
          if (specTask) {
            setTimeout(() => getOneTask(task_id), 0);
            setSpecTask(false);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (id) setSelectedUserId(parseInt(id));
    else setSelectedUserId(currentId);
  }, [currentId, id, setSelectedUserId]);

  useEffect(() => {
    changeTaskList([]);
  }, [id, changeTaskList]);
  
  useEffect(() => {
    setFilters("", "", [], [], [], false);
    fetchTasks(1);
    
    // TODO Opt это нужно удалить отсюда, данные о проекте должны приходит в ответе /tasks
    dispatch(getAllProjects());
  }, []);

  return (
    <WrapperTasksTable className="tasks-table plate2" ref={plate2}>
      <div className={'tasks-table__preloader-wr' +
        (isLoading ? ' tasks-table__preloader-wr--show' : '')
      }>
        <PreloaderLocal />
      </div>

      <div className={
        "tasks-table__content" +
        (tasks.length ? ' tasks-table__content--show' : '')
      }>
        <TasksTableContent
          updateTask={updateTask}
          setUpdateTask={setUpdateTask}
        />
      </div>
      
      <div className={
        "tasks-table__default-message" +
        ((!isLoading && !tasks.length) ? ' tasks-table__default-message--show' : '')
      }>
        Задачи отсутствуют...
      </div>
    </WrapperTasksTable>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    tasks: state.tasksPage.tasks,
    isLoading: state.tasksPage.isLoading,
    currentId: state.commonInfo.current_user
  };
};

const mapDispatchToProps = {
  setShownTaskInfoPlate,
  selectTask,
  changeTaskList,
  fetchTasks,
  setSelectedUserId,
  getOneTask,
  setFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTable);
