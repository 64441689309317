import {
  User,
  Role,
  Position,
  Department, 
  Section
} from "./types"
import { State } from "../rootReducer"
import { createSelector } from "reselect";
import { TProjectForSelect, TSelectOption } from "./interfaces/select";

export const getProjectsForSelect = 
  (state: State): TProjectForSelect[]|null => state.commonInfo.projectsForSelect.projects;
export const getSectionsForSelect =
  (state: State): Section[]|null => state.taskInfoPlate.defaultProject.sections;
export const getUsers = (state: State): User[] => state.commonInfo.users
export const getRoles = (state: State): Role[] => state.commonInfo.roles
export const getPositions = (state: State): Position[] => state.commonInfo.positions
export const getDeps = (state: State): Department[] => state.commonInfo.departments
export const getCurrentUserInfo = (state: State): User | null => state.commonInfo.currentUserInfo

export const getProjectsForSelectConverted = createSelector(
  [getProjectsForSelect],
  (projects): TSelectOption[]|null => {
    
    if(projects) {
      return projects.map(project => ({
        label: project.name,
        value: project.id.toString()
      }));
    }
    
    return null;
  }
);

export const getSectionsForSelectConverted = createSelector(
  [getSectionsForSelect],
  (sections): TSelectOption[]|null => {

    if(sections) {
      return sections.map(project => ({
        label: project.name,
        value: project.id.toString()
      }));
    }

    return null;
  }
);