import React from 'react';

const GoToBp = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.375 19.9432C7.82475 19.9432 9 18.7857 9 17.3579C9 15.9302 7.82475 14.7727 6.375 14.7727C4.92525 14.7727 3.75 15.9302 3.75 17.3579C3.75 18.7857 4.92525 19.9432 6.375 19.9432Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.375 8.86369C7.82475 8.86369 9 7.70625 9 6.27846C9 4.85068 7.82475 3.69324 6.375 3.69324C4.92525 3.69324 3.75 4.85068 3.75 6.27846C3.75 7.70625 4.92525 8.86369 6.375 8.86369Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.375 8.86365V14.7727" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6252 19.9432C19.075 19.9432 20.2502 18.7857 20.2502 17.3579C20.2502 15.9302 19.075 14.7727 17.6252 14.7727C16.1755 14.7727 15.0002 15.9302 15.0002 17.3579C15.0002 18.7857 16.1755 19.9432 17.6252 19.9432Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6252 14.7727L17.6251 11.0685C17.625 9.89318 17.1509 8.76601 16.3071 7.93492L12.75 4.43176" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.75 8.12494V4.43176H16.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default GoToBp;
