import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { connect } from "react-redux";
import { State } from "../../../../rootReducer";
import { deleteProject, setDeleteProjectModalOpen } from "../../actions";

import "./Modal.scss";
import {ReactComponent as CloseIcon} from '../../../../img/TrueIcons/close.svg';
import { deleteProjectStatuses } from "../../../../common/constants/constants";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { IDeleteProjectModal } from "../../action_types";

interface IDeleteProjectModalProps {
  deleteProject: (arg0: string | number) => void;
  deleteProjectModal: IDeleteProjectModal;
}

const DeleteProjectModal = ({
  deleteProject,
  deleteProjectModal,
}: IDeleteProjectModalProps) => {
  const textObj = {
    [deleteProjectStatuses.DELETE_PROJECT_TASK]: {
      title: "Удаление проекта",
      text: "В удаляемом проекте присутствуют:<br/>* Задачи со связями <br/> При продолжении удаления будут выполнены следующие действия:<br/>* Удалены связи у задач",
      btnDeleteText: "Удалить",
    },
    [deleteProjectStatuses.DELETE_PROJECT_BP]: {
      title: "Удаление проекта",
      text: "В удаляемом проекте присутствуют: <br/>* Бизнес процессы <br/>При продолжении удаления будут выполнены следующие действия:<br/>* Удалены бизнес процессы",
      btnDeleteText: "Удалить",
    },
    [deleteProjectStatuses.DELETE_PROJECT_TASK_AND_BP]: {
      title: "Удаление проекта",
      text: "В удаляемом проекте присутствуют:<br/>* Задачи со связями <br/>* Бизнес процессы<br/> При продолжении удаления будут выполнены следующие действия:<br/>* Удалены связи у задач<br/>* Удалены бизнес процессы",
      btnDeleteText: "Удалить"
    },
    [deleteProjectStatuses.DELETE_PROJECT_ERROR]: {
      title: "Удаление проекта",
      text: "Произошла ошибка при удалении проекта...",
    },
  };

  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<{
    title: string;
    text: string;
    btnDeleteText?: string;
  }>(textObj[deleteProjectStatuses.DELETE_PROJECT_ERROR]);

  useEffect(() => {
    if (deleteProjectModal.statusFromServer?.constraints) {
      const { constraints } = deleteProjectModal.statusFromServer;
      let result;
      
        for(let i = 0; i < constraints.length; i++){
            if(constraints[i] === i && constraints.length === 1){
                result = textObj[constraints[i]];
                }
                else if(constraints[i] === i && constraints.length === i){
                    result = textObj[constraints[i]];
        
                } else if(constraints[i] === i && constraints[i] === i){
                    result = textObj[3];
                }
        }

      if (result) setText(result);
      else setText(textObj[deleteProjectStatuses.DELETE_PROJECT_ERROR]);
    }

    setOpen(deleteProjectModal.isDeleteModalOpen);
  }, [deleteProjectModal]);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTaskHandler = () => {
    deleteProject(deleteProjectModal.projectId);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={"modal-project-delete"}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={"modal-task-delete__content-wr"}>
            <div className="modal-task-delete__content">
              <div className="modal-task-delete__content-item">
                <div className={"modal-task-delete__title-wr"}>
                  <div className="modal-task-delete__title">{text?.title}</div>
                  <div
                    className="modal-task-delete__delete-ico-wr"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div
                  className="modal-task-delete__text"
                  dangerouslySetInnerHTML={{ __html: text?.text }}
                ></div>
              </div>
            </div>

            <div className="modal-task-delete__btns">
              {text?.btnDeleteText && (
                <button
                  className={
                    "modal-task-delete__btn modal-task-delete__btn--delete " +
                    (deleteProjectModal.isShowPreloader
                      ? " modal-task-delete__btn--show-preloader"
                      : "")
                  }
                >
                  <span
                    className="modal-task-delete__btn-title"
                    onClick={deleteTaskHandler}
                  >
                    {text?.btnDeleteText}
                  </span>
                  <span className="modal-task-delete__btn-preloader">
                    <PreloaderLocal />
                  </span>
                </button>
              )}

              <button
                className="modal-task-delete__btn modal-task-delete__btn--cancel"
                onClick={handleClose}
              >
                <span className="modal-task-delete__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    deleteProjectModal: state.projectsPage.deleteProjectModal,
  };
};

const mapDispatchToProps = {
  setDeleteProjectModalOpen,
  deleteProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectModal);
