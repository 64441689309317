import './SectionSelect.scss';

import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { State } from "../../../../rootReducer";
import { TSelectOption } from "../../../../common/interfaces/select";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { getSectionsForSelectConverted } from "../../../../common/selectors";
import { SelectAsync } from "../../../../common/components/Selects/SelectAsync/SelectAsync";
import { setSelectedSection, setTaskSection } from "../../actions";
import { openExistingProject } from "../../../NewProject/actions";
import { setProjectSectionAddMode } from "../../../Projects/actions";
import { Project, User } from "../../../../common/types";
import { checkAddProjectRoles } from "../helpers";

const SectionSelect: FC<{
  onSelectChange: (value: number) => void,
  setSelectedSection: (section: TSelectOption|null) => void,
  openExistingProject: (project: Project) => void,
  setProjectSectionAddMode: (mode: boolean) => void,
  setTaskSection: (sectionId: number) => void,
  sectionsConverted: TSelectOption[]|null,
  defaultProject: Project|null,
  selectedSection: TSelectOption|null,
  isDefaultProjectSectionsLoading: boolean,
  currentUserInfo: User|null,
  disabled: boolean
}> = ({
  onSelectChange,
  setSelectedSection,
  openExistingProject,
  setProjectSectionAddMode,
  setTaskSection,
  sectionsConverted,
  defaultProject,
  selectedSection,
  isDefaultProjectSectionsLoading,
  currentUserInfo,
  disabled
}) => {

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string|null>(null);
  const [isUserCanAdd, setIsUserCanAdd] = useState<boolean>(false);

  const onMenuOpen = () => {
    setIsOpenMenu(true);
  };
  
  const onMenuClose = () => {
    setIsOpenMenu(false);
  };

  const onSelectChangeLocal = ( value: TSelectOption|null ) => {
    if(!value) setSearchValue('');
    
    value = value ?? { value: '', label: '' };
    
    if(value) {
      onSelectChange(+value.value);

      setTaskSection(+value.value);
      setSelectedSection(value);
    }
  };

  const onInputChange = ( value: string ) => {
    setSearchValue(value);
  };
  
  const onScrollToBottom = ( e: React.WheelEvent ) => {
   
  };
  
  const onAddSection = () => {
    if(defaultProject) {
      openExistingProject(defaultProject);
      setProjectSectionAddMode(true);
    }
  }

  useEffect(() => {
    if(currentUserInfo && defaultProject) {
      setIsUserCanAdd(checkAddProjectRoles(currentUserInfo));
    }
  }, [currentUserInfo]);

  return (
    <div className="section-select">
      <div 
        className={
          'section-select__preloader' +
          (isDefaultProjectSectionsLoading ? ' section-select__preloader--show' : '')
      }>
        <PreloaderLocal classPostfix="blue" />
      </div>
      
      <div className="section-select__select">
        <SelectAsync
          options={sectionsConverted ?? undefined}
          value={selectedSection}
          inputValue={searchValue ?? undefined}
          onMenuScrollToBottom={onScrollToBottom}
          onSelectChange={onSelectChangeLocal}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          placeholder="Выберите раздел..."
          disabled={disabled}
          rules={[{ required: true }]}
        />
      </div>

      {(!disabled && isUserCanAdd) && (
        <button
          className="section-select__add-btn"
          onClick={onAddSection}
        >
          + Создать новый раздел
        </button>
      )}
    </div>
  );
};

const mapStateToProps = ( state: State ) => {
  return {
    selectedSection: state.taskInfoPlate.defaultProject.selectedSection,
    sectionsConverted: getSectionsForSelectConverted(state),
    isDefaultProjectSectionsLoading: state.taskInfoPlate.defaultProject.isLoading,
    defaultProject: state.taskInfoPlate.defaultProject.project,
    currentUserInfo: state.commonInfo.currentUserInfo
  };
};

const mapDispatchToProps = {
  setSelectedSection,
  openExistingProject,
  setProjectSectionAddMode,
  setTaskSection
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionSelect);
