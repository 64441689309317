import {types} from './action_types';
import {takeEvery, call, put} from 'redux-saga/effects';
// import { changeTaskList, setIsFetchingTasks } from './actions';
import {store} from '../../App';
import {
    CollectAllFilters,
    sorting,
    table_filter,
} from '../../common/shared_data';
import {Item} from '../../common/types';
import {milisecondDateFromOurFormat} from '../TaskInfoPlate/reducer';
import {fetchData} from "../../utils/fetchData";
import {meetingPlateHidingSignal, setMeetingDefaultValues, setNewParametersToReducer, writeMeetings} from "./actions";
import {initialState} from "./reducer";

// export function* watchSetTableOrderProjects() {
//   yield takeEvery(types.SET_PROJECTS_TABLE_ORDER, apply_filters_orders);
// }

export function* watchFetchMeetings() {
    // @ts-ignore
    yield takeEvery(types.FETCH_MEETINGS, fetchMeetings);
}

export function* watchCreatMeeting() {
    yield takeEvery(types.CREATE_NEW_MEETING, create_new_meeting);
}

export function* watchEditMeeting() {
    yield takeEvery(types.EDIT_MEETING, edit_meeting);
}


export function* watchCreateChecklistItems() {
    yield takeEvery(types.CREATE_CHECKLIST_ITEMS, createChecklistItems);
}


export function* watchCreateResolutionItem() {
    // @ts-ignore
    yield takeEvery(types.CREATE_RESOLUTION_ITEM, CreateDeleteResolutionItem);
}


export function* watchPatchResolutionItem() {
    // @ts-ignore
    yield takeEvery(types.PATCH_RESOLUTION_ITEM, PatchResolutionItem);
}


function* PatchResolutionItem({context, id, body}) {
    const {
        selectedMeetingId,
        newRule,
        newTask,
        newIdea,
        meetingRules,
        meetingTasks,
        meetingIdeas
    } = store.getState().meetingsPage;

    switch (context) {
        case 'rule':
            const responseRule = yield call(
                fetchData.patch,
                `/api/v1/meetings/${selectedMeetingId}/meeting-rules/${id}`,
                JSON.stringify(body)
            );
            if (responseRule) {
                console.log('edit rule success ', responseRule)

                let copy_meetingRules = [...meetingRules]
                let index = meetingRules.findIndex(item => item['id'] === responseRule.id)
                // @ts-ignore
                copy_meetingRules.splice(index, 1, responseRule)

                yield put(setNewParametersToReducer({meetingRules: copy_meetingRules}))
            }
            break

        case 'task':
            const responseTask = yield call(
                fetchData.patch,
                `/api/v1/meetings/${selectedMeetingId}/meeting-tasks/${id}`,
                JSON.stringify(body)
            );
            if (responseTask) {
                console.log('edit task success ', responseTask)

                let copy_meetingTasks = [...meetingTasks]
                let index = meetingTasks.findIndex(item => item['id'] === responseTask.id)
                // @ts-ignore
                copy_meetingTasks.splice(index, 1, responseTask)

                yield put(setNewParametersToReducer({meetingTasks: copy_meetingTasks}))
            }
            break

        case 'idea':
            const responseIdea = yield call(
                fetchData.patch,
                `/api/v1/meetings/${selectedMeetingId}/meeting-ideas/${id}`,
                JSON.stringify(body)
            );
            if (responseIdea) {
                console.log('edit idea success ', responseIdea)

                let copy_meetingIdeas = [...meetingIdeas]
                let index = meetingIdeas.findIndex(item => item['id'] === responseIdea.id)
                // @ts-ignore
                copy_meetingIdeas.splice(index, 1, responseIdea)

                yield put(setNewParametersToReducer({meetingIdeas: copy_meetingIdeas}))
            }
            break
    }


}

function* CreateDeleteResolutionItem({context, delete_index}) {
    const {
        selectedMeetingId,
        newRule,
        newTask,
        newIdea,
        meetingRules,
        meetingTasks,
        meetingIdeas
    } = store.getState().meetingsPage;

    switch (context) {
        case 'rule':
            if (delete_index) {
                const response = yield call(
                    fetchData.delete,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-rules/${delete_index}`
                );
                if (response) {
                    console.log('delete rule success ', response)
                    let newArr = meetingRules.filter(rule => rule['id'] !== delete_index)
                    yield put(setNewParametersToReducer({meetingRules: [...newArr]}))
                }
            } else {
                const response = yield call(
                    fetchData.post,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-rules`,
                    JSON.stringify(newRule)
                );
                if (response) {
                    console.log('create rule success ', response)
                    yield put(setNewParametersToReducer({
                        meetingRules: [...meetingRules, response],
                        newRule: initialState.newRule
                    }))
                }
            }

            break
        case 'task':
            if (delete_index) {
                const response = yield call(
                    fetchData.delete,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-tasks/${delete_index}`
                );
                if (response) {
                    console.log('delete task success ', response)
                    let newArr = meetingTasks.filter(task => task['id'] !== delete_index)
                    yield put(setNewParametersToReducer({meetingTasks: [...newArr]}))
                }
            } else {
                const response = yield call(
                    fetchData.post,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-tasks`,
                    JSON.stringify(newTask)
                );
                if (response) {
                    console.log('create task success ', response)
                    yield put(setNewParametersToReducer({
                        meetingTasks: [...meetingTasks, response],
                        newTask: initialState.newTask
                    }))
                }
            }
            break

        case 'idea':
            if (delete_index) {
                const response = yield call(
                    fetchData.delete,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-ideas/${delete_index}`
                );
                if (response) {
                    console.log('delete idea success ', response)
                    let newArr = meetingIdeas.filter(idea => idea['id'] !== delete_index)
                    yield put(setNewParametersToReducer({meetingIdeas: [...newArr]}))
                }
            } else {
                console.log(newIdea)
                const response = yield call(
                    fetchData.post,
                    `/api/v1/meetings/${selectedMeetingId}/meeting-ideas`,
                    JSON.stringify(newIdea)
                );
                if (response) {
                    console.log('create idea success ', response)
                    yield put(setNewParametersToReducer({
                        meetingIdeas: [...meetingIdeas, response],
                        newIdea: initialState.newIdea
                    }))
                }
            }
            break
    }
}

function* createChecklistItems({}) {
    // yield put(setIsFetchingTasks(false));
    const {toCreateChecklistItems} = store.getState().meetingsPage;
    for (let i = 0; i < toCreateChecklistItems.length; i++) {
        yield call(
            fetchData.post,
            `/api/v1/meetings/12/agenda-items`,
            toCreateChecklistItems[i]
        );
    }
}

function* fetchMeetings({tableFilter}) {
    // const {current_set} = store.getState().commonInfo.filters;
    // yield put(setIsFetchingTasks(false));

    const response = yield call(
        fetchData.get,
        `/api/v1/meetings` + (tableFilter ? `?search=${tableFilter}` : '')
    );

    if (response) yield put(writeMeetings(response))
}

function* create_new_meeting() {
    const {name, description, projectId, chairman_id, secretary_id, participants, begin, author_id, meetings} =
        store.getState().meetingsPage;
    const {current_user} = store.getState().commonInfo

    if (name !== '' && begin.length) {
        const created_obj = {name, description, participants, begin}
        if (projectId) created_obj['projects'] = [projectId]
        if (chairman_id) created_obj['chairman_id'] = chairman_id
        if (secretary_id) created_obj['secretary_id'] = secretary_id
        if (!author_id) created_obj['author_id'] = current_user

        // console.log(created_obj)
        const response = yield call(
            fetchData.post,
            `/api/v1/meetings`,
            JSON.stringify(created_obj)
        );

        if (response) {
            // console.log(response)
            yield put(setNewParametersToReducer({
                isSendingData: false,
                showMeetingRightPlateAnimation: false,
                showRightPlate: false
            }))
            // yield put(meetingPlateHidingSignal(false))
            // yield put(setNewParametersToReducer({showRightPlate: false}))
            yield put(setMeetingDefaultValues())

            yield put(writeMeetings([...meetings, response]))
        }

    }
}


function* edit_meeting() {
    const {
        name,
        description,
        projectId,
        author_id,
        chairman_id,
        secretary_id,
        participants,
        begin,
        status,
        meetings,
        selectedMeetingId,
        changedChecklistItems,
        checkList,
        toCreateChecklistItems
    } = store.getState().meetingsPage;
    // const {current_user} = store.getState().commonInfo

    if (initialState.name !== name || initialState.description !== description || initialState.projectId !== projectId || initialState.author_id !== author_id || initialState.chairman_id !== chairman_id || initialState.secretary_id !== secretary_id || initialState.participants !== participants || initialState.begin !== begin || initialState.status !== status || initialState.changedChecklistItems !== changedChecklistItems || initialState.toCreateChecklistItems !== toCreateChecklistItems) {
        let objToSend = {}

        if (initialState.name !== name) objToSend['name'] = name
        if (initialState.description !== description) objToSend['description'] = description
        if (initialState.status !== status) objToSend['status'] = status
        if (initialState.projectId !== projectId) {
            if (meetings.find(item => item.id === selectedMeetingId)?.projects.length) objToSend['remove_projects'] = [meetings.find(item => item.id === selectedMeetingId)?.projects[0]]
            objToSend['projects'] = [projectId]
        }
        if (initialState.author_id !== author_id) objToSend['author_id'] = author_id
        if (initialState.chairman_id !== chairman_id) objToSend['chairman_id'] = chairman_id
        if (initialState.secretary_id !== secretary_id) objToSend['secretary_id'] = secretary_id
        if (initialState.participants !== participants) {
            let selected_meeting_participants = meetings.find(meeting => meeting.id === selectedMeetingId)?.participants

            let to_delete_arr: number[] = []
            if (selected_meeting_participants) {
                selected_meeting_participants.forEach(item => {
                    let found = false
                    participants.forEach(participant => {
                        if (participant === item) found = true
                    })
                    if (!found) to_delete_arr.push(item)
                    // console.log(participants, item)
                })
            }

            let to_add_arr: number[] = []
            if (participants) {
                participants.forEach(item => {
                    let found = false
                    if (selected_meeting_participants)
                        selected_meeting_participants.forEach(participant => {
                            if (participant === item) found = true
                        })
                    if (!found) to_add_arr.push(item)
                })
            }

            objToSend['remove_participants'] = to_delete_arr
            objToSend['participants'] = participants
        }
        if (initialState.begin !== begin) objToSend['begin'] = begin

        let obj_to_reducer = {...initialState}
        obj_to_reducer['meetings'] = [...meetings]

        if (Object.keys(objToSend).length) {
            const response = yield call(
                fetchData.patch,
                `/api/v1/meetings/${selectedMeetingId}`,
                JSON.stringify(objToSend)
            );

            if (response) {
                let index = meetings.findIndex(meeting => (meeting.id === selectedMeetingId))
                let meetingsCopy = [...meetings]
                meetingsCopy.splice(index, 1, response);
                obj_to_reducer['meetings'] = meetingsCopy
            }
        }

        let copy_changedChecklistItems = [...changedChecklistItems]
        if (copy_changedChecklistItems) {
            for (let i = 0; i < copy_changedChecklistItems.length; i++) {
                yield call(
                    fetchData.patch,
                    `/api/v1/meetings/${selectedMeetingId}/agenda-items/${copy_changedChecklistItems[i]['id']}`,
                    JSON.stringify(copy_changedChecklistItems[i])
                );
            }
            obj_to_reducer['showRightPlate'] = true
            obj_to_reducer['showMeetingRightPlateAnimation'] = true
            obj_to_reducer['selectedMeetingId'] = selectedMeetingId
            obj_to_reducer['checkList'] = checkList
        }


        let copy_toCreateChecklistItems = [...toCreateChecklistItems]
        if (copy_toCreateChecklistItems) {
            for (let i = 0; i < copy_toCreateChecklistItems.length; i++) {
                yield call(
                    fetchData.post,
                    `/api/v1/meetings/${selectedMeetingId}/agenda-items`,
                    JSON.stringify(copy_toCreateChecklistItems[i])
                );
            }
            obj_to_reducer['showRightPlate'] = true
            obj_to_reducer['showMeetingRightPlateAnimation'] = true
            obj_to_reducer['selectedMeetingId'] = selectedMeetingId
            obj_to_reducer['checkList'] = checkList
        }


        yield put(setNewParametersToReducer(obj_to_reducer))
    }

}


function display_type(argument) {
    return argument.exec_auth === '' ? '&displayType=relevant' : '';
}
