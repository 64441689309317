// НА ГАУФ НЕ ЗАЛИВАТЬ
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CheckboxQuestionnaire from "../../../common/components/CheckboxItem/CheckboxQuestionnaire";
import RadioButtonQuestionnaire from "../../../common/components/CheckboxItem/RadioBattonQuestionnaire";
import Icon from "../../../common/components/Icon";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { State } from "../../../rootReducer";
import { cookieMaster } from "../../../utils/CookieMaster";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";

const WrapperLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f7f7f9;
  min-width: 320px;
  width: 100%;
  height: 100vh;
  margin-top: 0px;
  z-index: 999;
`;
const HeaderStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  min-height: 72px;
  width: 100vw;
  z-index: 1;
`;

const GeneralWrapperQuestionnaire = styled.div`
  font-family: Roboto, sans-serif;
  padding: 50px 60px;
  background-color: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #292a34;
  z-index: 3;
  overflow: auto;
  display: flex;
  flex-direction: column;

  & > span:first-child {
    display: block;
  }
`;

const MainTitle = styled.div`
  display: flex;
  justify-content: center;
  & > span {
    align-items: center;
    display: block;
    margin: auto;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
  }
`;
const LittleTitle = styled.div`
  padding: 20px 0;
  & > span {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
`;

const VariantBlock = styled.div`
  display: flex;
  cursor: pointer;

  align-items: center;
  & > span {
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 16px;
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
  }
  & > div {
    margin-left: 40px;
  }

  :not(:last-child) {
    padding-bottom: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  align-items: center;
`;

// НА ГАУФ НЕ ЗАЛИВАТЬ

const Questionnaire: React.FC = () => {

  const { anketa_send,  setAnketa_send  } = useContext(useStateContext);

  const [anketa, setAnketa] = useState<Array<any>>([

  ]);
  const [checkAnswer, setCheckAnswer] = useState<any>({
    targetQ: 0,
    teamQ: 0,
    funcQ: [],
  });
  const [answer, setAnswer] = useState<Array<any>>([]);
  const [disableButton, setDisableButton] = useState(true);

  const history = useHistory();

  const { currentUserInfo } = useSelector((state: State) => state.commonInfo);

 useEffect(() => {
    if (currentUserInfo && currentUserInfo.anketa_check) {
      history.push("/");
    }

  }, [currentUserInfo]);


  let headers = {
    Authorization: cookieMaster.getCookie("access_token"),
    "company-id": 1,
  };

  const getConfig = {
    method: "get",
    url: `${process.env.REACT_APP_PUBLIC_API}/anketa`,
    headers: headers,
  };
  const postConfig = {
    method: "post",
    url: `${process.env.REACT_APP_PUBLIC_API}/anketa`,
    headers: headers,
    data: { answers: answer },
  };

  useEffect(() => {
    axios(getConfig)
      .then((res) => {
        console.log("res.data.data",res.data.data)
       if (res && res?.data && res?.data?.data && res?.data?.data?.length>0 ) {
       /*  let array1= [
          {
            id: 1,
            question: "Для каких целей вы планируете использовать EASY?",
            answer_type: "radio",
            variants: [
              {
                id: 1,
                title: "Для личного использования",
              },
              {
                id: 2,
                title: "Для командной работы",
              },
              {
                id: 3,
                title: "Для использования в компании",
              },
            ]
          },
        
          {
            id: 2,
            question: "Размер вашей команды/компании?",
            answer_type: "radio",
            variants: [
              {
                id: 4,
                title: "1 - 10",
              },
              {
                id: 5,
                title: "11 - 30",
              },
              {
                id: 6,
                title: "31 - 50",
              },
              {
                id: 7,
                title: "50 - 150",
              },
              {
                id: 8,
                title: "150+",
              },
            ],
          },

          {
            id: 2,
            question: "Размер вашей команды/компании?",
            answer_type: "radio",
            variants: [
              {
                id: 4,
                title: "1 - 10",
              },
              {
                id: 5,
                title: "11 - 30",
              },
              {
                id: 6,
                title: "31 - 50",
              },
              {
                id: 7,
                title: "50 - 150",
              },
              {
                id: 8,
                title: "150+",
              },
            ],
          },
          {
            id: 3,
            question: "Какой функционал вам интересен?",
            answer_type: "checkbox",
            variants: [
              {
                id: 9,
                title: "Постановка и контроль задач",
              },
              {
                id: 10,
                title: "Ведение проектов",
              },
              {
                id: 11,
                title: "Контроль бизнес-процессов",
              },
              {
                id: 12,
                title: "Ведение календаря",
              },
              {
                id: 13,
                title: "Проведение совещаний",
              },
              {
                id: 14,
                title: "Коммуникации внутри команды",
              },
              {
                id: 15,
                title: "Оценка эффективности сотрудников",
              },]}
      
        ]; */

        setAnketa(res.data.data);
        }
        else {
          setAnketa([]);
          history.push("/");
        }
      })
      .catch((e) => console.log("ошибка получения анкеты", e));
  }, []);

  const changeHandler = (variantId: number) => {
    if (checkAnswer.funcQ.includes(variantId)) {
      const newAnswer = checkAnswer.funcQ.filter((id) => id !== variantId);

      setCheckAnswer({ ...checkAnswer, funcQ: newAnswer });
    } else {
      setCheckAnswer({
        ...checkAnswer,
        funcQ: [...checkAnswer.funcQ, variantId],
      });
    }
  };

  useEffect(() => {
    if (anketa.length === 1 && checkAnswer.targetQ )     setDisableButton(false);
    else  if (anketa.length === 2 && answer[0] !== 1 && checkAnswer.targetQ && checkAnswer.teamQ )    setDisableButton(false);
    else  if (anketa.length === 2 && answer[0] === 1)    setDisableButton(false);
    else  if (anketa.length > 2 && checkAnswer.targetQ && checkAnswer.teamQ && checkAnswer.funcQ.length)    setDisableButton(false);
    else setDisableButton(true);
  }, [checkAnswer]);

  useEffect(() => {
    setAnswer([checkAnswer.targetQ, checkAnswer.teamQ, ...checkAnswer.funcQ]);
  }, [checkAnswer]);

  const handlerClick = () => {
    axios(postConfig)
      .then((res) => {
        setAnketa_send(true);
        if (res.data.status === "success") history.push("/");
      })
      .catch((e) => {
        console.log("ошибка отправки анкеты", e);
      });
  };

  return (
    <>
    { (anketa && anketa.length>0)  && ( 
    <WrapperLogin>
      <HeaderStyle>
        <Icon name="logo" />
      </HeaderStyle>
      <GeneralWrapperQuestionnaire>
        <MainTitle>
          <span>Перед началом работы</span>
        </MainTitle>

        <div>
          <LittleTitle>
            <span>{anketa[0].question}</span>
          </LittleTitle>
          {anketa[0].variants.map((variant) => {
            return (
              <VariantBlock key={variant.id}>
                <div>
                  <RadioButtonQuestionnaire             
                    name={"target-question"}
                    title={variant.title}
                    check={checkAnswer.targetQ === variant.id}
                    onChange={() => {
                      if (variant.id === 1) {
                        setCheckAnswer({
                          ...checkAnswer,
                          targetQ: variant.id,
                          teamQ: 4,
                        });
                      } else {
                        setCheckAnswer({ ...checkAnswer, targetQ: variant.id });
                      }
                    }}
                    value={variant.id}
                  />
                </div>
              </VariantBlock>
            );
          })}
        </div>
        <div>
          { (anketa.length > 1 && answer[0] !== 1) && (
            <LittleTitle>
              <span>{anketa[1].question}</span>
            </LittleTitle>
          )}
          { (anketa.length > 1 && answer[0] !== 1)
            ? anketa[1].variants.map((variant) => {
                return (
                  <VariantBlock  key={variant.id}>
                    <div>
                      <RadioButtonQuestionnaire
                        name={"team-question"}
                        title={variant.title}
                        check={checkAnswer.teamQ === variant.id}
                        onChange={() => {
                          setCheckAnswer({ ...checkAnswer, teamQ: variant.id });
                        }}
                        value={variant.id}
                      />
                    </div>
                  </VariantBlock>
                );
              })
            : null}
        </div>
        <div>
        { anketa.length > 2 && (
          <LittleTitle>
            <span>{anketa[2].question}</span>
          </LittleTitle>
        )}  

        
          {anketa.length > 2 ? anketa[2].variants.map((variant) => {
            return (
              <VariantBlock        key={variant.id}>
                <CheckboxQuestionnaire
                  checked={checkAnswer.funcQ.includes(variant.id)}
                  onClick={() => {
                    changeHandler(variant.id);
                  }}
                />
                <span
                  onClick={() => {
                    changeHandler(variant.id);
                  }}
                >
                  {variant.title}
                </span>
              </VariantBlock>
            );
          }) 
        : null}
        </div>

        <ButtonWrapper>
          <Button
            title="Ответить"
            disabled={disableButton}
            onClick={() => handlerClick()}
          />
        </ButtonWrapper>
      </GeneralWrapperQuestionnaire>
    </WrapperLogin>
    )}
    </>
  );
};

export default Questionnaire;
