import {
  types,
  fetchTasksAction,
  changeTaskListAction,
  selectProjectAction,
  setProjectAddModeAction,
  setProjectSectionAddModeAction,
  setIsFetchingTasksAction,
  setTableFilterAction,
  setStatusFilterAction,
  setProjectsTableOrderAction,
  setFilterAddModeAction,
  setFilterDraftAction,
  setFilterAddNewRow,
  setAllOrActualAction,
  setShowColumnsCustomAction,
  setShowableColumns,
  tableOrTreeTogglerAction,
  businessProcessesModalShowAction,
  setSearchDirtyAction,
  setProjectsMetaAction,
  deleteTaskFromProjecAction,
  setDeleteProjectModalOpenAction,
  checkDeleteProjectAction,
  deleteProjectFromStateAction,
  deleteProjectAction,
  getAllProjectsAction,
  setAllProjectsAction,
  updateProjectsAction,
  updateProjectByIdAction,
  setProjectTasksTableOrderAction,
  setProjectTasksCurrentPageAction,
  getProjectsAction,
  setProjectsAction,
  setProjectsCurrentPageAction
} from "./action_types";
import { IMeta, Task } from "../../common/types";
import { Project } from "../../common/types";

export const updateProjects = (projects: Project[]): updateProjectsAction => ({
  type: types.UPDATE_PROJECTS,
  projects
})

// TODO Opt delete
export const getAllProjects = (): getAllProjectsAction => ({
  type: types.GET_ALL_PROJECTS,
  projectsAll: []
})

// TODO Opt delete
export const setAllProjects = (projectsAll: Project[]): setAllProjectsAction => ({
  type: types.SET_ALL_PROJECTS,
  projectsAll
})

export const getProjects = (params?: object, needTodo?: string): getProjectsAction => ({
  type: types.GET_PROJECTS,
  params,
  needTodo
});

export const setProjects = (projects: Project[], needTodo: string): setProjectsAction => ({
  type: types.SET_PROJECTS,
  projects,
  needTodo
});

export const setProjectsCurrentPage = (projectCurrentPage: number): setProjectsCurrentPageAction => ({
  type: types.SET_PROJECTS_CURRENT_PAGE,
  projectCurrentPage
});

export const businessProcessesModalShow = (
  show: boolean
): businessProcessesModalShowAction => ({
  type: types.BUSINESS_PROCESSES_MODAL_SHOW,
  show,
});

export const fetchTasks = (
  projectId: number,
  pageNum: number,
  abortController?: AbortController
): fetchTasksAction => ({
  type: types.FETCH_TASKS,
  projectId,
  pageNum,
  abortController,
});

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
  type: types.CHANGE_TASK_LIST,
  tasks,
});

export const selectProject = (
  projectAll: Project | null
): selectProjectAction => ({
  type: types.SELECT_PROJECT,
  projectAll,
});

export const setProjectAddMode = (mode: boolean): setProjectAddModeAction => ({
  type: types.SET_PROJECT_ADD_MODE,
  mode,
});

export const setProjectSectionAddMode = (
  mode: boolean
): setProjectSectionAddModeAction => ({
  type: types.SET_PROJECT_SECTION_ADD_MODE,
  mode,
});

export const setIsFetchingTasks = (
  isFetchingTasks: boolean
): setIsFetchingTasksAction => ({
  type: types.SET_IS_FETCHING_TASKS,
  isFetchingTasks,
});

export const setTableFilter = (filter: string): setTableFilterAction => ({
  type: types.SET_TABLE_FILTER,
  filter,
});

export const setProjectsOrder = (
  col_name: string,
  order_direct: boolean,
): setProjectsTableOrderAction => ({
  type: types.SET_PROJECTS_TABLE_ORDER,
  col_name,
  order_direct,
});

export const setProjectTasksOrder = (
  col_name: string, 
  order_direct: boolean
): setProjectTasksTableOrderAction => ({
  type: types.SET_PROJECT_TASKS_TABLE_ORDER,
  col_name,
  order_direct
});

export const setFilterWindowShowProjects = (
  mode: boolean
): setFilterAddModeAction => ({
  type: types.SET_FILTER_WIN_PROJECTS_MODE,
  mode,
});

export const setFiltersProjects = (
  filterObject: Object
): setStatusFilterAction => ({
  type: types.SET_FILTERS_PROJECTS,
  filterObject,
});

export const setDraftFilterProjects = (
  draft: number
): setFilterDraftAction => ({
  type: types.SET_DRAFT_VALUE_PROJECTS,
  draft,
});

export const setAddNewRowProjects = (value: boolean): setFilterAddNewRow => ({
  type: types.SET_FILTERS_ADD_NEW_ROW_PROJECTS,
  value,
});

export const ActualToggleActionProjects = (
  value: object
): setAllOrActualAction => ({
  type: types.SET_ALL_OR_ACTUAL_PROJECTS,
  value,
});

export const setShowColumnsProjectsCustom = (
  show: boolean
): setShowColumnsCustomAction => ({
  type: types.SET_SHOW_COLUMNS_WINDOW_PROJECTS,
  show,
});

export const setShowProjectsCols = (value: string): setShowableColumns => ({
  type: types.SET_SHOWING_COLS_PROJECTS,
  value,
});

export const setTableOrTreeToggler = (
  value: string
): tableOrTreeTogglerAction => ({
  type: types.SET_TABLE_OR_TREE_TOGGLER,
  value,
});

export const setSearchDirty = (isSearchDirty: boolean): setSearchDirtyAction => ({
  type: types.SET_SEARCH_DIRTY,
  isSearchDirty,
});

export const setProjectsMeta = (projectsMeta: IMeta): setProjectsMetaAction => ({
  type: types.SET_PROJECTS_META,
  projectsMeta
});

export const deleteTaskFromProjectState = (id: string|number): deleteTaskFromProjecAction => ({
  type: types.DELETE_TASK_FROM_PROJECT,
  id
});

export const setDeleteProjectModalOpen = (
  {
    projectId,
    isDeleteModalOpen,
    isShowPreloader,
    isShowMenuPreloader,
    statusFromServer
  }: {
    projectId,
    isDeleteModalOpen,
    isShowPreloader?,
    isShowMenuPreloader?,
    statusFromServer?
  }
): setDeleteProjectModalOpenAction => ({
  type: types.SET_DELETE_PROJECT_MODAL_DATA,
  projectId,
  isDeleteModalOpen,
  isShowPreloader,
  isShowMenuPreloader,
  statusFromServer
});

export const checkDeleteProject = (id: string|number): checkDeleteProjectAction => ({
  type: types.CHECK_DELETE_PROJECT,
  id
});

export const deleteProjectFromProjectsState = (id: string|number): deleteProjectFromStateAction => ({
  type: types.DELETE_PROJECT_FROM_STATE,
  id
});

export const deleteProject = (id: string|number): deleteProjectAction => ({
  type: types.DELETE_PROJECT,
  id
});

export const updateProjectById = (project: Project): updateProjectByIdAction => ({
  type: types.UPDATE_PROJECT_BY_ID,
  project,
});

export const setProjectTasksCurrentPage = (page: number): setProjectTasksCurrentPageAction => ({
  type: types.SET_PROJECT_TASKS_CURRENT_PAGE,
  page
});
