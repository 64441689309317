import { TValidationRules } from "../interfaces/rules";
import { errorValidationMessages } from "../constants/rules";

export const checkInputs = (
  rules: TValidationRules[]|undefined,
  value: string|number
): string[]|boolean => {
  if(!rules) return false;

  const errorMessages = {
    max: errorValidationMessages.MAX,
    required: errorValidationMessages.REQUIRED,
  };
  const errorState: string[] = [];
  const valueLocal = value.toString();

  rules.forEach(rule => {
    if(rule['required'] && !valueLocal?.length) {
      errorState.push(errorMessages[ 'required' ]);
    }

    if(rule['max']) {
      if(value && (valueLocal?.length > rule.max)) {
        // TODO сделать подстановку значения max
        errorState.push(errorMessages[ 'max' ]);
      }
    }
  });

  return errorState;
};

// Нужно доработать или изменить проверку даты
/*export const checkDate = (
  rules: IValidationDate[]|undefined,
  value: Dayjs|null
): string[]|boolean => {
  if(!rules) return false;

  const errorMessages = {
    required: errorValidationMessages.REQUIRED,
    maxDate: errorValidationMessages.MAX_DATE,
    minDate: errorValidationMessages.MIN_DATE
  };
  const errorState: string[] = [];

  rules.forEach(rule => {
    if(rule['required'] && !dayjs(value).isValid()) {
      errorState.push(errorMessages[ 'required' ]);
    }
  });

  return errorState;
};*/
