import React, { useState, useCallback } from 'react';
import Input from '../../../common/components/Inputs/Input';
import { useLocation, useHistory } from 'react-router-dom';
import { validatePassword } from '../../../utils/validPatterns';
import { fetchData } from '../../../utils/fetchData';
import { CreateNotif } from '../../../utils/createNotification';
import CustomBlock from './CustomBlock';
import Button from '../../../common/components/newComponent/Buttons/Button';
import styled from 'styled-components';
import { recoveryMessages } from "../constants";

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 4px;
  }
  button {
    margin-top: 24px;
  }
`;

const PasswordRecovery = () => {
  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirm] = useState('');
  const [in_load, setInload] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setInload(true);
      let token = decodeURIComponent(
          location.pathname.split('/password/recovery/')[1]
        ),
        email = location.search.split('?email=')[1];

      let response = await fetchData.post(
        '/api/v1/password/change',
        JSON.stringify({
          email,
          token,
          password,
          password_confirmation,
        })
      );
      if (response?.success) {
        CreateNotif('Пароль успешно восстановлен', 'success');
        history.push('/login');
      }
      setInload(false);
    },
    [
      history,
      location.pathname,
      location.search,
      password,
      password_confirmation,
    ]
  );

  return (
    <CustomBlock 
      className='password-recovery'
      title="Новый пароль"
    >
      <FormStyle onSubmit={formSubmitHandler}>
        <Input
          inputType="password"
          placeholder="Новый пароль"
          withIcon={true}
          value={password}
          title=""
          changeHandler={(value) => setPassword(value)}
          isValid={password ? validatePassword(password) : true}
          validText={recoveryMessages.PASSWORD_RESTRICT}
        />
        <Input
          inputType="password"
          placeholder="Повторить"
          withIcon={true}
          value={password_confirmation}
          title=""
          changeHandler={(value) => setPasswordConfirm(value)}
          isValid={password ? validatePassword(password) : true}
          validText={recoveryMessages.PASSWORD_RESTRICT}
        />
        <Button big title="Сохранить" isSending={in_load} />
      </FormStyle>
    </CustomBlock>
  );
};

export default PasswordRecovery;
