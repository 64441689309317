import { types, TaskInfoPlateAction } from "./action_types";
import {
  Task,
  User,
  Comment,
  File as FileType,
  Schedule,
  Section,
  Dependences, Project,
} from "../../common/types";
import { Reducer } from "redux";
import { TDefaultProject } from "./interfaces/interfaces";

export const begin = dateFormatUTC(Date.now());
const end = dateFormatUTC(Date.now() + 3600000 * 24); // дедлайн по умолчанию- через сутки после начала задачи

export function dateFormatUTC(arg) {
  let date = new Date(arg);
  let dateNumber: any = date.getDate();
  let month: any = date.getMonth() + 1; // because numeration of month starts from 0
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let seconds: any = date.getSeconds();
  if (month < 10) month = "0" + month;
  if (dateNumber < 10) dateNumber = "0" + dateNumber;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  return (
    dateNumber +
    "-" +
    month +
    "-" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
}

export const convertFromStringToUSdateString = (arg) => {
  switch (arg.length) {
    case 19:
      let number = arg.substr(0, 2);
      let month = arg.substr(3, 2);
      let year = arg.substr(6, 4);
      let hour = arg.substr(11, 2);
      let minute = arg.substr(14, 2);
      let second = arg.substr(17, 2);

      return (
        month +
        "-" +
        number +
        "-" +
        year +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    case 12:
      break;
  }
};

export const parseStringToDate = (arg) => {
  let number = arg.substr(0, 2);
  let month = arg.substr(3, 2);
  let year = arg.substr(6, 4);
  let hour = arg.substr(11, 2);
  let minute = arg.substr(14, 2);
  let second = arg.substr(17, 2);

  return [year, month, number, hour, minute, second];
};

export const milisecondDateFromOurFormat = (arg) => {
  let number = arg.substr(0, 2);
  let month = arg.substr(3, 2);
  let year = arg.substr(6, 4);
  let hour = arg.substr(11, 2);
  let minute = arg.substr(14, 2);
  let second = arg.substr(17, 2);

  return (
    year +
    "-" +
    month +
    "-" +
    number +
    "T" +
    hour +
    ":" +
    minute +
    ":" +
    second +
    "Z"
  );
};

const initialState = {
  isShownTaskInfoPlate: false,
  selectedTask: null as Task | null,
  executor_id: null as number | null,
  name: "",
  description: ``,
  begin: begin,
  end: end,
  taskInfoActiveTab: "info",
  projectUsers: [] as User[],
  projectSections: [] as Section[],
  commentList: [] as Comment[],
  commentsLimit: 20,
  pageNum: 1,
  commentFiles: [] as FileType[],
  text: "",
  workflow_id: 1,
  priority_id: 0,
  provide_to: 0,
  task_load: 1 as any,
  work_load: 100 as any,
  project_section_id: null as number | null,
  project_id: null as number | null,
  parentComment: null as Comment | null,
  isSendingComment: false,
  isSendingDelegate: false,
  isDependencies: false,
  isSendingCreateNewTasks: false,
  executorSchedule: null as Schedule | null,
  delegateTo: null as number | null,
  checkList: [], // у айтема чек-листа есть текст и состояние булево. После того, как он отправлен на сервер, у него появляется 3й параметр - id
  delegated: false,
  taskWorkLoadSwitcher: "task_load",
  private: false,
  cellTaskData: null,
  prev_id: null as number | null,
  next_id: null as number | null,
  parent_id: null as number | null,
  child_tasks: [] as any[],
  id_bp: null as number | null,
  dependencesTask: {
    prev: null,
    next: null,
    child: [],
    parent: null,
  } as Dependences,

  successfulCreatedTasks: {},

  //////// edit cyclic task
  rememberObjectCyclicTaskToChange: {} as any, // храним объект со значениями, которые нужно поменять, когда всплывает модальное окно подтверждения изменения
  flagForActionCyclycTask: "", // может иметь 3 значения - '', 'task', 'taskAndCyclic'
  ////////

  repeat: {
    period: "", // '', 'every day', 'every week', 'every month', 'settings'
    params: [] as number[], // дни недели ИЛИ числа месяца
    interval: 1, // повторять каждые N недели и т.д. (1 по умолчанию)
    ending_condition: "never", // 'after count', 'after date'
    end_count: null as number | null,
    end_date: null as string | null,
    show_modal_window: false,
    settings_string: "",
  },
  parameter_trigger: true, // просто какой то параметр, который служит как триггер у useEffect, когда нужно вызвать действие из саги руками.
  // successfulDelegatedTo: [] as any[]  // этот параметр используется только для делегирования задач пользователям. Компонент ExecutorSelectWithDelegate

  labelsData: {
    comments_count: 0,
    files_count: 0,
  },
  taskCreateTime: 0,

  isMeetingTask: false,

  idQuestion: 0,
  isSuccessTaskCreate: false,
  spectators: [] as number[],
  defaultProject: {
    project: null,
    sections: null,
    isLoading: false,
    selectedSection: null,
    updateTrigger: null,
    reloadTrigger: null
  } as TDefaultProject
};

export type TaskInfoPlateState = typeof initialState;

const reducer: Reducer<TaskInfoPlateState, TaskInfoPlateAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.SET_DEFAULT_VALUES: {
      const defaultProject = { ...state.defaultProject };
      
      return {
        ...initialState,
        defaultProject
      };
    }
    
    case types.SET_SUCCESS_TASK_CREATE:
      return {
        ...state,
        isSuccessTaskCreate: action.mode
      };
     
      case types.SET_CELL_TASK_DATA:
        return {
          ...state,
          cellTaskData: action.data
        };
    case types.SET_DEPENDENCIS:
      return {
        ...state,
        dependencesTask: action.dependencies,
      };
    case types.SET_TASK_CREATE_TIME:
      return {
        ...state,
        taskCreateTime: action.taskCreateTime,
      };
    case types.SET_IS_MEETING_TASK:
      return {
        ...state,
        isMeetingTask: action.isMeetingTask,
      };
    case types.SET_ID_QUESTION:
      return {
        ...state,
        idQuestion: action.idQuestion,
      };
    case types.SETTING_DEPENDENCIES:
      return {
        ...state,
        isDependencies: action.isDependencies,
      };
    case types.SET_TASK_NAME:
      return {
        ...state,
        name: action.name,
      };
    case types.SET_TASK_PRIVATE:
      return {
        ...state,
        private: action.private1,
      };
    case types.SET_TASK_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
      
    case types.SET_TASK_PROJECT:
      return {
        ...state,
        project_id: action.projectId,
      };
      
    case types.SET_TASK_SECTION:
      return {
        ...state,
        project_section_id: action.id,
      };
      
    case types.SET_TASK_PRIORITY:
      return {
        ...state,
        priority_id: action.priority,
      };
    case types.SET_PROVIDE_TO:
      return {
        ...state,
        provide_to: action.val,
      };

    case types.SET_TASK_WORKLOAD:
      return {
        ...state,
        work_load: action.workLoad,
      };
    case types.SET_TASKLOAD:
      return {
        ...state,
        task_load: action.taskLoad,
      };

    case types.SET_SHOWN_TASK_INFO_PLATE:
      return {
        ...state,
        isShownTaskInfoPlate: action.isShownTaskInfoPlate,
        commentList: initialState.commentList,
        checkList: initialState.checkList,
      };
    case types.GET_COMMENTS_LIST:
      return {
        ...state,
        pageNum: action.pageNum,
      };
    case types.UPDATE_TASK_INFO_PLATE:
      return {
        ...state,
        author_id: action.task?.author_id,
        begin: action.task?.begin,
        child_tasks: action.task?.child_tasks,
        cyclic_task_id: action.task?.cyclic_task_id,
        description: action.task?.description,
        end: action.task?.end,
        executor_id: action.task?.executor_id,
        name: action.task?.name,
        next_id: action.task?.next_id,
        parent_id: action.task?.parent_id,
        prev_id: action.task?.prev_id,
        priority_id: action.task?.priority_id,
        project_id: action.task?.project_id,
        project_section_id: action.task?.project_section_id,
        provide_to: action.task?.provide_to,
        status_id: action.task?.status_id,
        status_related_user_id: action.task?.status_related_user_id,
        task_load: action.task?.task_load,
        work_load: action.task?.work_load,
        workflow_id: action.task?.workflow_id,
        id_bp: action.task?.id_bp,
        private: action.task?.private,
      };
    case types.DUPLICATE_TASK:
      return {
        ...state,
        delegatedTo: null,
        commentList: initialState.commentList,
        commentsLimit: initialState.commentsLimit,
        pageNum: initialState.pageNum,
        name: state.selectedTask?.name as string,
        description: state.selectedTask?.description as string,
        project_id: state.selectedTask?.project_id as number,
        project_section_id: state.selectedTask?.project_section_id as number,
        begin: state.selectedTask?.begin as string,
        end: state.selectedTask?.end as string,
        taskInfoActiveTab: "info",
        commentFiles: initialState.commentFiles,
        text: "",
        priority_id: state.selectedTask?.priority_id as number,
        provide_to: 0,
        task_load: state.selectedTask?.task_load as number,
        work_load: state.selectedTask?.work_load as number,
        parentComment: null as Comment | null,
        isSendingComment: false,
        isSendingDelegate: false,
        isSendingCreateNewTasks: false,
        executorSchedule: null as Schedule | null,
        delegateTo: null as number | null,
        delegated: false,
        taskWorkLoadSwitcher: state.taskWorkLoadSwitcher,
        id_bp: state.selectedTask?.id_bp as number | null,
        selectedTask: null,
        private: state.selectedTask?.private as boolean,
      };

    //     Добавлена строчка:
    //     priority_id: state.selectedTask?.priority_id as number,
    //     Для переноса при создании подзадачи приоретета задачи в подзадачу
    //     (значение в подзадаче устанавливается такое же как в задаче)
    //     *****************************************************************
    case types.MAKE_SUBTASK:
      return {
        ...state,
        name: initialState.name,
        description: initialState.description,
        project_id: state.selectedTask?.project_id as number,
        project_section_id: state.selectedTask?.project_section_id as number,
        begin: state.selectedTask?.begin as string,
        end: state.selectedTask?.end as string,
        parent_id: state.selectedTask?.id as number,
        priority_id: state.selectedTask?.priority_id as number,
        private: state.selectedTask?.private as boolean,
        dependencesTask: {
          parent: state.selectedTask,
          next: null,
          prev: null,
          child: [],
        },
        selectedTask: null,
      };
    //      *****************************************************************

    //     Добавлена строчка:
    //     priority_id: state.selectedTask?.priority_id as number,
    //     Для переноса при преобразовании задачи в подзадачу значения поля "приоретет"
    //     *****************************************************************
    case types.MAKE_AS_SUBTASK:
      return {
        ...state,
        name: initialState.name,
        description: initialState.description,
        project_id: state.selectedTask?.project_id as number,
        project_section_id: state.selectedTask?.project_section_id as number,
        begin: state.selectedTask?.begin as string,
        end: state.selectedTask?.end as string,
        child_tasks: [state.selectedTask?.id as number],
        priority_id: state.selectedTask?.priority_id as number,
        private: state.selectedTask?.private as boolean,
        dependencesTask: {
          parent: null,
          next: null,
          prev: null,
          child: [],
        },
        selectedTask: null,
      };
    //      *****************************************************************

    case types.SELECT_TASK:
      // сюда не надо никогда передавать null. В экшене это тоже надо запретить. Если хочешь сбросить выделенную таску, вызывай SET_DEFAULT_VALUES
      return {
        ...state,
        selectedTask: action.task,
        name: action.task?.name as string,
        description: action.task?.description as string,
        delegateTo: null,
        commentList: [...state.commentList],
        commentsLimit: initialState.commentsLimit,
        pageNum: initialState.pageNum,
        project_id: action.task?.project_id as number,
        isShownTaskInfoPlate: action.task ? true : state.isShownTaskInfoPlate,
      };
    case types.SET_TASK_INFO_ACTIVE_TAB:
      return {
        ...state,
        taskInfoActiveTab: action.activeTab,
      };
    case types.SET_PROJECT_USERS:
      return {
        ...state,
        projectUsers: action.users,
      };
    case types.ADD_COMMENTS_LIST:
      // in case of adding
      return {
        ...state,
        commentList: [...state.commentList, ...action.commentList],
      };
    case types.UPDATE_COMMENTS_LIST:
      // in case of adding
      return {
        ...state,
        commentList: [...action.commentList],
      };
    case types.SET_COMMENTS_LIST:
      // in case of delete
      return {
        ...state,
        commentList: [...action.commentList],
      };
    case types.SET_COMMENT_FILES:
      return {
        ...state,
        commentFiles: action.files,
      };
    case types.SET_COMMENT_TEXT:
      return {
        ...state,
        text: action.text,
      };
    case types.SET_IS_SENDING_COMMENT:
      return {
        ...state,
        isSendingComment: action.isSending,
      };
    case types.SET_IS_SENDING_DELEGATE:
      return {
        ...state,
        isSendingDelegate: action.isSending,
      };
    case types.SET_IS_SENDING_CREATE_NEW_TASK:
      return {
        ...state,
        isSendingCreateNewTasks: action.isSending,
      };
    case types.CLEAR_COMMENT_FILED:
      return {
        ...state,
        commentFiles: [],
        text: "",
        parentComment: null,
      };
    case types.SET_PARENT_COMMENT:
      return {
        ...state,
        parentComment: action.parent,
      };
    case types.SET_EXECUTOR_SCHEDULE:
      return {
        ...state,
        executorSchedule: action.schedule,
      };
    case types.SET_PROJECT_SECTIONS:
      return {
        ...state,
        projectSections: action.sections,
      };
    case types.DISPATCH_CHECKBOX_ITEM:
      let new_copy_checklist = state.checkList.map((item) => {
        return item;
      });

      if (action.actionType !== "delete") {
        // это может быть create и patch
        let currentElem;
        // @ts-ignore
        if (new_copy_checklist[action.orderNumber - 1]) {
          // @ts-ignore
          const idElem = new_copy_checklist[action.orderNumber - 1].id;
          currentElem = {
            name: action.text,
            done: action.checkboxState,
            id: idElem,
          };
        } else {
          currentElem = {
            name: action.text,
            done: action.checkboxState,
          };
        }

        if (action.orderNumber != null) {
          new_copy_checklist[
            (parseInt(String(action.orderNumber)) - 1).toString()
          ] = currentElem;
        }
      }

      return {
        ...state,
        checkList: new_copy_checklist,
      };
    case types.SET_TASKWORKLOAD_TOGGLER:
      return {
        ...state,
        taskWorkLoadSwitcher:
          state.taskWorkLoadSwitcher === "task_load"
            ? "work_load"
            : "task_load",
      };
    case types.UPDATE_CHECKLIST:
      return {
        ...state,
        checkList: action.checkItem,
      };
    case types.REWRITE_WHOLE_TASKLIST:
      return {
        ...state,
        checkList: action.newArray,
      };

    case types.SET_EXECUTOR_ID:
      return {
        ...state,
        executor_id: action.value,
      };

    case types.SET_TASK_BEGIN:
      return {
        ...state,
        begin: action.begin,
      };

    case types.SET_TASK_END:
      return {
        ...state,
        end: action.end,
      };

    case types.SET_PREV_ID:
      return {
        ...state,
        prev_id: action.value,
      };

    case types.SET_NEXT_ID:
      return {
        ...state,
        next_id: action.value,
      };

    case types.SET_PARAMETER_TRIGGER:
      return {
        ...state,
        parameter_trigger: !state.parameter_trigger,
      };

    case types.SET_START_CYCLICK:
      return {
        ...state,
        repeat: {
          ...state.repeat,
          period:
            action.period !== undefined ? action.period : state.repeat.period,
          params:
            action.params !== undefined ? action.params : state.repeat.params,
          interval:
            action.interval !== undefined
              ? action.interval
              : state.repeat.interval,
          show_modal_window:
            action.show_modal_window === undefined
              ? state.repeat.show_modal_window
              : action.show_modal_window,
          settings_string:
            action.settings_string === undefined
              ? state.repeat.settings_string
              : action.settings_string,
        },
      };
    case types.SET_FINISH_CYCLICK:
      return {
        ...state,
        repeat: {
          ...state.repeat,
          ending_condition:
            action.ending_condition !== undefined
              ? action.ending_condition
              : state.repeat.ending_condition,
          end_count:
            action.end_count !== undefined
              ? action.end_count
              : state.repeat.end_count,
          end_date:
            action.end_date !== undefined
              ? action.end_date
              : state.repeat.end_date,
        },
      };

    case types.SET_PARENT_ID:
      return {
        ...state,
        parent_id: action.value,
      };

    case types.SET_CHILD_TASKS:
      return {
        ...state,
        child_tasks: action.value,
      };

    case types.SUCCESSFUL_CREATED_NEW_TASKS:
      return {
        ...state,
        successfulCreatedTasks: action.value,
      };

    case types.SET_CYCLIC_TASK_CHANGE:
      return {
        ...state,
        rememberObjectCyclicTaskToChange: {
          ...state.rememberObjectCyclicTaskToChange,
          ...action.obj,
        },
      };

    case types.CLEAR_CYCLIC_TASK_CHANGE:
      return {
        ...state,
        rememberObjectCyclicTaskToChange:
          initialState.rememberObjectCyclicTaskToChange,
      };

    case types.SET_FLAG_REPEAT_TASK_CHANGE:
      return {
        ...state,
        flagForActionCyclycTask: action.val,
      };

    case types.SET_LABELS_DATA:
      let lb = state.labelsData;
      if (action.comments_count !== undefined)
        lb["comments_count"] = action.comments_count;
      if (action.files_count !== undefined)
        lb["files_count"] = action.files_count;

      return {
        ...state,
        labelsData: lb,
      };

    case types.UPDATE_REDUCER_CHECKBOX_ITEM:
      let copy_checklist = state.checkList.map((item) => item);
      let currentElem;
      // @ts-ignore
      if (copy_checklist[action.orderNumber - 1]) {
        if (action.actionType === "delete" && action.orderNumber) {
          copy_checklist.splice(action.orderNumber - 1, 1);
        } else {
          // похоже что айдишник используется в API, именно по нему идет адресация среди всех комментариям в БД
          // @ts-ignore
          let idElem = copy_checklist[action.orderNumber - 1].id;
          if (!idElem) {
            if (action.id) idElem = action.id;
          }
          currentElem = {
            name: action.text,
            done: action.checkboxState,
          };
          if (state.selectedTask) currentElem["id"] = idElem; // если задача создана
        }
      } else {
        currentElem = {
          name: action.text,
          done: action.checkboxState,
        };
      }

      if (action.orderNumber != null && action.actionType !== "delete") {
        // @ts-ignore
        copy_checklist[parseInt(action.orderNumber) - 1] = currentElem;
      }
      return {
        ...state,
        checkList: copy_checklist,
      };

    case types.SET_TASKS_OBSERVERS:
      return {
        ...state,
        spectators: action.spectators
      };

    case types.SET_DEFAULT_PROJECT_SECTIONS: {
      return {
        ...state,
        defaultProject: {
          ...state.defaultProject,
          project: action.project,
          sections: action.sections
        }
      };
    }

    case types.SET_DEFAULT_PROJECT_SECTIONS_LOADING: {
      return {
        ...state,
        defaultProject: {
          ...state.defaultProject,
          isLoading: action.isLoading
        }
      };
    }
    
    case types.SET_SELECTED_SECTION: {
      return {
        ...state,
        defaultProject: {
          ...state.defaultProject,
          selectedSection: action.selectedSection
        }
      };
    }
    
    case types.SET_DEFAULT_PROJECT_UPDATE_TRIGGER: {
      return {
        ...state,
        defaultProject: {
          ...state.defaultProject,
          updateTrigger: action.updateTrigger
        }
      };
    }
    
    case types.RELOAD_DEFAULT_PROJECT_SECTIONS_TRIGGER: {
      return {
        ...state,
        defaultProject: {
          ...state.defaultProject,
          reloadTrigger: action.reloadTrigger
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;
