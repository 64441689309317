import React, { useEffect, useState } from "react";
import "./TaskPriority.scss";
import {connect} from "react-redux";
import Icon from "../../../../../../../common/components/Icon";
import {State} from "../../../../../../../rootReducer";
import {Priority as PriorityType} from "../../../../../../../common/types";

interface TaskPriorityProps {
  priorityId: number;
  setPriorityId: (priorityId: number) => void;
  blockChanges: boolean;
  openMenuBp: boolean;
  priorities: PriorityType[];
}

const TaskPriority: React.FC<TaskPriorityProps> = ({
  priorityId,
  setPriorityId,
  blockChanges,
  openMenuBp,
  priorities
}) => {
  //let priorities = useSelector((state) => state.commonInfo.priorities);
  const [priority, setPriority] =
    useState<PriorityType | null>(priorities[1]);
  const [selectPriority, setSelectPriority] = useState(false);

  useEffect(() => {
    if (priorities.length > 0 && priorityId >= 0) {
      const foundPriority =
        priorities.find((item: PriorityType) => item.id === priorityId);
      if (foundPriority) {
        setPriority(foundPriority);
      } else {
        setPriority(priorities[1]);
      }
    }
  }, [priorityId, priorities]);


  return (
    <div
      className={
        !blockChanges ? "priority-button" : "priority-button blockChange"
      }
      onClick={() => {
        if (setPriorityId !== undefined && setPriorityId !== null) {
          setSelectPriority((prev) => !prev);
        }
      }}
    >

      {priority && priority.slug && (
          <Icon name={`priority_${priority?.slug}`} />
      )}

      {openMenuBp && (
          <span>{priority?.name}</span>
      )}

      {selectPriority && (
        <div className="priority-select">
          {priorities.map((item: PriorityType) => {
            return (
              <div
                id={item.id.toString()}
                className="priority-button in-table"
                onClick={(e) => {
                  setPriorityId(+e.currentTarget.id);
                }}
              >
                <Icon name={`priority_${item?.slug}`} />
                <span>{item?.name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  priorities: state.commonInfo.priorities,
});

export default connect(mapStateToProps)(TaskPriority);