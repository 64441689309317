// ********************************************************************
// Главное окно для добавления новой задачи или редактирования имеющейся
// (смотреть не в NewTask а тут код описан)

import "./styles.sass";

import React, { useState, useEffect, FC } from "react";
import { connect } from "react-redux";
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  fetchProjectSectionsTaskPlate,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setTaskPrivate,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData
} from "./actions";
import { IMeta } from "../../common/types";
import { State } from "../../rootReducer";
import {
  getProjectsForSelector,
  setProjectsForSelector, 
  setProjectsForSelectorCurrentPage,
  setProjectsForSelectorMeta,
  setSelectedProject
} from "../../common/actions";
import styled from "styled-components";
import MainAddTask from "./components/Main";
import { TProjectForSelect, TSelectOption } from "../../common/interfaces/select";

type Props = {
  // Эти свойства зачем нужны?
  // selectedTask: Task | null;
  // checkList: any[];
  // taskInfoActiveTab: string;
  // selectedUserId: number;
  // taskName: string;
  // users: User[];
  // commentList: Comment[];
  // setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  // setTaskInfoActiveTab: (activeTab: string) => void;
  // setProjectUsers: (users: User[]) => void;
  // setComentList: (commentList: Comment[]) => void;
  // updateTask: (id: number, params: any) => void;
  // setExecutorSchedule: (schedule: Schedule) => void;
  // fetchProjectSectionsTaskPlate: (projectId: number) => void;
  // rewriteWholeTaskList: (response: any) => void;
  // currentUserId: number | undefined;
  // currentUserTopRole: number | undefined;
  // selectTask: (val: Task | null) => void;
  // setTaskName: (name: string) => void;
  // flagForActionCyclycTask: string;
  // rememberObjectCyclicTaskToChange: {};
  // sendReqToChangeTemplRepTask: (obj: {}) => void;
  // setCyclicTaskToChange: (val: {}) => void;
  // setFlagRepeatTaskToChange: (val: string) => void;
  // clearCyclicTaskToChange: () => void;
  // labelsData: {
  //   comments_count: number;
  //   files_count: number;
  // };
  // setLabelsData: (comments_count?: number, files_count?: number) => void;

  // Эти свойства точно используются
  setDefaultValues: () => void;
  getProjectsForSelector: () => void;
  setProjectsForSelector: (projects: TProjectForSelect[]|null) => void;
  setProjectsForSelectorMeta: (meta: IMeta|null) => void;
  setSelectedProject: (project: TSelectOption|null) => void;
  setProjectsForSelectorCurrentPage: (page: number) => void;
  isShownTaskInfoPlate: boolean;
};

const WrapperTaskInfoPlate = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isShownTaskInfoPlate }) =>
    isShownTaskInfoPlate ? "0" : "-600px"};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 584px;
  height: calc(100vh - 76px);
`;

const TaskInfoPlate: FC<Props> = ({
  setDefaultValues,
  getProjectsForSelector,
  isShownTaskInfoPlate,
  setProjectsForSelector,
  setProjectsForSelectorMeta,
  setSelectedProject,
  setProjectsForSelectorCurrentPage
}) => {
  /*const {
    // setShownTaskInfoPlate,
    // selectedUserId,
    setDefaultValues,
    isShownTaskInfoPlate,
    // selectedTask,
    // users,
  } = props;*/

  /*const {
    checkedPersonalTask,
    setCheckedPersonalTask,
    setCheckedTaskBegin,
    setCheckedTaskEnd,
  } = useContext(useStateContext);*/

  // let plate2 = useRef<HTMLDivElement>(null);
  const [isShowAddObject, setIsShowAddObject] = useState<boolean>(false);
  // const [showContextDropDown, setShowContextDropDown] = useState(false);
  // let history = useHistory();

  useEffect(() => {
    // console.log('isShownTaskInfoPlate', isShownTaskInfoPlate);

    if(isShownTaskInfoPlate) {
      getProjectsForSelector();
    }
    
    if(!isShownTaskInfoPlate) {
      setDefaultValues();
      
      setProjectsForSelector(null);
      setProjectsForSelectorMeta(null);
      setSelectedProject(null);
      setProjectsForSelectorCurrentPage(1);
    }
  }, [isShownTaskInfoPlate]);

  return (
    <WrapperTaskInfoPlate
      className="task-info-plate"
      // ref={plate2}
      isShownTaskInfoPlate={isShownTaskInfoPlate}
    >
      <MainAddTask
        isShowAddObject={isShowAddObject}
        setIsShowAddObject={setIsShowAddObject}
      />
    </WrapperTaskInfoPlate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    // currentUserId: state.commonInfo.currentUserInfo?.id,
    // checkList: state.taskInfoPlate.checkList,
    // selectedUserId: state.tasksPage.selectedUserId,
    // currentUserTopRole:
    //   state.commonInfo.currentUserInfo?.roles &&
    //   state.commonInfo.currentUserInfo?.roles[0],
    // selectedTask: state.taskInfoPlate.selectedTask,
    // taskName: state.taskInfoPlate.name,
    // users: state.commonInfo.users,
    // taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    // flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    // rememberObjectCyclicTaskToChange:
    //   state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    // commentList: state.taskInfoPlate.commentList,
    // labelsData: state.taskInfoPlate.labelsData,
    
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate
  };
};

const mapDispatchToProps = {
  // setTaskPrivate,
  // setShownTaskInfoPlate,
  // setTaskInfoActiveTab,
  // setProjectUsers,
  // setComentList,
  // updateTask,
  // setExecutorSchedule,
  // fetchProjectSectionsTaskPlate,
  // rewriteWholeTaskList,
  // selectTask,
  // setTaskName,
  // sendReqToChangeTemplRepTask,
  // setCyclicTaskToChange,
  // setFlagRepeatTaskToChange,
  // clearCyclicTaskToChange,
  // setLabelsData,
  
  setDefaultValues,
  getProjectsForSelector,
  setProjectsForSelector,
  setProjectsForSelectorMeta,
  setSelectedProject,
  setProjectsForSelectorCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskInfoPlate);
