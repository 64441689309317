import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { IMeta, Task } from "../../../common/types";
import TaskTr from "./TaskTr";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import { fetchTasks, changeTaskList, getOneTask } from "../actions";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TasksTableHead from "./TasksTableHead";
import DateMaster from "../../../utils/DateMaster";
import { ETaskTableContext } from "../constants";

type Props = {
  tasks: Task[];
  tableFilter: string;
  fetchTasks: (pageNum: number) => void;
  changeTaskList: (tasks: Task[]) => void;
  filters: any;
  tasksOrder: Object;
  unreadNotification: any[];
  updateTask: boolean;
  setUpdateTask: (bool: boolean) => void;
  tasksMeta: IMeta;
  getOneTask:(taskId: number) => void
};

const WrapperTasksTable = styled.div`
  width: 100%;
  height: calc(100vh - 214px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f4f4f4;
`;

const TasksTableContent: React.FC<Props> = ({
  tasks,
  tableFilter,
  fetchTasks,
  changeTaskList,
  filters,
  tasksOrder,
  unreadNotification,
  updateTask,
  setUpdateTask,
  tasksMeta,
  getOneTask
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [isScroll, setScroll] = useState<boolean>(false);
  const [idOfTaskToShow, setIdOfTaskToShow] = useState<number>(0);
  const [showSidePlate, setShowSidePlate] = useState<boolean>(true);
  
  const ref = useRef<HTMLDivElement>(null);
  const scrollHandler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      
      if(scrollHeight <= scrollTop + clientHeight &&
        pageNum < tasksMeta.last_page) {
        setPageNum(pageNum + 1);
        setUpdateTask(false);
      }
    },
    [pageNum, tasks.length]
  );

  const { id } = useParams();

  useEffect(() => {
    if (pageNum !== 1) fetchTasks(pageNum);
  }, [fetchTasks, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [id, tasksOrder, filters.current_set]);
  
  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;
    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);
  
  const searchParams = new URLSearchParams(location.search);
  const taskId =  Number(searchParams.get('taskId'))
  
  useEffect(() => {
    if (taskId) {
     getOneTask(taskId)
    }
  },[])
  
  return (
    <>
      <TasksTableHead 
        contextCall={ETaskTableContext.TASKS_PAGE}
        isScroll={isScroll}
      />
      <WrapperTasksTable className='tasks-table-content' onScroll={scrollHandler} ref={ref}>
        {tasks.map((task: Task) => {
          if (task.status_id === 10 && filters.current_set.statuses.length) {
            // статус задачи "в работе", и какой то фильтр по статусу включен
            if (
              new Date(
                new DateMaster(task.end).year,
                new DateMaster(task.end).month,
                new DateMaster(task.end).day,
                new DateMaster(task.end).hours,
                new DateMaster(task.end).minutes
              ).getTime() <= new Date(Date.now()).getTime()
            ) {
              // эта задача просрочена
              if (filters.current_set.statuses.indexOf(14) !== -1) {
                // фильтр по статусу 14 включен
                return (
                  <TaskTr
                    idOfTaskToShow={idOfTaskToShow}
                    setIdOfTaskToShow={setIdOfTaskToShow}
                    showSidePlate={showSidePlate}
                    setShowSidePlate={setShowSidePlate}
                    key={task.id}
                    task={task}
                    markTask={
                      unreadNotification
                        .map((notif) => notif.data?.task_id)
                        .indexOf(task.id) >= 0
                    }
                  />
                );
              } else {
                // фильтр по статусу 14 НЕ включен
                // ПРОПУСКАЕМ
              }
            } else {
              // НЕ просрочена
              return (
                <TaskTr
                  idOfTaskToShow={idOfTaskToShow}
                  setIdOfTaskToShow={setIdOfTaskToShow}
                  showSidePlate={showSidePlate}
                  setShowSidePlate={setShowSidePlate}
                  key={task.id}
                  task={task}
                  markTask={
                    unreadNotification
                      .map((notif) => notif.data?.task_id)
                      .indexOf(task.id) >= 0
                  }
                />
              );
            }
          } else {
            return (
              <TaskTr
                idOfTaskToShow={idOfTaskToShow}
                setIdOfTaskToShow={setIdOfTaskToShow}
                showSidePlate={showSidePlate}
                setShowSidePlate={setShowSidePlate}
                key={task.id}
                task={task}
                markTask={
                  unreadNotification
                    .map((notif) => notif.data?.task_id)
                    .indexOf(task.id) >= 0
                }
              />
            );
          }
        })}
      </WrapperTasksTable>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    tableFilter: state.tasksPage.tableFilter.toLocaleLowerCase(),
    tasks: state.tasksPage.tasks,
    filters: state.commonInfo.filters,
    tasksOrder: state.tasksPage.tasksOrder,
    notification_for_tasks: state.tasksPage.notification_for_tasks,
    unreadNotification: state.commonInfo.remote_notifications.unread,
    tasksMeta: state.tasksPage.tasksMeta
  };
};

const mapDispatchToProps = {
  fetchTasks,
  changeTaskList,
  getOneTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTableContent);
