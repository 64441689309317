import './ProjectAllTable.scss';

import React, { useEffect } from "react";
import ProjectTableHead from "../ProjectAllTableHead";
import { State } from "../../../../rootReducer";
import { connect, useSelector } from "react-redux";
import { Task, Project, IMeta } from "../../../../common/types";
import { useParams } from "react-router-dom";
import { changeTaskList, getProjects, setProjectsCurrentPage, setProjectsOrder } from "../../actions";
import styled from "styled-components";
import ProjectAllTaskTr from "../ProjectAllTaskTr";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { useInView } from "react-intersection-observer";
import { getProjectsSortingLocal } from "../../helpers";
import { TSetProjectsSorting } from "../../interfaces";

type Props = {
  isFetchingTasks: boolean;
  projects: Project[];
  changeTaskList: (tasks: Task[]) => void;
  isLoadingProjects: boolean,
  projectsMeta: IMeta,
  setProjectsOrder: TSetProjectsSorting,
  projectCurrentPage: number,
  getProjects: (params?: object, needTodo?: string) => void,
  setProjectsCurrentPage: (page: number) => void
};

const WrapperTasks = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 240px);
  width: 100%;
`;

const WrapperTasksTable = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  background-color: #f4f4f4;
`;

const ProjectAllTable: React.FC<Props> = ({
  projects,
  changeTaskList,
  isLoadingProjects,
  projectsMeta,
  setProjectsOrder,
  projectCurrentPage,
  getProjects,
  setProjectsCurrentPage
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5
  });
  
  const isFetchingTasks = useSelector((state: State) => state.projectsPage.isFetchingTasks);
  const { id }: any = useParams();

  useEffect(() => {
    changeTaskList([]);
  }, [id, changeTaskList]);

  useEffect(() => {
    setProjectsCurrentPage(1);

    const projectsSortingLocal = getProjectsSortingLocal();
    
    if(projectsSortingLocal) {
      setProjectsOrder(projectsSortingLocal.name, projectsSortingLocal.order_direct);
    }
    else {
      getProjects();
    }
  }, []);

  useEffect(() => {
    if(inView && (projectCurrentPage < projectsMeta.last_page)) {
      setProjectsCurrentPage(projectCurrentPage + 1);
      getProjects({}, 'add');
    }
  }, [inView]);
  
  return (
    <WrapperTasksTable 
      isFetchingTasks={isFetchingTasks} 
      className='project-all-table'
    >
      <>
        {!!projects.length && (
          <ProjectTableHead />
        )}
        
        <div className='project-all-table__wr'>
          <div className={
            `project-all-table__preloader-wr ${isLoadingProjects 
              ? 'project-all-table__preloader-wr--show' : 
              ''}`}
          >
            <div className="project-all-table__preloader">
              <PreloaderLocal />
            </div>
          </div>

          {(!isLoadingProjects && projects.length === 0) && (
            <div
              className="main_title" 
              style={{paddingLeft: 24, fontSize: 24, background: '#fff'}}
            >
              Проекты отсутствуют
            </div>
          )}

          {!!projects.length && (
            <>
              <WrapperTasks 
                className='project-all-table__project-items' 
              >
                {projects.map((project: Project, i) => (
                  <div 
                    className='project-all-table__project'
                    key={project.id}
                    ref={(i === (projects.length - 1)) ? ref : null}
                  >
                    <ProjectAllTaskTr
                      project={project}
                    />
                  </div>
                ))}
              </WrapperTasks>
            </>
          )}
        </div>
      </>
    </WrapperTasksTable>
  );
};

const mapStateToProps = (state: State) => ({
  isFetchingTasks: state.projectsPage.isFetchingTasks,
  projects: state.projectsPage.projects,
  isLoadingProjects: state.commonInfo.isLoadingProjects,
  projectsMeta: state.projectsPage.projectsMeta,
  projectCurrentPage: state.projectsPage.projectCurrentPage
});

const mapDispatchToProps = {
  changeTaskList,
  getProjects,
  setProjectsCurrentPage,
  setProjectsOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAllTable);
