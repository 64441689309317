import { takeEvery, all, call, put, select, debounce } from "redux-saga/effects";
import {
  types,
  updateTaskAction,
  fetchExistingUserDataAction,
  setSearchClearAction,
  getOneProjectAction,
  getPositionsFromServerAction
} from "./action_types";
import { fetchData } from "../utils/fetchData";
import { store } from "../App";
import { changeTaskList, fetchVisualizationList } from "../pages/Tasks/actions";
import {
  selectTask,
  setShownTaskInfoPlate,
  setTaskPriority
} from "../pages/TaskInfoPlate/actions";
import {
  changeTaskList as changeProjectTaskList,
  setAllProjects,
  setIsFetchingTasks
} from "../pages/Projects/actions";
import { CreateNotif } from "../utils/createNotification";
import {
  openExistingUser,
  setUserSchedule,
  setUserWorkBegin,
  setUserWorkEnd,
  setUserDaysOff,
} from "../pages/NewUser/actions";
import { setUserAddMode } from "../pages/Users/actions";
import {
  setCurrentUserInfo,
  change_remote_notifications,
  setSearchMeetings,
  getOneProject,
  updateProjectById,
  updatePositions,
  addCommonInfoFast,
  addCommonInfoSlow, 
  setProjectsForSelectorLoading, 
  setProjectsForSelector, 
  setProjectsForSelectorMeta
} from "./actions";
import { IMeta, Position, User } from "./types";
import { formatStringDate, formatToStringUTC } from "./format";
import { timeZone } from "./format";
import { errorMessagesConstants } from "./constants/constants";
import { dayEventChannel } from "../utils/eventbus/channels/dayChannel";
import { State } from "../rootReducer";
import { TProjectForSelect } from "./interfaces/select";

export function* watchFetchCommonInfo() {
  yield takeEvery(types.FETCH_COMMON_INFO, fetchCommonInfo);
}

export function* watchUpdateTask() {
  yield takeEvery(types.UPDATE_TASK, updateTask);
}

export function* watchFetchExistingUser() {
  yield takeEvery(types.FETCH_EXISTING_USER_DATA, fetchExistingUser);
}

export function* watchNotificationsRead() {
  // @ts-ignore
  yield takeEvery(types.SET_NOTIFICATION_READ, setNotifRead);
}

export function* watchLoadNextPage() {
  // @ts-ignore
  yield takeEvery(types.SET_LOAD_NEXTPAGE_NOTIFICATIONS, loadNextpage);
}

export function* watchClearNotifications() {
  yield takeEvery(types.CLEAR_NOTIFICATIONS, clearNotifications);
}

export function* watchClearSearch() {
  yield takeEvery(types.SET_SEARCH_CLEAR, clearSearch);
}

export function* watchGetOneProject() {
  yield takeEvery(types.GET_ONE_PROJECT, getOneProjectSaga);
}

export function* watchGetPositionsFromServer() {
  yield takeEvery(types.GET_POSITIONS_FROM_SERVER, getPositionsFromServer);
}

export function* watchGetProjectsForSelector() {
  yield takeEvery(types.GET_PROJECT_FOR_SELECTOR, getProjectsForSelector);
}

export function* watchSearchProjectsForSelector() {
  yield debounce(500, types.SEARCH_PROJECT_FOR_SELECTOR, getProjectsForSelector);
}

function* getPositionsFromServer( { positionIds }: getPositionsFromServerAction) {
  let response: Position[] = [];
  
  for(let positionId of positionIds) {
    const responseLocal = yield call(
      fetchData.get,
      `/api/v1/positions/${positionId}`
    );

    if(responseLocal) {
      response.push(responseLocal);
    }
  }
  
  if(response.length) {
    yield put(updatePositions(response));
  }
}

function* getOneProjectSaga( { id }: getOneProjectAction) {
  try {
    let response: any = yield call(
      fetchData.get,
      `/api/v1/projects/${id}`
    );

    if(response) {
      yield put(updateProjectById(response));
    }
  }
  catch (e) {
    console.error(errorMessagesConstants.GET_ONE_PROJECT);
  }
}

function* clearSearch( { isNeedReload }: setSearchClearAction) {
  yield put(setSearchMeetings(''));
  
  if(isNeedReload) yield call(reloadAllProjects);
}

function* reloadAllProjects() {
  try {
    yield put(setIsFetchingTasks(true));
    
    let response: any = yield call(
      fetchData.get,
      '/api/v1/projects?limit=1000'
    );
    
    if(response?.data) {
      yield put(setAllProjects(response?.data));
    }

    yield put(setIsFetchingTasks(false));
  }
  catch (e) {
    console.error(errorMessagesConstants.GET_RELOADED_PROJECTS);
    yield put(setIsFetchingTasks(false));
  }
}

async function getCommonUsers() {
  let page = 1;
  let users: User[] = [];
  let response: any = {};
  
  do {
    response = await fetchData.get(
      `/api/v1/users?page=${page}&order=surname&ordertype=desc&limit=500&show_filter=all`,
      'returnFullResponse'
    );
    
    if(response.data) {
      users = users.concat(response.data);
      page++;
    }
  } while(response.links.next);

  return users;
}

function* fetchCommonInfo() {
  let currentUserId = store.getState().commonInfo.current_user;
  let companyList = yield call(
    fetchData.get,
    `/api/v1/users/${currentUserId}/companies`
  );

  if (companyList.length > 0) {
    localStorage.setItem('companyList', JSON.stringify(companyList));
  }

  let company_id;
  if (localStorage.getItem("company_id")) {
    company_id = localStorage.getItem("company_id");
    if (!companyList.includes(parseInt(company_id))) {
      localStorage.setItem("company_id", companyList[0]);
      // обновляется айдишник компании в заголовке для всех запросов, осуществляемых через fetchData
      fetchData.addHeaders({
        "company-id": `${localStorage.getItem("company_id")}`,
      });
    }
  }
  
  yield call(getCommonInfoFast);
  yield call(getCommonInfoSlow, currentUserId, companyList);
}

function* getCommonInfoFast() {
  const {
    priorities,
    statuses,
    roles,
    workgroups
  } = yield all({
    priorities: call(fetchData.get, '/api/v1/priorities'),
    statuses: call(fetchData.get, '/api/v1/statuses'),
    roles: call(fetchData.get, '/api/v1/roles'),
    workgroups: call(fetchData.get, '/api/v1/workgroups'),
  });

  // Для разных организаций может быть разный id для среднего приоритета, поэтому ищем в поиске
  let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
  yield put(setTaskPriority(priority.id));

  let sortedStatuses = [];
  
  Object.keys(statuses)
    .map((status) => statuses[status].name)
    .sort()
    .forEach((status_name) => {
      Object.keys(statuses).forEach((source_status) => {
        if (status_name === statuses[source_status]["name"])
          // @ts-ignore
          sortedStatuses.push(statuses[source_status]);
      });
    });
  
  yield put(addCommonInfoFast(
    priorities,
    sortedStatuses,
    roles,
    workgroups,
    true
  ));
}

function* getCommonInfoSlow(currentUserId, companyList) {
  let company = {};

  for (let i = 0; i < companyList.length; i++) {
    yield fetchData.get(`/api/v1/companies/${companyList[i]}`).then((data) => {
      company[companyList[i]] = data;
    });
  }
  
  const {
    users,
    positions,
    departments,
    sections,
    read_notifs,
    unread_notifs,
  } = yield all({
    users: call(getCommonUsers),
    positions: call(fetchData.get, `/api/v1/positions?limit=-1`),
    departments: call(fetchData.get, `/api/v1/departments?limit=-1`),
    unread_notifs: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/unread-notifications?order=created_at&offset=0&limit=10&type=system&orderType=desc`
    ),
    read_notifs: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/read-notifications?order=created_at&offtset=0&limit=10&type=system&orderType=desc`
    ),
  });

  let currentUser = users.find((user) => user.id === currentUserId);
  if(!currentUser)
    currentUser = yield call(fetchData.get, `/api/v1/users/${currentUserId}`);

  if(currentUser) {
    yield put(setCurrentUserInfo(currentUser));
  }
  
  yield put(addCommonInfoSlow(
    users,
    positions,
    departments,
    sections,
    unread_notifs,
    unread_notifs.length < 10 ? read_notifs : [],
    unread_notifs.length < 10 ? "read" : [],
    unread_notifs < 10 && read_notifs.length < 10,
    company,
    true
  ));
}

function* updateTask({ taskId, params, withNotif }: updateTaskAction) {
  if (params?.begin) params.begin = formatToStringUTC(params.begin);
  if (params?.end) params.end = formatToStringUTC(params.end);

  const task = yield call(
    fetchData.patch,
    `/api/v1/tasks/${taskId}`,
    JSON.stringify(params)
  );

  if (task) {
    const { selectedProject } = store.getState().projectsPage;
    let tasks = store.getState().tasksPage.tasks.slice();
    let projectTasks = store.getState().projectsPage.tasks.slice();

    let selectedTask = store.getState().taskInfoPlate.selectedTask;
    if (
      selectedTask &&
      selectedTask?.begin &&
      selectedTask?.begin.slice(2, 3) !== "-"
    ) {
      selectedTask.begin = formatStringDate(selectedTask.begin);
    }
    if (
      selectedTask &&
      selectedTask?.end &&
      selectedTask?.end.slice(2, 3) !== "-"
    ) {
      selectedTask.end = formatStringDate(selectedTask.end);
    }
    if (task && task?.begin && task?.begin.slice(2, 3) !== "-") {
      task.begin = formatStringDate(task.begin);
    }
    if (task && task?.end && task?.end.slice(2, 3) !== "-") {
      task.end = formatStringDate(task.end);
    }

    let myId = store.getState().commonInfo.current_user;

    let indexInTasks = tasks.findIndex((task) => task.id === taskId);
    // если в табличном списке найдена эта задача
    if (indexInTasks > -1) tasks.splice(indexInTasks, 1, task); //обновление задачи в списке
    yield put(changeTaskList(tasks.map((task) => {

      if (task.id === taskId) {
        return selectedTask || task
      } else {
        return task
      }
    })));

    let browserLink = window.location.href; // смотрит, какой url в браузере открыт
    
    // Только для проектов
    if (browserLink.indexOf('projects') > -1) {
      yield put(selectTask(task));
      yield put(getOneProject(task?.project_id));
      
      yield put(changeProjectTaskList([])); // очищаю все данные по списку или дереву

      if(!withNotif) yield put(setShownTaskInfoPlate(false));
    } 
    else if (browserLink.indexOf("tasks") > -1) {
      // если задачи
      yield put(changeTaskList(tasks));
    }

    // Только для календаря
    if(browserLink.indexOf('calendar') > -1) {
      dayEventChannel.emit('onUpdateDayTask', task);
    }

    if((browserLink.indexOf('projects') < 0) && selectedTask?.id === taskId) {
      // до сих пор ли выделена та самая задача, по которой мы изменяли данные ?
      yield put(selectTask(task));
    }

    let indexInProjectTasks = projectTasks.findIndex(
      (task) => task.id === taskId
    );
    if (indexInProjectTasks !== -1) {
      projectTasks.splice(indexInProjectTasks, 1, task);
      yield put(changeProjectTaskList(projectTasks));
    }
    if (withNotif) {
      CreateNotif(
        'Изменения сохранены. Для отмены нажмите "Ctrl + Z"',
        "success"
      );
    }

    yield put(fetchVisualizationList(myId));
  }
}

function* fetchExistingUser({ id }: fetchExistingUserDataAction) {
  let time_Zone = timeZone();
  let data = yield call(
    fetchData.get,
    `/api/v1/users/${id}/schedule?tz=${time_Zone}`
  );

  const users = store.getState().commonInfo.users;
  let user = users.find((user) => user.id === id);

  if (user) {
    yield put(openExistingUser(user));
    yield put(setUserAddMode(true));
    if (data.schedule.daysOff.length > 0) {
      yield put(setUserDaysOff(data.schedule.daysOff));
    }
  }
  if (data) {
    const schedule = data.schedule.weekDays.map((day) => day.day);

    if (schedule) {
      const { begin, end } = data.schedule.weekDays[0].time[0];

      yield put(setUserSchedule(schedule));
      yield put(setUserWorkBegin(begin.slice(0, 5)));
      yield put(setUserWorkEnd(end.slice(0, 5)));
      if (data.schedule.daysOff.length > 0) {
        yield put(setUserDaysOff(data.schedule.daysOff));
      }
    }
  }
}

function getFoundItemValue(source, id_notification, found_item) {
  source.forEach((item, id) => {
    if (item["id"] === id_notification) found_item = { item, id };
  });
  return found_item;
}

function* clearNotifications({}) {
  const current_user_id = store.getState().commonInfo.currentUserInfo?.id;

  const response = yield all({
    response: call(
      fetchData.patch,
      `/api/v1/users/${current_user_id}/read-system-notification`,
      {}
    ),
  });

  if (response.response === 1) {
    yield put(change_remote_notifications([], [], "read", false, 0));
    yield loadNextpage();
  }
}

function* setNotifRead({ id_notification, id_current_user, readonly }) {
  const remote_notifs = store.getState().commonInfo.remote_notifications;
  const { notification_for_tasks } = store.getState().tasksPage;

  let new_read = remote_notifs.read.map((item) => item);
  let new_unread = remote_notifs.unread.map((item) => item);
  let found_item: any;

  found_item = getFoundItemValue(new_unread, id_notification, found_item);

  if (found_item) {
    // this item was as unread
    let response = yield call(
      fetchData.patch,
      `/api/v1/users/${id_current_user}/read-notification?id=${id_notification}`,
      {}
    );

    if (response) {
      // @ts-ignore
      new_read.push(found_item.item);
      new_unread.splice(found_item.id, 1);
      yield put(change_remote_notifications(new_unread, new_read));
    }
  }
}

function* loadNextpage() {
  const currentUserId = store.getState().commonInfo.current_user;
  let remote_notifications = store.getState().commonInfo.remote_notifications;
  let { load_next, offset } = remote_notifications;
  const limit = 10;

  // request
  const { response } = yield all({
    response: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/${load_next}-notifications?order=created_at&offset=${offset}&limit=${limit}&type=system&orderType=desc`
    ),
  });

  let new_unread = remote_notifications.unread.map((item) => item);
  let new_read = remote_notifications.read.map((item) => item);

  if (load_next === "unread") new_unread.push.apply(new_unread, response);
  else new_read.push.apply(new_read, response);

  // put new values to reducer
  yield put({
    type: types.SET_REMOTE_NOTIFICATIONS,
    // unread: load_next_part === 'unread' ? response : [],
    unread: new_unread,
    read: new_read,
    load_next: load_next,
    end: load_next === "read" && response.length < 10,
    offset: remote_notifications.offset === 0 ? response.length : undefined,
  });

  // сразу же, когда заканчивается unread, грузить первую пачку read
  if (response.length < limit) {
    if (load_next === "unread") {
      load_next = "read";

      // request
      const { response_read } = yield all({
        response_read: call(
          fetchData.get,
          `/api/v1/users/${currentUserId}/${load_next}-notifications?order=created_at&offset=0&limit=${limit}&type=system&orderType=desc`
        ),
      });

      yield put({
        type: types.SET_REMOTE_NOTIFICATIONS,
        unread: new_unread,
        read: response_read,
        load_next: load_next,
        end: response_read.length < 10,
      });
    }
  }
}

function* getProjectsForSelector({ params, needTodo }: any) {
  yield put(setProjectsForSelectorLoading(true));

  let url = "?";

  const currentPage = yield select(
    (state: State) => state.commonInfo.projectsForSelect.currentPage
  );

  const search = yield select(
    (state: State) => state.commonInfo.projectsForSelect.search
  );

  if(currentPage) {
    url += `&page=${currentPage}`;
  }
  
  if(search?.length) {
    url += `&search=${search}`;
  }
  
  const response: {
    data: TProjectForSelect[]
    meta: IMeta
  } = yield call(
    fetchData.get,
    `/api/v1/projects/short-list${url}`,
    'returnFullResponse'
  );
  
  if(response?.data) {
    yield put(setProjectsForSelector(response.data, needTodo));
  }

  if(response?.meta) {
    yield put(setProjectsForSelectorMeta(response.meta));
  }

  yield put(setProjectsForSelectorLoading(false));
}
