import './Sidebar.scss';

import React, { useCallback, useEffect, useState } from "react";
import AddTaskForm from "./components/AddTaskForm";
import HeaderSidebar from "./components/Header";
import axios from "axios";
import { CreateNotif } from "../../../../utils/createNotification";
import { cookieMaster } from "../../../../utils/CookieMaster";
import AddMeetForm from "./components/AddMeetForm";
import AddEventForm from "./components/AddEventForm";
import { connect, useSelector } from "react-redux";
import { Task, User } from "../../../../common/types";
import { setProjectUsers } from "../../../TaskInfoPlate/actions";
import { State } from "../../../../rootReducer";
import { useParams } from "react-router-dom";

interface Props {
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  selectedTask: Task | null;
  setProjectUsers: (users: User[]) => void;
  usersTask: User[];
  cellData: any;
  setCellData: (mode: any) => void;
  addEventHandler: (event: any, ans: any) => void;
  deleteSelectedEvent: (id) => void;
  users: User[];
}

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;
let company_id = localStorage.getItem("company_id") || "";
let token = cookieMaster.getCookie("access_token");

const SidebarForCalnendar: React.FC<Props> = ({
  isShowAddObject,
  setIsShowAddObject,
  cellData,
  addEventHandler,
  setCellData,
  deleteSelectedEvent,
  users,
}) => {
  const [changeSection, setChangeSection] = useState<any>(() => {
    if (cellData) {
      if (cellData.type) {
        if (cellData.type == "EVENT_TASK_TYPE") {
          return 1;
        }
        if (cellData.type == "MEETING_TYPE") {
          return 2;
        }
        if (cellData.type == "EVENT_TYPE") {
          return 3;
        }
      }
    }
    return 1;
  });
  const [activeMeeting, setActiveMeeting] = useState(); // Вызывается при изменении совещания
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const userId = localStorage.getItem("user_id");
  const params = useParams<{ id: string }>();

  const { currentUserInfo: currentUser } = useSelector(
    (state: State) => state.commonInfo
  );

  const addMeeting = useCallback(async (prop) => {
    const data = await axios
      .post(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/${company_id}/meetings`,
        {
          ...prop,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        CreateNotif(`Совещание успешно добавлено!`, "success");
        setIsShowAddObject(false);
      })
      .catch((e) => {
        CreateNotif(
          `Ошибка при добавлении совещания! Проверьте введенные данные `,
          "error"
        );
      });
  }, []);

  const editMeeting = useCallback(async (id, props) => {
    try {
      const data = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${id}`,
        {
          chairman: props?.chairman,
          format: props?.format,
          location: props?.location,
          members: props?.members,
          name: props?.name,
          secretary: props?.secretary,
          start_at: props.start_at,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        }
      );
      CreateNotif(`Совещание успешно изменено!`, "success");
      setIsEdit(false);
    } catch (err) {
      CreateNotif(
        `Ошибка при изменении совещания! Возможно председатель и секретарь - один сотрудник, а должны быть разные`,
        "error"
      );
    }
  }, []);

  const checkRoles = (activeMeeting) => {
    const isInitiator = currentUser?.id === activeMeeting.initiator_id;
    const isChairman = currentUser?.id === activeMeeting.chairman.user_id;
    const isSecretary = currentUser?.id === activeMeeting.secretary.user_id;

    // Если это не админ и не инициатор совещания, председатель, секретарь
    if(!currentUser?.isAdmin
      && (!isInitiator && !isChairman && !isSecretary)
    ) {
      setIsDisabled(true);
    }

    if(currentUser?.isAdmin) {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if(currentUser && activeMeeting) {
      checkRoles(activeMeeting);
    }
  }, [activeMeeting, currentUser]);

  return (
    <div className='day-sidebar'>
      {isDisabled && (
        <div className='day-sidebar__disabled-layout'></div>
      )}
      <HeaderSidebar
        changeSection={changeSection}
        setChangeSection={setChangeSection}
        isShowAddObject={isShowAddObject}
        setIsShowAddObject={setIsShowAddObject}
        cellData={cellData}
        setCellData={setCellData}
        currentUser={currentUser}
      />
      {changeSection === 1 && (
        <AddTaskForm
          isShowAddObject={isShowAddObject}
          setIsShowAddObject={setIsShowAddObject}
          cellData={cellData}
          setCellData={setCellData}
          addEventHandler={addEventHandler}
          deleteSelectedEvent={deleteSelectedEvent}
        />
      )}
      {changeSection === 2 && (
            <AddMeetForm
              addMeeting={addMeeting}
              isShowAddMeeting={isShowAddObject}
              setIsShowAddObject={setIsShowAddObject}
              activeMeeting={activeMeeting}
              setActiveMeeting={setActiveMeeting}
              editMeeting={editMeeting}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              users={users}
              cellData={cellData}
              setCellData={setCellData}
              addEventHandler={addEventHandler}
            />
          )}
      {changeSection === 3 && userId === params.id && (
        <AddEventForm
          isShowAddObject={isShowAddObject}
          setIsShowAddObject={setIsShowAddObject}
          cellData={cellData}
          addEventHandler={addEventHandler}
          setCellData={setCellData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    usersTask: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  setProjectUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarForCalnendar);
