import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Task, Section, User } from "../../../common/types";
import DateMaster from "../../../utils/DateMaster";
import ProjectCell from "../../../common/components/TableCells/ProjectSection";
import IdCell from "../../../common/components/TableCells/IdCell";
import TaskNameCell from "../../../common/components/TableCells/TaskNameCell";
import DeadLineCell from "../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../common/components/TableCells/ExecutorCell";
import TaskWorkLoadingCell from "../../../common/components/TableCells/TaskWorkLoadingCell";
import PriorityCell from "../../../common/components/TableCells/PriorityCell";
import { selectTask } from "../../TaskInfoPlate/actions";
import { connect, useDispatch } from "react-redux";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import { setShownTaskInfoPlate } from "../../TaskInfoPlate/actions";
import { State } from "../../../rootReducer";
import { getSelectedTask } from "../../TaskInfoPlate/selectors";
import { updateStatusWindowTrue } from "../../../pages/Tasks/actions"; // 571 задача redmine *********************************
import { updateStatusWindowFalse } from "../../../pages/Tasks/actions"; // 571 задача redmine *********************************
import {
  openExistingTask,
  setProjectSectionsTask,
} from "../../NewTask/actions";
import {
  fetchTaskDependence,
  readNotificationByTask,
  setTaskAddMode,
} from "../actions";
import { store, urlApp } from "../../../App";
import styled, { keyframes } from "styled-components";
import BPModal from "../../../common/components/BPModalWindow/BPModalWindow";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import {
  showAnimateCompanyWindow,
  showCompanyWindow,
} from "../../../common/actions";
import { setInitial, setPlateEditMode } from "../../../pages/Company/actions";
import { Vector, Vector2, Vector3, Vector4 } from "../../../img/Task";
import BpItemStatus from "../../Bp/src/components/ui/bp-item-status/BpItemStatus";
import moment from "moment";
import { AccordionIcon7 } from "../../../img/projectIcons";
import Button from "@material-ui/core/Button";
import TaskMenu from "./ui/TaskMenu/TaskMenu";
import { useParams, useHistory } from 'react-router-dom';

type Props = {
  statusWindow: boolean; // 571 задача redmine *********************************
  updateStatusWindowTrue: (statusWindow: boolean) => void; // 571 задача redmine *********************************
  updateStatusWindowFalse: (statusWindow: boolean) => void; // 571 задача redmine ********************************* /
  task: Task|any;
  selectedTask: Task | null;
  taskAddMode: boolean;
  users: User[];
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  openExistingTask: (task: Task) => void;
  setTaskAddMode: (mode: boolean) => void;
  setProjectSectionsTask: (sections: Section[]) => void;
  activeColumns: string[];
  projects: object;
  partitions: object;
  markTask?: boolean;
  readNotificationByTask: (id_task: number) => void;
  idOfTaskToShow: number;
  setIdOfTaskToShow: (value: number) => void;
  showSidePlate: boolean;
  setShowSidePlate: (value: boolean) => void;
  currentUser,
  disableUrl?: boolean
};

const TaskStyle = styled.div`
  display: flex;
  margin: 0 12px;
  margin-bottom: 12px;
  /* margin-right: 40px; */
  justify-content: space-between;
  min-height: 84px;
  padding: 15px 0 15px 20px;
  // box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* border-bottom: 1px solid; */
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    // background: linear-gradient(
    //   to right,
    //   white 0%,
    //   #f2f2f2 5%,
    //   #f2f2f2 90%,
    //   white 100%
    // );
    box-shadow: 0px 4px 5px rgb(0 0 0 / 20%);
  }
  ${({ active }) =>
    active &&
    `
    background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
    border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;

    `}
`;
const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: center;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ImgDiv = styled.div`
  padding-top: 6px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  text-align: center;
  &:hover {
    background: rgb(244, 244, 244);
  }
`;

const blinking = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const MarkerNewTask = styled.div`
  animation: ${blinking} 2s linear infinite;
  opacity: 1;
  border-radius: 100%;
  max-width: 8px;
  width: 100%;
  height: 8px;
  background-color: #1baaf0;
  margin-top: 15px;
  position: absolute;
  left: 115px;
  top: 9px;
`;

const NameSection = styled.div`
  margin-right: 12px;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TaskTr: React.FC<Props> = ({
  statusWindow, // 571 задача redmine *********************************
  updateStatusWindowTrue, // 571 задача redmine *********************************
  updateStatusWindowFalse, // 571 задача redmine *********************************
  task,
  users,
  projects,
  partitions,
  selectedTask,
  setProjectSectionsTask,
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  activeColumns,
  markTask,
  readNotificationByTask,
  idOfTaskToShow,
  setIdOfTaskToShow,
  showSidePlate,
  setShowSidePlate,
  currentUser,
  disableUrl = false
}) => {
  const {
    show1,
    setshow1, // Показывать или нет Профиль пользователя / компании
    checkedPersonalTask,
    setCheckedPersonalTask,
    setCheckedTaskBegin,
    setCheckedTaskEnd,
  } = useContext(useStateContext);

  const dispatch = useDispatch();
  
  const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null);
  const [isTaskMenuOpen, setIsTaskMenuOpen] = useState<boolean>(false);
 
  const dateMaster = useMemo(() => {
    return new DateMaster(
      moment(task.end, "YYYY-MM-DDTHH:mm:ss[Z]", true).isValid()
        ? moment(task.end).format("DD-MM-YYYY HH:mm:ss")
        : task.end
    );
  }, [task]);
  
  const handleSetAnchorEl = value => {
    if(!value) setIsTaskMenuOpen(false);
    
    setAnchorEl(value);
  };
  
  const clickTaskActionsOpenHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTaskMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const { id } = useParams();
  const history = useHistory();

  const handleTaskClose = () => {
    history.replace(`/task/${id}`);
  };

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (idOfTaskToShow !== +target.id) {
      setShowSidePlate(false);
      handleTaskClose()
      setIdOfTaskToShow(+target.id);
    } else if (idOfTaskToShow === +target.id) {
      setShowSidePlate(true);
      setIdOfTaskToShow(0);
    }
  };
 
  const handleTaskClick = (taskId) => {
    history.push(`/tasks/${id}?taskId=${taskId}`);
  };
  
  // Тут вызывается окно редактирования задачи при клике на задачу
  const trClickHandler = (e: React.MouseEvent) => {
      if(isTaskMenuOpen) return;
      handleSetAnchorEl(null); // Скрываем меню три точки
      
      setshow1(false); // При открытии задачи закрываем окно профиля  компании / пользователя, если оно вдруг открыто

      // При открытии задачи закрываем окно "О компании", если оно вдруг открыто
      showAnimateCompanyWindow(false);
      setTimeout(() => {
        dispatch(showCompanyWindow(false));
        setPlateEditMode(false);
        setInitial();
      }, 800);

      setShowSidePlate(true);
      setIdOfTaskToShow(0);

      if (statusWindow) {
        // 571 задача redmine *********************************
        if (task.id !== selectedTask?.id) {
          let target = e.target;

          readNotificationByTask(task.id);

          if (store.getState().tasksPage.taskAddMode) return;
          
          if (selectedTask?.id !== task.id) {
            selectTask(task);
            
            if(!disableUrl) {
              handleTaskClick(task.id);
            }
          }
          
          if (!(target as HTMLElement).closest(".task_status_wrapper"))
            setShownTaskInfoPlate(true);
        }
      }
    }

  const executor = users.find((user) => user.id === task.executor_id);
  const author = users.find((user) => user.id === task.author_id);

  let project_of_task;
  let section_of_task;
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
      projects[id].sections.forEach((section) => {
        if (section.id === task.project_section_id) {
          section_of_task = section.name;
        }
      });
    }
  });

  // task?.last === true - последняя задача

  return (
    <TaskStyle className={'task-row'} 
               active={task.id === selectedTask?.id} 
               onClick={(e) => trClickHandler(e)}
    >
      <BlockLeftFlex>
        {activeColumns.includes("id") && <IdCell id={task.id} />}
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "7px" }}
        >
          <Vector3 />
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center ",
            minWidth: "18px",
            marginRight: "10px",
            width: "fit-content",
            color: "#94959A",
            paddingLeft: "4px",
          }}
        >
          {task.comments_count}
          {markTask && <MarkerNewTask />}
        </span>
        <NameSection>
          {activeColumns.includes("name") && (
            <TaskNameCell
              name={task.name}
              desc={task.description}
              notificationBall={false}
              mark={markTask}
              id={task.id}
            />
          )}
        </NameSection>
      </BlockLeftFlex>
      <BlockRightFlex>
        <div style={{ display: "flex", alignItems: "center" }}>
          {activeColumns.includes("author_id") && (
            <ExecutorCell
              minWidth={"54px"}
              url={
                task?.author?.image
                  ? urlApp + `/files/${task?.author?.image?.url}/download`
                  : null
              }
              executorName={
                task.author ? `${task.author.surname} ${task?.author?.name}` : "Неизвестен"
              }
              isActive={task.author?.is_active}
            />
          )}

          {activeColumns.includes("executor_id") && (
            <ExecutorCell
              minWidth={"70px"}
              url={
                executor?.image
                  ? urlApp + `/files/${executor.image.url}/download`
                  : null
              }
              executorName={
                executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
              }
              isActive={executor?.is_active}
              isExucutor={true}
            />
          )}
        </div>
        {activeColumns.includes("status_id") && (
          <TaskStatus // 571 задача из redmine. Из этого дочернего компонента- при начала работы с дочерним компонентом вернуть в родительский statusWindow= false.
            id={
              // 571 задача из redmine. При окончании- statusWindow = true
              // если статус "в работе" и дедлайн уже прошел, то надо ставить статус "просрочена" - id 14
              task.status_id === 10 &&
              new Date(
                dateMaster.year,
                dateMaster.month,
                dateMaster.day,
                dateMaster.hours,
                dateMaster.minutes
              ).getTime() <= new Date(Date.now()).getTime()
                ? 14
                : task.status_id
            }
            taskId={task.id}
            id_bp={task.id_bp}
          />
        )}
        {activeColumns.includes("end") && (
          <DeadLineCell
            end={task.end}
            deadline={dateMaster.deadlineMini}
            hot={task.hot}
          />
        )}

        {activeColumns.includes("task_load") && (
          <TaskWorkLoadingCell
            taskLoad={task.task_load}
            workLoad={task.work_load}
            checkedPersonalTask={checkedPersonalTask}
          />
        )}

        {activeColumns.includes("project_name") && (
          <ProjectCell
            data_project={project_of_task}
            data_section={section_of_task}
          />
        )}
        {activeColumns.includes("priority_id") && (
          <PriorityCell
            style={{ backgroundColor: "transparent" }}
            priorityId={task.priority_id}
          />
        )}
        <div
          className='task-tr__actions-wrapper'
          onClick={(e) => clickHandler(e)}
          id={task.id.toString()}
          style={{
            padding: "12px 4px",
            position: "relative",
            zIndex: 4,
            borderRadius: "4px",
            width: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {(currentUser?.isAdmin || (currentUser?.id === task.author_id)) &&
            <Button
              style={{minWidth: '30px'}}
              onClick={(e) => clickTaskActionsOpenHandler(e)}
            >
              <AccordionIcon7 />
            </Button>
          }
        </div>
      </BlockRightFlex>

      <TaskMenu 
        id={task.id}
        taskIdBp={task?.id_bp}
        anchorEl={anchorEl} 
        setAnchorEl={handleSetAnchorEl} 
      />
    </TaskStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    statusWindow: state.tasksPage.statusWindow, /// 571 задача из redmine *********************************************** */
    projects: state.projectsPage.projectsAll,
    partitions: state.commonInfo.sections,
    selectedTask: getSelectedTask(state),
    taskAddMode: state.tasksPage.taskAddMode,
    activeColumns: state.tasksPage.activeColumns,
    users: state.commonInfo.users,
    currentUser: state.commonInfo.currentUserInfo
  };
};

const mapDispatchToProps = {
  updateStatusWindowTrue, // 571 задача из redmine ************************************************ */
  updateStatusWindowFalse, // 571 задача из redmine ************************************************ */
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  setProjectSectionsTask,
  readNotificationByTask,
  fetchTaskDependence,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTr);
