// Создание нового пользователя

import "./style.scss";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setIsUserEdit,
  setUserAddMode,
} from "../Users/actions";
import {
  clearUser,
  createUser,
  setUserEmail,
  setUserName,
  setUserSurname,
  deleteUser,
  setPositionAddMode,
  setUserInActiveInfo,
  setUserActivity,
  setUserDeleteInfo, 
  createDefaultUserNotification,
} from "./actions";
import { State } from "../../rootReducer";
import { getCanSend } from "./selectors";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import palette from "../../common/components/palette";
import Button from "../../common/components/newComponent/Buttons/Button";
import { fetchData } from "../../utils/fetchData";
import { urlApp } from "../../App";
import Modal from "../../common/components/Modal";
import NewUserInCompany from "../Login/components/NewUserInCompany";
import Notifications from "./components/Notifications";
import Toggler from "../../common/components/Togglers/Toggler";
import ProfileSection from "./components/ProfileSection";
import ScheduleSection from "./components/ScheduleSection";
import Icon from "../../common/components/Icon";
import NameWithInputPosition from "../../common/components/newComponent/Inputs/NameWithInputPosition";
import UserPositionAdd from "./components/UserPositionAdd";
import { IUserNotification } from "./interfaces";

type Props = {
  canSend: boolean;
  isSending: boolean;
  userId: number;
  currentUser: number;
  deleteUser: (companyId: number, userId: number, isUserDelete: boolean) => void;
  clearUser: () => void;
  createUser: (
    update: boolean,
    changeDataUser: object,
    notificationOptions,
    notificationSuccess,
    isUserDelete
  ) => void;
  setUserAddMode: (mode: boolean) => void;
  changeIndicator: boolean;
  setUserInActiveInfo: (userInfo: any) => void;
  userInActiveInfo: any;
  setUserActivity: (activity: any) => void;
  setUserDeleteInfo: (mode: any) => void;
  isActive: any;
  deleteInfo: any;
  currentUserInfo: any;
  isUserDelete: boolean;
  createDefaultUserNotification: (idUser: string|number, data: Pick<IUserNotification, 'role'|'settings'>[]) => void;
  createdDefaultNotification: IUserNotification[]|null
};

const WrapperNewProject = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
  flex-direction: column;
`;
const StyleWrapperAddUser = styled.div`
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 76px;
  bottom: 0;
  right: 10px;
  transition: all 0.8s ease;
  margin-right: ${({ visibleModal }) => (visibleModal ? "0px" : "-600px")};
`;

const StyleSectionsWrap = styled.div`
  min-height: 70vh;
`;
const StyleBlockUsers = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  width: 584px;
  height: 100%;
  filter: ${({ modalDel }) => modalDel && "blur(10px)"};
  
  & > div:first-child {
    display: flex;
    justify-content: space-between;
  }
`;
const StyleHead = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${palette.textBlack};
  margin-bottom: 24px;
`;

const StyleWrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyleToggle = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const ModalBlockNewProject = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(41, 47, 76, 0.3);
  margin-top: 20px 0;
  transition: transform 0.3s ease;
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0vh)" : "translateY(-100vh)"};
  max-width: 530px;
  width: 100%;
  padding: 1.5rem 1rem;
  max-height: 100vh;
  overflow-y: auto;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0vh)" : "translateY(-100vh)"};
  & > div {
    flex: 1;
  }
`;

export type changeDataUserType = {
  data: {
    roles?: number[];
    name?: string;
    surname?: string;
  };
  schedule: {
    weekDays?: [];
    daysOff?: [];
  };
  processing: {
    days?: string;
    hours?: string;
  };
};

export type Verification = {
  email_verified: number;
  telegram_registered: number;
  disabled: boolean;
  telegram_code: number;
};

const ProjectAddPanel: React.FC<Props> = ({
  canSend,
  isSending,
  userId,
  clearUser,
  createUser,
  setUserAddMode,
  deleteUser,
  currentUser,
  changeIndicator,
  setUserInActiveInfo,
  userInActiveInfo,
  isActive,
  deleteInfo,
  setUserActivity,
  setUserDeleteInfo,
  currentUserInfo,
  isUserDelete,
  createDefaultUserNotification,
  createdDefaultNotification
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const roles: any = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.roles
  );

  const visibleModalSelector = useSelector(
    (state: State) => state.usersPage.userAddMode
  );
  const currentUserRoles = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.roles
  );
  const idUser = useSelector((state: State) => state.newUser.id);
  const currentId = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.id
  );
  const positionAddMode = useSelector(
    (state: State) => state.newUser.positionAddMode
  );

  const userInfo = useSelector((state: State) => state.newUser);
  const [userRoles, setUserRoles] = useState<any>();
  const [isAddUserInCompany, setIsAddUserInCompany] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  
  const defaultNotificationSettings: Pick<IUserNotification, 'role'|'settings'>[] = [
    {
      role: 'author',
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] }
    },
    {
      role: 'executor',
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] },
    }
  ];
  
  const [activeTab, setActiveTab] = useState({
    name: "Профиль",
    value: "profile",
  });

  const [changeDataUser, setChangeDataUser] = useState<changeDataUserType>({
    data: {},
    schedule: {
      weekDays: [],
      daysOff: [],
    },
    processing: {
      hours: "2",
      days: "3",
    },
  });
  const [notificationOptions, setNotificationOptions] = useState([
    {
      id: 0,
      user_id: 0,
      role: "author",
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] },
    },
    {
      id: 0,
      user_id: 0,
      role: "executor",
      settings: { 1: [1, 2, 3], 2: [1], 3: [3], 4: [], 5: [], 6: [] },
    },
  ]);

  const [verification, setVerification] = useState<Verification>({
    email_verified: 1,
    telegram_registered: 0,
    disabled: false,
    telegram_code: 0,
  });

  const [notificationSuccess, setNotificationSuccess] = useState(false);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    setTimeout(setIsVisible, 0, true);
  }, [setIsVisible]);

  const closeHandlerAddMode = () => {
    setIsVisible(false);
    dispatch(setPositionAddMode(false));
  };

  useEffect(() => {
    if (!location.pathname.includes("/users")) {
      closeHandler();
    }
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      setVisibleModal(visibleModalSelector);
    }, 0);
  }, [visibleModalSelector]);

  useEffect(() => {
    if (firstRender) setFirstRender(!firstRender);
    else {
      closeHandler();
    }
  }, [changeIndicator]);
  
  useEffect(() => {
    if (idUser !== 0) {
      fetchData
        .get(`/api/v1/users/${idUser}`)
        .then((data) => {
          setVerification({
            ...verification,
            email_verified: data.email_verified,
            telegram_registered: data.telegram_registered,
            telegram_code: data.telegram_code || 0,
          });
        })
        .catch((error) => console.log(error));

      fetchData
        .get(`/api/v1/users/${idUser}/notification-settings`)
        .then((data) => {
          if(data && !data.length) {
            // TODO когда на бэке будет доработан POST, раскоментить, проверить и если надо доработать под бэк
            // createDefaultUserNotification(idUser, defaultNotificationSettings);
          }
          
          if(data && data.length) {
            setNotificationOptions(data);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [idUser]);

  useEffect(() => {
    let path = history.location.pathname;
    let unique_link = path.split("/").find((el) => el === "unique_link");
    if (unique_link && roles[0] < 4) {
      fetchData.get(`/api/v1${history.location.pathname}`).then((data) => {
        setIsAddUserInCompany(true);
        dispatch(setUserSurname(data?.surname || ""));
        dispatch(setUserName(data?.name || ""));
        dispatch(setUserEmail(data?.email || ""));
      });
    } else if (unique_link && roles[0] > 3) {
      setUserAddMode(false);
      if (location.pathname.includes("/users")) {
        history.push("/users");
      }
    }
  }, [location.pathname, history.location.pathname]);

  useEffect(() => {
    if (userInActiveInfo) {
      setUserRoles(userInActiveInfo?.violations[0]?.type);
    }
  }, [userInActiveInfo]);

  const checkPermissions = useCallback(() => {
    return (
      idUser !== 0 && (currentUserRoles?.includes(1) || idUser === currentId)
    );
  }, [currentUserRoles, idUser, currentId]);

  const optionsToggle = useCallback(() => {
    return checkPermissions()
      ? [
          { name: "Профиль", value: "profile" },
          { name: "Рабочее время", value: "schedule" },
          { name: "Уведомления", value: "notifications" },
        ]
      : [{ name: "Профиль", value: "profile" }];
  }, [checkPermissions]);

  const handlerOnChangeToggle = (value) => {
    const optionsValue = optionsToggle().find((el) => el.value === value);

    if (optionsValue) {
      setActiveTab(optionsValue);
    }
  };

  const sendVerificationRequest = () => {
    setVerification({
      ...verification,
      disabled: true,
    });
    fetch(urlApp + `/email/resend/${idUser}`).catch((error) =>
      console.error(error)
    );
  };

  const closeHandler = useCallback(() => {
    setVisibleModal(false);

    dispatch(setIsUserEdit(false));

    setTimeout(() => {
      setUserAddMode(false);
      clearUser();
      if (location.pathname.includes("/users")) {
        history.push("/users");
      }
    }, 600);
  }, [clearUser, history, setUserAddMode, setVisibleModal, location.pathname]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let element = e.target as Node;
      if (element === plate.current) closeHandler();
    },
    [closeHandler]
  );

  const canSendDateWork = () => {
    if (
      Object.keys(changeDataUser.data).length ||
      changeDataUser.schedule.weekDays?.length ||
      changeDataUser.schedule.daysOff?.length ||
      changeDataUser.data.roles?.length ||
      notificationSuccess
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  const createUserLabel = () => {
    if (userId === 0) {
      return "Пригласить сотрудника";
    }

    if (userInfo.name && userInfo.surname) {
      return `${userInfo.surname} ${userInfo.name}`;
    }

    return "Данные сотрудника";
  };

  useEffect(() => {
    let hash = history.location.hash.slice(1).split("&");

    if (hash.length === 1) {
      if (hash[0].split("=")[1] === "schedule") {
        setActiveTab({ name: "Рабочее время", value: "schedule" });
      }
      if (hash[0].split("=")[1] === "notifications") {
        setActiveTab({ name: "Уведомления", value: "notifications" });
      }
    } else if (hash.length === 2) {
      if (hash[0].split("=")[1] === "notifications") {
        setActiveTab({ name: "Уведомления", value: "notifications" });
      }
    }
    if (location.pathname.includes("/users")) {
      history.push("/users");
    }
  }, []);

  const handleUserRole = (type) => {
    if (type == "PROJECT_CPE_VIOLATION") {
      return "Руководителем проектов";
    } else if (type == "DEPARTMENT_HEAD_VIOLATION") {
      return "Руководителем отделов";
    } else if (type == "GROUP_HEAD_VIOLATION") {
      return "Руководителем групп";
    }
  };

  const company_id: any = Number(localStorage.getItem("company_id"));

  const handleDelete = () => {
    deleteUser(company_id, userId, isUserDelete);
    closeHandler();
  };
  
  useEffect(() => {
    if(createdDefaultNotification) {
      setNotificationOptions(createdDefaultNotification);
    }
  }, [createdDefaultNotification]);

  return isAddUserInCompany ? (
    <NewUserInCompany
      changeDataUser={changeDataUser}
      setChangeDataUser={setChangeDataUser}
      isAddUserInCompany={isAddUserInCompany}
      setIsAddUserInCompany={setIsAddUserInCompany}
    />
  ) : (
    <WrapperNewProject className='new-user'>
      <ModalBlockNewProject
        isVisible={positionAddMode}
        style={{ zIndex: 10001 }}
      >
        <NameWithInputPosition title="Название должности" withoutIcon={true}>
          <UserPositionAdd
            closeHandler={closeHandlerAddMode}
            saveHandler={() => {}}
          />
        </NameWithInputPosition>
        <WrapperButtons isVisible={positionAddMode}>
          <Button
            onClick={closeHandlerAddMode}
            design="secondary"
            title="Отмена"
            style={{ marginRight: ".5rem" }}
          />
        </WrapperButtons>
      </ModalBlockNewProject>

      <StyleWrapperAddUser
        className='new-user__content'
        ref={plate} 
        visibleModal={visibleModal}
      >
        <StyleBlockUsers 
          className='new-user__content-wr'
          visibleModal={visibleModal} 
          modalDel={modalDel}
        >
          <div>
            <Icon
              name={"cross"}
              onClick={closeHandler}
              style={{ width: "22px", height: "22px", marginBottom: "5px" }}
            />
          </div>
          <StyleHead>{createUserLabel()}</StyleHead>

          <StyleToggle style={{ marginTop: "-10px" }}>
            <Toggler
              elemsList={optionsToggle()}
              selectHandler={handlerOnChangeToggle}
              activeValue={activeTab.value}
            />
          </StyleToggle>
          <StyleSectionsWrap style={{ marginTop: "-5px" }}>
            {activeTab.value === "profile" && (
              <ProfileSection
                setChangeDataUser={setChangeDataUser}
                setUserDelete={setUserDeleteInfo}
                currentUser={currentUser}
                isAddUserInCompany={isAddUserInCompany}
                sendVerificationRequest={sendVerificationRequest}
                userId={userId}
                verification={verification}
                changeDataUser={changeDataUser}
                user={currentUserInfo}
              />
            )}

            {activeTab.value === "schedule" && (
              <ScheduleSection setChangeDataUser={setChangeDataUser} />
            )}

            {activeTab.value === "notifications" && (
              <Notifications
                notificationOptions={notificationOptions}
                setNotificationOptions={setNotificationOptions}
                setNotificationSuccess={setNotificationSuccess}
                verificationTelegram={verification.telegram_registered}
                telegramCode={verification.telegram_code}
                currentUser={userId === currentUser}
              />
            )}
          </StyleSectionsWrap>

          <StyleWrapperButtons>
            <div></div>
            <div style={{ display: "flex", zIndex: 1 }}>
              <Button
                onClick={closeHandler}
                title="Отмена"
                design="secondary"
              />
              <Button
                onClick={() => {
                  createUser(
                    !!userId,
                    changeDataUser,
                    notificationOptions,
                    notificationSuccess,
                    isUserDelete
                  );
                }}
                title={userId === 0 ? "Пригласить" : "Готово"}
                disabled={userId === 0 ? !canSend : canSendDateWork()}
                isSending={isSending}
                style={{ marginLeft: ".5rem" }}
              />
            </div>
            {deleteInfo ? (
              deleteInfo?.canBeDeleted ? (
                <Modal
                  onClose={() => setUserDeleteInfo(null)}
                  title="Удаление учетной записи сотрудника"
                  description="Данный сотрудник еще ничего не сделал в системе, вы можете смело его удалить.
                Система забудет, что у вас был такой сотрудник. Он не сможет войти в свою учетную запись."
                >
                  <div className="buttons-wrapper">
                    <Button
                      title="Удалить"
                      style={{ width: "80%" }}
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                    <Button
                      style={{ width: "80%" }}
                      design="secondary"
                      title="Отмена"
                      onClick={() => setUserDeleteInfo(null)}
                    />
                  </div>
                </Modal>
              ) : (
                <Modal
                  onClose={() => setUserDeleteInfo(null)}
                  title="Удаление учетной записи сотрудника невозможно"
                  description="Данный сотрудник уже оставил свой след в системе, его нельзя просто удалить. 
              Вы можете воспользоваться функцией деактивации учетной записи сотрудника (параметр `Активность`)."
                >
                  <div className="buttons-wrapper">
                    <Button
                      title="Понятно"
                      style={{ width: "80%" }}
                      onClick={() => setUserDeleteInfo(null)}
                    />
                  </div>
                </Modal>
              )
            ) : null}
            {userInActiveInfo ? (
              userInActiveInfo.canBeDeactivated ? (
                isActive ? (
                  <Modal
                    onClose={() => setUserInActiveInfo(null)}
                    title="Деактивация учетной записи сотрудника"
                    description="Если этот сотрудник был Исполнителем для некоторых задач, то такие задачи будут помечены оранжевым
цветом, не забудьте назначить новых Исполнителей для соответствущих задач. Деактивированный
сотрудник более не сможет войти в свою учетную запись"
                  >
                    <div className="buttons-wrapper">
                      <Button
                        title="Деактивировать"
                        style={{ width: "80%" }}
                        onClick={() => {
                          userId !== 0 &&
                            setChangeDataUser((prev) => {
                              return {
                                ...prev,
                                data: { ...prev.data, is_active: false },
                                schedule: { ...prev.schedule },
                              };
                            });
                          setUserActivity(false);
                          setUserInActiveInfo(null);
                        }}
                      />
                      <Button
                        style={{ width: "80%" }}
                        design="secondary"
                        title="Отмена"
                        onClick={() => setUserInActiveInfo(null)}
                      />
                    </div>
                  </Modal>
                ) : (
                  <Modal
                    onClose={() => setUserInActiveInfo(null)}
                    title="Возврат сотрудника в список активных"
                    description="Пояснение «Пользователь снова сможет авторизоваться в системы и быть ее полноценным участником"
                  >
                    <div className="buttons-wrapper">
                      <Button
                        title="Подтвердить"
                        style={{ width: "80%" }}
                        onClick={() => {
                          userId !== 0 &&
                            setChangeDataUser((prev) => {
                              return {
                                ...prev,
                                data: { ...prev.data, is_active: true },
                                schedule: { ...prev.schedule },
                              };
                            });
                          setUserActivity(true);

                          setUserInActiveInfo(null);
                        }}
                      />
                      <Button
                        style={{ width: "80%" }}
                        design="secondary"
                        title="Отмена"
                        onClick={() => setUserInActiveInfo(null)}
                      />
                    </div>
                  </Modal>
                )
              ) : (
                <Modal
                  onClose={() => setUserInActiveInfo(null)}
                  title="Деактивация учетной записи сотрудника невозможна"
                  description="В системе существуют важные сущности, которые ссылаются на этого сотруднина. Для деактивации
                  учетной записи сотруднина необходимо назначить на его место других ответственных сотрудников."
                >
                  {userInActiveInfo.violations.length == 1 ? (
                    <>
                      <div className="switch-title">
                        Данный сотрудник является{" "}
                        {handleUserRole(
                          userInActiveInfo.violations[0].type
                        )?.toLowerCase()}
                      </div>
                      <div className="list">
                        {userInActiveInfo.violations[0].entities.map((name) => {
                          return <div className="list-item">{name.name}</div>;
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="switch-title">
                        Данный сотрудник является:
                      </div>
                      <div className="switch-list-item-titles-block">
                        {userInActiveInfo.violations.map((value) => {
                          return (
                            <div className="switch-list-item-title">
                              {handleUserRole(value.type)}
                            </div>
                          );
                        })}
                      </div>
                      <div className="switch-container">
                        <div className="switch" style={{ maxWidth: "700px" }}>
                          {userInActiveInfo.violations.length > 1 &&
                            userInActiveInfo.violations.map((val, ind) => {
                              return (
                                <div
                                  className={`switch-item${
                                    val.type == userRoles ? "-active" : ""
                                  }`}
                                  onClick={() => setUserRoles(val.type)}
                                ></div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="list">
                        {userInActiveInfo.violations
                          .find((viol) => viol.type == userRoles)
                          ?.entities.map((name) => {
                            return <div className="list-item">{name.name}</div>;
                          })}
                      </div>
                    </>
                  )}
                  <div className="buttons-wrapper">
                    <Button
                      style={{ width: "80%" }}
                      design="secondary"
                      title="Понятно"
                      onClick={() => {
                        setUserInActiveInfo(null);
                      }}
                    />
                  </div>
                </Modal>
              )
            ) : null}
          </StyleWrapperButtons>
        </StyleBlockUsers>
      </StyleWrapperAddUser>
    </WrapperNewProject>
  );
};

const mapStateToProps = (state: State) => {
  return {
    canSend: getCanSend(state),
    isSending: state.newUser.isSending,
    roles: state.newUser.roles,
    userId: state.newUser.id,
    currentUser: state.commonInfo.current_user,
    changeIndicator: state.newUser.changeIndicator,
    userInActiveInfo: state.newUser.userInfo,
    isActive: state.newUser.is_active,
    deleteInfo: state.newUser.deleteInfo,
    currentUserInfo: state.commonInfo!.currentUserInfo,
    isUserDelete: state.usersPage.isUserDelete,
    createdDefaultNotification: state.newUser.createdDefaultNotification
  };
};

const mapDispatchToProps = {
  setUserAddMode,
  clearUser,
  createUser,
  deleteUser,
  setUserInActiveInfo,
  setUserActivity,
  setUserDeleteInfo,
  createDefaultUserNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddPanel);
