import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  openDepGroupPlate,
  setTableFilter,
  setUsers,
  fetchUsers,
  setCurrentPage,
  setUserAddMode,
} from "../../actions";
import { State } from "../../../../rootReducer";
import { useLocation } from "react-router-dom";
import TableSearch from "../../../../common/components/TableSearch/TableSearch";
import { User } from "../../../../common/types";
import ButtonCustom from "../../../../common/components/newComponent/Buttons/Button";
import styled from "styled-components";
import axios from "axios";
import { cookieMaster } from "../../../../utils/CookieMaster";
import { urlApp } from "../../../../App";
import { CreateNotif } from "../../../../utils/createNotification";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { nanoid } from "nanoid";
import { useContext } from "react";
import { useStateContext } from "../../../../useStateContext";
import { tooltipsTitle } from "../../../../common/constants/constants";
import { CustomTooltip } from "../../../../common/components/Tooltip/CustomTooltip";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { setUserId } from "../../../NewUser/actions";

import './UsersActions.scss';

type Props = {
  users: User[];
  current_user: any;
  tableFilter: string;
  currentUserRole: number | undefined;
  users_length: number;
  setTableFilter: (filter: string) => void;
  setUsers: (users: User[]) => void;
  fetchUsers: () => void;
  setCurrentPage: (pageNum: number) => void;
  wasFetchedSlowRequests: boolean;
  setUserId: (id: number) => void;
  setUserAddMode: (mode: boolean) => void
};

const UserActionStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 40px 24px 40px;
`;

const RightBlockStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ModalContainer = styled.div`
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 650px;
  min-height: 200px;
  max-height: 700px;
  overflow-y: scroll;
  padding: 27px 40px 15px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 35%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin-top: 100px;
`;

const ModalContentDiv = styled.div`
  width: 500px;
  text-align: center;
  margin: 15px 0px;
  border: 1px solid #d0d0d0;
  box-shadow: 3px 3px 6px -4px rgb(0 0 0 / 75%);
  padding: 10px;
`;

const ModalContentH2 = styled.h2`
  text-align: center;
  margin-bottom: 13px;
  margin-top: 14px;
  font-size: 20px;
  text-decoration: underline;
`;

const UsersActions: React.FC<Props> = ({
  tableFilter,
  fetchUsers,
  setUsers,
  setTableFilter,
  users_length,
  currentUserRole,
  users,
  current_user,
  setCurrentPage,
  wasFetchedSlowRequests,
  setUserId,
  setUserAddMode,
}) => {
  const location = useLocation();

  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [errorFileUsers, setErrorFileUsers] = useState<any>();
  let userArr = users?.find((user) => user.id == current_user);
  const { warningWindow, setWarningWindow } = useContext(useStateContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    setCurrentPage(1)
    fetchUsers();
    setErrorFileUsers({});
  };

  // при поиске в тулбаре, исполняется этот колбэк
  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setUsers([]);
          setCurrentPage(1)
          fetchUsers();   
        }, 500)
      );      
    },
    [fetchUsers, setTableFilter, setUsers, timer]
  );

  useEffect(() => {
    setTableFilter("")
  },[location.pathname])
  
  const sendFormsExcelInput = (e) => {
    let company_id = localStorage.getItem("company_id");
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let sendFileAndGetIdConfig: any = {
      method: "POST",
      url: `${urlApp}/api/v1/upload-users`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${cookieMaster.getCookie("access_token")}`,
        "company-id": `${company_id}`,
      },
    };

   axios(sendFileAndGetIdConfig)
      .then((resp) => {
        CreateNotif("Импорт прошел успешно!", "success");
      })
      .catch((error) => {
        if (error.response.status == 400) {
          setErrorFileUsers(error.response.data.errors);
          handleOpen();
        } else if (error.response.status == 500) {
          CreateNotif("Ошибка в обработке данных на сервере", "error");
        }
        CreateNotif("Не получилось загрузить файл", "error");
      });
  };

  return (
    <UserActionStyle className='users-actions'>
      <div className="main_title">
        <div>Штат</div>
        
        <div className="users-actions__count-users-wr">
          <div className={'users-actions__count-users-preloader' +
            (!wasFetchedSlowRequests ? ' users-actions__count-users-preloader--show' : '')
          }>
            <PreloaderLocal />
          </div>
          
          <div className='users-actions__count-users'>{users_length} сотрудников</div>
        </div>
      </div>

      <RightBlockStyle>
        <div className="sub_actions">
          <div
            style={
              warningWindow
                ? { position: "absolute", top: "70px", right: "170px" }
                : { position: "absolute", top: "16px", right: "170px" }
            }
          >
            <TableSearch
              searchValue={tableFilter}
              setSearchValue={searchChangeHandler}
              isOpen={!!tableFilter}
            />
          </div>
        </div>

        {userArr && userArr?.isAdmin && (
          <>
            {" "}
            <label
              style={{ marginRight: "11px" }}
              htmlFor="download-button-file"
            >
              <a
                style={{ textDecoration: "none", color: "#00000" }}
                href={require("../../../../common/components/SendExcelUsers/excel_users.xlsx").default}
                target="_blanc"
              >
                <CustomTooltip title={tooltipsTitle.DOWNLOAD_FORM_FILE}>
                  <img
                    width={17}
                    style={{ cursor: "pointer" }}
                    src={require("../../../Bp/public/assets/download-excel-file.svg").default}
                    alt="download"
                  />
                </CustomTooltip>
              </a>
            </label>
            
            <label style={{ marginRight: "10px" }} htmlFor="send-button-file">
              <input
                onChange={sendFormsExcelInput}
                style={{ display: "none" }}
                id="send-button-file"
                multiple
                type="file"
              />
              
              <CustomTooltip title={tooltipsTitle.UPLOAD_FORM_FILE}>
                <img
                  style={{ cursor: "pointer" }}
                  width={19}
                  src={require("../../../Bp/public/assets/upload-excel-file.svg").default}
                  alt="send"
                />
              </CustomTooltip>
            </label>
          </>
        )}

        {/*пользователь с ролью исполнителя не может создавать новых пользователей*/}
        {currentUserRole !== 7 && (
          <ButtonCustom
            hint={
              !userArr?.verify_status
                ? "Зайдите в почту и подтвердите email для добавления новых сотрудников!"
                : ""
            }
            title="Добавить"
            icon="plus_circle"
            style={{
              padding: ".5rem",
              width: "100px",
              marginLeft: "1rem",
              justifyContent: "space-between",
            }}
            onClick={() => {
              // history.push("/users/0");
              setUserId(0)
              setUserAddMode(true)
            }}
            disabled={!userArr?.verify_status}
          />
        )}
      </RightBlockStyle>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalContainer>
          <h2 style={{ fontSize: "22px", marginBottom: "5px" }}>
            Не удалось создать следующих сотрудников:
          </h2>
          {errorFileUsers &&
            Object.keys(errorFileUsers).map((key) => (
              <div key={key} className="card-panel">
                <ModalContentDiv>
                  {errorFileUsers?.[key] &&
                    errorFileUsers?.[key].map((user) => (
                      <p
                        key={nanoid()}
                        style={{
                          margin: "5px 0px",
                          color: "#F44336",
                          fontSize: "20px",
                        }}
                      >
                        {user}
                      </p>
                    ))}
                  <ModalContentH2>{key}</ModalContentH2>
                </ModalContentDiv>
              </div>
            ))}
          <Button
            style={{
              background: "#01B0E9",
              width: "180px",
              marginTop: "10px",
            }}
            onClick={(e) => handleClose(e)}
            variant="contained"
            color="primary"
          >
            Ок
          </Button>
          <a
            style={{
              width: "2px",
              height: "2px",
              position: "absolute",
              bottom: "1px",
              right: "1px",
              zIndex: 10000,
              cursor: "default",
            }}
            href="https://youtu.be/FTu_ndnh-wc?t=27"
            target="_black"
          ></a>
        </ModalContainer>
      </Modal>
    </UserActionStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users_length: state.commonInfo.users.length,
    tableFilter: state.usersPage.tableFilter,
    current_user: state.commonInfo.current_user,
    users: state.commonInfo.users,
    currentUserRole: state.commonInfo.currentUserInfo?.roles[0],
    wasFetchedSlowRequests: state.commonInfo.wasFetchedSlowRequests,
  };
};

const mapDispatchToProps = {
  setTableFilter,
  setUsers,
  fetchUsers,
  openDepGroupPlate,
  setCurrentPage,
  setUserId,
  setUserAddMode
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersActions);
