import { User } from "../../../common/types";

export const checkAddSectionRoles = (userInfo: User, project: any): boolean => {
  const newRoles = getNewRoles(userInfo.roles);
  
  if(!userInfo || !project) return false;
  
  return newRoles.length && newRoles[0] < 3 &&
        (project.curators.includes(userInfo.id) ||
          project.author_id === userInfo.id ||
          project.cpe_list.includes(userInfo.id));
};

export const checkAddProjectRoles = (userInfo: User): boolean => {
  const newRoles = getNewRoles(userInfo.roles);

  if(!userInfo) return false;

  return Boolean(newRoles.length && (newRoles[0] < 3));
};

// Скопировано из старого компонента, как работают роли - никто не знает
const getNewRoles = (currentUserRoles: number[]): number[] => {
  const newRolesArr = currentUserRoles?.map((item) => {
    return item;
  });
  
  return newRolesArr?.sort();
}
