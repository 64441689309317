import React, { useContext } from "react";
import { StatusContext } from "../../context/status.js";
import BpItemStatus from "../ui/bp-item-status/BpItemStatus";
import BpItemMenu from "../ui/bp-item-menu/BpItemMenu.jsx";
import TasksList from "../tasks-list/TasksList.jsx";
import { useState } from "react";
import { useEffect } from "react";
import infoBp from "../Icons/VectorBp.svg";
import messageBp from "../Icons/icon.chat.svg";
import moment from "moment";
import { fetchData } from "../../../../../utils/fetchData";

const BpItem = ({ el, redirect }) => {
  const {
    createBpStatus,
    createBpSampleStatus,
    setIdBp,
    openTasks,
    setOpenTasks,
    setOpenMenuBp,
    openMenuTasks,
    openMenuBp,
    setIdCall,
    setOpenMenuTasks,
    selectedBp,
    setSelectedBp,
    bp,
    createBpFromSample,
    setOpenMenuTask,
    users,
    projects,
    setTaskId,
    setShowChatWindow,
    setThisTabs,
    setRedirectedByClick,
    selectColumns,
  } = useContext(StatusContext);

  const [project, setProject] = useState();
  const [projectSection, setProjectSection] = useState({});
  const [statusDisplay, setStatusDisplay] = useState("none");
  const [reg, setReg] = useState(false);

  const openTasksMenu = (e) => {
    if (e.id === openTasks) {
      setOpenTasks("");
      setTaskId();
    } else {
      setOpenTasks(e.id);
    }
  };

  //раскрытие плитки меню в случае перехода к БП из задач через модальное окно
  useEffect(() => {
    if (redirect) {
      setOpenTasks("business-item-" + el.id);
      setSelectedBp(el.id);
      setIdCall(el.id);
      setOpenMenuTasks(false);
    } else {
      if (selectedBp) {
        setOpenTasks("business-item-" + selectedBp);
        setSelectedBp(selectedBp);
        setIdCall(selectedBp);
      }
      setOpenMenuTasks(false);
    }
  }, [redirect]);
  const [isAuthorInfo, setIsAuthorInfo] = useState();
  const [isExecutorInfo, setIsExecutorInfo] = useState();
  const [isExecutor2Info, setIsExecutor2Info] = useState();
  const [isExecutor3Info, setIsExecutor3Info] = useState();

  useEffect(() => {
    if (users.length) {
      const initiator = users.find((user) => user.id === el.initiator_id);

      if (!initiator) {
        fetchData
          .get(`/api/v1/users/${el.initiator_id}`)
          .then((res) => setIsAuthorInfo(res));
      } else {
        setIsAuthorInfo(initiator);
      }
    }
  }, [el, users]);

  useEffect(() => {
    if (users.length) {
    const executor = users.find((user) => user.id === el.executors[0]);
    const executor2 = users.find((user) => user.id === el.executors[1]);
    const executor3 = users.find((user) => user.id === el.executors[2]);
    if (!executor && el.executors.length) {
      

      fetchData
        .get(`/api/v1/users/${el.executors[0]}`)
        .then((res) => setIsExecutorInfo(res));
    } else {
      setIsExecutorInfo(executor);
    }
    if (!executor2 && el.executors.length > 1) {
      fetchData
        .get(`/api/v1/users/${el.executors[1]}`)
        .then((res) => setIsExecutor2Info(res));
    } else {
      setIsExecutor2Info(executor);
    }
    if (!executor3 && el.executors.length > 2) {
      fetchData
        .get(`/api/v1/users/${el.executors[2]}`)
        .then((res) => setIsExecutor3Info(res));
    } else {
      setIsExecutor3Info(executor);
    }
  }
  }, [el.executors,users]);

  useEffect(() => {
    if (projects)
      setProject((prev) =>
        projects.find((project) => project.id === el.project_id)
      );
  }, [projects, el, el.project_id]);

  useEffect(() => {
    if (project)
      setProjectSection(
        project.sections.find((section) => section.id === el.project_section_id)
      );
  }, [project, projectSection, el, el.project_section_id]);

  let duration = moment.duration(el.duration)._days;

  const durationBp = (days) => {
    if (days >= 2 && days <= 4) {
      return "дня";
    } else if (days === 1) {
      return "день";
    } else {
      return "дней";
    }
  };
 
  return (
    <div>
      <div
        className={
          createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
            ? "business__main-content__list-block__item business__main-content__list-block__item-active"
            : "business__main-content__list-block__item"
        }
      >
        <div
          id={"business-item-" + el.id}
          onClick={(e) => {
            setIdCall(el.id);
            if (el.id) setSelectedBp(el.id);

            // для закрытия меню "запуск с" в BpItemTaskMenu
            // Mount.current = false
            setOpenMenuTask(false);
            openTasksMenu(e.currentTarget);
            setTaskId();
            setReg(!reg);
          }}
        >
          <div className="business__main-content__list-block__item-left">
            <div
              className={
                createBpStatus ||
                createBpSampleStatus ||
                openMenuTasks ||
                openMenuBp
                  ? "item_left_name_one"
                  : "item_left_name"
              }
            >
              <div className="infoBp">
                <img src={infoBp} alt="infoBp" style={{ opacity: 0 }} />
                <div
                  style={{ marginLeft: "10px" }}
                  className={`business__main-content__list-block__item__arrow ${
                    reg && el.id === selectedBp
                      ? ""
                      : "item_id__top-btn__active"
                  }`}
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
                    alt="drop"
                    style={
                      el.tasks.length > 0 ? { opacity: 0.5 } : { opacity: 0 }
                    }
                  />
                </div>
              </div>
              {selectColumns.includes("id1") && (
                <div className={`item_id `}>{el.id}</div>
              )}
              <div
                className="business__main-content__list-block__item__message business__main-content__list-block__item__message-active"
                id={"business-item-btn-" + 1}
                onClick={(e) => {
                  e.stopPropagation();
                  setStatusDisplay("none");
                  setOpenMenuBp(true);
                  setShowChatWindow(true);
                  setRedirectedByClick(false);
                  setThisTabs(3);
                  setSelectedBp(el.id);
                  setTaskId();
                }}
                style={{ padding: "10px", display: "flex" }}
              >
                <div style={{ marginRight: "5px" }}>
                  <img
                    src={messageBp}
                    alt="message"
                    title="Чат бизнес-процесса"
                  />
                </div>
                <span>{el.commentsCount}</span>
                {el.countUnreadComments ? (
                  <span className="message_active"></span>
                ) : (
                  ""
                )}
              </div>

              {selectColumns.includes("name") && (
                <div
                  className={
                    createBpStatus ||
                    createBpSampleStatus ||
                    openMenuTasks ||
                    openMenuBp
                      ? "business__main-content__list-block__item__name_one"
                      : "business__main-content__list-block__item__name"
                  }
                >
                  <p className="p-black" title={el.name}>
                    {el.name}
                  </p>
                </div>
              )}
            </div>
            <div className="item_right_bp">
              {selectColumns.includes("author") && (
                <div style={{ width: "56px" }}>
                  <div
                    className="business__main-content__list-block__item__logo"
                    style={{
                      opacity: `${isAuthorInfo?.is_active ? "1" : "0.5"}`,
                    }}
                  >
                    {isAuthorInfo ? (
                      isAuthorInfo.image ? (
                        <img
                          title={
                            isAuthorInfo?.name +
                            " " +
                            isAuthorInfo?.surname +
                            " "
                          }
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isAuthorInfo?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span
                          title={
                            isAuthorInfo?.name + " " + isAuthorInfo?.surname
                          }
                        >
                          {isAuthorInfo?.name[0] + isAuthorInfo?.surname[0]}
                        </span>
                      )
                    ) : null}
                  </div>
                </div>
              )}
              {selectColumns.includes("initiator_id") && (
                <div className="logo_parent">
                  {isExecutorInfo ? (
                    <div
                      className="business__main-content__list-block__item__logo logo_task_1"
                      style={{
                        border: `${
                          isExecutorInfo?.is_active
                            ? "none"
                            : "4px solid orange"
                        }`,
                      }}
                    >
                      {isExecutorInfo && isExecutorInfo.image ? (
                        <img
                          title={isExecutorInfo?.name + isExecutorInfo?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isExecutorInfo?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span
                          title={isExecutorInfo?.name + ' ' + isExecutorInfo?.surname}
                        >
                            {
                              (isExecutorInfo?.name ? isExecutorInfo.name[0] : "") +
                              (isExecutorInfo?.surname ? isExecutorInfo.surname[0] : "")
                            }
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {el.executors.length > 1 && (
                    <div
                      className="business__main-content__list-block__item__logo logo_task_2"
                      style={{
                        border: `${
                          isExecutor2Info?.is_active
                            ? "none"
                            : "4px solid orange"
                        }`,
                      }}
                    >
                      {isExecutor2Info && isExecutor2Info.image ? (
                        <img
                          title={
                            isExecutor2Info?.name + isExecutor2Info?.surname
                          }
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isExecutor2Info?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span
                          title={
                            isExecutor2Info?.name +  ' ' + isExecutor2Info?.surname
                          }
                        >
                          {isExecutor2Info?.name[0] + ' ' +
                            isExecutor2Info?.surname[0]}
                        </span>
                      )}
                    </div>
                  )}
                  {el.executors.length > 2 && (
                    <div
                      className="business__main-content__list-block__item__logo logo_task_3"
                      style={{
                        border: `${
                          isExecutor3Info?.is_active
                            ? "none"
                            : "4px solid orange"
                        }`,
                      }}
                    >
                      {isExecutor3Info && isExecutor3Info.image ? (
                        <img
                          title={
                            isExecutor3Info?.name + isExecutor3Info?.surname
                          }
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${isExecutor3Info?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span
                          title={
                            isExecutor3Info?.name + isExecutor3Info?.surname
                          }
                        >
                          {isExecutor3Info?.name[0] +
                            isExecutor3Info?.surname[0]}
                        </span>
                      )}
                    </div>
                  )}
                  {el.executors.length > 3 && (
                    <div
                      style={{ background: "#F4F4F4" }}
                      className="business__main-content__list-block__item__logo logo_task_4 "
                    >
                      <span style={{ color: "rgba(41, 42, 52, 0.5)" }}>
                        +{el.executors.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              )}

              {selectColumns.includes("status") && (
                <BpItemStatus
                  deadline={el.deadline}
                  status={el.status}
                  id={el.id}
                  workflow={el.workflow_id}
                />
              )}

              {selectColumns.includes("deadline") &&
                (el.tasks.length > 0 ? (
                  <p className="business__main-content__list-block__item__deadline p-black">
                    {new Date(
                      el.deadline.replace(new RegExp("-", "g"), "/")
                    ).toLocaleString("ru", {
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                ) : (
                  <span className="business__main-content__list-block__item__deadline p-black">
                    __________
                  </span>
                ))}
              {selectColumns.includes("duration") && (
                <div style={{ width: "106px" }}>
                  {duration + " " + durationBp(duration)}
                </div>
              )}
              {selectColumns.includes("project_id") && (
                <div className="business__main-content__list-block__item__project">
                  <p className="p-black project-name">
                    {project?.name}
                  </p>
                  <span className="p-grey">
                    {projectSection?.name}
                  </span>
                </div>
              )}
              <div
                className="dropdown-menu__bpItem"
                onClick={(e) => {
                  e.stopPropagation();
                  setIdBp(+el.id);
                  setSelectedBp(+el.id);
                  setOpenMenuTask(false);
                  setStatusDisplay((prev) =>
                    prev === "none" ? "block" : "none"
                  );
                }}
              >
                <div
                  style={{
                    marginTop: "17px",
                    paddingRight: "10px",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/drop-down-menu.svg`}
                    alt="menu"
                    data-id={el.id}
                    className="img_menu_bp_item"
                  />
                  <BpItemMenu
                    el={el}
                    id={el.id}
                    status={el.status}
                    statusDisplay={statusDisplay}
                    setStatusDisplay={setStatusDisplay}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedBp(+el.id);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {openTasks === "business-item-" + el.id ? (
        <>
          <TasksList
            tasks={el.tasks}
            projectName={project && project.name ? project.name : ""}
            projectSectionName={
              projectSection && projectSection.name ? projectSection.name : ""
            }
          />
        </>
      ) : (
        <></>
      )}

      {createBpFromSample && bp.id == el.id && openTasks ? (
        <TasksList
          tasks={el.tasks}
          projectName={project && project.name ? project.name : ""}
          projectSectionName={
            projectSection && projectSection.name ? projectSection.name : ""
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default BpItem;
