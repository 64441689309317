import React from 'react';
import PeopleItem from './Items/People';
import styled from 'styled-components';
import { Item } from '../../types';

type Props = {
  users: Item[];
  selectedUsers: Item[];
  checked?: boolean;
  radio?: boolean;
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
  attached_items?: Item[];
  length_block?: number;
  withImage?: boolean;
};

const WrapperBlockPeople = styled.div`
  position: absolute;
  top: 2.75rem;
  z-index: 10000;
  max-height: 304px;
  width: 100%;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`;

const CheckboxPeople: React.FC<Props> = ({
  users,
  selectedUsers,
  removeHandler,
  addHandler,
  radio,
  attached_items,
  length_block,
  withImage,
}) => {
  return (
    <WrapperBlockPeople className='checkbox-people'>
      {attached_items?.length &&
        attached_items.map((user, number) => (
          <PeopleItem
            bottom_split={number === attached_items.length - 1}
            name={user.rus_name ? user.rus_name : user.text}
            name_cap_letters={user.name_cap_letters}
            key={user.value}
            value={+user.value}
            urlImage={user.urlImage}
            position={user.position}
            checked={selectedUsers.some((i) => i.value === user.value)}
            radio={radio}
            addHandler={addHandler}
            removeHandler={removeHandler}
          />
        ))}

      {users.map((user) => {
        return (
          <PeopleItem
            key={user.value}
            name={user.rus_name ? user.rus_name : user.text}
            name_cap_letters={user.name_cap_letters}
            value={+user.value}
            withImage={withImage}
            urlImage={user.urlImage}
            position={user.position}
            checked={selectedUsers.some((i) => i.value === user.value)}
            radio={radio}
            addHandler={addHandler}
            removeHandler={removeHandler}
            length_block={length_block}
          />
        );
      })}
    </WrapperBlockPeople>
  );
};

export default CheckboxPeople;
