import React, { useEffect, useState } from "react";
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { CreateNotif } from "../../../utils/createNotification";
import { connect } from "react-redux";
import { State } from "../../../rootReducer";
import { updateTask } from "../../actions";
import {
  recalculateTaskLoad,
  setTaskEnd,
} from "../../../pages/TaskInfoPlate/actions";
import moment from "moment";
import { datePicker } from "../../constants/constants";


type Props = {
  date: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  acceptHandler: (isoDate: string) => void;
  hideTimeBlock?: boolean;
  setTimeValid: (mode: boolean) => void;
  selectedTask: any;
  isDateEnd: boolean;
};


const TaskDateTime: React.FC<Props> = ({
                                         date,
                                         acceptHandler,
                                         minDate,
                                         maxDate,
                                         disabled,
                                         hideTimeBlock = false,
                                         setTimeValid,
                                         selectedTask,
                                         isDateEnd,
                                       }) => {
  const [resultDate, setResultDate] = useState("");
  const [dateInput, setDateInput] = useState(new Date());
  const [openDate, setOpenDate] = useState('');

  const [isValid, setIsValid] = useState(true);

  const minMaxDate = date => {
    let dateToObject =  moment(date, datePicker.DATE_OPEN_FORMAT);
    const minDate = moment(datePicker.DATE_MIN_FOR_MOMENT, datePicker.DATE_OPEN_FORMAT)
    const maxDate = moment(datePicker.DATE_MAX_FOR_MOMENT, datePicker.DATE_OPEN_FORMAT)

    // если введенная дата меньше минимально заданной - вернуть минимально заданную в constants
    if (dateToObject.isBefore(minDate)) {
      return minDate.format(datePicker.DATE_OPEN_FORMAT);
    }

    // если введенная дата больше минимально заданной - вернуть максимально заданную в constants
    if (dateToObject.isAfter(maxDate)) {
      return maxDate.format(datePicker.DATE_OPEN_FORMAT);
    }

    return date
  };

  useEffect(() => {
    // задает дату предварительно проверив через minMaxDate
    setOpenDate(minMaxDate(date));
  }, [date, minDate, maxDate]);


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <KeyboardDateTimePicker
        invalidDateMessage="Введите дату и время пожалуйста!"
        invalidLabel="Введите дату и время пожалуйста!"
        cancelLabel="Отменить"
        showTodayButton={true}
        todayLabel="Сейчас"
        ampm={false}
        minDate={new Date(datePicker.DATE_MIN_DEFAULT)}
        minDateMessage={"Введите дату и время пожалуйста!"}
        maxDate={new Date(datePicker.DATE_MAX_DEFAULT)}
        maxDateMessage={"Введите дату и время пожалуйста!"}
        allowKeyboardControl
        disabled={disabled}
        format="dd-MM-yyyy HH:mm"
        value={openDate}

        onChange={(date) => {
          if (!moment(date).isValid()) {
            setIsValid(false);
            return;
          } else {
            setIsValid(true);
          }

          if (date) {
            if (date.getFullYear() >= +datePicker.YEAR_MAX_DEFAULT || date.getFullYear() < +datePicker.YEAR_MIN_DEFAULT) {
              CreateNotif(
                "Ошибка в дате. Введите, пожалуйста, правильную дату!",
                "warning"
              );
            }

            setDateInput(date);

            let formatDateOnChange = moment(date).format(datePicker.DATE_FORMAT);
            setResultDate(formatDateOnChange)
          }
        }}


        onBlur={() => {
          if (!isValid) {
            const newDate = minMaxDate(date);

            setOpenDate(
              moment(newDate, datePicker.DATE_FORMAT)
                .add(Math.floor(Math.random() * 19) - 1, 'seconds')
                .format(datePicker.DATE_OPEN_FORMAT)
            );

            setResultDate('');
            return;
          }

          if (resultDate) acceptHandler(resultDate);

          setResultDate('');
          return;
        }}


        onAccept={(date) => {
          if (!isValid) {
            const newDate = minMaxDate(resultDate);

            setOpenDate(
              moment(newDate, datePicker.DATE_FORMAT)
                .add(1, 'seconds')
                .format(datePicker.DATE_OPEN_FORMAT)
            );

            return;
          }

          if (date) {
            let formatDateOnAccept = moment(date).format(datePicker.DATE_FORMAT);
            acceptHandler(formatDateOnAccept)
          }
        }}


        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state: State) => {
  return {
    minDate: state.taskInfoPlate.begin,
    maxDate: state.taskInfoPlate.end,
    selectedTask: state.taskInfoPlate.selectedTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskDateTime);