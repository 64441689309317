import { useEffect, useState } from 'react';
import { cookieMaster } from '../CookieMaster';


export const useAuthToken = () => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const token = cookieMaster.getCookie("access_token");
    setIsLogin(!!token);
  }, []);

  return isLogin;
};