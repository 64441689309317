import './PreloaderLocal.scss';

import React, { FC } from 'react';

const PreloaderLocal: FC<{
  classPostfix?: 'blue';
}> = ({
  classPostfix
}) => {
  return (
    <div className={
      'preloader-local' +
      (classPostfix ? ` preloader-local--${classPostfix}` : '')
    }>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
};

export default PreloaderLocal;
