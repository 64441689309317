import { useEffect, useState } from "react";
import { CheckContexts, Roles } from "../../common/constants/constants";
import { useSelector } from "react-redux";
import { State } from "../../rootReducer";
import { User } from "../../common/types";

export default function(context: string, conditions?:any) {
  const [isValid, setIsValid] = useState<boolean>(false);
  const currentUser = useSelector<State, User|null>(
    (state) => state.commonInfo.currentUserInfo
  );
  const headOfProjectIds = useSelector<State, number[]>(
    (state) => state.newProject.cpe_list
  );
  const observersProjectIds = useSelector<State, number[]>(
    (state) => state.newProject.spectators
  );
  
  useEffect(() => {
    if(currentUser) check();
  }, []);
  
  const check = () => {
    if(context === CheckContexts.IS_USER_CAN_SET_PROJECT_OBSERVERS) {
      const allowedRoles = [Roles.ADMIN, Roles.DIRECTOR];

      currentUser?.roles.forEach(role => {
        if(
          headOfProjectIds.includes(currentUser.id) ||
          allowedRoles.includes(role)
        ) {
          setIsValid(true);
        }
      });
    }

    if(context === CheckContexts.IS_USER_CAN_EDIT_AND_SAVE_PROJECT) {
      checkCanEditAndSaveProject();
    }
  }
  
  const checkCanEditAndSaveProject = () => {
    if(currentUser?.id) {
      setIsValid(!observersProjectIds.includes(currentUser.id));
    }

    // Добавление новой задачи
    if(conditions?.projectId === 0) {
      setIsValid(true);
    }
  }
  
  return isValid;
}
