import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import FileElem from "../../../common/components/FileElem/FileElem";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import { Comment, Task, File as FileType } from "../../../common/types";
import CommentElem from "./CommentElem";
import {
  deleteComment,
  uploadFile,
  deleteFile,
  setCommentText,
  sendComment,
  setParentComment,
  addCommentsList,
  getCommentsList,
  setComentList,
} from "../actions";
import ParentComment from "./ParentComment";
import { fetchData } from "../../../utils/fetchData";
import SendIcon from "@material-ui/icons/Send";
import { TextareaAutosize } from "@material-ui/core";
import { nanoid } from "nanoid";

type Props = {
  commentList: Comment[];
  selectedTask: Task | null;
  commentFiles: FileType[];
  text: string;
  isSending: boolean;
  parentComment: Comment | null;
  deleteComment: (taskId: number, commentId: number) => void;
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
  setCommentText: (text: string) => void;
  sendComment: (task_Id?: number) => void;
  setParentComment: (parent: Comment | null) => void;
  addCommentsList: (commentList: Comment[]) => void;
  pageNumber: number;
  getCommentsList: (num: number) => void;
  setComentList: (list) => void;
};

const CommentTab: React.FC<Props> = (props) => {
  const {
    commentList,
    text,
    isSending,
    commentFiles,
    selectedTask,
    parentComment,
    deleteComment,
    uploadFile,
    deleteFile,
    setCommentText,
    sendComment,
    setParentComment,
    addCommentsList,
    pageNumber,
    getCommentsList,
    setComentList,
  } = props;
  const [comments, setComments] = useState<any[]>([]);
  const [stopLoadingNewComments, setStopLoadingNewComments] =
    useState<boolean>(false);

  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (scrollHeight <= scrollTop + clientHeight) {
        if (!stopLoadingNewComments) {
          getCommentsList(pageNumber + 1);
        }
      }
    },
    [pageNumber, commentList.length]
  );

  useEffect(() => {
    (async () => {
      const taskId = selectedTask?.id;
      if (!taskId) return;

      if (!isSending) {
        const commentListResponse = await fetchData.get(
          `/api/v1/tasks/${taskId}/comments?order=created_at&page=${pageNumber}&orderType=desc`
        );

        if (commentListResponse.length < 20) setStopLoadingNewComments(true);

        if (commentListResponse) {
          if (pageNumber === 1) {
            setComments(commentListResponse);
            setComentList(commentListResponse);
          } else {
            setComments([...comments, ...commentListResponse]);
            setComentList([...commentList, ...commentListResponse]);
          }
        }
      }
    })();
  }, [pageNumber, selectedTask, isSending, commentFiles]);

  useEffect(() => {
    if (commentFiles.length) {
      let textarea_files = document.getElementsByClassName("textarea_files")[0]
        .parentNode as Element;
      for (let i = 0; i < textarea_files.classList.length; i++) {
        if (textarea_files.classList[i] === "empty") {
          textarea_files.classList.remove("empty");
        }
      }
    }
  }, [commentFiles]);

  let textarea_parent_ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={"add_new_comment"}>
        <div className={"main_part empty"} ref={textarea_parent_ref}>
          <div className={"textarea_files"}>
            <TextareaAutosize
              aria-label="comment_textarea"
              placeholder="Введите комментарий"
              autoFocus
              onChange={(e) => {
                // @ts-ignore
                let parNode = e.currentTarget.parentNode.parentNode as Element;
                if (!e.currentTarget.value.length)
                  parNode.classList.add("empty");
                else parNode.classList.remove("empty");

                setCommentText(e.currentTarget.value);
              }}
              value={text}
            />

            {commentFiles.length > 0 && (
              <div className={"attachments"}>
                {commentFiles.map((elem) => (
                  <FileElem
                    url={elem.url}
                    key={nanoid()}
                    id={elem.id}
                    name={elem.name}
                    deleteHandler={deleteFile}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={"send_button"} onClick={() => sendComment()}>
            <SendIcon />
          </div>

          <label htmlFor="comment_files" className={"attach_file"}>
            <div className={"attachment_icon"} />
            <input
              onChange={(e) => {
                let files = e.currentTarget.files;
                if (!files) return;
                for (let i = 0; i < files?.length; i++) uploadFile(files[i]);
              }}
              type="file"
              id="comment_files"
              multiple={true}
            />
          </label>
        </div>
      </div>

      {parentComment && (
        <div className="answer_to">
          <ParentComment parentComment={parentComment} />
          <div onClick={() => setParentComment(null)} className="close_btn" />
        </div>
      )}
      {/* <div */}
      {/*    ref={dragArea}*/}
      {/* {' '} */}
      {/*    className={`comment_data${isDragEnter ? ' is_drag_enter' : ''}${parentComment ? ' with_parent' : ''}`}*/}
      {/*    onDrop={dropHandler}*/}
      {/*    onDragOver={e => e.preventDefault()}*/}
      {/*    onDragEnter={dragEnterHandler}*/}
      {/*    onDragLeave={dragLeaveHandler}*/}
      {/*> */}
      {/*<textarea rows={1}*/}
      {/*          onKeyDown={function (e) {*/}
      {/*              if (e.currentTarget.value.length) {*/}
      {/*                  // 2 варианта хоткеев для разных браузеров*/}
      {/*                  if (e.key !== undefined) {*/}
      {/*                      if (e.ctrlKey && e.key === 'Enter') {*/}
      {/*                          sendComment()*/}
      {/*                      }*/}
      {/*                  } else if (e.keyCode !== undefined) {*/}
      {/*                      if (e.ctrlKey && e.keyCode == 13) {*/}
      {/*                          sendComment()*/}
      {/*                      }*/}
      {/*                  }*/}
      {/*              }*/}
      {/*          }}*/}
      {/*          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentText(e.currentTarget.value)}*/}
      {/*          placeholder="Введите комментарий"*/}
      {/*          value={text}*/}
      {/*/>*/}
      {/*<input onChange={e => {*/}
      {/*    let files = e.currentTarget.files*/}
      {/*    if (!files) return*/}
      {/*    for (let i = 0; i < files?.length; i++) {*/}
      {/*        uploadFile(files[i])*/}
      {/*    }*/}
      {/*}} type="file" id="comment_files" multiple={true}/>*/}
      {/*<label htmlFor="comment_files">Выберите файл</label>*/}
      {/*<div className="comment_actions">*/}
      {/*<p>Или перетяните сюда</p>*/}
      {/*<div*/}
      {/*    onClick={sendComment}*/}
      {/*    className={`btn primary${isSending ? ' in_load' : ''}${(!text && commentFiles.length === 0) ? ' disabled' : ''}`}*/}
      {/*>*/}
      {/*    Отправить*/}
      {/*</div>*/}
      {/*</div> */}
      {/*</div>*/}
      <div className="comment_list" onScroll={scrollHandler}>
        {comments.map((comment) => (
          <CommentElem
            key={comment.id}
            comment={comment}
            deleteHandler={() => deleteComment(selectedTask!.id, comment.id)}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    pageNumber: state.taskInfoPlate.pageNum,
    commentList: state.taskInfoPlate.commentList,
    commentFiles: state.taskInfoPlate.commentFiles,
    selectedTask: state.taskInfoPlate.selectedTask,
    text: state.taskInfoPlate.text,
    isSending: state.taskInfoPlate.isSendingComment,
    parentComment: state.taskInfoPlate.parentComment,
  };
};

const mapDispatchToProps = {
  deleteComment,
  uploadFile,
  deleteFile,
  setCommentText,
  sendComment,
  setParentComment,
  addCommentsList,
  getCommentsList,
  setComentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentTab);
