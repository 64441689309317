// Точка входа для страницы со списком всех задач для раздела проекта, 
// после клика на кнопке в проектах Все задачи
// Роутинг: /projects/4

// Меню для одного "Проекта". 
// . Отсюда идут два меню- таблица (задач в проекте), дерево (задач в проекте) */

import React, {useState, useEffect, useLayoutEffect } from 'react';
import ProjectActions from '../ProjectActions';
import ProjectTasksTable from './components/ProjectTasksTable/ProjectTasksTable';
import {
  changeTaskList,
  getAllProjects,
  selectProject,
  setAllProjects,
  setTableOrTreeToggler,
} from '../../actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Project } from '../../../../common/types';
import { State } from '../../../../rootReducer';
import { useParams } from 'react-router-dom';
import ProjectTree from './components/ProjectTree/ProjectTree';
import { fetchUpdateData } from '../../../../utils/fetchUpdateData';
import { fetchData } from '../../../../utils/fetchData';
import {
  updateNewNotification,
  updateTimestamp,
} from '../../../../common/actions';
import { timeUpdate } from '../../../../App';
import { cookieMaster } from '../../../../utils/CookieMaster';

type Props = {
  projects: Project[];
  selectProject: (project: Project) => void;
  setTableOrTreeToggler: (val: string) => void;
  tableOrTreeToggler: string;
  businessProcessesModalShow: boolean;
  getAllProjects: () => void;
};

const ProjectTasks: React.FC<Props> = ({
  projects,
  selectProject,
  setTableOrTreeToggler,
  tableOrTreeToggler,
  getAllProjects
}) => {
  // Если пользователь не залогинен - переводим его на страницу авторизации
  useLayoutEffect( ()=>{
     let token = cookieMaster.getCookie('access_token'); 
     if ((token === undefined) || (token === null  ))  { window.location.replace(`/login`)} 
     if (token.length == 0) { window.location.replace(`/login`)} 
  },[])
  
  const [checked, setChecked] = useState(false);
  const [changeProject, setChangeProject] = useState(false); // меняется при выборе другого проекта или этого же заново
  const { id } = useParams();
  const dispatch = useDispatch();
  const timestamp = useSelector((state: State) => state.commonInfo.timestamp);
  const selectedProjectID = useSelector(
    (state: State) => state.projectsPage.selectedProject?.id
  );
  const selectedProject = useSelector(
    (state: State) => state.projectsPage.selectedProject
  );
  const tasksProject = useSelector((state: State) => state.projectsPage.tasks);

  const templateProjects = [...projects];
  const templateTasks = [...tasksProject];

  useEffect(() => {
    if (id) {
      let project = projects.find((pr) => pr.id === parseInt(id));
      if (project) selectProject(project);
    }
  }, [id, projects, selectProject]);

  useEffect(() => {
    setTableOrTreeToggler(
      localStorage.getItem('project_show_toggle') as string
    );
  }, [localStorage.getItem('project_show_toggle')]);

  if (
    [null, undefined, ''].indexOf(localStorage.getItem('project_show_toggle')) >
    -1
  ) {
    localStorage.setItem('project_show_toggle', 'table'); // инициализация
  }
  
  useEffect(() => {
    if (timestamp === '') {
      let day =
        new Date().getDate() > 9
          ? new Date().getDate()
          : '0' + new Date().getDate();
      let month =
        new Date().getMonth() > 9
          ? Number(new Date().getMonth()) + 1
          : '0' + (Number(new Date().getMonth()) + 1);
      let year = new Date().getFullYear();

      let dataString = `${year}-${month}-${day} ${new Date().toLocaleTimeString()}`;

      fetchData
        .get(`/api/v1/update-data?timestamp=${dataString}`)
        .then(({ timestamp }) => dispatch(updateTimestamp(timestamp)))
        .catch((error) => console.warn(error));
    } else {
      // Отдельная задача - проверить на бэке, почему возникает ошибка. С фронта запрос уходит корректный
      // После исправления на бэке, вернуть этот кусок кода
      /*const intervalFetch = setInterval(() => {
        fetchUpdateData(timestamp, 'projects', [selectedProjectID])
          ?.then(
            ({
              timestamp,
              notifications,
              projects: updateProjects,
              task_projects,
            }) => {
              dispatch(updateTimestamp(timestamp));

              if (updateProjects?.length > 0) {
                updateProjects.forEach((updateProject, i) => {
                  templateProjects.find((el, index) => {
                    if (el.id === updateProject.id) {
                      templateProjects.splice(index, 1, updateProject);
                    }
                  });
                  if (updateProject.id === selectedProject?.id) {
                    selectProject(updateProject);
                  }
                });
                dispatch(setAllProjects(templateProjects));
              }
              if (task_projects?.length > 0) {
                task_projects.forEach((updateTask) => {
                  templateTasks.map((el, index) => {
                    if (el.id === updateTask.id && !updateTask?.delete) {
                      templateTasks.splice(index, 1, updateTask);
                    } else if (el.id === updateTask.id && updateTask?.delete) {
                      templateTasks.splice(index, 1);
                    } else if (
                      templateTasks.length === ++index &&
                      updateTask?.create
                    ) {
                      templateTasks.unshift(updateTask);
                    }
                  });
                });
                dispatch(changeTaskList(templateTasks));
              }
              if (notifications?.length > 0) {
                notifications.map((el) => {
                  if (el.hasOwnProperty('create')) {
                    dispatch(updateNewNotification([el]));
                  }
                });
              }
            }
          )
          .catch((error) => console.warn(error));
      }, timeUpdate);

      return () => {
        clearInterval(intervalFetch);
      };*/
    }
  }, [selectedProjectID, changeProject]); // меняется при выборе другого проекта или этого же заново

  useEffect(() => {
    if(!projects.length) {
      getAllProjects();
    }
  }, []);
  
  return (
    <>
      <ProjectActions 
        setChecked={setChecked} 
        checked={checked} 
        changeProject={changeProject} 
        setChangeProject={setChangeProject}
      />
      
      {tableOrTreeToggler === 'table' && <ProjectTasksTable />}
      {tableOrTreeToggler === 'tree' && <ProjectTree checked={checked}/>}
    </>
  );
};
const mapStateToProps = (state: State) => {
  return {
    projects: state.projectsPage.projectsAll,
    tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
    businessProcessesModalShow: state.projectsPage.businessProcessesModalShow,
  };
};
const mapDispatchToProps = {
  selectProject,
  setTableOrTreeToggler,
  getAllProjects
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectTasks);
