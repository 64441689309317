import React from 'react';
import styled from 'styled-components';
import { urlApp } from '../../../App';
import Icon from '../Icon';
import palette from '../palette';

type Props = {
  text?: string;
  image?: string | boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  chipsWidth?: number;
};

const WrapperChips = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  background: ${palette.bgChips};
  color: ${({ disabled }) =>
    disabled ? `${palette.gray}` : `${palette.textBlack}`};
  margin: 0.25rem 0.25rem 0.25rem 0;
  padding: 5px;
  border-radius: 16px;
`;

const WrapperAvatar = styled.div`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  
  > img {
    object-fit: cover;
  }
`;

const ChipsBox = styled.div`
  margin: 0 0.75rem 0 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;

  ${({ chipsWidth }) =>
    chipsWidth ? `${'max-width: ' + chipsWidth + 'px;'}` : null}
`;

const Chips: React.FC<Props> = ({
  text,
  image,
  chipsWidth,
  disabled = false,
  onClick,
}) => {
  return (
    <WrapperChips disabled={disabled}>
      {image && (
        <WrapperAvatar>
          <Icon
            width="100%"
            height="100%"
            src={image ? urlApp + `/files/${image}/download` : ''}
          />
        </WrapperAvatar>
      )}

      <ChipsBox chipsWidth={chipsWidth} title={text}>
        {text}
      </ChipsBox>
      {!disabled && (
        <Icon
          name="rossDurkBlue"
          onClick={onClick}
          cursor="pointer"
          style={{ marginRight: '0.5rem' }}
        />
      )}
    </WrapperChips>
  );
};

export default Chips;
