import {
  types,
  fetchCommonInfoAction,
  enqueueSnackbarAction,
  closeSnackbarAction,
  removeSnackbarAction,
  setUsersAction,
  setDepartmentsAction,
  setWorkgroupsAction,
  setSectionsAction,
  updateTaskAction,
  setVerifiedAction,
  fetchExistingUserDataAction,
  setCurrentUserInfoAction,
  setShowNotifWindow,
  setChangeRemoteNotifs,
  setMarkNotificationRead,
  setLoadNextPageNotifications,
  changeWorkScheduleAction,
  clearNotificationsAction,
  updateTimestampAction,
  updateNewNotificationAction,
  setShowCompanyWindow,
  setFiltersAction,
  filterTemplateAction,
  ShowFiltersWindowAction,
  defaultfilterTemplateAction,
  setShowAnimateCompanyWindow,
  setAddedMeetingAction,
  setCreatedTaskInfoAction,
  setPositionsAction,
  setSearchMeetingsAction,
  getSearchProjectsAction,
  setSearchClearAction,
  setSearchProjectsAction,
  setIdActiveTaskAction,
  updateProjectByIdAction,
  getOneProjectAction,
  getProjectsAction,
  setLoadingProjectsAction,
  getPositionsFromServerAction,
  updatePositionsAction,
  addCommonInfoFastAction,
  addCommonInfoSlowAction,
  getProjectsForSelectorAction,
  setProjectsForSelectorAction,
  setProjectsForSelectorLoadingAction,
  setProjectsForSelectorMetaAction,
  setSelectedProjectAction, setProjectsForSelectorCurrentPageAction, searchProjectsForSelectorAction
} from './action_types';
import {
  User,
  Department,
  Workgroup,
  Section,
  Schedule,
  Position,
  Notification,
  Priority,
  Status,
  Role, 
  IMeta
} from './types';
import { TProjectForSelect, TSelectOption } from "./interfaces/select";

export const fetchCommonInfo = (): fetchCommonInfoAction => ({
    type: types.FETCH_COMMON_INFO,
});

export const addCommonInfoFast = (
  priorities: Priority[],
  statuses: Status[],
  roles: Role[],
  workgroups: Workgroup[],
  wasFetchedFastRequests: boolean
): addCommonInfoFastAction => ({
  type: types.ADD_COMMON_INFO_FAST,
  priorities,
  statuses,
  roles,
  workgroups,
  wasFetchedFastRequests
});

export const addCommonInfoSlow = (
  users: User[],
  positions: Position[],
  departments: Department[],
  sections: Section[],
  unread_notifications: Notification[],
  read_notifications: Notification[],
  load_next: string|[],
  end: boolean,
  company: any,
  wasFetchedSlowRequests: boolean
): addCommonInfoSlowAction => ({
  type: types.ADD_COMMON_INFO_SLOW,
  users,
  positions,
  departments,
  sections,
  unread_notifications,
  read_notifications,
  load_next,
  end,
  company,
  wasFetchedSlowRequests
});

export const changeWorkSchedule = (
    schedule: Schedule[]
): changeWorkScheduleAction => ({
    type: types.CHANGE_WORK_SCHEDULE,
    schedule,
});

export const enqueueSnackbar = (notification: any): enqueueSnackbarAction => {
    const key = notification.options && notification.options.key;
    return {
        type: types.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || performance.now(),
        },
    };
};

export const closeSnackbar = (key: number): closeSnackbarAction => ({
    type: types.CLOSE_SNACKBAR,
    key,
});

export const removeSnackbar = (key: number): removeSnackbarAction => ({
    type: types.REMOVE_SNACKBAR,
    key,
});

export const setUsers = (users: User[]): setUsersAction => ({
    type: types.SET_USERS,
    users,
});

export const setCreatedTaskInfo = (task: any): setCreatedTaskInfoAction => ({
    type: types.SET_CREATED_TASK_INFO,
    task,
});

export const setAddedMeeting = (meeting: any): setAddedMeetingAction => ({
    type: types.SET_ADDED_MEETING,
    meeting,
});

export const setDepartments = (
    departments: Department[]
): setDepartmentsAction => ({
    type: types.SET_DEPARTMENTS,
    departments,
});

export const setWorkgroups = (
    workgroups: Workgroup[]
): setWorkgroupsAction => ({
    type: types.SET_WORKGROUPS,
    workgroups,
});

export const setSections = (sections: Section[]): setSectionsAction => ({
    type: types.SET_SECTIONS,
    sections,
});

export const setPositions = (positions: Position[]): setPositionsAction => ({
    type: types.SET_POSITIONS,
    positions,
});

export const updatePositions = (positions: Position[]): updatePositionsAction => ({
  type: types.UPDATE_POSITIONS,
  positions
});

export const getPositionsFromServer = (positionIds: number[]): getPositionsFromServerAction => ({
  type: types.GET_POSITIONS_FROM_SERVER,
  positionIds
});

export const updateTask = (
    taskId: string|number,
    params: any,
    withNotif: boolean = true
): updateTaskAction => ({
    type: types.UPDATE_TASK,
    taskId,
    params,
    withNotif,
});

export const setSearchMeetings = (search: any): setSearchMeetingsAction =>({
    type: types.SET_SEARCH_MEETINGS,
    search
});

export const getSearchProjects = (search: string | null): getSearchProjectsAction =>({
    type: types.GET_SEARCH_PROJECTS,
    search
});

export const setSearchProjects = (search: string | null): setSearchProjectsAction =>({
    type: types.SET_SEARCH_PROJECTS,
    search
});

export const setVerified = (verified: boolean): setVerifiedAction => ({
    type: types.SET_VERIFYED,
    verified,
});

export const fetchExistingUserData = (
    id: number
): fetchExistingUserDataAction => ({
    type: types.FETCH_EXISTING_USER_DATA,
    id,
});

export const setCurrentUserInfo = (user: User): setCurrentUserInfoAction => ({
    type: types.SET_CURRENT_USER_INFO,
    user,
});

export const showNotifWindow = (val: boolean): setShowNotifWindow => ({
    type: types.SET_SHOW_NOTIF_WINDOW,
    val,
});
export const showAnimateCompanyWindow = (value: boolean): setShowAnimateCompanyWindow => ({
    type: types.SET_ANIMATE_SHOW_COMPANY_WINDOW,
    value,
});
export const showCompanyWindow = (value: boolean): setShowCompanyWindow => ({
    type: types.SET_SHOW_COMPANY_WINDOW,
    value,
});
export const mark_notification_as_read = (
    id_notification: string,
    id_current_user: number,
    readonly?: boolean
): setMarkNotificationRead => ({
    type: types.SET_NOTIFICATION_READ,
    id_notification,
    id_current_user,
    readonly,
});

export const change_remote_notifications = (
    unread: object,
    read: object,
    load_next?: string,
    end?: boolean,
    offset?: number
): setChangeRemoteNotifs => ({
    type: types.SET_REMOTE_NOTIFICATIONS,
    unread,
    read,
    load_next,
    end,
    offset,
});

export const load_nextpage_notifications = (
    context: string
): setLoadNextPageNotifications => ({
    type: types.SET_LOAD_NEXTPAGE_NOTIFICATIONS,
    context,
});

export const clearNotifications = (): clearNotificationsAction => ({
    type: types.CLEAR_NOTIFICATIONS,
});

export const updateTimestamp = (timestamp): updateTimestampAction => ({
    type: types.UPDATE_TIMESTAMP,
    timestamp,
});

export const setFilters = (date_from, date_to, executors, authors, statuses,actual): setFiltersAction => ({
    type: types.SET_FILTERS,
    date_from,
    date_to,
    executors,
    authors,
    statuses,
    actual
});

export const FilterTemplates = (data): filterTemplateAction => ({
    type: types.SET_FILTER_TEMPLATES,
    data
});

export const DefaultFilterTemplates = (data): defaultfilterTemplateAction => ({
    type: types.SET_DEFAULT_FILTER_TEMPLATES,
    data
});

export const ShowFiltersWindow = (): ShowFiltersWindowAction => ({
    type: types.SHOW_FILTERS_WINDOW,
});

export const updateNewNotification = (
    newNotifications
): updateNewNotificationAction => ({
    type: types.UPDATE_NEW_NOTOFICATION,
    newNotifications,
});

export const setSearchClear = (isSearchCleared, isNeedReload): setSearchClearAction => ({
    type: types.SET_SEARCH_CLEAR,
    isSearchCleared,
    isNeedReload,
});

export const setIdActiveTask = (idActiveTask): setIdActiveTaskAction => ({
    type: types.SET_ID_ACTIVE_TASK,
    idActiveTask
});

export const updateProjectById = (project: {id: string}): updateProjectByIdAction => ({
  type: types.UPDATE_PROJECT_BY_ID,
  project
});

export const getOneProject = (id: number|string): getOneProjectAction => ({
  type: types.GET_ONE_PROJECT,
  id
});

export const getProjects = (pageNum: number): getProjectsAction => ({
  type: types.GET_PROJECTS,
  pageNum
});

export const setLoadingProjects = (isLoadingProjects: boolean): setLoadingProjectsAction => ({
  type: types.SET_LOADING_PROJECTS,
  isLoadingProjects
});

export const getProjectsForSelector = (params?: any, needTodo?: string): getProjectsForSelectorAction => ({
  type: types.GET_PROJECT_FOR_SELECTOR,
  params,
  needTodo
});

export const setProjectsForSelector = (projects: TProjectForSelect[]|null, needTodo?: string): setProjectsForSelectorAction => ({
  type: types.SET_PROJECT_FOR_SELECTOR,
  projects,
  needTodo
});

export const setProjectsForSelectorLoading = (isProjectsForSelectLoading: boolean): setProjectsForSelectorLoadingAction => ({
  type: types.SET_PROJECT_FOR_SELECTOR_LOADING,
  isProjectsForSelectLoading
});

export const setProjectsForSelectorMeta = (projectsForSelectMeta: IMeta|null): setProjectsForSelectorMetaAction => ({
  type: types.SET_PROJECT_FOR_SELECTOR_META,
  projectsForSelectMeta
});

export const setSelectedProject = (selectedProject: TSelectOption|null): setSelectedProjectAction => ({
  type: types.SET_SELECTED_PROJECT,
  selectedProject
});

export const setProjectsForSelectorCurrentPage = (currentPage: number): setProjectsForSelectorCurrentPageAction => ({
  type: types.SET_PROJECT_FOR_SELECTOR_CURRENT_PAGE,
  currentPage
});

export const searchProjectsForSelector = (search: string|null): searchProjectsForSelectorAction => ({
  type: types.SEARCH_PROJECT_FOR_SELECTOR,
  search
});
