import React, { useState, useEffect, useCallback, useRef } from "react";
import { Task } from "../../../../../../common/types";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { useParams } from "react-router-dom";
import { fetchTasks, setProjectTasksCurrentPage } from "../../../../actions";
import styled from "styled-components";
import TaskTr from "../../../../../Tasks/components/TaskTr";
import TasksTableHead from "../../../../../Tasks/components/TasksTableHead";
import { ETaskTableContext } from "../../../../../Tasks/constants";
import { TTaskOrder } from "../../../../../Tasks/interfaces";

type Props = {
  tasks: Task[];
  fetchTasks: (projectId: number, pageNum: number) => void;
  setProjectTasksCurrentPage: (page: number) => void;
  projectTasksCurrentPage: number,
  tasksOrder: TTaskOrder
};

const WrapperTasksTable = styled.div`
  height: calc(100vh - 214px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
`;

const WrapperTasks = styled.div`
  width: 100%;
`;

const ProjectTasksTableContent: React.FC<Props> = ( {
  tasks,
  fetchTasks,
  setProjectTasksCurrentPage,
  projectTasksCurrentPage,
  tasksOrder
}) => {
  const [idOfTaskToShow, setIdOfTaskToShow] = useState<number>(0);
  const [showSidePlate, setShowSidePlate] = useState<boolean>(false);

  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      
      if (
        scrollHeight <= scrollTop + clientHeight &&
        tasks.length === 20 * projectTasksCurrentPage
      ) 
      {
        setProjectTasksCurrentPage(projectTasksCurrentPage + 1);
      }
    },
    [projectTasksCurrentPage, tasks.length, setProjectTasksCurrentPage]
  );

  const { id }: any = useParams();
  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if(projectTasksCurrentPage !== 1) {
      fetchTasks(id, projectTasksCurrentPage);
    }
  }, [projectTasksCurrentPage]);

  useEffect(() => {
    setProjectTasksCurrentPage(1);
  }, [id]);

  useEffect(() => {
    if(tasksOrder?.col_name.length) {
      setProjectTasksCurrentPage(1);
      fetchTasks(id, 1);
    }
    
  }, [tasksOrder]);

  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;

    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <>
      <TasksTableHead 
        contextCall={ETaskTableContext.PROJECTS_PAGE}
        isScroll={isScroll}
      />
      
      <WrapperTasksTable 
        className='project-table-content' 
        onScroll={scrollHandler} 
        ref={ref}
      >
        <WrapperTasks className='project-table-content__items'>
          {tasks.map((task: Task) => (
            <TaskTr
              idOfTaskToShow={idOfTaskToShow}
              setIdOfTaskToShow={setIdOfTaskToShow}
              showSidePlate={showSidePlate}
              setShowSidePlate={setShowSidePlate}
              key={task.id}
              task={task}
              disableUrl
            />
          ))}
        </WrapperTasks>
      </WrapperTasksTable>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    tasks: state.projectsPage.tasks,
    projectTasksCurrentPage: state.projectsPage.projectTasksCurrentPage,
    tasksOrder: state.projectsPage.tasksOrder
  };
};

const mapDispatchToProps = {
  fetchTasks,
  setProjectTasksCurrentPage
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTasksTableContent);
