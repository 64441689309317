import React, { useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { State } from "../rootReducer";
import {
  setFilterWindowShow,
  setShowColumnsCustom,
  setShowCols,
} from "../pages/Tasks/actions";
import {
  setShowColumnsProjectsCustom,
  setShowProjectsCols,
} from "../pages/Projects/actions";
import ColumnsCheckboxItem from "./components/ColumnsCustomItem/ColumnsCheckboxItem";
import { columnsOfTables } from "./shared_data";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type Props = {
  setShowColumnsCustom: (show: boolean) => void;
  setShowColumnsProjectsCustom: (show: boolean) => void;
  setShowCols: (value: string) => void;
  setShowProjectsCols: (value: string) => void;
  context: string;
  state: State;
};

const ColumnsWindow: React.FC<Props> = ({
  context,
  state,
  setShowProjectsCols,
  setShowColumnsCustom,
  setShowColumnsProjectsCustom,
  setShowCols,
}) => {
  // self-close for window
 let plate = useRef<HTMLDivElement>(null);
 /*  const outsideClickHandler = useCallback(
    function (e: Event): any {
      if (!plate.current?.closest("columnsCustomWindow")) {
       // setShowColumnsCustom(false);
      }
    },
    [setFilterWindowShow]
  );
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);
*/

const handleClickAway = () => {
  setShowColumnsCustom(false);
}

  let activeColumns =
    context === "tasks"
      ? state.tasksPage.activeColumns
      : state.projectsPage.activeColumns;

  const constValues = [
    {
      columnId: "DEFAULT",
      showingText: "По умолчанию",
    },
    {
      columnId: "ALL",
      showingText: "Все колонки",
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
    <div className={"columnsCustomWindow"} ref={plate}>
    <ColumnsCheckboxItem
        disabled={false}
        data={constValues[0]}
        withIcon={false}
        checked={
         !activeColumns.includes("project_name") &&
//          !activeColumns.includes("executor_id") &&
//       activeColumns.length === columnsOfTables.length - 2
        activeColumns.length === columnsOfTables.length -1
        }
        handler={(val) => {
          context === "tasks" ? setShowCols(val) : setShowProjectsCols(val);
        }}
      />

      <ColumnsCheckboxItem
        disabled={false}
        data={constValues[1]}
        withIcon={false}
        checked={activeColumns.length === columnsOfTables.length}
        handler={(val) => {
          context === "tasks" ? setShowCols(val) : setShowProjectsCols(val);
        }}
      />

      {Object.keys(columnsOfTables).map((key) => {
        return (
          <ColumnsCheckboxItem
            key={key}
            disabled={parseInt(key) === 1 || parseInt(key) === 2}
            data={columnsOfTables[key]}
            withIcon={true}
            checked={activeColumns.includes(columnsOfTables[key].columnId)}
            handler={(val) => {
              context === "tasks" ? setShowCols(val) : setShowProjectsCols(val);
            }}
          />
        );
      })}
    </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: State) => {
  return {
    state,
  };
};

const mapDispatchToProps = {
  setShowColumnsProjectsCustom,
  setShowColumnsCustom,
  setShowProjectsCols,
  setShowCols,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsWindow);
