import "./PaginationBp.scss";

import React, { useContext, useEffect, useState } from "react";
import Pagination from "rc-pagination";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useParams } from "react-router-dom";
import axios from "axios";
import { StatusContext } from "../../../context/status";

import {ReactComponent as LeftIcon} from '../../../../../../img/TrueIcons/arrow-left.svg';
import {ReactComponent as RightIcon} from '../../../../../../img/TrueIcons/arrow-right.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: 60,
      marginRight: 20,
    },
    selectEmpty: {},
  })
);
const DivSelect = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
`;
const WrapperPagination = styled.div`
  margin: 5px auto;
  display: flex;
  align-items: center;
`;

const PaginationBp = ({}) => {
  const {
    company_id,
    bearer,
    apiBp,
    setBpList,
    paginationMeta,
    setPaginationMeta,
    filter,
    filterMethod,
    openFilter
  } = useContext(StatusContext);

  const DEFAULT_LIMIT = 15;
  
  const classes = useStyles();
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState(1); // Номер страницы на которую мы нажимаем
  const [limitTasksPages, setLimitTasksPages] = useState(DEFAULT_LIMIT); // лимит наших задач которые будут показываться

  let token = document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  let myHeader = {
    Authorization: bearer,
    "company-id": company_id,
    "secret-token": token,
  };
  
  const fetchContent = () => {
    let url = '';
    
    const limit = `perPage=${limitTasksPages}`;
    const order = filterMethod === 'desc' ? '-' : '';
    
    if(filter === 'id1') {
      url += `sort=${order}id`;
    }
    
    if(filter !== 'id1') {
      url += `sort=${order}${filter}`;
    }

    return paginationMeta?.links?.map((linkObj) => {
      if(+linkObj.label === pageNumber) {
        let urlLocal = '';

        if(linkObj.url?.indexOf(url) === -1) {
          urlLocal += `&${url}`;
        }
        
        if(linkObj.url?.indexOf(limit) === -1) {
          urlLocal += `&${limit}`;
        }

        axios
          .get(linkObj.url + urlLocal, {
            headers: myHeader,
          })
          .then((response) => {
            setPaginationMeta(response.data.meta);
            setBpList(response.data.data);
          })
          .catch((error) => console.warn(error));
      }
    });
  };

  const onClickLimitTasks = (e) => {
    setPageNumber(1);
    setLimitTasksPages(e.target.value);
  };
  const onPageClickNumber = (number) => {
    setPageNumber(number);
  };
  
  useEffect(() => {
    fetchContent();
  }, [limitTasksPages, pageNumber]);
  
  useEffect(() => {
    setPageNumber(1);
  }, [id]);

  useEffect(() => {
    setPageNumber(1);
    setLimitTasksPages(DEFAULT_LIMIT);
  }, [filter, filterMethod, openFilter]);
  
  return (
    <>
      {paginationMeta.total > 1 && (
        <WrapperPagination className='pagination-bp'>
          <Pagination
            showTitle={false}
            onChange={(e) => onPageClickNumber(e)}
            className="ant-pagination"
            defaultCurrent={1}
            total={paginationMeta?.last_page * 10}
            current={paginationMeta?.current_page}
            prev_page={paginationMeta?.links[0]}
            next_page={paginationMeta?.links[-1]}
            prevIcon={
              <button type="button" aria-label="prev page" className="rc-pagination-item-link">
                <LeftIcon />
              </button>
            }
            nextIcon={
              <button type="button" aria-label="next page" className="rc-pagination-item-link">
                <RightIcon />
              </button>
            }
          />
          
          <DivSelect>
            <span>Показать задач</span>

            <FormControl className={classes.formControl}>
              <Select
                value={limitTasksPages}
                onChange={onClickLimitTasks}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
                disableUnderline
                defaultValue={DEFAULT_LIMIT}
              >
                <MenuItem value={DEFAULT_LIMIT}>{DEFAULT_LIMIT}</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </DivSelect>
        </WrapperPagination>
      )}
    </>
  );
};

export default PaginationBp;
