import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { useHistory, useLocation, useParams } from "react-router-dom";
import CustomBlock from "./CustomBlock";
import Icon from "../../../common/components/Icon";
import axios from "axios";
import CommonCirclePreloader from "../../../common/components/CommonInfoPreloader/CommonCirclePreloader";

const ContentRow = styled.div`
  min-height: 140px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  div {
    width: 100%;
  }
  button {
    margin-top: 24px;
    width: 100%;
  }
  p {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #02405e;
    margin: 24px 0;
  }
  span {
    font-size: 12px;
    line-height: 16px;
    color: #707070;
    margin-bottom: 8px;
  }
  img {
    margin: 0 auto;
  }
`;

const EmailConfirmed: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  const [Email, setEmail] = useState("");
  let history = useHistory();

  const tokenInUrl = location.pathname.split("/");
  const token = tokenInUrl[tokenInUrl.length - 1];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_URL}/register_verify/${token}`)
      .then((res) => {
        setLoading(false);

        if (res.status !== 200) {
          setStatus(false);
        } else {
          console.log(res.data.success);

          setStatus(res.data.success);
        }
      })
      .catch((e) => {
        setLoading(false);
        setStatus(false);
      });
  }, []);

  return (
    <CustomBlock>
      <ContentRow>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              top: `43px`,
              right: `9px`,
            }}
          >
            <CommonCirclePreloader isLoading={loading} />
          </div>
        ) : (
          <>
            <Icon name={!status ? "armor-cross" : "armor-ok"} />
            <p>
              {!status ? "Ошибка верификации" : "Верификация прошла успешно"}
            </p>
           
            {status && (
              <Button
                style={{ marginTop: "0" }}
                title="К задачам"
                onClick={() => {
                  history.push("/tasks");
                }}
              />
            )}
          </>
        )}
      </ContentRow>
    </CustomBlock>
  );
};

export default EmailConfirmed;
