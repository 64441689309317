import './TimezoneSelect.scss';

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectWithSecondaryBtn from "../../../../common/components/Selects/SelectWithUsers";
import { setUserTimezone } from "../../actions";
import { State } from "../../../../rootReducer";
import { Item } from "../../../../common/types";
import NameWithInput from "../../../../common/components/newComponent/Inputs/NameWithInput";
import { timezones } from "../../../../common/sharedData/timezones";
import { generateTimezoneTitle, getSelectedTimezone } from "../../../../common/helpers/timezones";

type Props = {
  setUserTimezone: (value: number|string) => void,
  title: string,
  timezone: number|string,
  setChangeDataUser: any
};

const TimezoneSelect: React.FC<Props> = ({
  setUserTimezone,
  title,
  timezone,
  setChangeDataUser
}) => {
  const [list] = useState<Item[]>(generateTimezoneTitle(timezones));
  const [selectedTimezone, setSelectedTimezone] = useState<Item|null>(null);

  const onSelectHandler = (value: number|string) => {
    setUserTimezone(value);

    setChangeDataUser((prev) => ({
      ...prev,
      data: { ...prev.data, timezone: value }
    }));
  };

  useEffect(() => {
    if(timezone) {
      const selectedTimezone = getSelectedTimezone(timezones, timezone);
    
      setSelectedTimezone(selectedTimezone);
    }
  }, [timezone]);
  
  return (
    <NameWithInput 
      className='timezone-select timezone-select--top'
      title={title} 
      iconName="clock"
    >
      <SelectWithSecondaryBtn
        disabled={false}
        list={list}
        selected={selectedTimezone}
        selectHandler={onSelectHandler}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    timezone: state.newUser.timezone
  };
};

const mapDispatchToProps = {
  setUserTimezone
};

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneSelect);
