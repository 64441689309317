// Тут заводится и рассчитывается дата начала задачи   

import React, { useCallback, useEffect, useState } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePikerTask';
import { connect } from 'react-redux'; // Добавлено
import {
  recalculateTaskLoad,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskBegin,
  setTaskEnd      // ***********  добавлено
} from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import moment from 'moment';
import { datePicker } from "../../../common/constants/constants";

type Props = {
  checkedPersonalTask?: boolean;
  checkedTaskBegin?: boolean;
  disabled?: boolean;
  begin: string;
  end: string;
  delegated: boolean;
  setTaskBegin: (begin: string) => void;
  setTaskEnd: (end: string) => void;    // ***********  добавлено
  recalculateTaskLoad: ({}) => void;
  selectedTask: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  cellData: any;
  setTimeValid: (mode: boolean) => void;
};

const TaskBegin: React.FC<Props> = ({
                                      checkedPersonalTask,
                                      checkedTaskBegin,
                                      disabled,
                                      begin,
                                      end,
                                      delegated,
                                      setTaskBegin,
                                      setTaskEnd,   // Добавлено
                                      recalculateTaskLoad,
                                      selectedTask,
                                      updateTask,
                                      flagForActionCyclycTask,
                                      setCyclicTaskToChange,
                                      sendReqToChangeTemplRepTask,
                                      cellData,
                                      setTimeValid
                                    }) => {
  // begin и end- переменные, поступают из state в props-ах в качестве параметров
  let beginTask = begin //useSelector((state: State) => state.taskInfoPlate.begin); ///*** изменено
  let endTask = end //useSelector((state: State) => state.taskInfoPlate.end); ///*** изменено

  const acceptHandler = useCallback(
    function (e): any {
      /*
      Добавлено. Задача 544 *******************************************
       если пользователь выбрал другую дату начала, большую, чем дата окончания (при новой задаче)
       (новая дата окончания = новая дата начала + differenceTime (разницу между
       старой датой начала и старой датой окончания)
      */

      let beginTaskOldStr = beginTask;
      let beginTaskNewStr = e;
      let endTaskStr = endTask;

      let beginTaskOld = moment.utc((beginTaskOldStr), datePicker.DATE_FORMAT).valueOf();
      let beginTaskNew = moment.utc((beginTaskNewStr), datePicker.DATE_FORMAT).valueOf();
      let endTaskDate = moment.utc((endTaskStr), datePicker.DATE_FORMAT).valueOf();
      let differenceTime = endTaskDate - beginTaskOld;

      // если дата начала, больше дедлайн - автоматически изменить дедлайн на разницу differenceTime
      if (beginTaskNew > endTaskDate) {
        let newEnd = beginTaskNew + differenceTime;
        endTaskStr = moment(newEnd).format(datePicker.DATE_FORMAT);
      }

      let datePlusDay =
        moment(e, datePicker.DATE_FORMAT).add(1, "day").format(datePicker.DATE_FORMAT);

      // Тут вызывается из state action, который изменяет дату окончания
      if (cellData) {
        setTaskEnd(datePlusDay);

      } else {
        setTaskEnd(endTaskStr);
      }

      // Тут вызывается из state action, который изменяет дату начала, если пользователь выбрал другую дату
      setTaskBegin(e);

      // если выбрана ранее созданная задача
      if (selectedTask) {
        let end = selectedTask.end;

        if (!moment(end, datePicker.DATE_FORMAT).isBefore(datePicker.YEAR_MAX_DEFAULT, 'year')) {
          end = datePicker.DATE_MAX_DEFAULT;
        }

        // реальный запрос на сервер и пересчет данных задачи - selectedTask
        recalculateTaskLoad({
          task_load: selectedTask.task_load,
          begin: e,
          end: end,
        });

        // TODO разобраться с цикличными задачами

        if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
          // сравнение одного с несколькими
          // значит циклическая
          if (flagForActionCyclycTask === '') {
            setCyclicTaskToChange({begin: e, end}); // заношу значения изменяемых параметров во временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === 'task') {
            updateTask(selectedTask!.id, {begin: e, end, private: checkedPersonalTask ? true : false});
          } else {
            updateTask(selectedTask!.id, {begin: e, end, private: checkedPersonalTask ? true : false});
            sendReqToChangeTemplRepTask({begin: e, end});
          }
        } else {
          // инициатор на обновление задачи
          updateTask(selectedTask!.id, {begin: e, end, private: checkedPersonalTask ? true : false});
        }
      }
    },
    [beginTask, endTask, selectedTask] ///*** изменено
  );

  // expected format is dd-mm-yyyy
  const [cellStart, setCellStart] = useState<any>(null)
  begin = begin.replace(/\//g, '-');

  useEffect(() => {
    if (cellData) {
      setCellStart(cellData.start_at)
    }
  }, [cellData])

  useEffect(() => {
    cellStart && acceptHandler(moment(cellStart, datePicker.DATE_FORMAT).format(datePicker.DATE_FORMAT))
  }, [cellStart])

  // функция подготовки даты начала
  const prepareDateBegin = () => {
    // если выбрана дата ячейки в Календаре
    if (cellData) {
      return moment(cellStart, datePicker.DATE_FORMAT).format(datePicker.DATE_OPEN_FORMAT);

    } else if (checkedTaskBegin) {
      return new Date();

      //если выбрана задача созданная ранее
    } else if (selectedTask) {

      // при первом открытии вновь созданной задачи
      if (selectedTask && selectedTask.task_load_sum === null) {
        return moment(selectedTask.begin).format(datePicker.DATE_OPEN_FORMAT);
      }

      // при последующих открытиях задач
      if (selectedTask && selectedTask.task_load_sum !== null) {
        return moment(selectedTask.begin, datePicker.DATE_FORMAT).format(datePicker.DATE_OPEN_FORMAT)
      }

    } else {
      return moment(begin, datePicker.DATE_FORMAT).format(datePicker.DATE_OPEN_FORMAT);
    }
  }

  let dateBegin = prepareDateBegin();


  return (
    <TaskDateTime
      setTimeValid={setTimeValid}
      disabled={disabled || delegated}
      acceptHandler={
        cellData ? (e) => {
          setCellStart(e)
        } : acceptHandler
      }
      date={dateBegin}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskBegin,
  setTaskEnd,    // *********** добавлено
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBegin);
