import React from 'react';
import styled from 'styled-components';
const WrapperSortIcon = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: ${({ active }) => (active ? '1' : '0')};
  top: -5px;
  right: -12px;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
`;
const TopButton = styled.div`
  border: 4px solid transparent;
  border-bottom: 6px solid
    ${({ activeSort, active }) =>
      activeSort === 'bottom' && active ? '#02405E' : ' #e0e0e0'};
  margin-bottom: 2px;
`;
const BottomButton = styled.div`
  border: 4px solid transparent;
  border-top: 6px solid
    ${({ activeSort, active }) =>
      activeSort === 'top' && active ? '#02405E' : '#e0e0e0'}; ;
`;
const SortIcon = ({ active, activeSort }) => {
  return (
    <WrapperSortIcon active={active}>
      <TopButton active={active} activeSort={activeSort} />
      <BottomButton active={active} activeSort={activeSort} />
    </WrapperSortIcon>
  );
};

export default SortIcon;
