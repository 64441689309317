import './ProjectsSelect.scss';

import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { State } from "../../../../rootReducer";
import { TSelectOption } from "../../../../common/interfaces/select";
import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import { getProjectsForSelectConverted } from "../../../../common/selectors";
import {
  getProjectsForSelector, 
  searchProjectsForSelector,
  setProjectsForSelectorCurrentPage,
  setSelectedProject
} from "../../../../common/actions";
import { SelectAsync } from "../../../../common/components/Selects/SelectAsync/SelectAsync";
import { IMeta, Project, User } from "../../../../common/types";
import { setProjectAddMode } from "../../../Projects/actions";
import { checkAddSectionRoles } from "../helpers";

const ProjectsSelect: FC<{
  onSelectChange: (value: number) => void,
  setSelectedProject: (project: TSelectOption|null) => void,
  setProjectsForSelectorCurrentPage: (page: number) => void,
  getProjectsForSelector: (params?: any, needTodo?: string) => void,
  searchProjectsForSelector: (search: string) => void,
  setProjectAddMode: (mode: boolean) => void,
  isProjectsForSelectLoading: boolean,
  projectsConverted: TSelectOption[]|null,
  defaultProject: Project|null,
  selectedProject: TSelectOption|null,
  currentPage: number,
  projectsForSelectMeta: IMeta|null,
  isDefaultProjectSectionsLoading: boolean,
  currentUserInfo: User|null,
  disabled: boolean
}> = ({
  onSelectChange,
  setSelectedProject,
  setProjectsForSelectorCurrentPage,
  getProjectsForSelector,
  searchProjectsForSelector,
  setProjectAddMode,
  isProjectsForSelectLoading,
  projectsConverted,
  defaultProject,
  selectedProject,
  currentPage,
  projectsForSelectMeta,
  isDefaultProjectSectionsLoading,
  currentUserInfo,
  disabled
}) => {

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string|null>(null);
  const [isUserCanAdd, setIsUserCanAdd] = useState<boolean>(false);
  
  const onMenuOpen = () => {
    setIsOpenMenu(true);
  };
  
  const onMenuClose = () => {
    setIsOpenMenu(false);
  };

  const onSelectChangeLocal = ( value: TSelectOption|null ) => {
    if(!value) setSearchValue('');
    
    value = value ?? { value: '', label: '' };
    
    if(value) {
      onSelectChange(+value.value);
      setSelectedProject(value);
    }
  };

  const onInputChange = ( value: string ) => {
    setSearchValue(value);
  };
  
  const onScrollToBottom = ( e: React.WheelEvent ) => {
    if(projectsForSelectMeta) {
      if(currentPage < projectsForSelectMeta.last_page) {
        setProjectsForSelectorCurrentPage(currentPage + 1);
        
        getProjectsForSelector({}, 'add');
      }
    }
  };
  
  const onAddProject = () => {
    setProjectAddMode(true);
  };

  useEffect(() => {
    // У плагина странный порядок срабатывания событий: сначала onChangeInput и только потом onClose
    // Из-за этого, при закрытии селекта уходит ненужный запрос
    if(isOpenMenu && (typeof searchValue === 'string')) {
      searchProjectsForSelector(searchValue);
    }
  }, [isOpenMenu]);
  // TODO на бэке поиск не сделан, включить, когда будет сделано
  // }, [isOpenMenu, searchValue]);

  useEffect(() => {
    if(defaultProject) {
      setSelectedProject({ value: defaultProject.id.toString(), label: defaultProject.name });
    }
  }, [defaultProject]);

  useEffect(() => {
    if(currentUserInfo && defaultProject) {
      setIsUserCanAdd(checkAddSectionRoles(currentUserInfo, defaultProject));
    }
  }, [currentUserInfo]);

  return (
    <div className="projects-select">
      <div 
        className={
          'projects-select__preloader' +
          (isDefaultProjectSectionsLoading ? ' projects-select__preloader--show' : '')
      }>
        <PreloaderLocal classPostfix="blue" />
      </div>
      
      <div className="projects-select__select">
        <SelectAsync
          options={projectsConverted ?? undefined}
          value={selectedProject}
          inputValue={searchValue ?? undefined}
          onMenuScrollToBottom={onScrollToBottom}
          onSelectChange={onSelectChangeLocal}
          onInputChange={onInputChange}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          placeholder="Выберите проект..."
          isLoading={isProjectsForSelectLoading}
          disabled={disabled}
          rules={[{ required: true }]}
          // TODO на бэке поиск не сделан, включить, когда будет сделано
          // isAsyncSearch
        />
      </div>

      {(!disabled && isUserCanAdd) && (
        <button
          className="projects-select__add-btn"
          onClick={onAddProject}
        >
          + Создать новый проект
        </button>
      )}
    </div>
  );
};

const mapStateToProps = ( state: State ) => {
  return {
    isProjectsForSelectLoading: state.commonInfo.projectsForSelect.isProjectsForSelectLoading,
    selectedProject: state.commonInfo.projectsForSelect.selectedProject,
    projectsConverted: getProjectsForSelectConverted(state),
    defaultProject: state.taskInfoPlate.defaultProject.project,
    currentPage: state.commonInfo.projectsForSelect.currentPage,
    projectsForSelectMeta: state.commonInfo.projectsForSelect.projectsForSelectMeta,
    isDefaultProjectSectionsLoading: state.taskInfoPlate.defaultProject.isLoading,
    currentUserInfo: state.commonInfo.currentUserInfo
  };
};

const mapDispatchToProps = {
  setSelectedProject,
  setProjectsForSelectorCurrentPage,
  getProjectsForSelector,
  searchProjectsForSelector,
  setProjectAddMode
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSelect);
