import React, { ReactNode } from "react";
import styled from "styled-components";
import Icon from "../../Icon";
import palette from "../../palette";
import CustomInput from "./CustomInput";
import PreloaderLocal from "../../PreloaderLocal/PreloaderLocal";
import { useSelector } from "react-redux";
import { State } from "../../../../rootReducer";

import './NameWithInput.scss';

type Props = {
  title?: string;
  iconName?: string;
  inputName?: string;
  className?: string;
  withoutIcon?: boolean;
  necessarily?: boolean;
  inputValue?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  style?: object;
  widthInput?: string;
  children?: ReactNode;
  register?: any;
  name?: string;
  inputChangeHandler?: (inputValue: string) => void;
  preloader?: boolean;
};

const WrapperBlockInput = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 2.5rem;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #707070;
  margin: 0.5rem 0;
`;

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  position: relative;
  min-width: 8.5rem;
  margin-right: 0.5rem;
`;
const SpanStyle = styled.div`
  margin-left: 0.5rem;
  color: ${palette.darkBlue};
  ${({ necessarily }) =>
    necessarily
      ? `&::after {
    content: '  *';
    color: ${palette.blue};
  }`
      : ""};
`;
const WrapperText = styled.div`
  width: ${({ width }) => width || "100%"};
`;

const NameWithInput: React.FC<Props> = ({
  title,
  iconName,
  inputName,
  className,
  register,
  name,
  inputChangeHandler,
  inputValue,
  placeholder,
  disabled,
  widthInput,
  type,
  children,
  style,
  necessarily,
  withoutIcon = false,
  preloader = false
}) => {
  const wasFetchedSlowRequests = useSelector((state: State) => state.commonInfo.wasFetchedSlowRequests);
  
  return (
    <WrapperBlockInput className={'name-with-input ' + className}>
      {title && (
        <>
          <TitleStyled>
            {!withoutIcon && <Icon name={iconName} />}
            <SpanStyle necessarily={necessarily}>{title}</SpanStyle>

            {inputName === "ObserversSelect" && (
              <img
                style={{ marginLeft: "6px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Наблюдатели за проектом смогут видеть проект (в разделе «Проекты») и прогресс выполнения задач в нем. Но не могут работать с задачами и не будут получать уведомления о событиях в задачах проекта"
              />
            )}

            {inputName === "TasksObserversSelect" && (
              <img
                style={{ marginLeft: "6px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Наблюдатели за задачей смогут видеть задачу (в разделе «Задачи»). Смогут писать комментарии, добавлять вложения и получать уведомления о событиях в задаче"
              />
            )}
            
            {inputName === "LeaderSelect" && (
              <img
                style={{ marginLeft: "17px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Один или несколько руководителей групп внутри отделов, в рамках которых реализуется проект. Имеют возможность добавлять документы к проекту. Могут добавлять, редактировать и удалять «Разделы» проекта."
              />
            )}

            {inputName === "CuratorsSelect" && (
              <img
                style={{ marginLeft: "32px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Один или несколько кураторов проекта. Имеют возможность редактировать информацию о проекте, в том числе изменять его приоритет, а также добавлять и удалять сотрудников в полях «Руководитель проекта», «Начальник отдела» и «Руководитель группы». Могут добавлять документы к проекту. Могут добавлять, редактировать и удалять «Разделы» проекта."
              />
            )}

            {inputName === "CpeSelect" && (
              <img
                style={{ marginLeft: "3px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Один или несколько руководителей проекта. Имеют возможность редактировать информацию о проекте, в том числе изменять его приоритет, а также добавлять и удалять сотрудников в полях «Начальник отдела» и «Руководитель группы». Могут добавлять документы к проекту. Могут добавлять, редактировать и удалять «Разделы» проекта."
              />
            )}

            {inputName === "ChiefSelect" && (
              <img
                style={{ marginLeft: "17px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Один или несколько начальников отделов, в рамках которых реализуется проект. Имеют возможность добавлять и удалять сотрудников в поле «Руководитель группы». Могут добавлять документы к проекту. Могут добавлять, редактировать и удалять «Разделы» проекта."
              />
            )}

            {inputName === "sectionSelect" && (
              <img
                style={{ marginLeft: "7px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Раздел внутри проекта, в рамках которого выполняется задача. По умолчанию выбран «Основной раздел»."
              />
            )}
        
            {inputName === "TaskWorkLoad" && (
              <img
                style={{ marginLeft: "5px", width: "16px", height: "16px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                title="Время, которое предположительно должен затратить на выполнение задачи исполнитель. Устанавливается в часах или в процентах от общей загрузки."
              />
            )}
          </TitleStyled>
        </>
      )}
      <WrapperText className='name-with-input__wr' width={widthInput} style={style}>
        {preloader && (
          <div className={'name-with-input__preloader' +
            (!wasFetchedSlowRequests ? ' name-with-input__preloader--show' : '')
          }>
            <PreloaderLocal/>
          </div>
        )}
        
        {type && (
          <CustomInput
            register={register}
            name={name}
            type={type}
            value={inputValue}
            disabled={disabled}
            placeholder={placeholder}
            inputChangeHandler={inputChangeHandler}
          />
        )}
        {children}
      </WrapperText>
    </WrapperBlockInput>
  );
};

export default NameWithInput;
