import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from "react-redux";
import { State } from "../../../../../rootReducer";
import { deleteTask, setDeleteTaskModalOpen } from "../../../actions";

import './Modal.scss';
import {ReactComponent as CloseIcon} from '../../../../../img/TrueIcons/close.svg';
import { deleteTaskStatuses } from "../../../../../common/constants/constants";
import PreloaderLocal from "../../../../../common/components/PreloaderLocal/PreloaderLocal";
import { IDeleteTaskModal } from "../../../action_types";

interface IDeleteTaskModalProps {
  deleteTask: (arg0: string|number) => void,
  deleteTaskModal: IDeleteTaskModal
}

const DeleteTaskModal = ({
  deleteTask,
 deleteTaskModal
}: IDeleteTaskModalProps) => {
  const textObj = {
      [deleteTaskStatuses.DELETE_TASK]:
        {title: 'Удаление задачи', text: 'У задачи существуют связи с другими задачами.<br/>Вы можете переназначить связи и повторить удаление или продолжить и связи будут удалены автоматически.', btnDeleteText: 'Удалить'},
    [deleteTaskStatuses.DELETE_TASK_ALLOWED]: 
      {title: 'Удаление задачи', text: 'Задача будет удалена. Больше её никто не увидит.<br/>Действие необратимо.', btnDeleteText: 'Удалить'},
    [deleteTaskStatuses.DELETE_TASK_ERROR]: 
      {title: 'Удаление задачи', text: 'Произошла ошибка при удалении задачи...'}
  };
  
  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<{
    title: string,
    text: string,
    btnDeleteText?: string
  }>(textObj[deleteTaskStatuses.DELETE_TASK_ERROR]);

  useEffect(() => {
    if(deleteTaskModal.statusFromServer?.constraints) {
      const {constraints} = deleteTaskModal.statusFromServer;
      const result = textObj[constraints[0]];
      
      if(result) setText(result);
      else setText(textObj[deleteTaskStatuses.DELETE_TASK_ERROR]);
    }

    setOpen(deleteTaskModal.isDeleteModalOpen);
  }, [deleteTaskModal]);

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTaskHandler = () => {
    deleteTask(deleteTaskModal.taskId);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'modal-task-delete'}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={'modal-task-delete__content-wr'}>
            <div className="modal-task-delete__content">

              <div className="modal-task-delete__content-item">
                <div className={'modal-task-delete__title-wr'}>
                  <div className="modal-task-delete__title">{text?.title}</div>
                  <div className="modal-task-delete__delete-ico-wr"
                       onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>

                <div className="modal-task-delete__text"
                     dangerouslySetInnerHTML={{__html: text?.text}}>
                </div>
              </div>
            </div>
            
            <div className="modal-task-delete__btns">
              {text?.btnDeleteText &&
                <button className={"modal-task-delete__btn modal-task-delete__btn--delete " + (deleteTaskModal.isShowPreloader ? ' modal-task-delete__btn--show-preloader' : '')}>
                <span className="modal-task-delete__btn-title"
                      onClick={deleteTaskHandler}
                >{text?.btnDeleteText}</span>
                  <span className="modal-task-delete__btn-preloader">
                  <PreloaderLocal />
                </span>
                </button>
              }
              
              <button className="modal-task-delete__btn modal-task-delete__btn--cancel"
                      onClick={handleClose}
              >
                <span className="modal-task-delete__btn-title">Отмена</span>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: State) => {
  return {
    deleteTaskModal: state.tasksPage.deleteTaskModal
  };
};

const mapDispatchToProps = {
  setDeleteTaskModalOpen,
  deleteTask
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTaskModal);
