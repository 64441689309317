import React, { useCallback, useState, useEffect } from "react";
import { Project, Task } from "../../../common/types";
import TaskNameCell from "../../../common/components/TableCells/TaskNameCell";
import {
  selectTask,
  setTaskPriority,
  setShownTaskInfoPlate, 
  setTaskProject
} from "../../TaskInfoPlate/actions";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import { getSelectedTask } from "../../TaskInfoPlate/selectors";
import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { addSection, openExistingProject } from "../../NewProject/actions";
import {
  AccordionIcon2,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../img/projectIcons";
import {
  setProjectAddMode,
  selectProject,
  checkDeleteProject,
} from "../actions";
import { fetchData } from "../../../utils/fetchData";
import { makeStyles } from "@material-ui/core/styles";
import ProjectAllSections from "./ProjecAllSections";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router-dom";
import EditSection from "./EditSection/EditSection";

type Props = {
  selectedTask: Task | null;
  selectTask: (task: Task) => void;
  selectedProject: Project | null;
  setShownTaskInfoPlate: (show: boolean) => void;
  isParent?: boolean;
  counter?: number;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  users: any; //User[]
  project?: any;
  partitions: object;
  activeColumns: string[];
  openExistingProject: (project: Project) => void;
  setProjectAddMode: (mode: boolean) => void;
  selectProject: (project: Project) => void;
  currentUserInfo?: any;
  priorities: any;
  addSection: (sectionId: number, name: string) => void;
  checkDeleteProject: (id: string|number) => void,
  setTaskProject: (projectId: number) => void;
};

const TextId = styled.p`
  display: block;
  color: #292a34;
  margin-right: 10px;
  font-size: 14px;
  margin-top: 6px;
  width: 45px;
  color: #a8a8a8;
  text-align: center;
`;

const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DivNumberTasks = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;

const AccrodHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
`;

const NameSection = styled.div`
  margin-right: 12px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WindowItem = styled.div`
  height: 32px;
  padding: 8px;
  font-size: 1rem;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.window-item {
    margin-top: 2px;
    position: relative;
    padding-top: 10px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 89%;
      height: 1px;
      border-top: 1px dashed #000;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.MuiAccordion-root:hover": {
      boxShadow: "0px 4px 5px #00000033 !important ",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
    },
  },
}));

const DivIcons2 = styled.p`
  transform: ${({ rotateLocal }) => rotateLocal && "rotate(90deg)"};
  width: 15px;
  min-width: 15px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 15.4px;
`;

const ProjectAllTaskTr: React.FC<Props> = ({
  project,
  openExistingProject,
  setProjectAddMode,
  priorities,
  addSection,
  currentUserInfo,
  checkDeleteProject,
  setTaskProject
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [projectSections, setProjectSections] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [nameSection, setNameSection] = useState<string>("");
  const [openTasks, setOpenTasks] = useState<any>([
    {
      id: "",
      completed: false,
    },
  ]); //для будущего функционала
  const [correctBool, setCorrectBool] = useState<boolean>(false);
  const userId = Number(localStorage.getItem("user_id"));

  const dispatch = useDispatch();

  const classes = useStyles();
  const { id }: any = useParams();

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
    
    setTaskProject(project.id);
    
    dispatch(setTaskPriority(priority.id));
    dispatch(setShownTaskInfoPlate(true));
    
    handleClose(e);
  };

  const editClickHandler = useCallback(async () => {
    if (project?.sections?.length > 0) {
      const sections = await fetchData.get(
        `/api/v1/projects/${project.id}/sections`
      );
      if (sections) {
        openExistingProject({
          ...project,
          sections,
        } as Project);
        setProjectAddMode(true);
      }
    }
  }, [id, openExistingProject, project, setProjectAddMode]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (project?.sections?.length > 0) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event: any) => {
    if (project?.sections?.length > 0) {
      setAnchorEl(null);
      event.stopPropagation();
    }
  };

  let { users, author_id, cpe_list } = project;

  const initiator = users.find((user) => user.id === cpe_list[0]);
  const initiator2 = users.find((user) => user.id === cpe_list[1]);
  const initiator3 = users.find((user) => user.id === cpe_list[2]);

  useEffect(() => {
    if (openAccord && project?.sections?.length > 0) {
      fetchData.get(`/api/v1/projects/${project.id}/sections`).then((res) => {
        setProjectSections(res);
        setOpenTasks([
          ...openTasks,
          ...res.map((item) => {
            return { id: item.id, completed: false };
          }),
        ]);
      });
    }
  }, [openAccord, correctBool]);



  const clickDeleteHandler = (e, id: string | number) => {
    checkDeleteProject(id);
    setAnchorEl(null)
    e.stopPropagation();
  };

  return (
    <>
      {correctBool && (
        <EditSection
          openAddWindow={correctBool}
          setOpenAddWindow={setCorrectBool}
          addHandler={addSection}
          valueQuestions={nameSection}
          setValueQuestions={setNameSection}
          id={project.id}
        />
      )}
      <Accordion
        className="project-all-task-tr"
        classes={{
          root: classes.MuiAccordionroot + ' project-all-task-tr',
        }}
        onChange={(e, expanded) => setOpenAccord(expanded)}
        style={{
          width: "98%",
          margin: "0px 5px 10px 10px",
          boxShadow: "none",
          border: "none",
          borderRadius: "4px",
        }}
      >
        <AccordionSummary
          classes={{ root: classes.MuiAccordionSym + ' project-all-task-tr__header'}}
          style={{
            width: "100%",
            height: "84px",
            overflow: "hidden",
            padding: "0px 19px 0px 11px",
            cursor: project?.sections?.length > 0 ? "pointer" : "auto",
          }}
        >
          <AccrodHeaderDiv>
            <DivLeft>
              {project?.sections?.length > 0 ? (
                <DivIcons2 rotateLocal={openAccord ? openAccord : undefined}>
                  <AccordionIcon2 />
                </DivIcons2>
              ) : (
                <DivIcons2 style={{ opacity: "0" }}></DivIcons2>
              )}
              <div>
                <TextId>{project.id}</TextId>
              </div>
              <NameSection>
                <TaskNameCell
                  id={project.id}
                  name={project.name}
                  desc={project.description}
                />
              </NameSection>
            </DivLeft>

            <DivRight>
              <div style={{ display: "flex" }}>
                <div className="logo_parent_v2">
                  {initiator ? (
                    <div className="business__main-content__list-block__item__logo logo_task_1_v2">
                      {initiator && initiator.image ? (
                        <img
                          title={initiator?.name + initiator?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${initiator?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={initiator?.name + initiator?.surname}>
                          {initiator?.name[0] + initiator?.surname[0]}
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="logo_parent_v2">
                  {users[0] ? (
                    <div className="business__main-content__list-block__item__logo logo_task_1_v2">
                      {users[0] && users[0].image ? (
                        <img
                          title={users[0]?.name + users[0]?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${users[0]?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={users[0]?.name + users[0]?.surname}>
                          {users[0]?.name[0] + users[0]?.surname[0]}
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {users.length > 1 && (
                    <div className="business__main-content__list-block__item__logo logo_task_2_v2">
                      {users[1] && users[1].image ? (
                        <img
                          title={users[1]?.name + users[1]?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${users[1]?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={users[1]?.name + users[1]?.surname}>
                          {users[1]?.name[0] + users[1]?.surname[0]}
                        </span>
                      )}
                    </div>
                  )}
                  {users.length > 2 && (
                    <div className="business__main-content__list-block__item__logo logo_task_3_v2">
                      {users[2] && users[2].image ? (
                        <img
                          title={users[2]?.name + users[2]?.surname}
                          src={`${process.env.REACT_APP_PUBLIC_URL}/files/${users[2]?.image.url}/download`}
                          alt="logo"
                        />
                      ) : (
                        <span title={users[2]?.name + users[2]?.surname}>
                          {users[2]?.name[0] + users[2]?.surname[0]}
                        </span>
                      )}
                    </div>
                  )}
                  {users.length > 3 && (
                    <div
                      style={{ background: "#F4F4F4" }}
                      className="business__main-content__list-block__item__logo logo_task_4_v2 "
                    >
                      <span style={{ color: "rgba(41, 42, 52, 0.5)" }}>
                        +{users.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  paddingLeft: "20px",
                  minWidth: "170px",
                }}
              >
                <p>{project.tasks_count}</p>
              </div>
              <DivNumberTasks>
                <AccordionIcon4 />
                <p style={{ marginLeft: "6px", color: "#436ea0" }}>
                  {project.tasks_in_work_count - project.overdue_tasks_count}
                </p>
              </DivNumberTasks>
              <DivNumberTasks>
                <AccordionIcon5 />
                <p style={{ marginLeft: "6px", color: "#44a047" }}>
                  {project.completed_tasks_count}
                </p>
              </DivNumberTasks>
              <DivNumberTasks>
                <AccordionIcon6 />
                <p style={{ marginLeft: "6px", color: "#f44336" }}>
                  {project.overdue_tasks_count}
                </p>
              </DivNumberTasks>
              <Button onClick={handleClick}>
                <AccordionIcon7 />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop: "45px", overflowY: "scroll" }}
              >
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    editClickHandler();
                  }}
                >
                  Редактировать
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    setCorrectBool(!correctBool);
                  }}
                >
                  Создать раздел
                </MenuItem>
                <MenuItem onClick={(e) => clickHandler(e)}>
                  Создать задачу
                </MenuItem>
                {project.author_id === userId || currentUserInfo?.isAdmin ? (
                  <WindowItem
                    onClick={(e) => clickDeleteHandler(e, project.id)}
                    className="window-item"
                  >
                    Удалить проект
                  </WindowItem>
                ) : null}
              </Menu>
            </DivRight>
          </AccrodHeaderDiv>
        </AccordionSummary>
        <AccordionDetails
          style={{
            background: "#FFFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 16px 0px 30px",
          }}
        >
          {!!projectSections.length ? (
            projectSections?.map((item) => (
              <ProjectAllSections
                openAccordSelect={openAccord}
                openTasks={openTasks}
                handleSetOpenTasks={setOpenTasks}
                key={item.id}
                projectSection={item}
                correctBool={correctBool}
                setCorrectBool={setCorrectBool}
              />
            ))
          ) : (
            <p
              style={{ margin: "20 auto", fontSize: "17px", fontWeight: 500 }}
            ></p>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    partitions: state.commonInfo.sections,
    sections: state.commonInfo.sections,
    activeColumns: state.projectsPage.activeColumns,
    selectedTask: getSelectedTask(state),
    selectedProject: state.projectsPage.selectedProject,
    currentUserInfo: state.commonInfo.currentUserInfo,
    priorities: state.commonInfo.priorities,
  };
};

const mapDispatchToProps = {
  selectTask,
  selectProject,
  openExistingProject,
  setProjectAddMode,
  setShownTaskInfoPlate,
  addSection,
  checkDeleteProject,
  setTaskProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAllTaskTr);
