export const patterns = {
  email: /.*@.*\..*/i,
  password: /[A-ZА-ЯЁ][0-9].{6,30}/g,
  name: /^[a-zA-Zа-яА-ЯЁё-\s]*$/,
  surname: /^[a-zA-Zа-яА-ЯЁё-\s]*$/,
};

export function validatePassword(password: string): boolean {
  if (!password) return false;
  if (!password.match(/[A-ZА-ЯЁ]/)) return false;
  if (!password.match(/[0-9]/i)) return false;
  if (password.length > 30 || password.length < 6) return false;
  return true;
}

export function validateEmail(email: string): boolean {
  if (!email) return false;

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
  return emailRegex.test(email);
}

export function validateName(name: string): boolean {
  if (!name) return false;
  if (!name.match(/^[a-zA-Zа-яА-ЯЁё-\s]*$/)) return false;
  return true;
}
export function validateSurname(surname: string): boolean {
  if (!surname) return false;
  if (!surname.match(/^[a-zA-Zа-яА-ЯЁё-\s]*$/)) return false;
  return true;
}

export function validatePhoneNumber(number: string): boolean {
  if (!number) return false;
  if (
    !number.match(
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
    )
  )
    return false;
  return true;
}

export function validateNameCompany(name: string): boolean {
  if (!name) return false;
  if (name.length <= 1 || name.length >= 80) return false;
  return true;
}

export function validateInnCompany(inn: string): boolean {
  if(inn?.length) {
    if ( ( inn.match(/^[\d+]{10,12}$/)) ) return true;
    else return false;
  }
  
  return true;
}

export function validateKppCompany(kpp: string): boolean {
  if(kpp?.length) {
    if (!kpp.match(/[0-9]{9}/)) return false;
  }
  
  return true;
}

export function validateOgrnCompany(orgn: string): boolean {
  if(orgn?.length) {
    if (!orgn.match(/[0-9]{13}/)) return false;
  }
  
  return true;
}

export function validateBikCompany(bik: string): boolean {
  if (!bik) return false;
  if (!bik.match(/[0-9]{9}/)) return false;
  return true;
}

export function validateСorrespondentAccountCompany(
  orrespondentA: string
): boolean {
  if (!orrespondentA) return false;
  if (!orrespondentA.match(/[0-9]{20}/)) return false;
  return true;
}

// export function validateEmailv2(email: string): boolean {
//   if (!email) return false;
//   if (!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) return false;
//   return true;
// }
export function validateAdressCompany(adress: string): boolean {

  if (!adress) return false;
  if (
    !adress.match(
      /(\d{1,}) [a-zA-Z0-9\s]+(\.)? [a-zA-Z]+(\,)? [A-Z]{2} [0-9]{5,6} /g
    )
  )
    return false;
  return true;
}
