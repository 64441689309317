import './SelectAsync.scss';

import React, { FC, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { TSelectOption } from "../../../interfaces/select";
import PreloaderLocal from "../../PreloaderLocal/PreloaderLocal";
import { TValidationRules } from "../../../interfaces/rules";
import { checkInputs } from "../../../helpers/validation";

const Menu = (props: any) => {
  return (
    <div className="select-async-custom__menu-list-wr">
      <components.Menu {...props}>
        <div className={
            'select-async-custom__preloader' +
            (props.isLoading ? ' select-async-custom__preloader--show' : '')
          }>
            <PreloaderLocal classPostfix='blue' />
          </div>

        {props.children}
      </components.Menu>
    </div>
  );
};

// Не нашел другого способа отключить фильтрацию и оставить рабочим инпут
const filterOption = ( candidate: { label: string; value: string; data: any }, input: string ) => {
  return true;
};

const Placeholder = (props: any) => {
  return <components.Placeholder {...props} />;
};

export const SelectAsync: FC<{
  onMenuOpen?: () => void,
  onMenuClose?: () => void,
  onSelectChange: (value: TSelectOption|null) => void,
  onInputChange: (value: string) => void,
  onMenuScrollToBottom: (e: any) => void,
  options: TSelectOption[]|undefined,
  value: TSelectOption|null,
  inputValue?: string|undefined,
  isLoading?: boolean,
  placeholder?: string,
  isAsyncSearch?: boolean,
  disabled?: boolean
  checkTrigger?: string|null,
  rules?: TValidationRules[]
}> = ({
  onMenuOpen,
  onMenuClose,
  onInputChange,
  onSelectChange,
  onMenuScrollToBottom,
  options,
  value,
  inputValue,
  isLoading,
  placeholder = 'Введите значение...',
  isAsyncSearch = false,
  disabled,
  checkTrigger,
  rules
}) => {
  const [skipCheck, setSkipCheck] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string[]>([]);

  const checkHelper = () => {
    if(skipCheck) {
      setSkipCheck(false);
      return;
    }

    setErrorState([]);

    const checkResult = checkInputs(rules, value?.value ?? '');

    if(Array.isArray(checkResult)) {
      setErrorState(checkResult);
    }
  };

  useEffect(() => {
    checkHelper();
  }, [checkTrigger, value]);

  return (
    <div className={
      'select-async-custom-wr' +
      (disabled ? ' select-async-custom-wr--disabled' : '') +
      (errorState.length ? ' select-async-custom-wr--validation-error' : '')
    }>
      {disabled && (
        <div className="select-async-custom-wr__disabled"></div>
      )}
      
      <Select
        classNamePrefix="select-async-custom"
        className={'select-async-custom' +
          (errorState.length ? ' select-async-custom--error' : '')
        }
        options={options}
        value={value}
        inputValue={inputValue}
        noOptionsMessage={() => 'Ничего не найдено...'}
        placeholder={placeholder}
        isLoading={isLoading}
        components={{ Menu, Placeholder }}
        isClearable
        isDisabled={disabled}

        {...(isAsyncSearch && {filterOption})}

        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={onSelectChange}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onInputChange}
      />
      
      {errorState.length > 0 && (
        <div className="select-async-custom-wr__error-messages">
          {errorState.map((error, i) => (
            <div className="select-async-custom-wr__error-message" key={i}>
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
