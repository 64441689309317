// Верхнее меню в экране "Задачи"
import React, { useEffect, useMemo, useState } from "react";
import UserProjectSelect from "../../../common/components/Selects/UserProjectSelect/UserProjectSelect";
import { User, Item, Task } from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setTaskAddMode,
  setShowVisulaization,
  changeTaskList,
  fetchTasks,
  setTableFilter,
  setShowColumnsCustom,
  closingDay,
  setShowDaysOff,
  setPrivateToggle
} from "../actions";
import { State } from "../../../rootReducer";
import ColumnsWindow from "../../../common/ColumnsCustomWindow";
import {
  setShownTaskInfoPlate,
  setTaskPriority,
} from "../../TaskInfoPlate/actions";
import Button from "../../../common/components/newComponent/Buttons/Button";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import FiltersWindow from "../../../common/FiltersWindow";
import {
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  ShowFiltersWindow,
} from "../../../common/actions";
import { fetchData } from "../../../utils/fetchData";
import { urlApp } from "../../../App";
import { cookieMaster } from "../../../utils/CookieMaster";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import Toggler from "../../../common/components/Togglers/Toggler";
import {formatStringDate} from "../../../common/format";
import { tooltipsTitle } from "../../../common/constants/constants";
import { CustomTooltip } from "../../../common/components/Tooltip/CustomTooltip";

type Props = {
  users: User[];
  selectedUserId: number;
  showVisual: boolean;
  priorities: any;
  setTaskAddMode: (mode: boolean) => void;
  setShowVisulaization: (show: boolean) => void;
  setShowColumnsCustom: (show: boolean) => void;
  showFiltersWindow: boolean;
  showColumnsWindow: boolean;
  tableFilter: string;
  setTableFilter: (filter: string) => void;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  setShownTaskInfoPlate: (val: boolean) => void;
  positions: any;
  current_user: any;
  filters: any;
  closingDay: (value: boolean) => void;
  closingDayWindowShow: boolean;
  ShowFiltersWindow: () => void;
  DefaultFilterTemplates: (data) => void;
  FilterTemplates: (data: {}) => void;
  setShowDaysOff: (show: boolean) => void;
  setPrivateToggle: (privateToggle: boolean) => void;  
  isShownDayClose: boolean;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  handleChangeFactPage: () => void;
  isFactPage: boolean;
  isSettingsPage: boolean;
  handleChangeSettingsPage: () => void;
  wasFetchedSlowRequests: boolean
};
const WrapperTasksActions = styled.div`
  justify-content: space-between;
  padding: 23px 18px;
`;
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;
const RightBlock = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperIconColumn = styled.div`
  margin-right: 20px;
  margin-left: 15px;
  border: none;
  width: 25px;
  height: 33px;
  padding-top: 5px;
  text-align: center;
  border-radius: 25%;
  background: ${({ showColumnsWindow }) =>
    showColumnsWindow ? "rgb(244, 244, 244)" : "transparent"};
  &:hover {
    background: rgb(244, 244, 244);
  }
`;
const WrapperIconVisual = styled.div`
  margin-left: 20px;
  border: none;
  border-radius: 25%;
  background: ${({ showVisual }) =>
  showVisual ? "rgb(244, 244, 244)" : "transparent"};
  &:hover {
    background: rgb(244, 244, 244);
  }
`;

const WrapperIconExcel = styled.div`
  border: none;
  border-radius: 25%;
  width: 25px;
  background: ${({ showColumnsWindow }) =>
    showColumnsWindow ? "rgb(244, 244, 244)" : "transparent"};
  height: 33px;
  text-align: center;
  &:hover {
    background: rgb(244, 244, 244);
  }
`;

const LineOfActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TasksActions: React.FC<Props> = ({
  setShownTaskInfoPlate,
  changeTaskList,
  setPrivateToggle,
  users,
  selectedUserId,
  showVisual,
  setShowVisulaization,
  setShowColumnsCustom,
  showColumnsWindow,
  showFiltersWindow,
  positions,
  priorities,
  current_user,
  ShowFiltersWindow,
  filters,
  wasFetchedSlowRequests
}) => {
  const {
    setStartTaskTime, // Вычисляется путём вычисления времени от начала создания в системе задачи до момента нажатия на кнопку "Сохранить"
    setAllEmployee,
    tableOrTreeToggler,   // Переключатель между рабочими и личными задачами
    setTableOrTreeToggler
  } = useContext(useStateContext);

  useEffect(()=> {
    setAllEmployee(false);
  }, []);
  
  useState<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();
  const tasksOrder = useSelector((state: State) => state.tasksPage.tasksOrder);
  
  const dataForUserSelect = useMemo(() => {
    let selected = null as Item | null;

    let found_my_user = users.find((user) => {
      return user.id === current_user;
    });

    let attached_items: Item[] = [];
    if (found_my_user) {
      attached_items.push({
        text: `${found_my_user.surname} ${found_my_user.name}`,
        name_cap_letters:
          found_my_user.surname.substr(0, 1).toUpperCase() +
          found_my_user.name.substr(0, 1).toUpperCase(),
        value: found_my_user.id,
        subtitle: found_my_user.email,
        position: positions.find(
          (pos) => pos.id === found_my_user?.positions[0]
        )?.name,
        urlImage: found_my_user?.image?.url,
      });
    }

    let list = users
      .map((user) => {
        let item = {
          text: `${user.surname} ${user.name}`,
          name_cap_letters:
            user.surname.substr(0, 1).toUpperCase() +
            user.name.substr(0, 1).toUpperCase(),
          value: user.id,
          subtitle: user.email,
          position: positions.find((pos) => pos.id === user.positions[0])?.name,
          urlImage: user?.image?.url,
        };
        if (user.id === selectedUserId) selected = item;
        return item;
      })
      .filter((list_item) => list_item.value !== current_user);

    return { selected, list, attached_items };
  }, [selectedUserId, users]);

  let history = useHistory();

  let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
  let company_id = localStorage.getItem("company_id");
  let default_filter_active = false;
  let filter_btn_inscription;
  Object.keys(filters["default_templates"]).forEach((item) => {
    if (filters["default_templates"][item].active) {
      filter_btn_inscription = item;
      default_filter_active = true;
    }
  });
  if (filter_btn_inscription === undefined) {
    Object.keys(filters["templates"]).forEach((item) => {
      if (filters["templates"][item].active) {
        filter_btn_inscription = item;
      }
    });
  }
  const uploadTasksExcel = () => {
    let link = `/uploads/tasks?order=${tasksOrder.col_name}&`;
    const { executors, authors, statuses, date_from, date_to } =
      filters.current_set;
    if (!tasksOrder.order_direct) link = link + `orderType=desc&`;
    if (executors.length > 0) link = link + `executor_id=${executors}&`;
    if (authors.length > 0) link = link + `author_id=${authors}&`;
    if (statuses.length > 0) link = link + `status_id=${statuses}&`;
    if (date_from.length > 0) link = link + `begin_from=${date_from}&`;
    if (date_to.length > 0) link = link + `end_to=${date_to}&`;

    fetch(`${urlApp + link}`, {
      headers: {
        "company-id": `${company_id}`,
        Authorization: `${cookieMaster.getCookie("access_token")}`,
      },
    })
      .then((data) => data.blob())
      .then((blob) => {
        let tmp = document.createElement("a");
        let csvData = new Blob([blob], { type: "text/xlsx" });
        let csvUrl = URL.createObjectURL(csvData);
        tmp.href = csvUrl;
        tmp.setAttribute("download", "tasks_from_view.xlsx");
        tmp.click();
      });
  };

  useEffect(() => {
    setTableOrTreeToggler("work");
  }, []);
  

  return (
    <WrapperTasksActions className='tasks-actions'>
      <LineOfActions>
        <LeftBlock>
          {/* Показывается выбранный сотрудник */}
          <Button
            design={default_filter_active ? "outline" : "blue_lightest"}
            onClick={() => ShowFiltersWindow()}
            style={{
              border: "1px solid #DBDBDB",
              height: "50px",
              marginRight: "8px",
              marginLeft: "10px",
              padding: "10px",
              minWidth: "150px",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#292A34",
              fontWeight: "400",
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            <Icon
              name={"VectorFilter"}
              style={{ marginRight: "5px", paddingBottom: "4px" }}
            />
            <span>
              {" "}
              {filter_btn_inscription
                ? filter_btn_inscription
                : "Пользовательские"}{" "}
            </span>
          </Button>
          {/* Выбор сотрудника, по которому фильтруются задачи */}
          <UserProjectSelect
            type="tasks"
            itemsList={dataForUserSelect.list}
            attached_items={dataForUserSelect.attached_items}
          />

          {/* Переключатель рабочие / личные */}
          <Toggler
            styles={{ marginLeft: "30px", marginTop: "-4px" }}
            elemsList={[
              { name: "Рабочие", value: "work" },
              { name: "Личные", value: "personal" },
            ]}
            selectHandler={(active) => {
              setTableOrTreeToggler(active);
              if (active == "personal") {
                dispatch(setPrivateToggle(true));
                fetchData.get(`/api/v1/tasks?private=true`).then((res) => {
                  if (res) {
                    res.forEach((item, index, array) => {
                      let begin = item.begin;
                      let end = item.end;
                      item.begin = formatStringDate(begin);
                      item.end = formatStringDate(end);
                    });
                  }
                  changeTaskList(res);
                });
              } else if (active == "work") {
                dispatch(setPrivateToggle(false));
                fetchData.get(`/api/v1/tasks?private=false`).then((res) => {
                  if (res) {
                    res.forEach((item, index, array) => {
                      let begin = item.begin;
                      let end = item.end;
                      item.begin = formatStringDate(begin);
                      item.end = formatStringDate(end);
                    });
                  }
                  changeTaskList(res);
                });
              }
            }}
            activeValue={tableOrTreeToggler}
          />
          {/* ******************************* */}
        </LeftBlock>
        
        <RightBlock>
          <CustomTooltip title={tooltipsTitle.DOWNLOAD_EXCEL_FILE}>
            <WrapperIconExcel>
              <Icon
                name="iconLoad"
                width="18px"
                height="32px"
                onClick={() => uploadTasksExcel()}
              />
            </WrapperIconExcel>
          </CustomTooltip>

          <CustomTooltip title={tooltipsTitle.SHOW_CALENDAR}>
            <WrapperIconVisual showVisual={showVisual}>
              <Icon
                name="iconChartBar"
                width="24px"
                height="32px"
                onClick={() => setShowVisulaization(!showVisual)}
              />
            </WrapperIconVisual>
          </CustomTooltip>

          <CustomTooltip title={tooltipsTitle.SELECT_COLUMNS}>
            <WrapperIconColumn showColumnsWindow={showColumnsWindow}>
              <Icon
                name="iconEyeSlash"
                onClick={() => {
                  setShowColumnsCustom(!showColumnsWindow);
                }}
              />
            </WrapperIconColumn>
          </CustomTooltip>
      
          <Button
            title="Добавить"
            icon="addPlus"
            design="blue-btn"
            isSending={!wasFetchedSlowRequests}
            style={{
              padding: ".5rem",
              width: "150px",
              marginLeft: "8px",
              height: "50px",
              background: "#01B0E9",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: "400",
            }}
            onClick={() => {
              setStartTaskTime(new Date());
              dispatch(setTaskPriority(priority.id));
              setShownTaskInfoPlate(true);
            }}
          />
        </RightBlock>
      </LineOfActions>
      {showColumnsWindow && <ColumnsWindow context={"tasks"} />}
      {showFiltersWindow && <FiltersWindow context={"tasks"} />}
    </WrapperTasksActions>
  );
};

const mapStateToProps = (state: State) => {
  return {
    positions: state.commonInfo.positions,
    priorities: state.commonInfo.priorities,
    current_user: state.commonInfo.current_user,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    users: state.commonInfo.users,
    selectedUserId: state.tasksPage.selectedUserId,
    showVisual: state.tasksPage.showVisualization,
    tableFilter: state.tasksPage.tableFilter,
    showColumnsWindow: state.tasksPage.showColumnsWindow,
    closingDayWindowShow: state.tasksPage.closingDayWindowShow,
    showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
    filters: state.commonInfo.filters,
    isShownDayClose: state.tasksPage.isShownDayClose,
    wasFetchedSlowRequests: state.commonInfo.wasFetchedSlowRequests
  };
};

const mapDispatchToProps = {
  setTaskAddMode,
  setShowVisulaization,
  setShowColumnsCustom,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setShownTaskInfoPlate,
  closingDay,
  setTaskPriority,
  ShowFiltersWindow,
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  setShowDaysOff,
  setPrivateToggle
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksActions);
