import { PROJECTS_SORTING } from "../../common/constants/localStorage";
import { TProjectsSortingLocal } from "./interfaces";

export const getProjectsSortingLocal = (): TProjectsSortingLocal|null => {
  if(localStorage.getItem(PROJECTS_SORTING)) {
    return JSON.parse(
      localStorage.getItem(PROJECTS_SORTING) as string
    );
  }
  
  return null;
};
