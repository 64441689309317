import React, {
  useState,
  useEffect
} from "react";
import { State } from "../../../rootReducer";
import {
  setTableFilter,
  fetchTasks,
  changeTaskList,
  setOrder as setTaskPageTasksOrder
} from "../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { columnsOfTables } from "../../../common/shared_dataTask";
import styled from "styled-components";
import SortIcon from "../../../common/components/SortIcon";
import { ETaskTableContext } from "../constants";
import { setProjectTasksOrder } from "../../Projects/actions";

type Props = {
  isScroll?: boolean;
  dataWasFetched: boolean,
  contextCall: ETaskTableContext
};

const WrapperTasksTableHead = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  height: 60px;
`;

const BlockStyled = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  
  & > span {
    position: relative;
  }
`;

const TasksTableHead: React.FC<Props> = ({
  isScroll,
  dataWasFetched,
  contextCall
}) => {
  const dispatch = useDispatch();
  
  const activeColumns = useSelector((state: State) => {
    return (contextCall === ETaskTableContext.PROJECTS_PAGE)
      ? state.projectsPage.activeColumns
      : state.tasksPage.activeColumns;
  });
  
  const isLoading = useSelector((state: State) => {
    return (contextCall === ETaskTableContext.PROJECTS_PAGE)
      ? state.projectsPage.isFetchingTasks
      : state.tasksPage.isLoading;
  });
  
  const [clicked, setClicked] = useState<any>({
    col: 'id',
    count: 1
  });
  
  useEffect(() => {
    if(dataWasFetched && !isLoading) {
      (contextCall === ETaskTableContext.PROJECTS_PAGE)
        ? dispatch(setProjectTasksOrder(clicked.col, clicked.count === 1))
        : dispatch(setTaskPageTasksOrder(clicked.col, clicked.count === 1));

      localStorage.setItem(
        'cols_order',
        JSON.stringify({
          name: clicked.col,
          order_direct: clicked.count === 1,
        })
      );
    }
  }, [clicked.col, clicked.count]);

  useEffect(() => {
    if(localStorage.getItem('cols_order')) {
      let parsed_obj = JSON.parse(
        localStorage.getItem('cols_order') as string
      );

      setClicked({
        col: parsed_obj.name,
        count: parsed_obj.order_direct ? 1 : 2,
      });
    }
  }, []);

  return (
    <WrapperTasksTableHead className='task-table-head' isScroll={isScroll}>
      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            activeColumns.includes(columnsOfTables[key].columnId) &&
            (columnsOfTables[key].columnId === "id" ||
              columnsOfTables[key].columnId === "name") && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count:
                      clicked.col === columnsOfTables[key].columnId
                        ? clicked.count === 2
                          ? 1
                          : clicked.count + 1
                        : 1,
                  });
                }}
              >
                <span>
                  {columnsOfTables[key].showingText}
                  <SortIcon
                    active={clicked.col === columnsOfTables[key].columnId}
                    activeSort={
                      clicked.col === columnsOfTables[key].columnId &&
                      clicked.count === 2
                        ? "top"
                        : "bottom"
                    }
                  />
                </span>
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>

      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return activeColumns.includes(columnsOfTables[key].columnId) &&
            (columnsOfTables[key].columnId === "author_id" ||
              columnsOfTables[key].columnId === "executor_id" ||
              columnsOfTables[key].columnId === "status_id" ||
              columnsOfTables[key].columnId === "end" ||
              columnsOfTables[key].columnId === "task_load" ||
              columnsOfTables[key].columnId === "project_name" ||
              columnsOfTables[key].columnId === "priority_id") ? (
            <HeaderItemName
              key={columnsOfTables[key].columnId}
              style={columnsOfTables[key].style}
              onClick={() => {
                setClicked({
                  col: columnsOfTables[key].columnId,
                  count:
                    clicked.col === columnsOfTables[key].columnId
                      ? clicked.count === 2
                        ? 1
                        : clicked.count + 1
                      : 1,
                });
              }}
            >
              <span>
                {columnsOfTables[key].showingText}
                <SortIcon
                  active={clicked.col === columnsOfTables[key].columnId}
                  activeSort={
                    clicked.col === columnsOfTables[key].columnId &&
                    clicked.count === 2
                      ? "top"
                      : "bottom"
                  }
                />
              </span>
            </HeaderItemName>
          ) : null;
        })}
      </BlockStyled>
    </WrapperTasksTableHead>
  );
};

const mapStateToProps = (state: State) => {
  return {
    dataWasFetched: state.commonInfo.wasFetchedFastRequests
  };
};

const mapDispatchToProps = {
  setTableFilter,
  fetchTasks,
  changeTaskList
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTableHead);
