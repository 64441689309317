import { Item } from "../types";

export const timezones: Item[] = [
  {
    text: 'Калининград',
    value: 2
  },
  {
    text: 'Москва',
    value: 3
  },
  {
    text: 'Самара',
    value: 4
  },
  {
    text: 'Екатеринбург',
    value: 5
  },
  {
    text: 'Омск',
    value: 6
  },
  {
    text: 'Красноярск',
    value: 7
  },
  {
    text: 'Иркутск',
    value: 8
  },
  {
    text: 'Якутск',
    value: 9
  },
  {
    text: 'Владивосток',
    value: 10
  },
  {
    text: 'Магадан',
    value: 11
  },
  {
    text: 'Камчатка',
    value: 12
  }
];
