// Тарификатор
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import WarningIcon from "@material-ui/icons/Warning";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  NameIcon,
  DefaultIcon,
  EmailIcon,
  MapIcon,
  PhoneIcon,
} from "../../img/company";
import { fetchData } from "../../utils/fetchData";
import Checkbox from "../../common/components/CheckboxItem/Checkbox";
import Picture from "../Company/Components/picture";

import {
  validatePhoneNumber,
  validateNameCompany,
  validateBikCompany,
  validateOgrnCompany,
  validateKppCompany,
  validateInnCompany,
  validateEmail,
} from "../../utils/validPatterns";
import formsObj from "../../utils/formsCompany";
import Button from "@material-ui/core/Button";
import { State } from "../../rootReducer";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CreateNotif } from "../../utils/createNotification";
import { nanoid } from "nanoid";
import {
  actualRequest,
  fetchOfflineBillStatus,
  fetchOnlineBillStatus,
} from "../Payment/actions";
import palette from "../../common/components/palette";
interface Props {
  handleChangeInput: (event: any) => void;
  handlerBlur: (event: any) => void;
  value: string;
  name: string;
  error?: boolean;
  disabled?: boolean;
  subscribe_status?: boolean;
  subscribe_end?: boolean;
  payment_request_id?: number | null;
  url?: string;
}
const CompanyBlock = styled.div`
  width: 100%;

  padding: 20px 30px;
  font-family: Roboto, sans-serif;
`;

const CompanyDiv = styled.div`
  background: #f5f5f5;
  overflow-y: scroll;
  padding: 5px 5px 30px 30px;
  height: 77vh;
  width: 100%;
  box-shadow: 2px 5px 9px 1px rgba(34, 60, 80, 0.4);
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 1400px) {
    flex-direction: column-reverse
  }
  `;

const CompanyDivLeft = styled.div`
  /* margin-right: 30px; */
  height: min-content
`;
const CompanyDivRight = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
  max-width: 400px;

`;

const AbbreviationDiv = styled.div`
  display: flex;
  align-items: center;
  min-width: 130px;
  margin: 11px 8px 10px 0px;
`;

const Icon = styled.div`
  margin-right: 8px;
`;
const IconError = styled.div`
  margin-left: 6px;
`;

const Text = styled.div`
  width: 160px;
  font-size: 16px;
`;
const TextV2 = styled.div`
  width: 190px;
  font-size: 16px;
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  /* max-width: 400px; */
`;

const H1Text = styled.h2`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 22px;
  letter-spacing: 0.15px;
  margin-top: 25px;
`;

const ImgDiv = styled.div`
  width: 209px;
  height: 201px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #01b0e9;
  background: #e5e5e5;
  margin: 50px auto 180px;
  /* max-width: 400px; */
`;
const ImgNotDiv = styled.div`
  width: 209px;
  height: 201px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #01b0e9;
  background: #e5e5e5;
  margin: 50px auto 180px;
  /* max-width: 400px; */
`;

const CompanyDetails: React.FC<any> = ({
  currentUserInfo,
  users,
  subscribe_status,
  subscribe_end,
  payment_request_id,
  url,
}) => {
  const [companyImage, setCompanyImage] = useState(null);

  let companyId: any = localStorage.getItem("company_id");

  const [isAdressSame, setIsAdressSame] = useState<boolean>(false);
  const [usersInitiator, setUsersInitiator] = useState<any>({
    id: nanoid(),
    value: "",
    userId: "",
    inputValue: "",
  });
  const [isRedact, setIsRedact] = React.useState<boolean>(true);
  const [inputsValue, setInputsValue] = React.useState<any>({
    active_em: 0,
    address: "",
    bank_name: "",
    bik: "",
    checking_account: "",
    correspondent_account: "",
    description: "",
    email: "",
    id: 0,
    inn: "",
    kpp: "",
    logo: null,
    name: "",
    ogrn: "",
    okved: "",
    paid: 0,
    phone: "",
    phys_address: "",
    pricing_plan_id: 2,
    site: "",
    edo: false,
    edo_id: "",
    edo_operator: "",
    responsible_user_id: 0,
  });

  const navigation = useHistory();
  const dispatch = useDispatch();

  const [iconsNameValue, setIconsNameValue] = useState<any>({
    // active_em: {},
    address: { name: "Почтовый адрес", icon: <EmailIcon /> },
    bank_name: { name: "Наименование банка", icon: <DefaultIcon /> },
    bik: { name: "БИК", icon: <DefaultIcon /> },
    checking_account: { name: "Расчетный счет", icon: <DefaultIcon /> },
    correspondent_account: { name: "Корр. счет", icon: <DefaultIcon /> },
    email: { name: "Email", icon: <EmailIcon /> },
    // id: {},
    inn: { name: "ИНН", icon: <DefaultIcon /> },
    kpp: { name: "КПП", icon: <DefaultIcon /> },
    // logo: {},
    name: { name: "Наименование", icon: <NameIcon /> },
    ogrn: { name: "ОГРН/ОГРНИП", icon: <DefaultIcon /> },
    // okved: {},
    // paid: {},
    phone: { name: "Телефон", icon: <PhoneIcon /> },
    phys_address: { name: "Юридический адрес", icon: <MapIcon /> },
    // pricing_plan_id: {},
    site: { name: "Сайт", icon: <MapIcon /> },
  });

  useEffect(() => {
    dispatch(actualRequest(companyId));
  }, []);

  useEffect(() => {
    fetchData
      .get(`/api/v1/companies/${companyId}`)
      .then((res) => {
        setInputsValue(res);
      })
      .catch((error) => {
        console.log("err", error);
        CreateNotif("Произошла ошибка при получении данных о компании");
      });
  }, []);

  useEffect(() => {
    if (companyImage !== null) {
      inputsValue.logo = companyImage;

      fetchData
        .patch(`/api/v1/companies/${companyId}`, JSON.stringify(inputsValue))
        .then((res) => {
          setInputsValue(res);
        })
        .catch(function (error) {
          CreateNotif("Произошла ошибка");
        });
    }
  }, [companyImage]);

  useEffect(() => {
    if (inputsValue?.responsible_user_id) {
      fetchData
        .get(`/api/v1/users/${inputsValue?.responsible_user_id}`)
        .then((res) => {
          setUsersInitiator({
            id: nanoid(),
            value: " ",
            userId: res.id,
            inputValue: `${res.id} ${res.name} ${res.surname}`,
          });
          setInputsValue({
            ...inputsValue,
            ...(inputsValue.responsible_user_id = res.id),
          });
        })
        .catch((error) =>
          CreateNotif("Произошла ошибка при получении данных о компании")
        );
    }
  }, [inputsValue?.responsible_user_id]);

  useEffect(() => {
    let user = users?.find(
      (user) => user.id == inputsValue.responsible_user_id
    );
    // деструктизировать
    let id = user?.id;
    let name = user?.name;
    let surname = user?.surname;

    setUsersInitiator((usersInitiator) => {
      return {
        ...usersInitiator,
        value: `${id} ${name} ${surname}`,
        userId: id,
      };
    });
  }, [users, usersInitiator.id]);

  const handlerBlur = () => {
    if (inputsValue && inputsValue?.logo && inputsValue?.logo.id) {
      inputsValue.logo = inputsValue?.logo.id;
    }
    fetchData
      .patch(`/api/v1/companies/${companyId}`, JSON.stringify(inputsValue))
      .then((res) => {
        if (res) setInputsValue(res); // Обработка ошибки в запросе
      })
      .catch(function (error) {
        CreateNotif("Произошла ошибка");
      });
  };

  const onChangeInput = (e) => {
    let { name, value } = e.target;
    setInputsValue((inputsValue) => {
      return { ...inputsValue, [name]: value };
    });
  };

  const onChangeInputAdressSame = () => {
    setIsAdressSame((isAdressSame) => !isAdressSame);
  };

  const onChangeInputDocimentFlow = (bol) => {
    setInputsValue((inputsValue) => {
      return { ...inputsValue, ["edo"]: bol };
    });
  };

  const inputValueInitiator = (value) => {
    let userId = value.split(" ").shift();
    setUsersInitiator((usersInitiator) => {
      return { ...usersInitiator, inputValue: value, userId: userId };
    });
    setInputsValue((inputsValue) => {
      return { ...inputsValue, ["responsible_user_id"]: +userId };
    });
  };

  const btnRedactTarif = () => {
    navigation.push("/payment");
  };
  const btnExtendTarif = () => {
    navigation.push("/payment/method");
  };

  useEffect(() => {
    dispatch(actualRequest(companyId));
    dispatch(fetchOnlineBillStatus(companyId));
    dispatch(fetchOfflineBillStatus(companyId));
  }, []);

  useEffect(() => {
    if (isAdressSame)
      setInputsValue({ ...inputsValue, phys_address: inputsValue.address });
  }, [isAdressSame, inputsValue?.address, inputsValue?.phys_address]);

  useEffect(() => {
    currentUserInfo?.roles.map((rol) => {
      if (rol === 1 || rol === 2) {
        return setIsRedact(false);
      }
    });
  }, [currentUserInfo]);

  return (
    <CompanyBlock>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          style={{
            fontWeight: 500,
            marginBottom: "20px",
            fontSize: "36px",
            width: "100%",
          }}
          type="text"
          name="name"
          value={inputsValue.name}
          onChange={onChangeInput}
        />
      </div>
      <CompanyDiv>
        <CompanyDivLeft>
          <H1Text>Реквизиты компании</H1Text>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.name.icon}</Icon>
            <Text>{iconsNameValue.name.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.name}
                name="name"
                error={!isRedact && !validateNameCompany(inputsValue.name)}
              />
            </Input>
            {!isRedact && !validateNameCompany(inputsValue.name) && (
              <CustomIconEroor title={formsObj.inn.errorHint} />
            )}
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.inn.icon}</Icon>
            <Text>{iconsNameValue.inn.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={
                  validateInnCompany(inputsValue.inn)
                    ? handlerBlur
                    : () => {
                        inputsValue.inn = "";
                      }
                }
                value={inputsValue.inn}
                name="inn"
                error={!isRedact && !validateInnCompany(inputsValue.inn)}
              />
            </Input>
            {!isRedact && !validateInnCompany(inputsValue.inn) && (
              <CustomIconEroor title={formsObj.inn.errorHint} />
            )}
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.kpp.icon}</Icon>
            <Text>{iconsNameValue.kpp.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={
                  validateKppCompany(inputsValue.kpp)
                    ? handlerBlur
                    : () => {
                        inputsValue.kpp = "";
                      }
                }
                value={inputsValue.kpp}
                name="kpp"
                error={!isRedact && !validateKppCompany(inputsValue.kpp)}
              />
            </Input>
            {!isRedact && !validateKppCompany(inputsValue.kpp) && (
              <CustomIconEroor title={formsObj.kpp.errorHint} />
            )}
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.ogrn.icon}</Icon>
            <Text>{iconsNameValue.ogrn.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={
                  validateOgrnCompany(inputsValue.ogrn)
                    ? handlerBlur
                    : () => {
                        inputsValue.ogrn = "";
                      }
                }
                value={inputsValue.ogrn}
                name={"ogrn"}
                error={!isRedact && !validateOgrnCompany(inputsValue.ogrn)}
              />
            </Input>
            {!isRedact && !validateOgrnCompany(inputsValue.ogrn) && (
              <CustomIconEroor title={formsObj.ogrn.errorHint} />
            )}
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.phys_address.icon}</Icon>
            <Text>{iconsNameValue.phys_address.name}</Text>
            <Input>
              <CustomInput
                disabled={isAdressSame || isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.phys_address}
                name="phys_address"
              />
            </Input>
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.address.icon}</Icon>
            <Text>{iconsNameValue.address.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.address}
                name="address"
              />
            </Input>
          </AbbreviationDiv>

          {/* тут инпут с checkbox */}
          <div
            style={{
              margin: "15px 30px 15px 215px",
              width: "350px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              disabled={isRedact}
              checked={isAdressSame}
              onChange={onChangeInputAdressSame}
            />

            {/* <input
              onChange={onChangeInputAdressSame}
              checked={isAdressSame}
              type="checkbox"
            /> */}
            <span
              style={{ fontWeight: 500, fontSize: "13.5px", marginLeft: "8px" }}
            >
              Почтовый адрес совпадает с юридическим
            </span>
          </div>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.phone.icon}</Icon>
            <Text>{iconsNameValue.phone.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.phone}
                name="phone"
                error={!isRedact && !validatePhoneNumber(inputsValue.phone)}
              />
            </Input>
            {!isRedact && !validatePhoneNumber(inputsValue.phone) && (
              <CustomIconEroor title={formsObj.phone.errorHint} />
            )}
          </AbbreviationDiv>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.email.icon}</Icon>
            <Text>{iconsNameValue.email.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={
                  validateEmail(inputsValue.email)
                    ? handlerBlur
                    : () => {
                        inputsValue.email = "";
                      }
                }
                value={inputsValue.email}
                name="email"
                error={!isRedact && !validateEmail(inputsValue.email)}
              />
            </Input>
            {!isRedact && !validateEmail(inputsValue.email) && (
              <CustomIconEroor title={formsObj.email.errorHint} />
            )}
          </AbbreviationDiv>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.site.icon}</Icon>
            <Text>{iconsNameValue.site.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.site}
                name="site"
              />
            </Input>
          </AbbreviationDiv>

          <H1Text>Банковские реквизиты</H1Text>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.bik.icon}</Icon>
            <Text>{iconsNameValue.bik.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.bik}
                name="bik"
                error={!isRedact && !validateBikCompany(inputsValue.bik)}
              />
            </Input>
            {!isRedact && !validateBikCompany(inputsValue.bik) && (
              <CustomIconEroor title={formsObj.bik.errorHint} />
            )}
          </AbbreviationDiv>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.bank_name.icon}</Icon>
            <Text>{iconsNameValue.bank_name.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.bank_name}
                name="bank_name"
              />
            </Input>
          </AbbreviationDiv>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.checking_account.icon}</Icon>
            <Text>{iconsNameValue.checking_account.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.checking_account}
                name="checking_account"
              />
            </Input>
          </AbbreviationDiv>
          <AbbreviationDiv>
            <Icon>{iconsNameValue.correspondent_account.icon}</Icon>
            <Text>{iconsNameValue.correspondent_account.name}</Text>
            <Input>
              <CustomInput
                disabled={isRedact}
                handleChangeInput={onChangeInput}
                handlerBlur={handlerBlur}
                value={inputsValue.correspondent_account}
                name="correspondent_account"
              />
            </Input>
          </AbbreviationDiv>

          <H1Text>Документооборот</H1Text>

          <AbbreviationDiv>
            <Icon>{iconsNameValue.checking_account.icon}</Icon>
            <p style={{ fontSize: "16px", marginRight: "20px" }}>
              Электронный документооборот
            </p>
            <Input>
              <Checkbox
                disabled={isRedact}
                checked={inputsValue.edo ? inputsValue.edo : ""}
                onChange={() => onChangeInputDocimentFlow(true)}
              />
              <span style={{ margin: "0px 15px 0px 10px", fontSize: "18px" }}>
                Да
              </span>
              <Checkbox
                disabled={isRedact}
                checked={!inputsValue.edo && !inputsValue.edo}
                onChange={() => onChangeInputDocimentFlow(false)}
              />
              <span style={{ margin: "0px 15px 0px 10px", fontSize: "18px" }}>
                Нет
              </span>
            </Input>
          </AbbreviationDiv>
          {inputsValue.edo ? (
            <>
              <AbbreviationDiv>
                <TextV2>№ идентификатора</TextV2>
                <Input>
                  <CustomInput
                    disabled={isRedact}
                    handleChangeInput={onChangeInput}
                    handlerBlur={handlerBlur}
                    value={inputsValue.edo_id}
                    name="edo_id"
                  />
                </Input>
              </AbbreviationDiv>
              <AbbreviationDiv>
                <TextV2>Наименование оператора ЭДО</TextV2>
                <Input>
                  <CustomInput
                    disabled={isRedact}
                    handleChangeInput={onChangeInput}
                    handlerBlur={handlerBlur}
                    value={inputsValue.edo_operator}
                    name="edo_operator"
                  />
                </Input>
              </AbbreviationDiv>
            </>
          ) : (
            ""
          )}
        </CompanyDivLeft>
        <CompanyDivRight>
          {inputsValue.logo ? (
            <ImgDiv>
              <Picture
                image={inputsValue?.logo}
                edit={true}
                changeHandler1={handlerBlur}
                setInputsValue={setInputsValue}
                companyImage={companyImage}
                setCompanyImage={setCompanyImage}
              />
              {/* <img src={inputsValue?.logo?.url} alt="foto" />*/}
            </ImgDiv>
          ) : (
            <ImgDiv>
              {/* <HomeIcon
                style={{ color: "white", width: "150px", height: "150px" }}
             />*/}
              <Picture
                image={inputsValue?.logo}
                edit={true}
                changeHandler1={handlerBlur}
                setInputsValue={setInputsValue}
                companyImage={companyImage}
                setCompanyImage={setCompanyImage}
              />
            </ImgDiv>
          )}
          <h2
            style={{ fontWeight: 500, fontSize: "20px", marginBottom: "26px" }}
          >
            Подписка на сервис
          </h2>
          <h2 style={{ marginBottom: "20px" }}>
            {subscribe_status
              ? `Подписка оплачена до: ${subscribe_end} г.`
              : "Подписка отсутствует"}
          </h2>{" "}
          {!!url && (
            <div style={{ marginBottom: "10px" }}>
              <span> {"Заявка ожидает оплаты. "}</span>
              <a
                href={url}
                style={{
                  textDecoration: "none",
                  color: palette.blue,
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                }}
                target="_blank"
              >
                Перейти
              </a>
            </div>
          )}
          {!!payment_request_id && (
            <div style={{ marginBottom: "20px" }}>
              <span> {"У вас есть неоплаченный счет. "}</span>
              <a
                href={`${process.env.REACT_APP_PUBLIC_URL}/payment/bill-pdf-create/${payment_request_id}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: palette.blue,
                  margin: 0,
                  padding: 0,
                  cursor: "pointer",
                }}
              >
                Cкачать
              </a>
            </div>
          )}
          <div
            style={{
              maxWidth: "360px",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "50px",
            }}
          >
            <Button
              disabled={isRedact}
              style={{ width: "146px", backgroundColor: "#1aabf0" }}
              variant="contained"
              color="primary"
              onClick={btnRedactTarif}
            >
              Изменить подписку
            </Button>
            {subscribe_status && (
              <Button
                disabled={isRedact}
                style={{ width: "146px", backgroundColor: "#1aabf0" }}
                variant="contained"
                color="primary"
                onClick={btnExtendTarif}
              >
                Продлить подписку
              </Button>
            )}
          </div>
          <h2
            style={{ marginBottom: "20px", fontSize: "21px", fontWeight: 500 }}
          >
            Ответственный сотрудник
          </h2>
          <p style={{ marginBottom: "10px" }}>
            На электронную почту сотрудника будут приходить уведомления об
            окончании подписки
          </p>
          <>
            <input
              list="initiators"
              onChange={(e) => {
                inputValueInitiator(e.target.value);
              }}
              onBlur={handlerBlur}
              defaultValue={" "}
              value={usersInitiator.inputValue}
              style={{ marginLeft: "0px", maxWidth: 400 }}
              className="input-form "
              id="demo-simple-select-filled"
            />

            <datalist id="initiators">
              {users?.map((initiators) => (
                <option key={initiators.id}>
                  {`${initiators.id}  ${initiators.name} ${initiators.surname}`}
                </option>
              ))}
            </datalist>
          </>
        </CompanyDivRight>
      </CompanyDiv>
    </CompanyBlock>
  );
};

const CustomIconEroor: React.FC<any> = ({ title }) => {
  return (
    <IconError>
      <Tooltip style={{ padding: 0 }} title={title}>
        <IconButton>
          <WarningIcon htmlColor="red" />
        </IconButton>
      </Tooltip>
    </IconError>
  );
};

const CustomInput: React.FC<Props> = ({
  handleChangeInput,
  value,
  name,
  error,
  disabled,
  handlerBlur,
}) => {
  return (
    <TextField
      disabled={disabled}
      error={error}
      name={name}
      onChange={handleChangeInput}
      onBlur={handlerBlur}
      value={value}
      label=""
      InputLabelProps={{
        style: { padding: 5 },
      }}
      InputProps={{
        // disableUnderline: true,
        style: {
          padding: 5,
          background: "#efefef",
        },
      }}
      inputProps={{
        style: {
          padding: 2,
        },
      }}
      style={{ width: "320px", marginLeft: "20px" }}
      id="filled-basic"
      variant="filled"
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserInfo: state.commonInfo.currentUserInfo,
    users: state.commonInfo.users,
    subscribe_status: state.payment.subscribe_status,
    subscribe_end: state.payment.subscribe_end,
    payment_request_id: state.payment.payment_request_id,
    url: state.payment.url,
  };
};

export default connect(mapStateToProps)(CompanyDetails);
