// Тут заводится и рассчитывается дата окончания задачи

import React, { useCallback } from "react";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePikerTask";
import { connect } from "react-redux";
import {
  recalculateTaskLoad,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskEnd,
} from "../actions";
import { State } from "../../../rootReducer";
import { updateTask } from "../../../common/actions";
import {formatStringDate} from "../../../common/format";
import moment from "moment/moment";
import {datePicker} from "../../../common/constants/constants";

const TaskEnd = ({
                   checkedPersonalTask,
                   checkedTaskEnd,
                   disabled,
                   begin,
                   end,
                   setTaskEnd,
                   delegated,
                   recalculateTaskLoad,
                   selectedTask,
                   updateTask,
                   flagForActionCyclycTask,
                   setCyclicTaskToChange,
                   sendReqToChangeTemplRepTask,
                   cellData,
                   setDataEnd,
                   setTimeValid
                 }) => {
  const acceptHandler = useCallback(
    function (e): any {
      setTaskEnd(e);
      setDataEnd(e);

      // если выбрана ранее созданная задача
      if (selectedTask) {
        // реальный запрос на сервер и пересчет данных задачи - selectedTask
        recalculateTaskLoad({task_load: selectedTask.task_load, end: e});

        // TODO разобраться с цикличными задачами

        if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
          // сравнение одного с несколькими
          // эта задача вообще циклическая или сама по себе ?
          // значит циклическая
          if (flagForActionCyclycTask === "") {
            setCyclicTaskToChange({end: e}); // заношу значения изменяемых параметров во временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === "task") {
            updateTask(selectedTask!.id, {
              begin,
              end: e,
              private: checkedPersonalTask ? true : false,
            });
          } else {
            updateTask(selectedTask!.id, {
              begin,
              end: e,
              private: checkedPersonalTask ? true : false,
            });
            sendReqToChangeTemplRepTask({end: e});
          }
        } else
          updateTask(selectedTask!.id, {
            begin: selectedTask.begin,
            end: e,
            private: checkedPersonalTask ? true : false,
          });

      }
    },
    [selectedTask]
  );

  end = end.replace(/\//g, "-");

  let dateForEndPlusDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

  // функция подготовки даты дедлайн
  const prepareDateEnd = () => {
    if (checkedTaskEnd) {
      return dateForEndPlusDay

      //если выбрана задача созданная ранее
    } else if (selectedTask) {

      // при первом открытии вновь созданной задачи
      if (selectedTask && selectedTask.task_load_sum === null) {

        if (formatStringDate(selectedTask.end) === "Invalid date") {
          return moment(selectedTask.end, datePicker.DATE_FORMAT).format(datePicker.DATE_FORMAT)

        } else {
          return moment(selectedTask.end).format(datePicker.DATE_OPEN_FORMAT);
        }
      }

      // при последующих открытиях задач
      if (selectedTask && selectedTask.task_load_sum !== null) {
        return moment(selectedTask.end, datePicker.DATE_FORMAT).format(datePicker.DATE_OPEN_FORMAT)
      }

    } else {
      return moment(end, datePicker.DATE_FORMAT).format(datePicker.DATE_OPEN_FORMAT)
    }
  }

  let dateEnd = prepareDateEnd();


  return (
    <TaskDateTime
      setTimeValid={setTimeValid}
      disabled={disabled || delegated}
      acceptHandler={acceptHandler}
      date={dateEnd}
      isDateEnd={true}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEnd);
