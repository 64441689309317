// ********************************************************************
// Главное окно для добавления новой задачи или редактирования имеющейся
// (смотреть не в NewTask а тут код описан)
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setTaskPrivate,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  updateChecklist,
  updateCommentsList,
  clearCyclicTaskToChange,
  setCommentText,
  setLabelsData,
  setTaskBegin,
  setTaskEnd,
  setCellTaskData,
} from "../actions";
import { Task, User, Comment, Schedule } from "../../../common/types";
import Toggler, {
  ToggleElem,
} from "../../../common/components/Togglers/Toggler";
import InfoTab from "./InfoTab";
import CommentTab from "./CommentTab";
import { State } from "../../../rootReducer";
import { fetchData } from "../../../utils/fetchData";
import "../styles.sass";
import { setIdActiveTask, updateTask } from "../../../common/actions";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import DateMaster from "../../../utils/DateMaster";
import { TextareaAutosize } from "@material-ui/core";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { timeUpdate, urlApp } from "../../../App";
import { fetchUpdateData } from "../../../utils/fetchUpdateData";
import Button from "../../../common/components/newComponent/Buttons/Button";
import ContextDropdownMenu from "./ContextDropdownMenu";
import { fetchTaskDependence, setFilterWindowShow } from "../../Tasks/actions";
import Notifications from "./Notifications";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import Icon from "../../../common/components/Icon";
import moment from "moment";
import {
  CANCELED_STATUS,
  DRAFT_STATUS,
  SEND_AUTHOR_STATUS,
} from "../../Day/helpers";

type Props = {
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  checkList: any[];
  taskInfoActiveTab: string;
  selectedUserId: number;
  taskName: string;
  users: User[];
  commentList: Comment[];
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  setTaskInfoActiveTab: (activeTab: string) => void;
  setProjectUsers: (users: User[]) => void;
  setComentList: (commentList: Comment[]) => void;
  updateTask: (id: number, params: any) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  rewriteWholeTaskList: (response: any) => void;
  currentUserId: number | undefined;
  currentUserTopRole: number | undefined;
  selectTask: (val: Task | null) => void;
  setTaskName: (name: string) => void;
  setDefaultValues: () => void;
  flagForActionCyclycTask: string;
  rememberObjectCyclicTaskToChange: {};
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setCyclicTaskToChange: (val: {}) => void;
  setFlagRepeatTaskToChange: (val: string) => void;
  clearCyclicTaskToChange: () => void;
  labelsData: {
    comments_count: number;
    files_count: number;
  };
  setLabelsData: (comments_count?: number, files_count?: number) => void;
  visibleForm?: boolean;
  isShowAddObject: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  cellData?: any;
  setCellData?: (mode: any) => void;
  addEventHandler?: (event: any, ans: any) => void;
  deleteSelectedEvent?: (id) => void;
  begin: string;
  end: string;
  cellTaskData: any;
  setCellTaskData: (data: any) => void;
  setIdActiveTask: (idActiveTask: string | number) => void;
  priority_id: any;
  currentUser;
};

const togglerElems: ToggleElem[] = [
  {
    name: "Информация",
    value: "info",
  },
  {
    name: "Комментарии",
    value: "comments",
  },
];
const scheduler = window.scheduler;

const WrapperNotification = styled.div`
  position: absolute;
  z-index: 1;
  width: 352px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  top: 40px;
`;

const MainAddTask: React.FC<Props> = (props) => {
  const {
    setShownTaskInfoPlate,
    setTaskInfoActiveTab,
    setProjectUsers,
    setComentList,
    commentList,
    updateTask,
    checkList,
    setExecutorSchedule,
    selectedTask,
    selectedUserId,
    users,
    taskInfoActiveTab,
    rewriteWholeTaskList,
    currentUserId,
    currentUserTopRole,
    setTaskName,
    setDefaultValues,
    isShownTaskInfoPlate,
    taskName,
    flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setCyclicTaskToChange,
    setFlagRepeatTaskToChange,
    clearCyclicTaskToChange,
    labelsData,
    setLabelsData,
    visibleForm,
    isShowAddObject,
    setIsShowAddObject,
    cellData,
    setCellData,
    addEventHandler,
    deleteSelectedEvent,
    begin,
    end,
    cellTaskData,
    setCellTaskData,
    priority_id,
    setIdActiveTask,
    currentUser
  } = props;

  const {
    checkedPersonalTask,
    setCheckedPersonalTask,
    setCheckedTaskBegin,
    setCheckedTaskEnd,
  } = useContext(useStateContext);

  const location = useLocation();

  const [dataEnd, setDataEnd] = useState<any>();
  let plate2 = useRef<HTMLDivElement>(null);

  function changeCheckbox() {
    //************************************* */
    let dateNow = new Date();

    let dateNowString = `${dateNow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${dateNow.toLocaleString().split(",")[1]}`;

    let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    let tomorrowString = `${tomorrow
      .toLocaleString()
      .split(",")[0]
      .split(".")
      .join("-")}${tomorrow.toLocaleString().split(",")[1]}`;

    if (selectedTask?.id) {
      if (checkedPersonalTask === true) {
        setCheckedTaskBegin(false);
        setCheckedTaskEnd(false);
        updateTask(selectedTask!.id, {
          begin: dateNowString,
          end: tomorrowString,
          private: !checkedPersonalTask,
        });
      } else if (checkedPersonalTask === false) {
        updateTask(selectedTask!.id, { private: !checkedPersonalTask });
      }
    } else {
      dispatch(setTaskPrivate(!checkedPersonalTask));

      if (checkedPersonalTask === true) {
        setCheckedPersonalTask(false);
        setCheckedTaskBegin(false);
        setCheckedTaskEnd(false);
        dispatch(setTaskBegin(dateNowString));
        dispatch(setTaskEnd(tomorrowString));
      } else if (checkedPersonalTask === false) {
        setCheckedPersonalTask(true);
      }
    }
  }

  const { timestamp } = useSelector((state: State) => state.commonInfo);
  const { dependencesTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );
  const [notification, setNotification] = useState(false);
  const templateChecklist = [...checkList];
  const templateСommentList = [...commentList];
  const [titleStyle, setTitleStyle] = useState({});

  const [showContextDropDown, setShowContextDropDown] = useState(false);

  useEffect(() => {
    if (selectedTask?.id) {
      setCheckedPersonalTask(selectedTask.private);

      setIdActiveTask(selectedTask?.id);
    } else setCheckedPersonalTask(false);
  }, [selectedTask]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedTask?.id && isShownTaskInfoPlate) {
      dispatch(fetchTaskDependence(selectedTask.id));
    }
  }, [selectedTask?.id, isShownTaskInfoPlate]);

  // Скрывать меню в задаче по щелчку за меню
  const outsideClickHandlerDropdownMenu = useCallback(
    function (e: Event): any {
      let Element = e.target as Element;
      if (!Element.closest(".ThreeDotsDropdownMenu"))
        setShowContextDropDown(false);
    },
    [setFilterWindowShow]
  );

  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    if (selectedTask) {
      setTitleStyle({
        background: "white",
        borderColor: "white",
        borderRadius: "6px",
        fontSize: "20px",
        color: "#212121",
        padding: "0",
        height: "50px",
      });
    } else {
      setTitleStyle({
        background: "FFF",
        borderRadius: "6px",
        padding: "12px",
        height: "50px",
        color: "#212121",
      });
    }
  }, [selectedTask]);

  // при открытии должен ходить запрос на получение чек-листа. Изначально задача чек-лист в себе не хранит.
  useEffect(() => {
    (async () => {
      if (flagForActionCyclycTask !== "") setFlagRepeatTaskToChange(""); // обнуляю определенное значение для подтверджения изменения повторящейся задачи.

      if (selectedTask) setShownTaskInfoPlate(true);
      const taskId = selectedTask?.id;
      if (!taskId) return;
      let checklistForTask = await fetchData.get(
        `/api/v1/tasks/${taskId}/checklists`
      );
      if (checklistForTask) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        checklistForTask = Object.values(checklistForTask);
        rewriteWholeTaskList(checklistForTask);
      }

      // информация по лейблам о комментах и атачментах
      const executor_id = selectedTask?.executor_id;
      if (!executor_id) return;
      const data = await fetchData.get(
        `/api/v1/tasks/${selectedTask?.id}/info`
      );
      setLabelsData(0, 0);
      if (data) {
        let comments = data?.comments_count;
        let files_count = data?.files_count;
        setLabelsData(comments, files_count);
      }
    })();
  }, [selectedTask?.id]);
  const projectId = selectedTask?.project_id;

  const [usersForProject, setUsersForProject] = useState<any>([]);

  useEffect(() => {
    // запрашивать список юзеров по проекту
    (async () => {
      const projectId = selectedTask?.project_id;
      if (!projectId) return;
      let usersForProject = await fetchData.get(
        `/api/v1/projects/${projectId}/users`
      );
      if (usersForProject) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        usersForProject = Object.values(usersForProject);
        let usersList = users?.filter((user) =>
          usersForProject.includes(user.id)
        );
        setProjectUsers(usersList);
      }
    })();
  }, [setProjectUsers, users, visibleForm, projectId]);

  useEffect(() => {
    (async () => {
      const taskId = selectedTask?.id;
      if (!taskId) return;
      const commentList = await fetchData.get(
        `/api/v1/tasks/${taskId}/comments?order=created_at&page=1&orderType=desc`
      );
      if (commentList) {
        setComentList(commentList);
      }
    })();
  }, [setComentList]);

  useEffect(() => {
    (async () => {
      const executor_id = selectedTask?.executor_id;
      if (!executor_id) return;
      const data = await fetchData.get(`/api/v1/users/${executor_id}/schedule`);
      if (data?.schedule) {
        setExecutorSchedule(data.schedule);
      }
    })();
  }, []);

  const dateMaster = useMemo(() => {
    if (selectedTask) return new DateMaster(selectedTask?.end);
  }, [selectedTask]);

  //  отслеживает подтвердждения изменений повторяющихся задач
  useEffect(() => {
    if (selectedTask && Object.keys(rememberObjectCyclicTaskToChange).length) {
      updateTask(selectedTask.id, rememberObjectCyclicTaskToChange);
      if (flagForActionCyclycTask !== "task")
        sendReqToChangeTemplRepTask(rememberObjectCyclicTaskToChange);
      clearCyclicTaskToChange();
    }
  }, [flagForActionCyclycTask]);

  useEffect(() => {
    let intervalFetch;
    if (timestamp) {
      if (selectedTask) {
        intervalFetch = setInterval(() => {
          fetchUpdateData(timestamp, "checklistAndComments", [selectedTask?.id])
            ?.then((data) => {
              return data;
            })
            .then((data) => {
              if (data.tasks_comments?.length > 0) {
                data.tasks_comments.forEach((updateComment) => {
                  if (templateСommentList.length === 0) {
                    templateСommentList.unshift(updateComment);
                  } else {
                    for (let i = 0; i < templateСommentList.length; i++) {
                      if (
                        updateComment.hasOwnProperty("create") ||
                        (templateСommentList.length === i + 1 &&
                          templateСommentList[i].id !== updateComment.id)
                      ) {
                        templateСommentList.unshift(updateComment);
                        break;
                      } else if (
                        templateСommentList[i].id === updateComment.id &&
                        updateComment.hasOwnProperty("delete")
                      ) {
                        templateСommentList.splice(i, 1);
                        break;
                      } else if (
                        templateСommentList[i].id === updateComment.id &&
                        !updateComment.hasOwnProperty("delete") &&
                        !updateComment.hasOwnProperty("create")
                      ) {
                        templateСommentList.splice(i, 1, updateComment);
                        break;
                      }
                    }
                  }
                });

                dispatch(updateCommentsList(templateСommentList));
              }
            })
            .catch((error) => console.warn(error));
        }, timeUpdate);
      }
    }
    return () => {
      clearInterval(intervalFetch);
    };
  }, [timestamp, taskInfoActiveTab, templateChecklist, templateСommentList]);

  //**************************************************** */
  const inputEl = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, [isShownTaskInfoPlate]);

  const ChangeEvent = (eventChanges) => {
    if (cellTaskData.type == "EVENT_TASK_TYPE") {
      const correctEndAt = moment(
        dataEnd ?? eventChanges.end_at,
        "DD-MM-YYYY HH:mm:ss"
      ).toDate();

      const start = cellTaskData.scheduler.date.add(
        correctEndAt,
        -15,
        "minute"
      );
      const currentEvent = cellTaskData.scheduler.getEvent(
        cellTaskData.event_id
      );
      currentEvent.text = eventChanges.name;
      currentEvent.meta.task_priority_id = selectedTask?.priority_id;
      currentEvent.meta.task_status_id =
        dateMaster &&
        selectedTask?.status_id === 10 &&
        new Date(
          dateMaster.year,
          dateMaster.month,
          dateMaster.day,
          dateMaster.hours,
          dateMaster.minutes
        ).getTime() <= new Date(Date.now()).getTime()
          ? 14
          : selectedTask?.status_id;

      cellTaskData.scheduler.updateEvent(cellTaskData.event_id);

      cellTaskData.scheduler.callEvent("onEventChanged", [
        cellTaskData.event_id,
        cellTaskData.scheduler.getEvent(cellTaskData.event_id),
      ]);

      //////////////
      if (cellTaskData.deadlineEventId) {
        const deadlineEvent = cellTaskData.scheduler.getEvent(
          cellTaskData.deadlineEventId
        );
        if (deadlineEvent) {
          deadlineEvent.text = eventChanges.name;
          deadlineEvent.start_date = new Date(start);
          deadlineEvent.end_date = new Date(correctEndAt);
          deadlineEvent.meta.task_priority_id = selectedTask?.priority_id;
          deadlineEvent.meta.task_status_id =
            dateMaster &&
            selectedTask?.status_id === 10 &&
            new Date(
              dateMaster.year,
              dateMaster.month,
              dateMaster.day,
              dateMaster.hours,
              dateMaster.minutes
            ).getTime() <= new Date(Date.now()).getTime()
              ? 14
              : selectedTask?.status_id;

          cellTaskData.scheduler.updateEvent(cellTaskData.deadlineEventId);
          cellTaskData.scheduler.callEvent("onEventChanged", [
            cellTaskData.deadlineEventId,
            cellTaskData.scheduler.getEvent(cellTaskData.deadlineEventId),
          ]);
          if (
            currentEvent.meta.task_status_id === DRAFT_STATUS ||
            currentEvent.meta.task_status_id === CANCELED_STATUS ||
            currentEvent.meta.task_status_id === SEND_AUTHOR_STATUS
          ) {
            cellTaskData.scheduler.deleteEvent(deadlineEvent?.id);
          }
        }
      }
      if (
        currentEvent.meta.task_status_id === DRAFT_STATUS ||
        currentEvent.meta.task_status_id === CANCELED_STATUS ||
        currentEvent.meta.task_status_id === SEND_AUTHOR_STATUS
      ) {
        cellTaskData.scheduler.deleteEvent(currentEvent?.id);
      }
      setIsShowAddObject(false);
      setCellTaskData(null);
    }
    if (cellTaskData.type == "TASK_TYPE") {
      const correctEndAt = moment(
        dataEnd ?? eventChanges.end_at,
        "DD-MM-YYYY HH:mm:ss"
      ).toDate();

      const start = cellTaskData.scheduler.date.add(
        correctEndAt,
        -15,
        "minute"
      );

      const deadlineEvent = cellTaskData.scheduler.getEvent(
        cellTaskData.event_id
      );
      deadlineEvent.text = eventChanges?.name;
      deadlineEvent.start_date = new Date(start);
      deadlineEvent.end_date = new Date(correctEndAt);
      deadlineEvent.meta.task_priority_id = selectedTask?.priority_id;
      deadlineEvent.meta.task_status_id =
        dateMaster &&
        selectedTask?.status_id === 10 &&
        new Date(
          dateMaster.year,
          dateMaster.month,
          dateMaster.day,
          dateMaster.hours,
          dateMaster.minutes
        ).getTime() <= new Date(Date.now()).getTime()
          ? 14
          : selectedTask?.status_id;
      cellTaskData.scheduler.updateEvent(cellTaskData.event_id);
      cellTaskData.scheduler.callEvent("onEventChanged", [
        cellTaskData.event_id,
        cellTaskData.scheduler.getEvent(cellTaskData.event_id),
      ]);

      //////////////

      if (cellTaskData.taskEventId) {
        const currentEvent = cellTaskData.scheduler.getEvent(
          cellTaskData.taskEventId
        );
        if (currentEvent) {
          currentEvent.text = eventChanges?.name;
          currentEvent.meta.task_priority_id = selectedTask?.priority_id;
          currentEvent.meta.task_status_id =
            dateMaster &&
            selectedTask?.status_id === 10 &&
            new Date(
              dateMaster.year,
              dateMaster.month,
              dateMaster.day,
              dateMaster.hours,
              dateMaster.minutes
            ).getTime() <= new Date(Date.now()).getTime()
              ? 14
              : selectedTask?.status_id;

          cellTaskData.scheduler.updateEvent(currentEvent.id);
          cellTaskData.scheduler.callEvent("onEventChanged", [
            currentEvent.id,
            cellTaskData.scheduler.getEvent(currentEvent.id),
          ]);
          if (
            deadlineEvent.meta.task_status_id === DRAFT_STATUS ||
            deadlineEvent.meta.task_status_id === CANCELED_STATUS ||
            deadlineEvent.meta.task_status_id === SEND_AUTHOR_STATUS
          ) {
            cellTaskData.scheduler.deleteEvent(currentEvent?.id);
          }
        }
      }
      if (
        deadlineEvent.meta.task_status_id === DRAFT_STATUS ||
        deadlineEvent.meta.task_status_id === CANCELED_STATUS ||
        deadlineEvent.meta.task_status_id === SEND_AUTHOR_STATUS
      ) {
        cellTaskData.scheduler.deleteEvent(deadlineEvent?.id);
      }
      setIsShowAddObject(false);
      setCellTaskData(null);
    }
    setIsShowAddObject(false);
    setCellTaskData(null);
  };

  const fragment = window.location.href;
  const addDescriptionToURL = (hash) => {
    //добавляем хэш открытой вкладки(инф, комм) задачи
    if(selectedTask){
      window.location.hash = hash;
    }
  };
  
  const isComments = fragment.includes('comments');
  useEffect(() => {
    if(fragment.includes('taskId=')){
      if(isComments){
      setTaskInfoActiveTab("comments")
      } 
      else if(taskInfoActiveTab == "info"){
      window.location.hash = 'info';
      }
    }
  },[isShownTaskInfoPlate])
  
  return (
    <>
      {isShowAddObject ? (
        <></>
      ) : (
        <Icon
          style={{ marginBottom: "8px", width: "23px" }}
          name="cross"
          onClick={() => {
            if (cellTaskData) {
              ChangeEvent({
                name: taskName ? taskName : "",
                start_at: begin,
                end_at:
                  dateMaster &&
                  new Date(
                    dateMaster.year,
                    dateMaster.month,
                    dateMaster.day,
                    dateMaster.hours,
                    dateMaster.minutes
                  ),
                priority_id: priority_id,
                status_id: selectedTask?.status_id,
              });
            }

            if (!location.pathname.includes("/projects")) {
              setDefaultValues();
            }

            setShowContextDropDown(false);
            setShownTaskInfoPlate(false);
            setDataEnd(null);
            history.location.pathname.includes("tasks") &&
              history.replace(`${selectedUserId}`);
          }}
        />
      )}
      <div className="task_name">
        {/* Выводим предупреждение о важных полях, помеченных звездочкой- их надо заполнить, иначе не получится сохранить задачу */}
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <span> * - обязательные для заполнения поля </span>
          </div>
          {selectedTask && selectedTask.private && (
            <div style={{ marginRight: "20px" }}>
              <span style={{ marginRight: "10px" }}> Личная</span>
              <input
                disabled
                type="checkbox"
                checked={checkedPersonalTask}
                onChange={changeCheckbox}
              ></input>
            </div>
          )}

          {!selectedTask && (
            <div style={{ marginRight: "20px" }}>
              <span
                style={{ cursor: "pointer", marginRight: "10px" }}
                onClick={changeCheckbox}
              >
                {" "}
                Личная
              </span>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                checked={checkedPersonalTask}
                onChange={changeCheckbox}
              ></input>
            </div>
          )}
        </div>
        <br /> <br />
        <TextareaAutosize
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
          ref={inputEl}
          id={"rightPlate_name_textarea"}
          aria-label=""
          placeholder="Введите название*"
          onChange={(e) => {
            setTaskName(e.currentTarget.value);
          }}
          className={
            selectedTask &&
            currentUserId !== selectedTask?.author_id &&
            currentUserTopRole !== 1
              ? "disabled"
              : ""
          }
          style={titleStyle}
          onBlur={(e) => {
            if (selectedTask) {
              if (e.target.value !== selectedTask?.name) {
                // если новое значение отличается от начального
                // мгновенно обновляю значение для заколлапсированного состояния
                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  setCyclicTaskToChange({ name: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                } else updateTask(selectedTask!.id, { name: e.target.value });
              }
            } else {
              setTaskName(e.target.value);
            }
          }}
          value={taskName}
        />
      </div>

      {selectedTask && (
        <div className="tabs_desc">
          {notification && (
            <WrapperNotification>
              <Notifications />
            </WrapperNotification>
          )}

          <div className={"first_row"} style={{ position: "relative" }}>
            <Toggler
              elemsList={togglerElems}
              selectHandler={(active) => {
                if (active === "info") {
                  dispatch(setCommentText(""));
                }              
                if(isComments){
                  setTaskInfoActiveTab("comments")
                }
                setTaskInfoActiveTab(active as string);
                addDescriptionToURL(active)
              }}
              activeValue={taskInfoActiveTab}
            />

            <div className={"iconsBar"}>
              <div className={"attachments"}>
                <div className={"count"}>{labelsData.files_count}</div>
                <div className={"icon"} />
              </div>
              <div className={"comments"}>
                <div className={"count"}>{labelsData.comments_count}</div>
                <div className={"icon"} />
              </div>
              <div
                className={"bell"}
                onClick={() => {
                  setNotification(!notification);
                }}
              />
            </div>

            {/*если статус в работе и дедлайн прошел - он подменяется на просрочено*/}
            {selectedTask && (
              <TaskStatus
                buttonWrapperSize={150}
                id={
                  dateMaster &&
                  selectedTask.status_id === 10 &&
                  new Date(
                    dateMaster.year,
                    dateMaster.month,
                    dateMaster.day,
                    dateMaster.hours,
                    dateMaster.minutes
                  ).getTime() <= new Date(Date.now()).getTime()
                    ? 14
                    : selectedTask.status_id
                  // 14
                }
                taskId={selectedTask.id}
                id_bp={selectedTask.id_bp}
              />
            )}

            {/* Тут описывается кнопка выбора дополнительного меню (например, для создания подзадач) */}
            <Button
              onClick={() => {
                setTaskInfoActiveTab("info" as string); // При клике на кнопку перенаправляем на вкладку "Инфо"
                // setTaskInfoActiveTab - функция, переданная из state в props, которая изменяет переменную taskInfoActiveTab
                // Если activeValue= "info" - открывается главная вкладка задачи
                // Если activeValue= "comments" - открывается вкладка с комментариями
                setShowContextDropDown(!showContextDropDown); // делаем кнопку видимой/скрываем при клике на нее
              }}
              icon={"3dots"}
              design={"outline"}
            />
            {/* тут запускается дополнительное меню (например, для создания подзадач) */}
            {(showContextDropDown && selectedTask) && 
              (
                <ContextDropdownMenu
                  idSelectedTask={selectedTask.id}
                  isBpTask={!!selectedTask.id_bp}
                  isDeleteActionAllowed={currentUser?.isAdmin || (currentUser?.id === selectedTask.author_id)}
                  setShowContextDropDown={setShowContextDropDown}
                />
              )}
          </div>
        </div>
      )}

      {/* Тут выбирается какую вкладку запускать- info или comment */}

      {taskInfoActiveTab === "info" && (
        <InfoTab
          checkedPersonalTask={checkedPersonalTask}
          setCheckedPersonalTask={setCheckedPersonalTask}
          isShowAddObject={isShowAddObject}
          setIsShowAddObject={setIsShowAddObject}
          taskName={taskName}
          cellData={cellData}
          setDataEnd={setDataEnd}
          addEventHandler={addEventHandler}
          deleteSelectedEvent={deleteSelectedEvent}
          isVisibleTaskInfoPlate={visibleForm}
        />
      )}
      {taskInfoActiveTab === "comments" && <CommentTab />}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserId: state.commonInfo.currentUserInfo?.id,
    checkList: state.taskInfoPlate.checkList,
    selectedUserId: state.tasksPage.selectedUserId,
    currentUserTopRole:
      state.commonInfo.currentUserInfo?.roles &&
      state.commonInfo.currentUserInfo?.roles[0],
    selectedTask: state.taskInfoPlate.selectedTask,
    taskName: state.taskInfoPlate.name,
    users: state.commonInfo.users,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    commentList: state.taskInfoPlate.commentList,
    labelsData: state.taskInfoPlate.labelsData,
    begin: state.taskInfoPlate.begin,
    end: state.taskInfoPlate.end,
    cellTaskData: state.taskInfoPlate.cellTaskData,
    priority_id: state.taskInfoPlate.priority_id,
    currentUser: state.commonInfo.currentUserInfo
  };
};

const mapDispatchToProps = {
  setTaskPrivate,
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  updateTask,
  setExecutorSchedule,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData,
  setCellTaskData,
  setIdActiveTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainAddTask);
