import React from 'react';
import NameCell from '../../../common/components/TableCells/NameCell';
import { User } from '../../../common/types';
import UserEmailCell from '../../../common/components/TableCells/UserEmailCell';
import PositionCell from '../../../common/components/TableCells/RoleCell';
import Avatar from '../../../common/components/Avatar/Avatar';
import { urlApp } from '../../../App';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import UserActivityCell from './UserActivityCell';
import { useDispatch } from "react-redux";
import { setIsUserEdit } from "../actions";
import { setUserId } from '../../NewUser/actions';
import { fetchExistingUserData } from '../../../common/actions';
type Props = {
  user: User;
};
const WrapperUserTr = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0;
  position: relative;
  font-size: 15px;
  color: #5f6368;
  background-color: #fff;
  transition: background-color 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;
const WrapperName = styled.div`
  width: 100%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const UserTr: React.FC<Props> = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openModalHandler = () => {
    dispatch(setUserId(user.id))
    dispatch(fetchExistingUserData(user.id))
    dispatch(setIsUserEdit(true));
    //было завязано на урле изза чего урл менялся и сбрасывался поиск
    // history.push(`/users/${user.id}`);
  };
  return (
    <WrapperUserTr onClick={() => openModalHandler()}>
      <WrapperName>
        <Avatar
          style={{
            minWidth: '32px',
            maxWidth: '32px',
            height: '32px',
            marginRight: '8px',
          }}
          name={`${user.surname} ${user.name}`}
          url={user.image ? urlApp + `/files/${user.image.url}/download` : null}
        />
        <NameCell
          name={`${user.surname} ${user.name} ${user.patronymic || ''}`}
          roleId={user.roles[0]}
        />
      </WrapperName>
      <UserEmailCell email={user.email} />
      <PositionCell roleId={user.roles[0]} userPositionId={user.positions[0]} />
      <UserActivityCell activity={user.is_active}/>
    </WrapperUserTr>
  );
};

export default UserTr;
