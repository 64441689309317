// Аккордеон в разделе "Проекты"
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AccordionIcon2,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../img/projectIcons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import ProjectTableMiniHead from "./ProjectAllTableMiniHead";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { fetchData } from "../../../utils/fetchData";
import ProjectSectionTasks from "./ProjectAllSectionTasks";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import CommonCirclePreloader from "../../../common/components/CommonInfoPreloader/CommonCirclePreloader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditSection from "./EditSection/EditSection";
import { changeSectionName } from "../../NewProject/actions";
import { formatStringDate } from "../../../common/format";
import {
  setTaskPriority,
  setShownTaskInfoPlate, 
  setTaskProject, 
  setTaskSection
} from "../../TaskInfoPlate/actions";
import { Section, Task } from "../../../common/types";
import { errorMessagesConstants, pagesName, taskStatuses } from "../../../common/constants/constants";
import { getOneProject, updateTask } from "../../../common/actions";
import { projectsEventChannel } from "../../../utils/eventbus/channels/projectsChannel";
import DateMaster from "../../../utils/DateMaster";

interface Props {
  projectSection: Section;
  current_user?: any;
  users?: any;
  currentUserInfo?: any;
  openTasks: any;
  handleSetOpenTasks: (item: any) => void;
  openAccordSelect: boolean;
  changeSectionName: (sectionId: number, name: string) => void;
  priorities: any;
  correctBool: boolean;
  setCorrectBool: (mode: boolean) => void;
  createdTaskInfo: Task | null,
  selectedTask: Task | null,
  getOneProject: (id: string|number) => void,
  setTaskProject: (projectId: number) => void;
  setTaskSection: (sectionId: number) => void;
}
const DivNumberTasks = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;
const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DivContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const DivButton = styled.div`
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin: 0 auto;
`;
const Button1 = styled.button`
  letter-spacing: 0.15px;
  color: #01b0e9;
  background: rgba(0, 0, 0, 0);
  font-size: 16px;
`;
const TextSection = styled.h2`
  box-sizing: border-box;
  display: inline;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DivIcons2 = styled.p`
  transform: ${({ rotateLocal }) => rotateLocal && "rotate(90deg)"};
  width: 10px;
  height: 15px;
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: "0",
      border: "1px solid rgba(41, 42, 52, 0.5)",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    ".Mui-expanded": {
      minWidth: "48px",
      border: "1px solid #36EA0",
    },
  },
  MuiAccordionrootNone: {
    "&>.MuiAccordionSummary-root:hover": {
      cursor: "default",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const ProjectAllSections: React.FC<Props> = ({
  projectSection,
  openAccordSelect,
  changeSectionName,
  priorities,
  createdTaskInfo,
  selectedTask,
  getOneProject,
  setTaskProject,
  setTaskSection
}) => {
  const [tasksState, setTasksState] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [idOfTaskToShow, setIdOfTaskToShow] = useState<number>(0);
  const [showSidePlate, setShowSidePlate] = useState<boolean>(true);
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const [nameSection, setNameSection] = useState<string>(projectSection.name);
  const [fetchDataZeroLength, setFetchZeroLength] = useState<boolean>(false); //для того чтобы убрать преждевременную надпись о том что постов нет
  const [fetchSectionAllTasksNumber, setFetchSectionAllTasksNumber] =
    useState<any>({
      tasks_count: null,
      tasks_in_work_count: null,
      completed_tasks_count: null,
      overdue_tasks_count: null,
    });
  const [selectedTaskPreviousState, setSelectedTaskPreviousState] = useState<any>();

  const dispatch = useDispatch();

  const navigate = useHistory();
  const classes = useStyles();

  const btnProject = () => {
    navigate.push(`/projects/${projectSection.project_id}`);
  };
  
  const convertDates = obj => {
    obj.begin = obj?.begin ? formatStringDate(obj.begin) : '';
    obj.end = obj?.end ? formatStringDate(obj.end) : '';
    
    return obj;
  };
  
  const fetchTaskStatistics = () => {
    fetchData
      .get(`/api/v1/projectsections/${projectSection.id}/task-statistics`)
      .then(res => {
        setFetchSectionAllTasksNumber(res);
      })
      .catch(() => console.error(errorMessagesConstants.GET_TASK_STATISTICS));
  };
  
  const fetchTasks = expanded => {
    fetchData
      .get(`/api/v1/tasks?project_section_id=${projectSection.id}&limit=3`)
      .then((res) => {
        if (res && res.length > 0) {
          res.forEach((item, index) => {
            res[index] = convertDates(item);
          });

          setTasksState(res);
        }

        if (!res.length) {
          setFetchZeroLength(false);
        } else if (res.length) {
          setFetchZeroLength(true);
          setOpenAccord(expanded);
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    // Отлавливаем событие onDeleteTaskFromProject, которое отправляем в src/pages/Tasks/saga.tsx/deleteTask()
    projectsEventChannel.on('onDeleteTaskFromProject', id => {
      deleteTaskFromProject(id);
    });
  }, []);

  // Обновление состояние после создания новой задачи
  useEffect(() => {
    if(createdTaskInfo?.project_section_id === projectSection.id) {
      const newTask = convertDates(createdTaskInfo);
      setTasksState([newTask, ...tasksState]);

      fetchTaskStatistics();
    }
  }, [createdTaskInfo]);

  // Обновление одной задачи
  useEffect(() => {
    if(selectedTask) {
      if(!selectedTaskPreviousState) setSelectedTaskPreviousState(selectedTask);

      let idTaskForUpdate = null;
      let idSectionForUpdate = null;
      const {id} = selectedTask;

      const updatedTasks = tasksState?.map(el => {
        if(el.id === id) {
          idTaskForUpdate = el.id;
          idSectionForUpdate = el.project_section_id;

          return selectedTask;
        }
        
        return el;
      });
      
      // Перемещение задачи в другой раздел
      if(
        (idSectionForUpdate !== projectSection?.id) && 
        (selectedTask?.project_section_id === projectSection?.id)) 
      {
        setTasksState((state) => {
          return [
            selectedTask,
            ...state
          ]
        });

        updateTaskStatistics(selectedTask, 'add');
      }
      // Обновление задачи, без перемещения
      else {
        if(id === idTaskForUpdate) {
          setTasksState(updatedTasks);
          fetchTaskStatistics();
        }
      }

      // Удаление задачи из раздела
      if(
        (selectedTask?.project_section_id !== projectSection?.id)) {
        setTasksState((state) => {
          return[...state.filter(task => task.id !== selectedTask.id)];
        });
        
        updateTaskStatistics(selectedTask, 'delete');

        if(openAccord && tasksState.length <= 1) fetchTasks(true);
      }
    }
    
  }, [selectedTask]);
  
  const checkOverdueStatus = task => {
    const dateMaster = new DateMaster(task.end);

    return task.status_id === 10 &&
      new Date(
        dateMaster?.year,
        dateMaster?.month,
        dateMaster?.day,
        dateMaster?.hours,
        dateMaster?.minutes
      ).getTime() <= new Date(Date.now()).getTime()
        ? 14
        : task.status_id;
  };
  
  const updateTaskStatistics = (updatedTask, action) => {
    if(selectedTaskPreviousState?.project_section_id === projectSection.id) {
      if(action === 'delete') {
        setFetchSectionAllTasksNumber(state => {
          return {
            ...state,
            tasks_count: state.tasks_count ? state.tasks_count - 1 : state.tasks_count,
            tasks_in_work_count: (selectedTaskPreviousState.status_id === taskStatuses.IN_WORK) 
              ? state.tasks_in_work_count 
                ? state.tasks_in_work_count - 1 
                : state.tasks_in_work_count
              : state.tasks_in_work_count,
            completed_tasks_count: (selectedTaskPreviousState.status_id === taskStatuses.COMPLETED)
              ? state.completed_tasks_count
                ? state.completed_tasks_count - 1
                : state.completed_tasks_count
              : state.completed_tasks_count,
            overdue_tasks_count: (checkOverdueStatus(selectedTaskPreviousState) === taskStatuses.OVERDUE)
              ? state.overdue_tasks_count
                ? state.overdue_tasks_count - 1
                : state.overdue_tasks_count
              : state.overdue_tasks_count
          };
        });
      }
    }

    if(updatedTask?.project_section_id === projectSection.id) {
      if(action === 'add') {
        setFetchSectionAllTasksNumber(state => {
          return {
            ...state,
            tasks_count: state.tasks_count + 1,
            tasks_in_work_count: (selectedTaskPreviousState.status_id === taskStatuses.IN_WORK) 
              ? state.tasks_in_work_count + 1 
              : state.tasks_in_work_count,
            completed_tasks_count: (selectedTaskPreviousState.status_id === taskStatuses.COMPLETED) 
              ? state.completed_tasks_count + 1 
              : state.completed_tasks_count,
            overdue_tasks_count: (checkOverdueStatus(updatedTask) === taskStatuses.OVERDUE)
              ? state.overdue_tasks_count + 1 
              : state.overdue_tasks_count
          };
        });
      }
    }
    
    setSelectedTaskPreviousState(selectedTask);
  };
  
  const deleteTaskFromProject = ({id}) => {
    if(navigate.location.pathname.includes(pagesName.PROJECTS_PAGE)) {
      setTasksState((state) => {
        if(state.length) {
          const tasksFiltered = state.filter(task => task.id !== id);
          
          fetchTaskStatistics();
          
          if(!tasksFiltered.length) {
            if(projectSection?.project_id) {
              getOneProject(projectSection.project_id);
              fetchTasks(true);
            }
          }
          
          return tasksFiltered;
        }

        return state
      });
    }
  };

  useEffect(() => {
    // Обновление статистики по секции
    if (openAccordSelect) {
      fetchTaskStatistics();
    }
  }, [openAccordSelect]);

  const handleClick = (expanded) => {
    if (expanded && fetchSectionAllTasksNumber.tasks_count) {
      fetchTasks(expanded);
    } else {
      setOpenAccord(false);
      setFetchZeroLength(false);
    }
  };

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
    
    if(projectSection?.project_id) {
      setTaskProject(projectSection.project_id);
      setTaskSection(projectSection.id);
    }
    
    dispatch(setTaskPriority(priority.id));
    dispatch(setShownTaskInfoPlate(true));
    
    handleCloseMenu(e);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <>
      {openWindow && (
        <EditSection
          openAddWindow={openWindow}
          setOpenAddWindow={setOpenWindow}
          addHandler={changeSectionName}
          valueQuestions={nameSection}
          setValueQuestions={setNameSection}
          id={projectSection.id}
        />
      )}
      <Accordion
        className='project-all-sections'
        expanded={openAccord}
        onChange={(e, expanded) => {
          handleClick(expanded);
        }}
        classes={
          fetchSectionAllTasksNumber.tasks_count
            ? {
                root: classes.MuiAccordionroot,
              }
            : {
                root: classes.MuiAccordionrootNone,
              }
        }
        style={{
          width: "100%",
          boxShadow: "none",
          marginBottom: "10px",
          borderRadius: "4px 4px 0px 0px",
        }}
      >
        <AccordionSummary
          disableTouchRipple={!tasksState.length}
          classes={{
            root: classes.MuiAccordionSym,
          }}
          style={{
            height: "46px",
            overflow: "hidden",
            border: "1px solid #dbdbdb",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 12px",
            minHeight: "46px",
          }}
        >
          <DivLeft>
            <div style={{ paddingLeft: "15px", width: "70px" }}>
              {!!fetchSectionAllTasksNumber.tasks_count ? (
                <DivIcons2 rotateLocal={openAccord ? openAccord : undefined}>
                  <AccordionIcon2 />
                </DivIcons2>
              ) : (
                <DivIcons2 style={{ opacity: "0" }}></DivIcons2>
              )}
            </div>
            <TextSection>{projectSection?.name}</TextSection>
          </DivLeft>
          <DivRight>
            <div
              style={{
                paddingLeft: "20px",
                minWidth: "170px",
              }}
            >
              <div>
                {fetchSectionAllTasksNumber?.tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </div>
            </div>
            <DivNumberTasks>
              <AccordionIcon4 />
              <div style={{ marginLeft: "6px", color: "#436ea0" }}>
                {fetchSectionAllTasksNumber?.tasks_in_work_count !== null &&
                fetchSectionAllTasksNumber?.overdue_tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.tasks_in_work_count -
                  fetchSectionAllTasksNumber?.overdue_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </div>
            </DivNumberTasks>
            <DivNumberTasks>
              <AccordionIcon5 />
              <div style={{ marginLeft: "6px", color: "#44a047" }}>
                {fetchSectionAllTasksNumber?.completed_tasks_count !== null ? (
                  fetchSectionAllTasksNumber?.completed_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </div>
            </DivNumberTasks>
            <DivNumberTasks>
              <AccordionIcon6 />
              <div style={{ marginLeft: "6px", color: "#f44336" }}>
                {fetchSectionAllTasksNumber?.overdue_tasks_count !== null ? (
                  fetchSectionAllTasksNumber.overdue_tasks_count
                ) : (
                  <CommonCirclePreloader isLoading={true} />
                )}
              </div>
            </DivNumberTasks>
            <Button onClick={handleClickMenu} style={{ minWidth: "53px" }}>
              <AccordionIcon7 />
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              style={{ marginTop: "45px" }}
            >
              <MenuItem
                onClick={(e) => {
                  handleCloseMenu(e);
                  setOpenWindow(!openWindow);
                }}
              >
                Редактировать
              </MenuItem>
              <MenuItem onClick={(e) => clickHandler(e)}>
                Создать задачу
              </MenuItem>
            </Menu>
          </DivRight>
        </AccordionSummary>
        {fetchDataZeroLength && !!tasksState.length && (
          <AccordionDetails
            style={{
              backgroundColor: "#f4f4f4",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!!tasksState.length ? (
              <>
                <ProjectTableMiniHead />
                <DivContainerDetails>
                  {tasksState?.slice(0, 3).map((item) => (
                    <ProjectSectionTasks
                      idOfTaskToShow={idOfTaskToShow}
                      setIdOfTaskToShow={setIdOfTaskToShow}
                      setShowSidePlate={setShowSidePlate}
                      task={item}
                      key={item.id}
                    />
                  ))}

                  {fetchSectionAllTasksNumber?.tasks_count > 3 && (
                    <DivButton>
                      <Button1 onClick={btnProject}>Все задачи</Button1>
                      <ArrowForwardIosIcon
                        style={{
                          color: "#01b0e9",
                          width: "17px",
                          paddingTop: "2px",
                        }}
                      />
                    </DivButton>
                  )}
                </DivContainerDetails>
              </>
            ) : null}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    current_user: state.commonInfo.current_user,
    currentUserInfo: state.commonInfo.currentUserInfo,
    sections: state.newProject.sections,
    priorities: state.commonInfo.priorities,
    createdTaskInfo: state.commonInfo.createdTaskInfo,
    selectedTask: state.taskInfoPlate.selectedTask
  };
};

const mapDispatchToProps = {
  changeSectionName,
  updateTask,
  getOneProject,
  setTaskProject,
  setTaskSection
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAllSections);
