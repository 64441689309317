// Тут разрабатывается компонент "Календарь"
import React, { FC, useState, useEffect, useRef } from "react";
import { SchedulerStatic } from "dhtmlx-scheduler";
import "./style.sass";
import Scheduler from "./components/Scheduler";
import TasksSection from "./components/TasksSection";
import { Task } from "redux-saga";
import { useForm } from "react-hook-form";
import SidebarForCalnendar from "./components/Sidebar";
import styled from "styled-components";
import { fetchData } from "../../utils/fetchData";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { State } from "../../rootReducer";
import { adjustDates, COMPLETED_STATUS, IN_WORK_STATUS, MADE_STATUS, OVERDUE_STATUS } from "./helpers";
import axios from "axios";
import { cookieMaster } from "../../utils/CookieMaster";
import { useParams } from "react-router-dom";
import { dayEventChannel } from "../../utils/eventbus/channels/dayChannel";
import { Status } from "../../common/types";

declare global {
  interface Window {
    scheduler: SchedulerStatic;
  }
}

export interface IEvent {
  start_date: string;
  end_date: string;
  text: string;
  id: number | string;
  holder?: string;
}

interface ICalendar {
  events: Array<IEvent>;
  selectedTask: any;
}

const scheduler = window.scheduler;

const CalendarAddPanelMain = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isVisible }) => (isVisible ? "0" : "-100%")};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 25px 25px 25px 53px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 750px;
  height: calc(100vh - 76px);
`;

const Calendar: FC<ICalendar> = ({ selectedTask }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [pageNum, setPageNum] = useState<number>(1);
  const [updateTask, setUpdateTask] = useState<boolean>(false);
  const [isShowAddObject, setIsShowAddObject] = useState<boolean>(false);
  const [view, setView] = useState<"day" | "week">("week");
  const [currentUser, setCurrentUser] = useState<number | string>();
  const [cellData, setCellData] = useState<any>(null);

  const [datas, setDatas] = useState<Array<IEvent | Task>>([]);
  const [isTaskView, setIsTaskView] = useState(false);
  const { control, watch } = useForm();
  const watchFields = watch(["tasks", "meeting", "connection"]);

  const [data, setData] = useState<any>([]);
  const [dataEvent, setDataEvent] = useState<any>([]);
  const [users, setUsers] = useState<any>();

  const schedulerContainerRef = useRef<HTMLDivElement>(null);
  let token = cookieMaster.getCookie("access_token");

  const params = useParams<{ id: string }>();
  const [updatedTask, setUpdatedTask] = useState<any>();

  const taskSectionRef: any = useRef(null);
  // Функция, которая возвращает дату с первым числом месяца, с учетом часовых поясов
  function formatFirstDayOfMonthWithTimezone(date) {
    return moment(date)
      .subtract(1, "month")
      .startOf("month")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  }

  // Функция, которая возвращает дату с последним числом месяца, с учетом часовых поясов
  function formatLastDayOfMonthWithTimezone(date) {
    return moment(date)
      .add(2, "month")
      .startOf("month")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
  }

  const AddEvent = async () => {
    fetchData
      .get(
        `/api/v1/day/${
          params.id
        }?start_date=${formatFirstDayOfMonthWithTimezone(
          selectedDate
        )}&end_date=${formatLastDayOfMonthWithTimezone(
          selectedDate
        )}&includeTypes[]=TASK_TYPE&includeTypes[]=MEETING_TYPE&includeTypes[]=EVENT_TASK_TYPE&includeTypes[]=EVENT_TYPE`
      )
      .then((task) => {
        setData(task);
        setDatas(task);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // Отлавливаем событие onUpdateDayTask, которое отправляем в common/saga/updateTask()
    dayEventChannel.on('onUpdateDayTask', (task) => {
      setUpdatedTask(task);
    });
    
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_URL}/api/v1/users?page=1&order=id&ordertype=desc&limit=500`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setUsers(res.data.data);
      });
  }, []);

  useEffect(() => {
    // Обновление одной задачи
    if(updatedTask) {
      data.forEach((el) => {
        if(updatedTask?.id === el.meta.task_id) {
          el.meta.task_status_id = updatedTask?.status_id;
          
          el.meta.is_done = updatedTask?.status_id === MADE_STATUS;
          el.meta.is_completed = updatedTask?.status_id === COMPLETED_STATUS;
        }
      });

      setData(data);
      setDatas(data);
    }
  }, [updatedTask]);
  
  useEffect(() => {
    AddEvent();
  }, [selectedDate.getMonth(), currentUser]);

  useEffect(() => {
    if (data) {
      const filtred = data.map((el) => {
        const startAt =
          el.entity.type === "TASK_TYPE"
            ? new Date(el.end_date).setMinutes(
                new Date(el.end_date).getMinutes() - 15
              )
            : new Date(el.start_date);
        const endAt = new Date(el.end_date);
        if (el.entity.type === "EVENT_TYPE") {
          el.color = el.meta.entityData.color;
        }
        return {
          ...el,
          ...adjustDates(startAt, endAt),
          text: el.text,
        };
      });

      setDataEvent(filtred);
    }
  }, [data, datas]);

  useEffect(() => {
    if (schedulerContainerRef.current) {
      scheduler.init(schedulerContainerRef.current, new Date(), "week");
    }

    return () => {
      if (schedulerContainerRef.current) {
        scheduler.init(schedulerContainerRef.current);
      }
    };
  }, []);
  const addEventHandler = (
    event,
    ans: any = false,
    evenType = "EVENT_TYPE"
  ) => {
    if (ans) {
      if (evenType == "EVENT_TYPE") {
        const entityData = {
          entityData: { color: event.color, description: event.description },
        };
        scheduler.getEvent(ans.id).text = event.name;
        scheduler.getEvent(ans.id).color = event.color;
        scheduler.getEvent(ans.id).meta = entityData;
        scheduler.getEvent(ans.id).start_date = new Date(event.start_at);
        scheduler.getEvent(ans.id).end_date = new Date(event.deadline_at);
        scheduler.getEvent(ans.id).entity = { type: evenType, id: event.id };
        scheduler.updateEvent(ans.id);
      }
      if (evenType == "MEETING_TYPE") {
        const end = scheduler.date.add(event.start_at, 60, "minute");

        scheduler.getEvent(ans.id).text = event.name;
        scheduler.getEvent(ans.id).meta = {
          initiator_id: event.initiator_id,
          chairman: {
            user_id: event.chairman,
          },
          secretary: { user_id: event.secretary },
        };

        scheduler.getEvent(ans.id).start_date = new Date(event.start_at);
        scheduler.getEvent(ans.id).end_date = new Date(end);
        scheduler.getEvent(ans.id).entity = { type: evenType, id: event.id };
        scheduler.updateEvent(ans.id);
      }
      if (evenType == "EVENT_TASK_TYPE") {
        const correctStartAt = moment(
          event.start_at,
          "DD-MM-YYYY HH:mm:ss"
        ).toDate();
        const correctEndAt = moment(
          event.end_at,
          "DD-MM-YYYY HH:mm:ss"
        ).toDate();
        const end = scheduler.date.add(correctStartAt, 60, "minute");
        const deadlineEnd = scheduler.date.add(correctEndAt, -15, "minute");
        scheduler.getEvent(ans.id).text = event?.name;
        scheduler.getEvent(ans.id).start_date = correctStartAt;
        scheduler.getEvent(ans.id).end_date = end;
        scheduler.getEvent(ans.id).entity = {
          type: evenType,
          id: event.event_task_id,
        };
        scheduler.getEvent(ans.id).meta = {
          task_priority_id: event.priority_id,
          task_status_id: event.status_id,
          task_id: event.task_id,
          is_cyclic: event.is_cylcic,
        };

        scheduler.updateEvent(ans.id);
        const newEventId = scheduler.addEvent({
          start_date: deadlineEnd,
          end_date: correctEndAt,
          text: event.name,

          entity: { type: "TASK_TYPE", id: event.task_id },
          meta: {
            task_priority_id: event.priority_id,
            task_status_id: event.status_id,
            task_id: event.task_id,
            is_cyclic: event.is_cylcic,
          },
        });
        const arr = [
          scheduler.getEvent(ans.id),
          scheduler.getEvent(newEventId),
        ];
        setDatas((prev) => {
          return [...arr];
        });
        setData((prev) => {
          return [...prev, ...arr];
        });
      }
    } else {
      if (evenType == "EVENT_TYPE") {
        const entityData = {
          entityData: { color: event.color, description: event.description },
        };
        
        scheduler.addEvent({
          color: event.color,
          start_date: new Date(event.start_at),
          meta: entityData,
          end_date: new Date(event.deadline_at),
          text: event.name,
          entity: { type: evenType, id: event.id },
        });
        
        scheduler.updateView();
      }
      
      if (evenType == "MEETING_TYPE") {
        const end = scheduler.date.add(event.start_at, 60, "minute");
        const newEventId = scheduler.addEvent({
          start_date: new Date(event.start_at),
          end_date: end,
          text: event.name,
          entity: { type: evenType, id: event.id },
          meta: {
            initiator_id: event.initiator_id,
            chairman: {
              user_id: event.chairman,
            },
            secretary: { user_id: event.secretary },
          },
        });
        scheduler.updateEvent(newEventId);
        scheduler.updateView();
      }
      if (evenType == "EVENT_TASK_TYPE") {
        const correctStartAt = moment(
          event.start_at,
          "DD-MM-YYYY HH:mm:ss"
        ).toDate();
        const end = scheduler.date.add(correctStartAt, 60, "minute");
        const newTaskEvent = scheduler.addEvent({
          start_date: correctStartAt,
          end_date: end,
          text: event.name,
          entity: { type: "EVENT_TASK_TYPE", id: event.event_task_id },
          meta: {
            task_priority_id: event.priority_id,
            task_status_id: event.status_id,
            task_id: event.task_id,
            is_cyclic: event.is_cylcic,
          },
        });
        const correctEndAt = moment(
          event.end_at,
          "DD-MM-YYYY HH:mm:ss"
        ).toDate();
        const deadlineEnd = scheduler.date.add(correctEndAt, -15, "minute");
        const newDeadLineEvent = scheduler.addEvent({
          start_date: deadlineEnd,
          end_date: correctEndAt,
          text: event.name,
          entity: { type: "TASK_TYPE", id: event.task_id },
          meta: {
            task_priority_id: event.priority_id,
            task_status_id: event.status_id,
            task_id: event.task_id,
            is_cyclic: event.is_cylcic,
          },
        });
        scheduler.updateView();
        const arr = [
          scheduler.getEvent(newTaskEvent),
          scheduler.getEvent(newDeadLineEvent),
        ];
        setDatas((prev) => {
          return [...prev, ...arr];
        });
        setData((prev) => {
          return [...prev, ...arr];
        });
      }
    }
  };
  function deleteSelectedEvent(id) {
    scheduler.deleteEvent(id);
    setIsShowAddObject(false);
    setCellData(null);
  }
  const { statuses } = useSelector((state: State) => state.commonInfo);

  const currentStatus = (stat, date): Status | null => {
    let status = statuses?.find((status) => {
      if (
        stat === IN_WORK_STATUS &&
        new Date(date).getTime() <= new Date(Date.now()).getTime()
      ) {
        return status.id === OVERDUE_STATUS;
      } else {
        return status.id === stat;
      }
    });
    return status ? status : null;
  };

  useEffect(() => {
    if (isShowAddObject || selectedTask !== null) {
      setIsTaskView(false);
    }
  }, [isShowAddObject, selectedTask]);

  const [highlightedItem, setHighlightedItem] = useState(null);

  return (
    <div style={{ display: "flex" }}>
      <div
        className={`scheduler-container ${
          isShowAddObject || selectedTask !== null ? "disabled" : ""
        }`}
      >
        <Scheduler
          events={dataEvent}
          data={datas}
          refA={schedulerContainerRef}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          view={view}
          setView={setView}
          setIsTaskView={setIsTaskView}
          setIsShowAddObject={setIsShowAddObject}
          setCellData={setCellData}
          addEventHandler={addEventHandler}
          currentStatus={currentStatus}
          statuses={statuses}
          setCurrentUser={setCurrentUser}
          highlightedItem={highlightedItem}
        />
      </div>
      {isTaskView && (
        <div ref={taskSectionRef}>
          <TasksSection
            pageNum={pageNum}
            setPageNum={setPageNum}
            setIsTaskView={setIsTaskView}
            currentStatus={currentStatus}
            setHighlightedItem={setHighlightedItem}
          />
        </div>
      )}
      <CalendarAddPanelMain isVisible={isShowAddObject}>
        {isShowAddObject && (
          <SidebarForCalnendar
            isShowAddObject={isShowAddObject}
            setIsShowAddObject={setIsShowAddObject}
            cellData={cellData}
            setCellData={setCellData}
            addEventHandler={addEventHandler}
            deleteSelectedEvent={deleteSelectedEvent}
            users={users}
          />
        )}
      </CalendarAddPanelMain>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.cellTaskData,
  };
};

const mapDispatchToProps = {};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
