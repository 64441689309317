import React, { useContext, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StatusContext } from "../../context/status";
import "./CreateTaskForm.scss";

import TextareaAutosize from "react-textarea-autosize";
import TaskPriority from "./components/taskPriority/TaskPriority";
import TaskCreateLoad from "./components/TaskCreateLoad";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import MultySelect from "./components/MultySelect/MultySelect";
import {formatToStringUTC} from "../../../../../common/format";

const CreateTaskForm = ({
  validDateCreateTask,
  setValidDateCreateTask,
  setValidDateEndTimeLastTask,
  validDateEndTimeLastTask,
}) => {
  const {
    bearer,
    company_id,
    createTaskForm,
    setCreateTaskForm,
    createTaskFormDate,
    setCreateTaskFormDate,
    depsTask,
    setDepsTask,
    tasks,
    createTaskSampleFormStatus,
    sampleArr,
    idSample,
    arrTasksSample,
    setArrTasksSample,
    valueTaskSample,
    setValueTaskSample,
    nowTask,
    setTasksArr,
    tasksArr,
    taskSample,
    setTaskSample,
    setStatusCreateTask,
    setCreateTaskStatus,
    setCreateBpSampleStatus,
    setLengthArrTasks,
    setCreateBpStatus,
    users,
    businessProcessCurrentGlobal,
    descript,
    apiBp,
    setDescript,
    projects,
    projectSections,
    setProjectSections,
    setDateValid,
    selectedBp,
    createTaskStatus,
    addTasksMenu,
    userId,
    dateValid,
    idExecutor,
    setIdExecutor,
  } = useContext(StatusContext);

  const author = users.find((user) => user.id === userId);
  const etApi = process.env.REACT_APP_PUBLIC_API;
  
  const [priorityId, setPriorityId] = useState(createTaskForm.data.priority_id);
  const [taskLoadValue, setTaskLoadValue] = useState(0.1);
  const [workLoadValue, setWorkLoadValue] = useState(0.1);
  const [typeOfValue, setTypeOfValue] = useState("task_load");

  const [endTimeLastTaskData, setEndTimeLastTaskData] = useState(""); //дата прошлой задачи   '2005.05.05'
  const [endTimeLastTaskTime, setEndTimeLastTaskTime] = useState(""); //время прошлой задачи  '05:05'
  const [openSelectExecutor, setOpenSelectExecutor] = useState(false);

  //Автоподставление дат при добовлении задачи в бп
  let dateActual = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  let day;
  let month;
  let year;
  let dataString;

  const dateToday = () => {
    day =
      new Date().getDate() > 9
        ? new Date().getDate()
        : "0" + new Date().getDate();
    month =
      new Date().getMonth() > 9
        ? Number(new Date().getMonth()) + 1
        : "0" + (Number(new Date().getMonth()) + 1);

    year = new Date().getFullYear();

    return (dataString = `${year}-${month}-${day}`);
  };

  const dateNext = () => {
    day =
      tomorrow.getDate() > 9 ? tomorrow.getDate() : "0" + tomorrow.getDate();
    month =
      new Date().getMonth() > 9
        ? Number(new Date().getMonth()) + 1
        : "0" + (Number(new Date().getMonth()) + 1);

    year = new Date().getFullYear();

    return (dataString = `${year}-${month}-${day}`);
  };

  useEffect(() => {
    setCreateTaskFormDate({
      ...createTaskFormDate,
      beginDate: dateToday(),
      beginTime: dateActual,
      endDate: dateNext(),
      endTime: dateActual,
    });
  }, []);
  
  useEffect(() => {
    let { beginDate, beginTime, endDate, endTime } = createTaskFormDate;
    //проверка валидности даты
    if (!!beginDate && !!beginTime && !!endDate && !!endTime) {
      if (endDate > beginDate) {
        setValidDateCreateTask(true);
      } else if (endDate == beginDate) {
        if (endTime > beginTime) {
          setValidDateCreateTask(true);
        } else {
          setValidDateCreateTask(false);
        }
      } else if (endDate < beginDate) {
        setValidDateCreateTask(false);
      }
    } else {
      setValidDateCreateTask(false);
    }
  }, [
    createTaskStatus,
    addTasksMenu,
    createTaskFormDate,
    validDateEndTimeLastTask,
    validDateCreateTask,
    dateActual,
    day,
    month,
    year,
    dataString,
  ]);

  useEffect(() => {
    let { beginDate, beginTime } = createTaskFormDate;
    // валидация: время последней созданной задачи должно быть меньше чем у того у кторого ты создаешь
    if (beginDate > endTimeLastTaskData) {
      setValidDateEndTimeLastTask(true);
    } else if (beginDate == endTimeLastTaskData) {
      if (beginTime > endTimeLastTaskTime) {
        setValidDateEndTimeLastTask(true);
      } else {
        setValidDateEndTimeLastTask(false);
      }
    } else if (beginDate < endTimeLastTaskData) {
      setValidDateEndTimeLastTask(false);
    }
  }, [
    createTaskStatus,
    addTasksMenu,
    createTaskFormDate,
    validDateEndTimeLastTask,
    validDateCreateTask,
    dateActual,
    day,
    month,
    year,
    dataString,
  ]);

  useEffect(() => {
    if (tasks.length > 0) {
      let config = {
        method: "get",
        url: `${apiBp}/task/${tasks.slice(-1).join(" ")}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      axios(config).then((res) => {
        setEndTimeLastTaskData(
          //вытаскиваем дату
          new Date(res.data.end)
            .toLocaleDateString("ru-RU")
            .split(".")
            .reverse()
            .join("-")
        );
        setEndTimeLastTaskTime(
          //вытаскиваем время
          new Date(res.data.end).toLocaleTimeString("ru-RU").slice(0, 5) //при получении даты я убрал секунды так как они мешают
        );
        setValidDateEndTimeLastTask(false);
        setValidDateCreateTask(false);
      });
    }
  }, [tasks, createTaskStatus, addTasksMenu]);

  useEffect(() => {
    if (createTaskForm.data.project_id) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/projects/${createTaskForm.data.project_id}`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((res) => {
          setProjectSections(res.data.data.sections);
        });
    }
  }, [createTaskForm, createTaskForm.data.project_id]);

  useEffect(() => {
    if (
      typeOfValue &&
      createTaskForm.executor_id > 0 &&
      createTaskForm.begin &&
      createTaskForm.end
    ) {
      switch (typeOfValue) {
        case "task_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+createTaskForm.executor_id}&begin=${
              formatToStringUTC(createTaskForm.begin)
            }&end=${formatToStringUTC(createTaskForm.end)}&task_load=${+taskLoadValue}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config).then((res) => {
            setWorkLoadValue((prev) => +res.data.work_load);
          });

          break;
        }
        case "work_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+createTaskForm.executor_id}&begin=${
              formatToStringUTC(createTaskForm.begin)
            }&end=${formatToStringUTC(createTaskForm.end)}&work_load=${+workLoadValue}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config).then((res) => {
            setTaskLoadValue((prev) => +res.data.task_load);
          });

          break;
        }
        default:
          break;
      }
    }
  }, [typeOfValue, taskLoadValue, workLoadValue]);

  useEffect(() => {
    setCreateTaskForm({
      ...createTaskForm,
      data: {
        ...createTaskForm.data,
        priority_id: priorityId,
      },
    });
  }, [priorityId]);

  useEffect(() => {
    typeOfValue === "task_load"
      ? setCreateTaskForm({
          ...createTaskForm,
          data: {
            ...createTaskForm.data,
            task_load: +taskLoadValue,
          },
        })
      : setCreateTaskForm({
          ...createTaskForm,
          data: {
            ...createTaskForm.data,
            work_load: +workLoadValue,
          },
        });
  }, [typeOfValue, taskLoadValue, workLoadValue]);

  useEffect(() => {
    if (
      !!createTaskFormDate.endDate &&
      !!createTaskFormDate.endTime &&
      !!createTaskFormDate.beginDate &&
      !!createTaskFormDate.beginTime
    ) {
      setCreateTaskForm({
        ...createTaskForm,
        end:
          createTaskFormDate.endDate + " " + createTaskFormDate.endTime + ":00",
        begin:
          createTaskFormDate.beginDate +
          " " +
          createTaskFormDate.beginTime +
          ":00",
      });
    }
  }, [createTaskFormDate, createTaskForm.name]);

  useEffect(() => {
    if (createTaskSampleFormStatus) {
      let bp = sampleArr.filter((el) => el.id === parseInt(idSample));

      if (bp[0].tasksId) {
        setArrTasksSample([...bp[0].tasksId.split("|")]);
      }

      if (bp[0].businessProcessId) {
        if (bp[0].businessProcessId.tasks) {
          let arrMas = [];
          for (let i in bp[0].businessProcessId.tasks) {
            arrMas.push(bp[0].businessProcessId.tasks[i].id);
          }
          setArrTasksSample([...arrMas]);
        }
      }
    }
  }, [createTaskSampleFormStatus]);

  useEffect(() => {
    if (arrTasksSample.length >= 1) {
      Promise.all(
        arrTasksSample.map(async (el) => {
          return axios
            .get(`${process.env.REACT_APP_PUBLIC_API}/tasks/${el}`, {
              headers: {
                Authorization: bearer,
                "company-id": company_id,
              },
            })
            .then((result) => {
              return result.data.data;
            })
            .catch((e) => {
              console.log(e);
            });
        })
      )
        .then((r) => setValueTaskSample(r))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [arrTasksSample]); 

  useEffect(() => {
    if (tasksArr.length > 0) {
      if (tasksArr.length === valueTaskSample.length) {
        for (let i in tasksArr) {
          if (!!valueTaskSample[i].next_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].next_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { next_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!!valueTaskSample[i].parent_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].parent_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { parent_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!!valueTaskSample[i].prev_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].prev_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { prev_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        setStatusCreateTask(false);
        setCreateTaskStatus(false);
        setCreateBpStatus(true);
        setCreateBpSampleStatus(true);
      }
    }
  }, [tasksArr]);

  useEffect(() => {
    setTaskSample(createTaskForm);
    if (!!nowTask) {
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/tasks`,
          {
            author_id: nowTask.author_id,
            begin: nowTask.begin,
            cyclic_task_id: nowTask.cyclic_task_id,
            description: nowTask.description,
            end: nowTask.end,
            executor_id: taskSample.executor_id,
            name: taskSample.name,
            next_id: null,
            parent_id: null,
            prev_id: null,
            priority_id: nowTask.priority_id,
            project_id: createTaskForm.data.project_id,
            project_section_id: createTaskForm.data.project_section_id,
            provide_to: 1,
            status_id: nowTask.status_id,
            status_related_user_id: null,
            task_load: nowTask.task_load,
            task_load_sum: nowTask.task_load_sum,
            timesheets: nowTask.timesheets,
            work_load: nowTask.work_load,
            workflow_id: nowTask.workflow_id,
            id_bp: 130,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((res) => {
          setTaskSample({
            ...taskSample,
            name: "",
          });
          setTasksArr([...tasksArr, res.data.data.id]);
          setCreateTaskForm({ ...createTaskForm, name: "" });
        })
        .catch((err) => {
          setLengthArrTasks(tasksArr.length);
          setTaskSample({
            ...taskSample,
          });
        });
    }
  }, [nowTask]);

  //нижние переменные и useEffect для того чтобы юзер не мог для новой задачи бп не мог ввести дату раньше окончания предыдущей
  // let dayTaskDate;
  // let mounthTaskDate;
  // let yearTaskDate;
  let dayTargetDate;
  let mounthTargetDate;
  let yearTargetDate;
  let dateTarget;

  useEffect(() => {
    let dateBp = businessProcessCurrentGlobal?.tasks;
    let dateTarget = createTaskFormDate?.beginDate;
    let timeTarget = createTaskFormDate?.beginTime;
    if (dateBp || dateTarget) {
      // dayTaskDate = dateBp[dateBp.length - Target]?.end?.slice(8, 10);
      // mounthTaskDate = dateBp[dateBp.length - 1]?.end?.slice(5, 7);
      // yearTaskDate = dateBp[dateBp.length - 1]?.end?.slice(0, 4);
      dayTargetDate = dateTarget?.slice(8, 10);
      mounthTargetDate = dateTarget?.slice(5, 7);
      yearTargetDate = dateTarget?.slice(0, 4);
    }
    // dateTask = yearTaskDate + "-" + mounthTaskDate + "-" + dayTaskDate;
    dateTarget =
      yearTargetDate +
      "-" +
      mounthTargetDate +
      "-" +
      dayTargetDate +
      "T" +
      timeTarget;
    if (
      new Date(dateTarget) < new Date(businessProcessCurrentGlobal?.deadline)
    ) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  }, [
    businessProcessCurrentGlobal,
    createTaskFormDate,
    businessProcessCurrentGlobal.tasks,
  ]);

  const executors = useMemo(() => users, [users]);

  const selectUserHandler = (id) => {
    setCreateTaskForm({
      ...createTaskForm,
      executor_id: id,
    });
    setTaskSample({
      ...taskSample,
      executor_id: id,
    });
  };

  useEffect(() => {
    selectUserHandler(idExecutor);
  }, [idExecutor]);

  return (
    <form id="new-bp__form">
      <div>
        <label className="p__drop-content" htmlFor="input-name-Task">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
          />
          Наименование задачи*
        </label>
        <input
          className="input-form input-name-task__list"
          type="text"
          id="input-name-task"
          value={createTaskForm.name}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setCreateTaskForm({ ...createTaskForm, name: "" });
              setTaskSample({ ...taskSample, name: "" });
            } else {
              setCreateTaskForm({ ...createTaskForm, name: e.target.value });
              setTaskSample({ ...taskSample, name: e.target.value });
            }
          }}
        />
      </div>
      {!createTaskSampleFormStatus && (
        <div>
          <label className="p__drop-content">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/NewspaperClipping.svg`}
            />
            Описание
          </label>
          <TextareaAutosize
            minRows={2}
            className="input-form input-form-create-bp"
            placeholder="Введите описание"
            type="text"
            value={descript ?? ''}
            onChange={(e) => {
              setDescript((prev) => e.target.value);
              setCreateTaskForm({
                ...createTaskForm,
                description: e.target.value,
              });
            }}
          ></TextareaAutosize>
        </div>
      )}
      {/* <div>
        <TaskCheckList
          checkListArr={checkListArr}
          setCheckListArr={setCheckListArr}
        />
      </div> */}

      <div>
        <label className="p__drop-content" htmlFor="input-project">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
            alt="Article"
          />
          Проект*
        </label>
        <select
          id="input-project"
          className="input-form"
          value={
            createTaskForm.data.project_id ? createTaskForm.data.project_id : ""
          }
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_id: parseInt(+e.target.value),
              },
            });
            setTaskSample({
              ...taskSample,
              project_id: parseInt(+e.target.value),
            });
          }}
        >
          <option value={null}>Выбрать</option>
          {projects.map((el) => {
            return (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label className="p__drop-content" htmlFor="input-project-section">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
            alt="Article"
          />
          Раздел проекта*
        </label>
        <select
          id="input-project-section"
          className="input-form"
          disabled={!createTaskForm.data.project_id ? true : false}
          value={
            createTaskForm.data.project_section_id
              ? createTaskForm.data.project_section_id
              : ""
          }
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_section_id: parseInt(+e.target.value),
              },
            });
            setTaskSample({
              ...taskSample,
              project_section_id: parseInt(+e.target.value),
            });
          }}
        >
          <option value={null}>Выбрать</option>
          {projectSections.map((el) => {
            return (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        <div className="oneRowTwoElem">
          <div>
            <label
              className="p__drop-content"
              // htmlFor="businessTask__executor"
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
              />
              Автор
            </label>
            <input
              type="text"
              className="input-form blockOfFormAutor"
              id="businessTask__executor"
              value={author?.name + " " + author?.surname}
            />
          </div>

          <div>
            <label
              className="p__drop-content"
              // htmlFor="businessTask__executor"
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                alt="Article"
              />
              Приоритет
            </label>
            <div>
              <TaskPriority
                priorityId={priorityId}
                setPriorityId={setPriorityId}
                openMenuBp={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <label className="p__drop-content">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
          />
          Исполнитель
        </label>
        {/* <select
          className="input-form"
          id="businessTask__executor"
          value={taskSample.executor_id}
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              executor_id: parseInt(e.target.value),
            });
            setTaskSample({
              ...taskSample,
              executor_id: parseInt(e.target.value),
            });
          }}
        >
          {executors.map((executor) => (
            <option key={executor.id} value={executor.id}>
              {executor.name} {executor.surname}
            </option>
          ))}
        </select> */}

        <MultySelect
          executors={executors}
          selectUserHandler={selectUserHandler}
          setIdExecutor={setIdExecutor}
        />
      </div>

      {!createTaskSampleFormStatus ? (
        <>
          <div className="input__date">
            <label className="p__drop-content">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
              />
              Дата и время начала*
            </label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className="input-form"
                type="date"
                id="businessTask__date-start"
                htmlFor="businessTask__date-start"
                value={createTaskFormDate.beginDate}
                onChange={(e) => {
                  if (!!e.target.value) {
                    if (
                      e.target.value < createTaskFormDate.endDate ||
                      e.target.value === createTaskFormDate.endDate
                    ) {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        beginDate: e.target.value,
                      });
                    } else {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        beginDate: e.target.value,
                      });
                    }
                  }
                }}
              />

              <input
                className="input-form"
                type="time"
                value={createTaskFormDate.beginTime}
                onChange={(e) => {
                  if (!!e.target.value) {
                    if (
                      e.target.value < createTaskFormDate.endTime ||
                      e.target.value === createTaskFormDate.endTime
                    ) {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        beginTime: e.target.value,
                      });
                    } else {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        beginTime: e.target.value,
                      });
                    }
                  }
                }}
              />

              <div>
                {!validDateEndTimeLastTask && (
                  <Tooltip title="Проверьте пересечение дат и времени">
                    <WarningIcon htmlColor="red" />
                  </Tooltip>
                )}
              </div>
            </div>
            {!validDateCreateTask && (
              <div>
                <span style={{ color: "red", margin: "5px 43px" }}>
                  Ошибка в значении даты! Дата окончания задачи должа быть
                  позднее даты начала.
                </span>
              </div>
            )}
          </div>

          <div className="input__date">
            <label className="p__drop-content" htmlFor="businessTask__date-end">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
              />
              Дата и время окончания*
            </label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className="input-form"
                type="date"
                id="businessTask__date-end"
                value={createTaskFormDate.endDate}
                onChange={(e) => {
                  if (!!e.target.value) {
                    if (
                      e.target.value > createTaskFormDate.beginDate ||
                      e.target.value === createTaskFormDate.beginDate
                    ) {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        endDate: e.target.value,
                      });
                    } else {
                      setCreateTaskFormDate({
                        ...createTaskFormDate,
                        endDate: e.target.value,
                      });
                    }
                  }
                }}
              />
              <input
                className="input-form"
                type="time"
                value={createTaskFormDate.endTime}
                onChange={(e) => {
                  if (
                    e.target.value > createTaskFormDate.beginTime ||
                    e.target.value === createTaskFormDate.beginTime
                  ) {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      endTime: e.target.value,
                    });
                  } else {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      endTime: e.target.value,
                    });
                  }
                }}
              />
            </div>{" "}
            {!validDateCreateTask && (
              <div>
                <span style={{ color: "red", margin: "5px 43px" }}>
                  Ошибка в значении даты! Дата окончания задачи должна быть
                  позднее даты начала.
                </span>
              </div>
            )}
          </div>

          {/* <div>
            <label className="p__drop-content">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
              />
              Прикрепить файл
            </label>
            <TaskCreateAddFileButton
              allFiles={allFiles}
              setAllFiles={setAllFiles}
            />
          </div> */}

          <div>
            <TaskCreateLoad
              taskLoadValue={taskLoadValue}
              setTaskLoadValue={setTaskLoadValue}
              workLoadValue={workLoadValue}
              setWorkLoadValue={setWorkLoadValue}
              typeOfValue={typeOfValue}
              setTypeOfValue={setTypeOfValue}
            />
          </div>
          {/* {tasks.length >= 1 ? (
            <div className="form-task__dependencies">
              <div className="p__drop-content">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/ArrowUDownRight.svg`}
                />
                Зависимости
              </div>
              <div className="form-task__dependencies__btns">
                <button
                  className={
                    depsTask === "Родительская"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Родительская");
                    setCreateTaskForm({
                      ...createTaskForm,
                      prev_id: null,
                      parent_id: null,
                      next_id: null,
                    });
                  }}
                >
                  Родительская
                </button>
                <button
                  className={
                    depsTask === "Дочерняя"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Дочерняя");
                    setCreateTaskForm({
                      ...createTaskForm,
                      next_id: null,
                      prev_id: null,
                      parent_id: tasks[tasks.length - 1],
                    });
                  }}
                >
                  Дочерняя
                </button>
                <button
                  className={
                    depsTask === "Предыдущая"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Предыдущая");
                    setCreateTaskForm({
                      ...createTaskForm,
                      next_id: null,
                      parent_id: null,
                    });
                  }}
                >
                  Предыдущая
                </button>
                <button
                  className={
                    depsTask === "Следующая"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Следующая");
                    setCreateTaskForm({
                      ...createTaskForm,
                      prev_id: null,
                      parent_id: null,
                    });
                  }}
                >
                  Следующая
                </button>
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </>
      ) : (
        <div className="task-sample__deps">
          <div className="p__drop-content">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/ArrowUDownRight.svg`}
            />
            Зависимости
          </div>
          <div className="input-form">Зависимости</div>
        </div>
      )}
    </form>
  );
};

export default CreateTaskForm;
